import React from 'react';
import { Form, Button } from "react-bootstrap";
import AsyncSelect from '@/shared/components/atoms/AsyncSelect';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { connect } from 'react-redux';
import AddEMRCredentail from "./addEditEMR";
import PopupModal from '../modal/popupmodal';
import NewBrightreePhysician from '../physician/newbrightreephysician';
import { Animated } from "react-animated-css";
import CheckPermission from "../../General/CheckPermission";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import Loader from "react-loader-spinner";
import FacilityContact from './facilityContacts';
import PhysicianModal from '../physician/newPhysician';
import moment from "moment-timezone"

import { injectIntl } from "react-intl";

var emrModal, addEMR;

class EditFacility extends React.Component {

    constructor(props) {

        super(props);
        this.intl = this.props.intl;
        this.state = {
            IsLoading: false,
            openPhysicianModal: false,
            selectOrgOptions: [],
            physicianList: [],
            states: [],
            physicianList: [],
            physician: (props.Facility.fkPhysicianId > 0 ? { value: props.Facility.physicianBtId, label: props.Facility.physicianName } : null),
            physiciansInputValue: '',
            canEdit: props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_Administrator) == undefined ? true : false,
            manager: '',
            vicePresident: '',
            selectOrgChainOptions: [],
            selectFacilityTypeOptions: [],
            selectProductOptions: [],
            selectGroupPurchasingOptions: [],
            selectEhrvendorOption: [],
            selectWarehouseOption: [],
            selectClinicianOptions: [],
            formValidated: false,
            FaciltyID: { ...props.FacilityID },
            Facilty: { ...props.Facility }

        };
        this.facilityContact = null;
        this.emailError = false;
    }

    componentDidMount() {
        this.getAllLookupsValues()
        this.getAllActiveEMR()
        this.getAllState()
        if (this.props.Facility.fkClinicianId > 0) {
            this.getRegionalManagerAndVicePresident(this.props.Facility.fkClinicianId);
        }
    }
    async getAllLookupsValues() {

        var res = await Api.getRequest(Api.Uri_GetOrganization);
        const data = res.data.result
        let optionTemplate = data.map(v => (
            <option key={v.key} value={v.key}>{v.value}</option>
        ));
        this.setState({ selectOrgOptions: optionTemplate })

        var facilityType = await Common.getLookupOptions(Common.LookupsFK.Fk_FacilityTypeID)
        this.setState({ selectFacilityTypeOptions: facilityType })

        var productLine = await Common.getLookupOptions(Common.LookupsFK.Fk_ProductLineID)
        this.setState({ selectProductOptions: productLine })

        // var selectGroupPurchasingOptions = await Common.getLookupOptions(Common.LookupsFK.OrganizationID)
        // this.setState({ selectGroupPurchasingOptions: selectGroupPurchasingOptions })

        var selectEhrvendorOption = await Common.getLookupOptions(Common.LookupsFK.Fk_EHRVendorID)
        this.setState({ selectEhrvendorOption: selectEhrvendorOption })

        var selectWarehouseOption = await Common.getLookupOptions(Common.LookupsFK.Fk_WarehouseID)
        this.setState({ selectWarehouseOption: selectWarehouseOption })
        var clinicain = await Api.getRequestById(Api.Uri_GetUserByRoleID, Common.LookupsFK.Fk_RoleClinicianID);
        const clinicainData = clinicain.data.result
        let clinicainOption = clinicainData.map(v => (
            <option key={v.key} value={v.key}>{v.value}</option>
        ));
        this.setState({ selectClinicianOptions: clinicainOption })


    }

    async getChainByOrgID(e) {
        Common.updateInputValueInState(this.state.Facilty, e, this, this.state);
        var orgID = this.state.Facilty.fkOrgId;
        const res = await Api.getRequestById(Api.Uri_GetChainByOrgID, orgID);
        const data = res.data.result;
        let optionTemplate = data.map((v) => (
            <option key={v.key} value={v.key}>
                {v.value}
            </option>
        ));
        this.setState({ selectOrgChainOptions: optionTemplate });
    }

    // async getChainByOrgID(e) {

    //     // Common.updateInputSelectValueInState(this.state.Facilty, e,"FkOrgId", this, this.state)

    //     var orgID = e.target.value
    //     this.getChain(orgID);
    // }
    async getChain(orgID) {
        const res = await Api.getRequestById(Api.Uri_GetChainByOrgID, orgID)
        const data = res.data.result
        let optionTemplate = data.map(v => (
            <option key={v.key} value={v.key}>{v.value}</option>
        ));
        this.setState({ selectOrgChainOptions: optionTemplate })
    }

    onSubmit() {

        if (this.form.checkValidity() === false) {
            let _state = this.state;
            _state.formValidated = true;
            this.setState(_state);
        } else {
            const obj = this.state.Facilty;
            var postalCode =   Common.isValidZipCode(obj.address.zip)

            if(!postalCode && obj.address.zip!=""){
            //    Common.showSuccessAlert("The value is NOT a valid US Zip code", "warning", "Atenção!", 3000);
            Common.showSuccessAlert(this.intl.formatMessage({id: "EDIT_FACILITY.THE_VALUE_IS_NOT_A_VALID_ZIP_CODE"}), "warning", this.intl.formatMessage({id: "BUTTON.WARNING.TITLE"}), 3000);
               return false;
            }

            this.setState({ IsLoading: true });

            obj.centerSupplyPersonPhone =
                Common.replaceNullWithString(obj.centerSupplyPersonPhone) != ""
                    ? obj.centerSupplyPersonPhone.replace(/[^0-9]/g, "").substr(0, 10)
                    : null;
            obj.partBrecPersonPhone = Common.replaceNullWithString(obj.partBrecPersonPhone) != ""
                ? obj.partBrecPersonPhone.replace(/[^0-9]/g, "").substr(0, 10)
                : null;
            if (obj.address != null) {
                obj.address.phone1 = Common.replaceNullWithString(obj.address.phone1) != ""
                    ? obj.address.phone1.replace(/[^0-9]/g, "").substr(0, 10)
                    : null;

                obj.address.fax = Common.replaceNullWithString(obj.address.fax) != ""
                    ? obj.address.fax.replace(/[^0-9]/g, "").substr(0, 10)
                    : null;
            }
            
            if(this.state.Facilty.email) {
                if(!Common.isValidEmail(this.state.Facilty.email)) {
                    Common.showSuccessAlert("Please enter a valid email address", "warning", "Warning!", 3000);
                    this.setState({ IsLoading: false });
                    return false;
                }
            }

            Api.postRequest(Api.Uri_UpdateFacility, obj).then((res) => {

                if (res.data.success) {
                    this.setState({ IsLoading: false });
                    Common.showSuccessAlertAutoClose(
                        this.intl.formatMessage({id: "BUTTON.DATA_SAVED_SUCCESSFULLY.TITLE"}),
                        "success",
                        // "Success!",
                        this.intl.formatMessage({id: "BUTTON.SUCCESS.TITLE"}),
                        3000
                    );
                    this.props.onSaveAPI();
                } else {
                    Common.showSuccessAlert(res.data.message, "error", this.intl.formatMessage({id: "BUTTON.ERROR.TITLE"}), 3000);
                    this.setState({ IsLoading: false });
                }

            });
        }
        //Common.showSuccessAlert(res.data.message, "error", "Error! " + res.data.statusCode);
    }
    setNpiValue = (e) => {
        var npi = e.target.value;
        if (npi.length < 11) {

            Common.updateInputValueInState(this.state.Facilty, e, this, this.state)


        }

    }
    handleCloseAdd = () => { this.setState({ addBtPhysicianModal: false }) };
    handleShowAddBrightreePhysician = () => {

        this.setState({
            addBtPhysicianComponent: <NewBrightreePhysician close={this.handleCloseAdd}
                selectPhysician={this.selectPhysician} />
        })
        this.setState({ addBtPhysicianModal: true })
    };
    selectPhysician = (obj) => {
        // Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, obj.brightreeId, "fkPrimaryPhysicianId")
        // Common.withOutEventUpdateInputValueInState(this.state.Resident, this, this.state, (obj.lastName+" "+obj.firstName), "primaryPhysicianName")
        var _selectedPhysician = this.state.Facilty;
        _selectedPhysician.physicianViewModel = obj;
        _selectedPhysician.fkPhysicianId = null
        _selectedPhysician.physicianName = Common.replaceNullWithString(obj.lastName) + " " + Common.replaceNullWithString(obj.firstName);
        this.setState({ Facilty: _selectedPhysician })
        // Common.showSuccessAlert("Physician selected successfully", "success", "Success!", 3000);
        Common.showSuccessAlert(this.intl.formatMessage({id: "EDIT_FACILITY.PHYSICIAN_SELECTED_SUCCESSFULLY"}), "success", this.intl.formatMessage({id: "BUTTON.SUCCESS.TITLE"}), 3000);

    }
    onProductSpecialistChange = (e) => {
        Common.updateInputValueInState(
            this.state.Facilty,
            e,
            this,
            this.state
        )
        this.setState({ manager: '', vicePresident: '' });

        if (e.target.value > 1) {
            this.getRegionalManagerAndVicePresident(e.target.value);
        }

    }
    getRegionalManagerAndVicePresident = (userID) => {
        Api.getRequestQry(Api.Uri_GetRegionalManagerAndVicePresident, "?userID=" + userID)
            .then((response) => {

                if (response.data.result.length > 0) {
                    for (var i = 0; i < response.data.result.length; i++) {
                        var usr = response.data.result[i];
                        if (usr.managerType == "Manager") {
                            this.setState({ manager: usr.lastName + ' ' + usr.firstName });
                        } else if (usr.managerType == "VicePresident") {
                            this.setState({ vicePresident: usr.lastName + ' ' + usr.firstName });

                        }
                    }

                }

                // this.setState({ selectOrgOptions: optionTemplate });
            })

    }
    getAllActiveEMR() {

        Api.getRequestQry(Api.apiUrls.getAllEMR, "?isActive=" + 1)
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            this.setState({
                                selectGroupPurchasingOptions: res.data.result
                            });
                        }
                    }
                }
            })
            .catch((ex) => { });
    }
    setAllEMR(users) {
        return users.map((usr) => (
            <option key={usr.id} value={usr.id}>
                {usr.name}
            </option>
        ));
    }
    showEditEMR = async (id) => {

        var findEmr = this.state.selectGroupPurchasingOptions.find(x => x.id == this.state.Facilty.fkGroupPurchasingOrg)
        // await setEMR(obj);
        if (findEmr != undefined) {
            findEmr.isCredentailUpdate = true;
            this.setState({ emr: findEmr })
            emrModal.showModal();
        }

    };
    onEMRFormSubmit = () => {
        emrModal.hideModal();
        //getHeaderValue() ;
        // Common.showSuccessAlertAutoClose("EMR Saved successful", "success", "Success!", 3000);
        Common.showSuccessAlertAutoClose(this.intl.formatMessage({id : "EDIT_FACILITY.EMR_SAVED_SUCCESSFUL"}), "success", this.intl.formatMessage({id: "BUTTON.SUCCESS.TITLE"}), 3000);
        this.getAllActiveEMR();
    }
    SyncFacilityGroup = () => {
        Api.getRequest(Api.apiUrls.fetchFacilityGroups)
            .then((res) => {
                if (res.data.statusCode == 200) {
                    // Common.showSuccessAlert("Facility Group Fetch Successful", "success", "Success!", 3000);
                    Common.showSuccessAlert(this.props.formatMessage({id:"EDIT_FACILITY.FACILITY_GROUP_FETCH_SUCCESSFUL"}), "success", this.intl.formatMessage({id: "BUTTON.SUCCESS.TITLE"}), 3000);
                }
            })
            .catch((ex) => {
                Common.LogError(ex, "Facility", "NewFacility");

            });
    }
    getAllState() {
        Api.getRequest(Api.Uri_GetState)
            .then((res) => {
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            this.setState({ states: res.data.result });
                        }
                    }
                }
            })
            .catch((ex) => {
                Common.LogError(ex, "Facility", "NewFacility");

            });
    }
    setOptionsUI(options) {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    loadPhysicians = async (inputValue) => {
        try {
            if (inputValue.length > 1) {

                var { data } = await Api.getRequestQry(
                    Api.apiUrls.doctorsSearch, `?searchTerm=${inputValue}`
                );
                if (data.success) {
                    let orgList = [];
                    if (data.result.length) {
                        orgList = data.result;
                    }
                    this.setState({ physicianList: orgList })
                    // setOrganizations(orgList)
                    return orgList.map((e) => {
                        return {
                          label: `${e.lastName} ${e.firstName}`,
                          value: (e.brightreeId || e.physicianId)
                        }
                    });
                }
            }
            return [];
        } catch (error) {
            return [];
        }
    };

    setPhysicianInputValue = async (value) => {
        // this.setState({physiciansInputValue:value})
        this.setState({ physiciansInputValue: value });
    }
    
    setPhysician = (physician) => {
        this.setState({ physician: physician });

        if(physician) {
          const physicianComplete = this.state.physicianList.find(element => (element.brightreeId === physician.value || element.physicianId === physician.value));

          this.setState({ Facilty: { ...this.state.Facilty,
            physicianViewModel: physicianComplete,
            fkPhysicianId: null,
            physicianName: physician.label},
            physician: physician});
        } else {
          this.setState({ Facilty: { ...this.state.Facilty,
            physicianViewModel: null,
            fkPhysicianId: null,
            physicianName: ''}});
        }
    }

    setNewPhysician = (newPhysician) => {
      this.setState({ Facilty: { ...this.state.Facilty,
        physicianViewModel: newPhysician,
        fkPhysicianId: null,
        physicianName: `${newPhysician.lastName} ${newPhysician.firstName}`},
        physician: {value: newPhysician.physicianId, label: `${newPhysician.lastName} ${newPhysician.firstName}`}});
    }

    render() {
        return (
        
            <>
                {(this.state.IsLoading == false) ?
                <>
                <Form
                    ref={(ref) => { this.form = ref }}
                    validated={this.state.formValidated}
                    onSubmit={e => this.onSubmit(e)}
                >


                    {this.state.addBtPhysicianModal && <Animated animationIn="fadeInLeft" animationInDuration={1000}>

                        {this.state.addBtPhysicianComponent}

                    </Animated>}
                    {!this.state.addBtPhysicianModal && <Animated animationIn="fadeInLeft" animationInDuration={1000}>
                        <fieldset  className="p-2 mb-2 bg-light border border-dark rounded">
                            {/* <legend className="text-primary fw-500">Basic Information:</legend> */}
                            <legend className="text-primary fw-500">{this.intl.formatMessage({id: "EDIT_FACILITY.BASIC_INFORMATION"})}:</legend>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="ThisFacilityBelongsTo">  Ownership-Headquarters </label> */}
                                        <label className="form-label" htmlFor="ThisFacilityBelongsTo">  { this.intl.formatMessage({id: "EDIT_FACILITY.OWNERSHIP_HEADQUARTERS"}) } </label>
                                        <div className="input-group">
                                            <select className="form-control form-control-sm"
                                                name="fkOrgId"
                                                disabled={this.state.canEdit == true ? true : false}
                                                value={Common.replaceNullWithString(this.state.Facilty.fkOrgId)}
                                                onChange={e => this.getChainByOrgID(e)}>

                                                {/* <option value="N/A">Select Organization</option> */}
                                                <option value="N/A"> { this.intl.formatMessage({id: "EDIT_FACILITY.SELECT_ORGANIZATION"}) }  </option>
                                                {this.state.selectOrgOptions}
                                            </select>
                                            <CheckPermission
                                                userRole={RBAC.Role_Gentell_Administrator}
                                                yes={() => (
                                                    <>
                                                        <div className="input-group-append"><button
                                                            type="button"
                                                            onClick={() => { this.SyncFacilityGroup() }}
                                                            // className="btn btn-secondary">Sync</button>
                                                            className="btn btn-secondary"> { this.intl.formatMessage({id: "EDIT_FACILITY.SYNC"}) } </button>
                                                        </div>
                                                    </>
                                                )}
                                                no={() => null}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="ThisFacilityBelongsTo">Purchasing Group-Chain  </label> */}
                                                <label className="form-label" htmlFor="ThisFacilityBelongsTo">{this.intl.formatMessage({
                                            id: "WORKQUEUE.PURCHASING_GROUP_CHAIN"
                                        })}</label>
                                        <select className="form-control form-control-sm"
                                            name="fkChainId"
                                            disabled={this.state.canEdit == true ? true : false}
                                            value={Common.replaceNullWithString(this.state.Facilty.fkChainId)}
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}>
                                            {/* <option value="N/A">Select Chain</option> */}
                                                    <option value="N/A">{this.intl.formatMessage({
                                                id: "WORKQUEUE.SELECT_CHAIN"
                                            })}</option>
                                            {this.state.selectOrgChainOptions}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="FacilityName">Facility Name</label> */}
                                        <label className="form-label" htmlFor="FacilityName">Nome do Estabelecimento</label>
                                        <input type="text" id="FacilityName"
                                            name="facilityName"
                                            disabled={this.state.canEdit == true ? true : false}
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}
                                            value={this.state.Facilty.facilityName}
                                            required
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FacilityType">{this.intl.formatMessage({
                                            id: "WORKQUEUE.FACILITY_TYPE"
                                        })}</label>
                                        <select className="form-control form-control-sm"
                                            name="fkFacilityTypeId"
                                            value={this.state.Facilty.fkFacilityTypeId}
                                            disabled={this.state.canEdit == true ? true : false}
                                            required
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}>
                                            {/* <option value="N/A">Select Facility Type</option> */}
                                                    <option value="N/A">{this.intl.formatMessage({
                                                id: "WORKQUEUE.SELECT_FACILITY_TYPE"
                                            })}</option>
                                            {this.state.selectFacilityTypeOptions}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                                <label className="form-label" htmlFor="NPINumber">{this.intl.formatMessage({
                                            id: "WORKQUEUE.NPI"
                                        })}:</label>
                                        <input type="Number" id="NPINumber"
                                            name="npi"
                                            disabled={this.state.canEdit == true ? true : false}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            value={Common.replaceNullOrEmptyWithString(this.state.Facilty.npi, "")}
                                            onChange={e => this.setNpiValue(e)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="FacilityType">Product Line</label> */}
                                                <label className="form-label" htmlFor="FacilityType">{this.intl.formatMessage({
                                            id: "WORKQUEUE.PRODUCT_LINE"
                                        })}</label>
                                        <select className="form-control form-control-sm"
                                            name="fkProductLineId"
                                            value={Common.replaceNullWithString(this.state.Facilty.fkProductLineId)}
                                            required
                                            disabled={this.state.canEdit == true ? true : false}
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}>
                                            {/* <option value="N/A">Select Product Line</option> */}
                                                    <option value="N/A">{this.intl.formatMessage({
                                                id: "WORKQUEUE.SELECT_PRODUCT_LINE"
                                            })}</option>
                                            {this.state.selectProductOptions}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="NumberofBeds">Number of Beds</label> */}
                                                <label className="form-label" htmlFor="NumberofBeds">{this.intl.formatMessage({
                                            id: "WORKQUEUE.NUMBER_OF_BEDS"
                                        })}</label>
                                        <input type="text" id="NumberofBeds"
                                            name="numberofBeds"
                                            disabled={this.state.canEdit == true ? true : false}
                                            // required
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}
                                            value={this.state.Facilty.numberofBeds}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="MemberofGroupPurchasingOrg.">EMR System</label> */}
                                                <label className="form-label" htmlFor="MemberofGroupPurchasingOrg.">{this.intl.formatMessage({
                                            id: "WORKQUEUE.EMR_SYSTEM"
                                        })}</label>
                                        <div className="input-group">
                                            <select className="form-control form-control-sm"
                                                name="fkGroupPurchasingOrg"
                                                // disabled={this.state.canEdit == true ? true : false}
                                                disabled={
                                                    this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_Administrator) == undefined
                                                 && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_WoundCareSpecialist) == undefined 
                                                 && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_RegionalManager) == undefined
                                                 && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_IntakeProcessor) == undefined
                                                 && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_SeniorBiller) == undefined 
                                                 || this.state.canEdit == true 
                                                 ?
                                                     true : false}
                                                // required
                                                value={Common.replaceNullWithString(this.state.Facilty.fkGroupPurchasingOrg)}
                                                onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}>
                                                <option value="N/A"></option>
                                                {this.setAllEMR(this.state.selectGroupPurchasingOptions)}
                                            </select>
                                            {/* <CheckPermission
                                                userRole={RBAC.Role_Gentell_Administrator}
                                                yes={() => (
                                                    <>
                                                        <div className="input-group-append"><button
                                                            type="button"
                                                            disabled={this.state.Facilty.fkGroupPurchasingOrg > 0 ? false : true}
                                                            onClick={() => { this.showEditEMR() }}
                                                            className="btn btn-secondary">Edit</button>
                                                        </div>
                                                    </>
                                                )}
                                                no={() => null}
                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                {this.state.Facilty.fkGroupPurchasingOrg == 3 &&
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="WebSiteAddress">
                                             {/* Account Name */}
                                                        {this.intl.formatMessage({
                                                    id: "WORKQUEUE.ACCOUNT_NAME"
                                                })}
                                            </label>
                                            <input
                                                name="websiteAddress"
                                                required
                                                defaultValue={
                                                    this.state.Facilty.websiteAddress
                                                }
                                                onChange={(e) =>
                                                    Common.updateInputValueInState(
                                                        this.state.Facilty,
                                                        e,
                                                        this,
                                                        this.state
                                                    )
                                                }
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                }
                                <CheckPermission
                                    userRole={[
                                        RBAC.Role_Gentell_Administrator,
                                        RBAC.Role_Gentell_RegionalManager,
                                        RBAC.Role_Gentell_WoundCareSpecialist,
                                        RBAC.Role_Gentell_IntakeProcessor,
                                        RBAC.Role_Gentell_SeniorBiller
                                    ]}
                                    yes={() => (
                                        <>


                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="doB">
                                                        {/* User ID */}
                                                        {this.intl.formatMessage({
                                                            id: "WORKQUEUE.USER_ID"
                                                        })}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="emrlogin"
                                                        disabled={
                                                            this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_Administrator) == undefined
                                                         && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_WoundCareSpecialist) == undefined 
                                                         && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_RegionalManager) == undefined
                                                         && this.props.roles.roleList.find(x => RBAC.Role_Gentell_IntakeProcessor) == undefined
                                                         && this.props.roles.roleList.find(x => RBAC.Role_Gentell_SeniorBiller) == undefined
                                                         || this.state.canEdit == true
                                                         ?
                                                             true : false}
                                                        className="form-control form-control-sm"
                                                        name="emrlogin"
                                                        defaultValue={
                                                            this.state.Facilty.emrlogin
                                                        }
                                                        onChange={(e) =>
                                                            Common.updateInputValueInState(
                                                                this.state.Facilty,
                                                                e,
                                                                this,
                                                                this.state
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="doB">
                                                        {/* Password */}
                                                        {this.intl.formatMessage({
                                                            id: "WORKQUEUE.PASSWORD"
                                                        })}
                                                    </label>
                                                    <input
                                                        type="text"
                                                        id="emrpassword"
                                                        disabled={
                                                            this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_Administrator) == undefined
                                                         && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_WoundCareSpecialist) == undefined 
                                                         && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_RegionalManager) == undefined
                                                         && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_IntakeProcessor) == undefined
                                                         && this.props.roles.roleList.find(x => x.roleName == RBAC.Role_Gentell_SeniorBiller) == undefined
                                                         || this.state.canEdit == true
                                                         ?
                                                             true : false}
                                                        className="form-control form-control-sm"
                                                        name="emrpassword"
                                                        defaultValue={
                                                            this.state.Facilty.emrpassword
                                                        }
                                                        onChange={(e) =>
                                                            Common.updateInputValueInState(
                                                                this.state.Facilty,
                                                                e,
                                                                this,
                                                                this.state
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    no={() => null}
                                />
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="Shipsfromthiswarehouse:">Ships from this warehouse</label> */}
                                                <label className="form-label" htmlFor="Shipsfromthiswarehouse:">{this.intl.formatMessage({
                                            id: "WORKQUEUE.SHIPS_FROM_THIS_WAREHOUSE"
                                        })}</label>
                                        <select className="form-control form-control-sm"
                                            name="fkWarehouseId"
                                            disabled={this.state.canEdit == true ? true : false}
                                            value={this.state.Facilty.fkWarehouseId}
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}>
                                            <option value={null}></option>
                                            {this.state.selectWarehouseOption}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FacilityType">
                                            {/* Wound and Product Specialist */}
                                                    {this.intl.formatMessage({
                                                id: "WORKQUEUE.WOUND_AND_PRODUCT_SPECIALIST"
                                            })}
                                        </label>
                                        <select
                                            className="form-control form-control-sm"
                                            name="fkClinicianId"
                                            disabled={this.state.canEdit == true ? true : false}
                                            // required
                                            value={this.state.Facilty.fkClinicianId}
                                            onChange={(e) =>
                                                this.onProductSpecialistChange(e)
                                            }
                                        >
                                            <option></option>
                                            {this.state.selectClinicianOptions}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FacilityType">
                                            {/* Regional Manager */}
                                                    {this.intl.formatMessage({
                                                id: "WORKQUEUE.REGIONAL_MANAGER"
                                            })}
                                        </label>
                                        <input
                                            type="text"
                                            disabled
                                            value={this.state.manager}


                                            className="form-control form-control-sm"
                                        />

                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FacilityType">
                                            {/* Vice President */}
                                                    {this.intl.formatMessage({
                                                id: "WORKQUEUE.VICE_PRESIDENT"
                                            })}
                                        </label>
                                        <input
                                            type="text"
                                            disabled
                                            value={this.state.vicePresident}


                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                              <div className="col-2">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="DateAdded">Date Added</label>
                                    <input type="text"
                                      id="DateAdded"
                                      name="dateAdded"
                                      disabled={true}
                                      value={moment(this.state.Facilty.createdDate).format("MM/DD/YYYY")}
                                      className="form-control form-control-sm"
                                    />
                                  </div>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset disabled={this.state.canEdit == true ? true : false} className="p-2 mb-2 bg-light border border-dark rounded">
                            {/* <legend className="text-primary fw-500">Address Information</legend> */}
                                    <legend className="text-primary fw-500">{this.intl.formatMessage({
                                id: "WORKQUEUE.ADDRESS_INFORMATION"
                            })}</legend>
                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="Memo">
                                            {/* Address Line 1 */}
                                                    {this.intl.formatMessage({
                                                        id: "WORKQUEUE.ADDRESS_LINE_1"
                                                    })}
                                        </label>
                                        <input
                                            className="form-control form-control-sm"

                                            id="address1"
                                            name="addressLine1"
                                            value={Common.replaceNullOrEmptyWithString(this.state.Facilty.address.addressLine1, "")}
                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.Facilty.address,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                        ></input>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="Memo">
                                            {/* Address Line 2 */}
                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.ADDRESS_LINE_2"
                                                    })}
                                        </label>
                                        <input
                                            className="form-control form-control-sm"
                                            id="addressLine2"
                                            name="addressLine2"
                                            value={Common.replaceNullOrEmptyWithString(this.state.Facilty.address.addressLine2, "")}

                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.Facilty.address,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                        ></input>
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="City">City</label> */}
                                                <label className="form-label" htmlFor="City">
                                                {this.intl.formatMessage({
                                                        id: "WORKQUEUE.CITY"
                                                    })}
                                        </label>
                                        <input type="text" id="City"
                                            name="city"
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty.address, e, this, this.state)}
                                            value={Common.replaceNullOrEmptyWithString(this.state.Facilty.address.city, "")}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="State">State</label> */}
                                                <label className="form-label" htmlFor="State">
                                                {this.intl.formatMessage({
                                                        id: "WORKQUEUE.STATE"
                                                    })}
                                        </label>
                                        <select className="form-control form-control-sm"
                                            name="stateId"
                                            required
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty.address, e, this, this.state)}
                                            value={Common.replaceNullWithString(this.state.Facilty.address.stateId)}
                                        >
                                            <option></option>
                                            {this.setOptionsUI(this.state.states)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">


                                <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="Phone1">Postal code</label> */}
                                                <label className="form-label" htmlFor="Phone1">
                                                    {this.intl.formatMessage({
                                                        id: "WORKQUEUE.POSTAL_CODE"
                                                    })}
                                        </label>
                                        {/* <input type="text" id="Zip"
                                            name="zip"
                                            // onChange={e => Common.updateInputValueInState(this.state.Facilty.Address, e, this, this.state)}
                                            //  value={Common.replaceNullWithString(this.state.Facilty.address.zip)}
                                            className="form-control form-control-sm" /> */}
                                        <input
                                            type="number"
                                            id="Zip"
                                            name="zip"
                                            minLength="5" maxLength="9"
                                            value={this.state.Facilty?.address?.zip?.trim()}
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onChange={(e) =>
                                                Common.updateInputValueInState(
                                                    this.state.Facilty.address,
                                                    e,
                                                    this,
                                                    this.state
                                                )
                                            }
                                            className="form-control form-control-sm"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="Country">Country</label> */}
                                                <label className="form-label" htmlFor="Country">
                                                {this.intl.formatMessage({
                                                        id: "WORKQUEUE.COUNTRY"
                                                    })}
                                        </label>
                                        <select className="form-control form-control-sm"
                                            name="countryId"
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty.address, e, this, this.state)}
                                            id="State"
                                            value={Common.replaceNullWithString(this.state.Facilty.address.countryId)}
                                        >
                                            {/* <option value="1">United States</option> */}
                                                    <option value="1">
                                                        {this.intl.formatMessage({
                                                            id: "WORKQUEUE.UNITED_STATES"
                                                        })}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="Phone1">Phone</label> */}
                                                <label className="form-label" htmlFor="Phone1">
                                                    {this.intl.formatMessage({
                                                        id: "WORKQUEUE.PHONE"
                                                    })}
                                        </label>
                                        <input type="phone" id="Phone1"
                                            name="phone1"
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty.address, e, this, this.state)}
                                            value={Common.replaceNullOrEmptyWithString(Common.getFormattedPhoneNum(this.state.Facilty.address.phone1), "")}
                                            className="form-control form-control-sm" />

                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        {/* <label className="form-label" htmlFor="Phone1">Fax</label> */}
                                                <label className="form-label" htmlFor="Phone1">
                                                    {this.intl.formatMessage({
                                                        id: "WORKQUEUE.FAX"
                                                    })}
                                        </label>
                                        <input type="text" id="Fax"
                                            name="fax"
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty.address, e, this, this.state)}
                                            value={Common.replaceNullOrEmptyWithString(Common.getFormattedPhoneNum(this.state.Facilty.address.fax), "")}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                            </div>
                        </fieldset>
                        {/* <fieldset className={"p-2 mb-2 bg-light border border-dark rounded "}>
                <legend className="text-primary fw-500">
                Facility Contacts{" "}
                </legend>
               

                <FacilityContact ref={(ref) => this.facilityContact = ref} residentID={0}
                  residentInsuranceList={[]}/>
              </fieldset> */}
                        <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                            {/* <legend className="text-primary fw-500">Other Information</legend> */}
                                    <legend className="text-primary fw-500">{this.intl.formatMessage({
                                id: "WORKQUEUE.OTHER_INFORMATION"
                            })}</legend>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                                    <ul className="list-unstyled my-5 border-dark border-md-0 border-right-lg">
                                        <li className="mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="medicarePartBbyGentell"
                                                    onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}
                                                    checked={this.state.Facilty.medicarePartBbyGentell}
                                                    className="m-1"
                                                    disabled={this.state.canEdit == true ? true : false}
                                                />
                                                <span className="mr-3" />
                                                {/* Woundcare Part B */}
                                                        {this.intl.formatMessage({
                                                    id: "WORKQUEUE.WOUNDCARE_PART_B"
                                                })}
                                            </label>
                                        </li>
                                        <li className="mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="outProgram"
                                                    onChange={e => {
                                                      Common.updateInputValueInState(this.state.Facilty, e, this, this.state); 
                                                    }}
                                                    checked={this.state.Facilty.outProgram}
                                                    className="m-1"
                                                    disabled={this.state.canEdit == true ? true : false}
                                                />
                                                <span className="mr-3" />
                                                {/* OUT`s Program */}
                                                        {this.intl.formatMessage({
                                                    id: "WORKQUEUE.OUT_PROGRAM"
                                                })}
                                            </label>
                                        </li>
                                        {this.state.Facilty.outProgram && (
                                          <div className='pl-3 pr-8 mb-3'>
                                            <select className="form-control form-control-sm"
                                              name="fkOutProgramWarehouse"
                                              onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}
                                              id="fkOutProgramWarehouse"
                                              value={this.state.Facilty.fkOutProgramWarehouse}>
                                              <option value={33}>Bristol</option>
                                              <option value={34}>Chicago</option>
                                            </select>
                                          </div>
                                        )}

                                        <li className="mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="isEcp"
                                                    checked={this.state.Facilty.isEcp}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="m-1"
                                                    disabled={this.state.canEdit == true ? true : false}
                                                />
                                                <span className="mr-3" />
                                                {/* Equal Care Program */}
                                                        {this.intl.formatMessage({
                                                    id: "WORKQUEUE.EQUAL_CARE_PROGRAM"
                                                })}
                                            </label>
                                        </li>
                                        <li className="mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="isThisFacilityFollowingTheCycle"
                                                    onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}
                                                    checked={this.state.Facilty.isThisFacilityFollowingTheCycle}
                                                    className="m-1"
                                                    disabled={this.state.canEdit == true ? true : false}
                                                />
                                                <span className="mr-3" />
                                                {/* 28-day billing cycle */}
                                                        {this.intl.formatMessage({
                                                    id: "WORKQUEUE.ENTERAL_PROGRAM"
                                                })}
                                            </label>
                                        </li>
                                        <li className="mb-3">
                                            <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    name="canAddAssessment"
                                                    checked={this.state.Facilty.canAddAssessment}

                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="m-1"
                                                />
                                                <span className="mr-3" />
                                                {/* Facility use of fastcare */}
                                                        {this.intl.formatMessage({
                                                    id: "WORKQUEUE.FACILITY_USE_OF_FASTCARE"
                                                })}
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    htmlFor="PartBReceivingPerson"
                                                >
                                                    {/* Part B Receiving Person */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.PART_B_RECEIVING_PERSON"
                                                    })}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="PartBReceivingPerson"
                                                    name="partBrecPerson"
                                                    value={this.state.Facilty.partBrecPerson}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Phone1">
                                                    {/* Receiving Person Phone # */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.RECEIVING_PERSON_PHONE"
                                                    })}
                                                </label>
                                                <input
                                                    type="phone"
                                                    id="partBrecPersonPhone"
                                                    name="partBrecPersonPhone"
                                                    value={Common.replaceNullOrEmptyWithString(Common.getFormattedPhoneNum(this.state.Facilty.partBrecPersonPhone), "")}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>


                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Phone1">
                                                    {/* Receiving Person Email */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.RECEIVING_PERSON_EMAIL"
                                                    })}
                                                </label>
                                                <input
                                                    type="phone"
                                                    id="partBrecPersonEmail"
                                                    name="partBrecPersonEmail"
                                                    value={Common.replaceNullOrEmptyWithString(this.state.Facilty.partBrecPersonEmail, "")}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                            <div className="form-group">
                                                <label
                                                    className="form-label"
                                                    htmlFor="Central Supply Person"
                                                >
                                                    {/* Central Supply Person */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.CENTRAL_SUPPLY_PERSON"
                                                    })}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="Stree2:"
                                                    name="centralSupplyPerson"
                                                    value={this.state.Facilty.centralSupplyPerson}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Phone1">
                                                    {/* Central Supply Person Phone # */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.CENTRAL_SUPPLY_PERSON_PHONE"
                                                    })}
                                                </label>
                                                <input
                                                    type="phone"
                                                    id="centerSupplyPersonPhone"
                                                    name="centerSupplyPersonPhone"
                                                    value={Common.replaceNullOrEmptyWithString(Common.getFormattedPhoneNum(this.state.Facilty.centerSupplyPersonPhone), "")}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Phone1">
                                                    {/* Central Supply Person Email */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.CENTRAL_SUPPLY_PERSON_EMAIL"
                                                    })}
                                                </label>
                                                <input
                                                    type="text"
                                                    id="centerSupplyPersonEmail"
                                                    name="centerSupplyPersonEmail"
                                                    value={Common.replaceNullOrEmptyWithString(this.state.Facilty.centerSupplyPersonEmail, "")}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                            this.state.Facilty,
                                                            e,
                                                            this,
                                                            this.state
                                                        )
                                                    }
                                                    className="form-control form-control-sm"
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    <div className="row">
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="gender">
                                                    {/* Medical Director or Physician signing Facility CMNs */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.MEDICAL_DIRECTOR_OR_PHYSICIAN_SIGNING_FACILITY_CMNS"
                                                    })}
                                                </label>
                                                <AsyncSelect
                                                    name='organizationID'
                                                    noOptionsMessage={() => 
                                                      this.state.physiciansInputValue.length>=3 ?
                                                      <Button  variant="success" className="mr-5 px-10" size="sm"
                                                        onClick={() => this.setState({openPhysicianModal:true})}>New</Button> : ''}
                                                    inputValue={this.state.physiciansInputValue}
                                                    isDisabled={this.state.canEdit == true ? true : false}
                                                    onInputChange={this.setPhysicianInputValue}
                                                    value={this.state.physician}
                                                    onChange={(obj) => {
                                                        this.setPhysician(obj)
                                                    }}

                                                    loadOptions={this.loadPhysicians}
                                                    isClearable
                                                />
                                            </div>
                                        </div>
                                        <div className="col-4">
                                          <div className="form-group">
                                                <label className="form-label" htmlFor="email">Email</label>
                                                <input 
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    className="form-control form-control-sm"
                                                    value={this.state.Facilty.email}
                                                    onChange={(e) =>
                                                    { Common.updateInputValueInState(this.state.Facilty, e, this, this.state); 
                                                        if(e.target.value && !Common.isValidEmail(e.target.value)) {
                                                        this.setState({emailError: true})
                                                        } else {
                                                        this.setState({emailError: false})
                                                        }
                                                    }
                                                    }  
                                                />
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <div className="form-group mt-8">
                                                <label className="checkbox">
                                                    <input
                                                    type="checkbox"
                                                    name="singingForAllCmns"
                                                    checked={this.state.Facilty.singingForAllCmns}
                                                    disabled={this.state.canEdit == true ? true : false}
                                                    onChange={(e) =>
                                                        Common.updateInputValueInState(
                                                        this.state.Facilty,
                                                        e,
                                                        this,
                                                        this.state
                                                        )
                                                    }
                                                    className="m-1"
                                                    />
                                                    <span className="mr-3" />
                                                    {/* Signing For All CMNs */}
                                                            {this.intl.formatMessage({
                                                        id: "WORKQUEUE.SIGNING_FOR_ALL_CMNS"
                                                    })}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                                <label className="form-label" htmlFor="Memo">
                                                    {this.intl.formatMessage({
                                                        id: "WORKQUEUE.MEMO"
                                                    })}
                                        </label>
                                        <textarea className="form-control"
                                            value={this.state.Facilty.memo}
                                            disabled={this.state.canEdit == true ? true : false}
                                            name="memo"
                                            onChange={e => Common.updateInputValueInState(this.state.Facilty, e, this, this.state)}
                                            id="Memo" rows="3"
                                            // disabled={this.state.canEdit == true ? true : false}     
                                            ></textarea>
                                    </div>
                                </div>
                            </div>
                        </fieldset>


                    </Animated>
                    }


                </Form>

                {this.state.openPhysicianModal && (
                  <PhysicianModal
                    onSaveAPI={this.setNewPhysician}
                    open={this.state.openPhysicianModal}
                    toggleModal={()=>this.setState({openPhysicianModal:false})}
                  />
                )}

                <PopupModal
                    size="lg"
                    title={this.intl.formatMessage({id: "WORKQUEUE.ADD_EMR"})}
                    module={ModulesName.Module_Setup}
                    action={UserActions.Create}
                    buttonPress={() => addEMR.onSubmit()}
                    ref={(ref) => {
                        emrModal = ref;
                    }}
                >
                    {" "}
                    <AddEMRCredentail
                        ref={(ref) => {
                            addEMR = ref;
                        }}
                        onSaveAPI={() => this.onEMRFormSubmit()}
                        emr={this.state.emr}
                    />{" "}
                </PopupModal>
                </>
                :
            <div className="d-flex justify-content-center">
              <Loader
                type="Puff"
                color="#00BFFF"
                height={50}
                width={50}
              />
            </div>
          }
            </>
        );
    }
}
const mapStateToProps = (state) => {
    const roles = state.auth.user.roles;
    return { roles }
};
//   export default connect(mapStateToProps)(ProductSelection)
export default injectIntl(connect(mapStateToProps, {}, null, { forwardRef: true })(EditFacility));




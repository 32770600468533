import React from "react";
import {
    Button
} from "react-bootstrap";
import {
    Card,
    CardBody,

    CardHeader
} from "../../../../_metronic/_partials/controls/Card";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import { ModulesName, UserActions } from "../../../General/roleModuleActions";
import PopupModal from "../../modal/popupmodal";
import AddRoleForm from "./addRoleForm";
import AssignActionForm from "./assignActionForm";
import EditRoleForm from "./editRoleForm";
import RoleTable from "./roleTable";


const toolbar = (
  <div className="card-toolbar">
    <div className="example-tools">
      <Button
        variant="primary"
        size="sm"
        onClick={() => addRoleModal.showModal()}
      >
        New Role
      </Button>
    </div>
  </div>
);

var addRoleModal = null;
var addRole = null;

var editRoleModal = null;
var editRole = null;

var assignActionModal = null;
var assignAction = null;

export default class Role extends React.Component {
  rows = [];
  constructor(props) {
    super(props);
    this.state = { userroleList: [], objectToEdit: null };
  }
  componentDidMount() {
    //initialize datatable
    this.loadData();
    var test = Common.GetCurrentUser();
    // // debugger;
  }
  loadData = () => {
    Api.getRequest("Role/GetAllRoles")
      .then((response) => {
        this.setState({ userroleList: response.data.result });
        Common.LoadDataTable("userroleList");
      })
      .catch(function(error) {
        Common.LogError(error, "Role", "LoadData");
      });
    addRoleModal.hideModal();
    editRoleModal.hideModal();
  };

  showEditPopup = (obj) => {
    this.setState({ objectToEdit: obj });
    editRoleModal.showModal();
  };

  hideAssignActionModal = () => {
    assignActionModal.hideModal();
  };

  showAssignActionPopup = (obj) => {
    this.setState({ objectToEdit: obj });
    assignActionModal.showModal();
  };

  tabRowRender = () => {
    return this.state.userroleList.map((object, i) => {
      return (
        <RoleTable
          showEditPopup={this.showEditPopup}
          showAssignActionPopup={this.showAssignActionPopup}
          obj={object}
          key={i}
        />
      );
      // return <RoleTable showEditPopup={()=>{ editRole={...object}; alert(JSON.stringify(editRole));editRoleModal.showModal(); }} obj={object} key={i} />;
    });
  };

  render() {
    return (
      <>
        <div>
          <Card className="example example-compact">
            {/* <CardHeader title="Role List" toolbar={toolbar} /> */}
            <CardHeader title="Role List" />

            <CardBody>
              <table
                className="table table-striped table-hover table-sm table-bordered"
                id="userroleList"
              >
                <thead>
                  <tr>
                    <th data-priority="1">Role Name</th>
                    <th>Description</th>
                    <th>Group Type</th>
                    <th>Status</th>
                    <th data-priority="2" width="130px">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>{this.tabRowRender()}</tbody>
              </table>
            </CardBody>
          </Card>
          <PopupModal
            size="lg"
            title={"Assign Action to ("+this.state.objectToEdit?.roleName+")"}
            module={ModulesName.Module_Setup}
            action={UserActions.Update}
            buttonPress={() => assignAction.onSubmit()}
            ref={(ref) => {
              assignActionModal = ref;
            }}
          >
            {" "}
            <AssignActionForm
              ref={(ref) => {
                assignAction = ref;
              }}
              role={this.state.objectToEdit}
              onSaveAPI={() => this.loadData()}
              onhideModal={() => this.hideAssignActionModal()}
            />{" "}
          </PopupModal>

          {/* Edit role modal  */}
          <PopupModal
            size="lg"
            title="Edit Role"
            buttonPress={() => editRole.onSubmit()}
            ref={(ref) => {
              editRoleModal = ref;
            }}
          >
            {" "}
            <EditRoleForm
              ref={(ref) => {
                editRole = ref;
              }}
              role={this.state.objectToEdit}
              onSaveAPI={() => this.loadData()}
            />{" "}
          </PopupModal>

          {/* add role */}
          <PopupModal
            size="lg"
            title="Add Role"
            buttonPress={() => addRole.onSubmit()}
            ref={(ref) => {
              addRoleModal = ref;
            }}
          >
            {" "}
            <AddRoleForm
              ref={(ref) => {
                addRole = ref;
              }}
              onSaveAPI={() => this.loadData()}
            />{" "}
          </PopupModal>
        </div>
      </>
    );
  }
}

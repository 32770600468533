import React, { useCallback, useEffect, useState } from 'react';
//import { useState } from 'react';
import { Animated } from "react-animated-css";
import { Alert, Badge, Button, ButtonToolbar, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import ExcelToJson from "../../General/excelToJson";
import uuid from 'node-uuid'
export function ExcelSheetFacility() {

    useEffect(() => { getSyncLog(); }, [getSyncLog]);
    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(false);

    const [tableFacility, setFacility] = useState([]);
    const [logList, setLogListUI] = useState([]);
    const [pasredDataList, setPasredDataList] = useState([]);
    const [days, setDays] = useState(90);



    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);




   const saveParsedData=()=>{
    setIsSubmitting(true)
       if(pasredDataList.length>0){
        Api.postRequest(Api.Uri_saveParseFacilityList, pasredDataList)
        .then((res) => {
            if (res.data.success) {
                // this.props.onSaveAPI();
                setIsSubmitting(false)
                getSyncLog();
                showAdvanceSearchSectionState(false)
                Common.showSuccessAlert(
                    res.data.message,
                    "success",
                    "Success!"
                );
            } else {
                setIsSubmitting(false)
                Common.showSuccessAlert(
                    res.data.message,
                    "warning",
                    "Error!",
                    3000
                );
            }

        });
       }else{
        setIsSubmitting(false)
        Common.showSuccessAlert(
            "Data is not available in list",
            "warning",
            "warning!",
            3000
        );
       }
   }

 const  excelDataRead=(data)=> {
    setIsLoading(true)
    Api.postRequest(Api.Uri_parseFacilityFile, data)
        .then((res) => {
            if (res.data.success) {
                // this.props.onSaveAPI();
                // this.props.foundFacilityInBt(res.data.result);
               setFacilityListUi(res.data.result);
               setIsLoading(false)
                Common.showSuccessAlertAutoClose(
                    res.data.message,
                    "success",
                    "Success!",
                    3000
                );
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "warning",
                    "Error!",
                    3000
                );
            }

        });

}

    const setFacilityListUi = (list) => {
        setPasredDataList(list);
        var rows = [];
        list.map((object, i) => {
            rows.push(<tr key={i.toString()}>
                <td >

                    {object.facilityName}
                </td>
                <td>{Common.replaceNullOrEmptyWithString(object.facilityGroupName)}</td>
                <td>{object.btId == null ? <Badge variant="danger">Not Found</Badge> : <Badge variant="info">Found</Badge>} </td>
                <td>{Common.replaceNullOrEmptyWithString(object.facilityNPI, "")}</td>
                <td>{Common.replaceNullOrEmptyWithString(object.facilityCity, "")}</td>

            </tr>);
        })

        setFacility(rows)
        Common.LoadDataTable("facilitySheetList");
    }




    const handleDownLoadFacility = () => {
        var msg = "You want to download all facility.It is long process and may slow the system performance.If Possible please perform this activity in off hours";
        Common.showConfirmation(msg, "", fetchAllFacility, null, null)

    };
    const fetchAllFacility = () => {
        setIsDownloading(true)
        Api.getRequestQry(Api.Uri_fetchAllFacility,"?downloadDays="+days)
            .then((res) => {
                if (res.data.success) {
                    getSyncLog();
                    setIsDownloading(false)
                    Common.showSuccessAlert(
                        'Facility Download Completed',
                        "success",
                        "Success!"
                    );
                } else {
                    setIsDownloading(false)
                    Common.showSuccessAlertAutoClose(
                        res.data.message,
                        "warning",
                        "Error!",
                        3000
                    );
                }

            });

    }
    const getSyncLog = () => {
        setIsLoading(true)
        Api.getRequest(Api.Uri_getSyncLog)
            .then((res) => {
                debugger
                if (res.data.success) {
                    if (res.data.result != null) {
                        setIsLoading(false)
                        // setResidentWoundListUI([]);
                        setLogList(res.data.result);

                    }
                }

                //  this.setState({ IsLoading: true });
            });

    }
    const setLogList = (logDetail) => {
        debugger
        let rows = [];
        try {
            for (var i = 0; i < logDetail.syncLogDetailViewModelList.length; i++) {

                const obj = logDetail.syncLogDetailViewModelList[i];
                rows.push(
                    <React.Fragment key={i}>

                        <tr key={uuid()}>
                            <td className="justify-content-center"> <b>{i + 1}</b> </td>
                            <td>
                                {Common.replaceNullWithString(obj.facilityName)}
                            </td>
                            <td>
                                {Common.replaceNullWithString(obj.totalResidentSync)}
                            </td>
                            <td align="center">{obj.isSuccess == true ? <Badge variant="success">Success</Badge>
                                : obj.isSuccess == false ? <Badge variant="danger">Failed</Badge> : <Badge variant="info">In Process</Badge>} </td>
                            <td >  {obj.syncCount}</td>
                            <td>{Common.replaceNullWithString(obj.exception)}</td>



                        </tr>

                    </React.Fragment>
                );
            }
            setLogListUI(rows);
            Common.LoadDataTable("facilityTable");
            // this.setState({ IsLoading: true });


        } catch (error) {
            Common.LogError(error, "WorkqueueWounds", "SetWoundList");
        }
    }
    const handleDays=(e)=>{
        debugger
        setDays(e.target.value)
    }
    const toolbar = (
        <div className="card-toolbar">
            <div className="example-tools">
                {/* <Button variant="warning" className="mr-5" size="sm" onClick={showAdvanceSearchSection}>Advanced Search</Button> */}
                {/* <Button variant="primary" size="sm" onClick={handleShowNewFacility}>New Facility</Button> */}
                <CheckPermission
                    permission={UserActions.Create}
                    module={ModulesName.Module_Facility}
                    yes={() => (
                        <>
 <div className="row">
<div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
<div className="d-flex align-items-center w-100">
                                        <label className="form-label" htmlFor="NumberofBeds">Days</label>
                                        <input type="number" 
                                            min="0"
                                            name="numberofBeds"
                                            id="numberofBeds"
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            onChange={(e) => handleDays(e)}
                                            value={days}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => handleDownLoadFacility()}>{isDownloading ? (
                    <span className="p-3 spinner spinner-white"></span>
                  ) : (
                    'Download All Facility'
                  )}</Button>
                            <Button variant="success" size="sm" onClick={() => showAdvanceSearchSectionState(true)}>Upload Facility Sheet</Button>
                       
                            </div>
                            </div>

                        </>
                    )}
                    no={() => null}
                />


            </div>
        </div>
    );
    return (
        <>
            {ShowAdvancedSearch && <Animated animationIn="fadeInLeft" animationInDuration={1000}>
                <Card className="">
                    <CardHeader title='Facility List' />
                    <CardBody>




                        <ExcelToJson excelDataRead={excelDataRead} isLoading={isLoading} />


                        <div>
                            {isLoading ?
                                <div className="row d-flex justify-content-center">
                                    <span className="mt-5 spinner spinner-primary"></span>
                                </div> : (
                                    <table className="table table-striped table-hover table-sm table-bordered" id="facilitySheetList">
                                        <thead>
                                            <tr>
                                                <th>Facility Name</th>
                                                <th>Facility Group Name</th>

                                                <th>Status</th>
                                                <th>NPI</th>

                                                <th>facility City</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableFacility}
                                        </tbody>
                                    </table>
                                )}
                        </div>


                    </CardBody>
                    <CardFooter className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm"   onClick={saveParsedData}>{isSubmitting ? (
                    <span className="p-3 spinner spinner-white"></span>
                  ) : (
                    'Save Data'
                  )}</Button>
                        <Button variant="danger" size="sm" onClick={() => showAdvanceSearchSectionState(false)}>Cancel</Button>
                    </CardFooter>
                </Card>
            </Animated>}
            {!ShowAdvancedSearch && 
                <Card className="" key={uuid()}>
                    <CardHeader title='Facility List' toolbar={toolbar} />
                    <CardBody>

                        <div>
                            {isLoading ?
                                <div className="row d-flex justify-content-center">
                                    <span className="mt-5 spinner spinner-primary"></span>
                                </div> : (
                                    <table key={uuid()} className="table table-striped table-hover table-sm table-bordered" id="facilityTable">
                                        <thead>
                                            <tr key={uuid()}>
                                                <th>#</th>
                                                <th>Facility Name</th>
                                                <th>Total Resident Sync</th>
                                                <th>Status</th>
                                                <th>Sync Count</th>
                                                <th>Exception</th>
                                            </tr>

                                        </thead>
                                        <tbody key={uuid()}>


                                            {logList}


                                        </tbody>
                                    </table>
                                )}
                        </div>


                    </CardBody>
                    <CardFooter className="d-flex justify-content-end py-3">

                    </CardFooter>
                </Card>
            }
        </>

    );
}

export default ExcelSheetFacility;
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import * as Common from "@/app/General/common";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { pdfjs } from "react-pdf";
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

function ResidentDetailsBR(props) {
  const { intl } = props;

  const {
    firstName,
    middleName,
    lastName,
    ssn,
    doB,
    unit,
    room,
    admissionDate,
    genderName,
    primaryPhysicianName,
    residentStatus,
    isEcp,
  } = props.resident;

  const [residentInsuranceList, setResidentInsuranceListUI] = useState([]);

  useEffect(() => {
    setResidentInsuranceList(props.resident.residentInsuranceList);
  }, []);

  const setResidentInsuranceList = (list) => {
    let rows = [];
    try {
      for (var i = 0; i < list.length; i++) {
        const obj = list[i];
        rows.push(
          <tr key={i.toString()}>
            <td>{Common.replaceNullWithString(obj.insuranceName)}</td>
            <td>
              {obj.fkInsuranceLevelId == 1
                ? "Primary"
                : obj.fkInsuranceLevelId == 2
                ? "Secondary"
                : obj.fkInsuranceLevelId == 3
                ? "Tertiary"
                : "N/A"}
            </td>

            <td>
              {Common.replaceNullWithString(obj.policyNo) != ""
                ? obj.policyNo
                : "N/A"}
            </td>
          </tr>
        );
      }
      setResidentInsuranceListUI(rows);
    } catch (error) {}
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <fieldset className="rounded border p-2 mb-3">
            <legend className="text-primary fw-500">
              <FormattedMessage id="RESIDENTDETAILS.BASIC_INFORMATION" />:
            </legend>
            <table
              className="table table-sm border-0 w-100 table-striped"
              id=""
            >
              <tbody>
                <tr>
                  <td width="25%" className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.RESIDENT" />
                  </td>
                  <td>{`${firstName == null ? "" : firstName} ${
                    middleName == null ? "" : middleName
                  } ${lastName}`}</td>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.SOCIAL_SECURITY" />
                  </td>
                  <td>{ssn}</td>
                </tr>
                <tr>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.GENDER" />
                  </td>
                  <td>{genderName}</td>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.PACIENT_STATUS" />
                  </td>
                  <td>{residentStatus}</td>
                </tr>
                <tr>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.UNIT" />
                  </td>
                  <td>{unit}</td>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.DATE_OF_BIRTH" />
                  </td>
                  <td>{<FormattedDate value={doB} />}</td>
                </tr>
                <tr>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.ROOM" />
                  </td>
                  <td>{room}</td>
                </tr>
                <tr>
                  <td className="font-weight-bolder">
                    <FormattedMessage id="RESIDENTDETAILS.CURRENT_ADMISSION_DATE" />
                  </td>
                  <td>{<FormattedDate value={admissionDate} />}</td>
                  <td className="font-weight-bolder">
                    <FormControlLabel
                      label={`${intl.formatMessage({
                        id: "RESIDENTDETAILS.EQUAL_CARE",
                      })}:`}
                      labelPlacement="start"
                      control={<Checkbox checked={isEcp} value="checkedA" />}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <div className="row">
            <div className="col-xl-12">
              <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                  <FormattedMessage id="RESIDENTDETAILS.INSURANCE_INFORMATION" />
                  :
                </legend>
                <table
                  className="table table-sm border-0 w-100 table-striped"
                  id=""
                >
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="RESIDENTDETAILS.INSURANCE_NAME" />
                      </th>
                      <th>
                        <FormattedMessage id="RESIDENTDETAILS.INSURANCE_LEVEL" />
                      </th>
                      <th>
                        <FormattedMessage id="RESIDENTDETAILS.POLICY_NO" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>{residentInsuranceList}</tbody>
                </table>
              </fieldset>
            </div>
            <div className="col-xl-6">
              <fieldset className="rounded border p-2 mb-3">
                <legend className="text-primary fw-500">
                  <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_INFORMATION" />
                  :
                </legend>
                <table
                  className="table table-sm border-0 w-100 table-striped"
                  id=""
                >
                  <tbody>
                    <tr>
                      <td className="font-weight-bolder">
                        <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN" />
                      </td>
                      <td>{primaryPhysicianName}</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bolder">
                        <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_ADDRESS" />
                      </td>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bolder">
                        <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_PHONE" />
                      </td>
                      <td>(11) 99999-9999</td>
                    </tr>
                    <tr>
                      <td className="font-weight-bolder">
                        <FormattedMessage id="RESIDENTDETAILS.PHYSICIAN_FAX" />
                      </td>
                      <td>N/A</td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(ResidentDetailsBR);

import React from "react";

export default function SubHeaderTimerUK({ monthName, day }) {
  return (
    <span
      className="text-primary font-weight-bold"
      id="kt_dashboard_daterangepicker_date"
    >
      {monthName} {day.toString()}
    </span>
  );
}

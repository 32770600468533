import { common } from '@material-ui/core/colors';
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import React from 'react';
import { Alert, Button, Form, Dropdown, DropdownButton,ButtonToolbar } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

export default class NewBrightreePhysician extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            physicianListUI: [],
            Physician:
            {
                firstName: '',
                lastName: '',
                npi: '',

            }

        }
    }

    componentDidMount() {


    }
setNpiValue=(e)=>{
var npi=e.target.value;
    if(npi.length<11){
        Common.updateInputValueInState(this.state.Physician, e, this, this.state)
    }
    
}
    render() {
        return (
            <>

                <Form validated={this.state.formValidated}>
                    <Card className="">
                        <CardHeader title='Search Physician' />
                        <CardBody>
                            <Alert className="mb-5" variant="secondary">
                                Fill in one or more fields to search physician. For wild card search use * at the end.
                            </Alert>

                            <div className="row">
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="LastName">Last Name</label>
                                        <input type="text" id="LastName"
                                            name="lastName"
                                            onChange={e => Common.updateInputValueInState(this.state.Physician, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                        <div className="invalid-feedback">Last Name Is Required</div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="FirstName">First Name</label>
                                        <input type="text" id="FirstName"
                                            name="firstName"
                                            onChange={e => Common.updateInputValueInState(this.state.Physician, e, this, this.state)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="NPI">NPI</label>
                                        <input type="number" id="NPI"
                                            name="npi"
                                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                            placeholder="0123456789"
                                            value={this.state.Physician.npi}
                                            onChange={e => this.setNpiValue(e)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>

                        </CardBody>
                        <CardFooter className="d-flex justify-content-end py-3">
                        <Button variant="primary" className="mr-5 px-10" size="sm" onClick={e => {this.handleSubmit(e,true)}}>Request New Physician</Button>

                            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e =>{this.handleSubmit(e,false)}}>Find</Button>
                            <Button variant="danger" size="sm" onClick={() => this.props.close()}>Cancel</Button>
                        </CardFooter>
                    </Card>
                </Form>


                <Card className="example example-compact">
                    <CardHeader title='Physician List' />
                    <CardBody>
                        <table className="table table-striped table-hover table-sm table-bordered" id="physicianList">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Telephone #</th>
                                    <th>Zip Code</th>
                                    <th>NPI #</th>
                                    <th width="130px">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.physicianListUI}
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </>
        );
    }

    //Functions

    //#region Get States
    getAllState() {
        Api.getRequest(Api.Uri_GetState).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setState({ states: res.data.result })
                    }
                }
            }

        }).catch((ex) => {
        });
    }

    setAllState(states) {
        return states.map(state => (
            <option
                key={state.key}
                value={state.key}
            >
                {state.value}
            </option>
        ))
    }
    //#endregion

    handleSubmit = (event,isRequest) => {
        const form = event.currentTarget.form;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            let obj = this.state.Physician;
            if(isRequest){
             
                var requestValidation='';
                if(obj.firstName=='')
                {
                    requestValidation='First name is required'
                }
                if(obj.lastName=='')
                {
                    requestValidation=(requestValidation==''?' ':requestValidation +' and')+' Last name is required'
                }
                if(obj.npi=='')
                {
                    requestValidation=(requestValidation==''?' ':requestValidation +' and')+' NPI is required'
                }
                if(requestValidation!=''){
                  
                    Common.showSuccessAlert(requestValidation, "warning", "Alert!", 3000);
                    return false;
                }else{
                    this.URI_FindPhysician(true);
                }

            }
            else{
                if(obj.firstName==''&&obj.lastName==''&&obj.npi==''){
                    Common.showSuccessAlert("please enter first name, last name or npi", "warning", "Alert!", 3000);
                    return false;

                }else{
                    this.URI_FindPhysician(false);
                }
               
            }
            
        }
    }

    URI_FindPhysician(isRequest) {
        let obj = this.state.Physician;

        Api.getRequestQry(
            Api.apiUrls.searcDoctors, `?lastName=${obj.lastName}&firstName=${obj.firstName}&npi=${obj.npi}&isRequest=${isRequest}`
        )
            .then((res) => {
                if (res.data.success) {
                    if(isRequest){
                        Common.showSuccessAlert(res.data.message, "success", "Success!", 3000);
                    }else{
                    if (res.data.result != null) {
                        if(res.data.result.length>0){
                            this.setPhysicianList(res.data.result);
                        }else{
                            Common.showSuccessAlert(res.data.message, "warning", "Warning!", 3000);
                        }
                      
                        // Common.showSuccessAlertAutoClose("Physician Created Successfully", "success", "Success!", 3000);
                        // this.props.close();
                    }
                    else {
                        Common.showSuccessAlert(res.data.message, "warning", "Warning!", 3000);
                    }
                }
                }
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Warning!", 3000);
                }
            }).catch((ex) => {

                // // debugger
                Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
            });
    }
    setPhysicianList = (list) => {
        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.lastName) + " " + Common.replaceNullWithString(obj.firstName)}</td>
                    <td>{Common.replaceNullWithString(obj.address.phone1) != '' ? obj.address.phone1 : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.address.zip) != '' ? obj.address.zip : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.npi) != '' ? obj.npi : 'N/A'} </td>
                    <td align="center">
                        <div className="text-center">

                            <ButtonToolbar className="justify-content-center">
                                <div className="mr-md-4 mr-0 mb-1 mb-md-0">

                                    <Button
                                        size="xs"
                                        variant="success"
                                        onClick={() => {this.props.selectPhysician(obj);this.props.close()}}
                                    >
                                        Select
                                    </Button>
                                </div>
                            </ButtonToolbar>
                        </div>

                    </td>
                </tr>);
            }
            this.setState({ physicianListUI: rows });
        } catch (error) {
        }
    }
}//end of class

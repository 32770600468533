// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const AddFacilityMsg = (props) => {
    // ** States
    const [id, setID] = useState(0);
    const [msg, setMsg] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [msgList, setFacilityMsgList] = useState([])

    
    useEffect(() => {
        formik.setValues({
            msg
        });
    }, [msg]);
    const Schema = Yup.object().shape({
        msg: Yup.string().max(45, "Maximum limit (45 characters)").typeError("Invalid Value").required("Msg is required")

    });
    const formik = useFormik({
        initialValues: {
            msg
        },
        // validateOnChange: false, // this one
        // validateOnBlur: false, // and this one
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleValidSubmit();
            setSubmitting(false);
        },
    });
    useEffect(() => {
        if (props.msg != null) {
            setMsg(props.msg.msg);
            formik.setValues((old) => {
                return {
                    ...old,
                    msg: props.msg.msg,
                }
            })
        } else if (props.msg == null) {
            setMsg('');
            formik.resetForm();
        }
    }, [props.msg]);
    //  Get All organization Type 
    useEffect(() => {
        getAllFacilityMsg();
    }, []);
    const getAllFacilityMsg = async () => {
        setLoading(true)
        var { data } = await Api.getRequestQry(Api.Uri_GetFacilityMsgsByFacilityId,"?facilityId=" + props.facilityID)
        if (data.success) {
            setFacilityMsgList(data.result)
            setLoading(false)

        } else {
            setLoading(false)
        }

    }
    const handleValidSubmit = () => {
        debugger
        Api.postRequest(Api.Uri_CreateFacilityMsg, {msg:msg,fkFacilityId:props.facilityID}).then((res) => {
            if (res.data.success) {
                // props.onSaveAPI();
                Common.showSuccessAlertAutoClose(
                    "Data Saved Successfully",
                    "success",
                    "Success!",
                    3000
                );
                setMsg('')
                getAllFacilityMsg();
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "error",
                    "Error!",
                    3000
                );
            }
        });
    }
    return (
        <Fragment>
            <div className="row" >
                <div className="col-12">
                    <div className="form-group">
                        <label className="form-label font-weight-bold" htmlFor="Length">New Message</label>
                        <textarea id="msg"
                            rows="3"
                            className="form-control"
                            name="msg"
                            value={msg}
                            onChange={e => setMsg(e.target.value)}
                        />
                            <div style={{ fontSize: ".8rem" }} className="text-danger">
                                    {formik.errors.msg}
                                </div>

                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end py-3">
                <Button variant="success" className="mr-5 px-10" size="sm" onClick={formik.handleSubmit}>Save</Button>
            </div>
            <Card>
                <CardHeader className='border-bottom py-1'>
                    <div className="action-icons">
                        
                    </div>
                </CardHeader>
                <CardBody className='pt-2'>
                    <Table responsive striped hover bordered size='sm' className='w-100'>
                        <thead>
                            <tr>
                                <th>Sr #</th>
                                <th>Message</th>
                                <th>Created By</th>
                                {/* <th>Modified By</th> */}
                            </tr>
                        </thead>
                        <tbody>
                        {msgList?.map((ms, i) => {
                                return (
                                    <tr key={i.toString()}>
                                <td>{i+1}</td>
                                <td>{ms.msg}</td>
                                <td>{ms.createdByName}</td>

                                {/* <td>{ms.modifiedByName}</td> */}
                            
                            </tr>
                                 );
                                })}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default AddFacilityMsg

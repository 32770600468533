const groupingHashMapBR = {
  "Pressão/Lesão por Pressão em Membrana Mucosa/Lesão por Pressão Relacionada a Dispositivo Médico": [
    "Pressão",
    "Lesão por Pressão Relacionada a Dispositivo Médico",
    "Lesão por Pressão em Membrana Mucosa",
  ],
  "Diabético/Neuropático": ["Diabético"],
  Arterial: ["Arterial"],
  Venoso: ["Venoso"],
  "Vascular Misto": ["Vascular Misto"],
  Cirúrgico: ["Cirúrgico"],
  "Trauma na Pele": ["Laceração de Pele"],
  Fístula: ["Fístula"],
  "Dermatite Associada à Umidade (DAU)": [
    "Dermatite Associada à Umidade (DAU)",
  ],
  "Úlcera Terminal de Kennedy": ["Úlcera Terminal de Kennedy"],
  Outro: ["Outro", "Sem Pressão"],
};

export default groupingHashMapBR;

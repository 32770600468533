import React from "react";
//import {useLang} from "./Metronici18n";
import {IntlProvider} from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import enMessages from "./messages/en";
import esMessages from "./messages/es";
import ptBRMessages from "./messages/pt-BR";
import { regionTypeEnum } from "../../shared/models/enums/RegionTypeEnum";

const allMessages = {
  'en': enMessages,
  'es': esMessages,
  'pt-BR': ptBRMessages
};


export function I18nProvider({ children }) {

  const locale = regionTypeEnum[process.env.REACT_APP_COUNTRY]?.i18n || regionTypeEnum.US.i18n;
  const messages = allMessages[locale] ? allMessages[locale]:  allMessages['en'] ;

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

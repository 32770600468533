import PropTypes from "prop-types";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";

import { injectIntl } from "react-intl";

const PopupModalWrapper = (props) => {
  const { intl } = props;

  let buttonText = props.buttonText;

  if (props.buttonText === null) {
    buttonText = intl?.formatMessage({
      id: "BUTTON.SAVE_CHANGES.TITLE",
    });
  }

  const handleCloseModal = () => {
    Common.showConfirmation(
      intl?.formatMessage({ id: "BUTTON.CLOSE_WITHOUT_SAVING_DATA.TITLE" }),
      intl?.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
      () => props.hideModal(true), // Passa true para não mostrar a confirmação novamente
      () => { },
      null,
      intl?.formatMessage({ id: "BUTTON.EXIT_WITHOUT_SAVING.TITLE" }),
      false,
      null,
      intl.formatMessage({ id: "BUTTON.CANCEL.TITLE" })
    );
  }

  return (
    <>
      {props.state.show == true && (
        <Modal
          size={props.size}
          aria-labelledby="newProductModal"
          centered
          scrollable
          backdrop="static"
          keyboard={false}
          show={props.state.show}
          onHide={handleCloseModal} // Chama handleCloseModal para garantir a confirmação ao fechar
          dialogClassName={props.dialogClassName}
          enforceFocus={false}
        >
          <Modal.Header className="py-3" closeButton>
            <Modal.Title id="newProductModal">{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>{props.children}</div>
          </Modal.Body>
          <Modal.Footer className="py-2">
            <Button
              variant="danger"
              size="sm"
              onClick={handleCloseModal}
            >
              {intl?.formatMessage({
                id: "BUTTON.CANCEL.TITLE",
              })}
            </Button>
            {props.showButton == true ? (
              <>
                <CheckPermission
                  permission={props.action}
                  module={props.module}
                  yes={() => (
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => props.onButtonPress()}
                    >
                      {buttonText}
                    </Button>
                  )}
                  no={() => null}
                />
              </>
            ) : null}
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const PopupWrapperWithIntl = injectIntl(PopupModalWrapper);

export default class PopupModalComponent extends React.Component {
  constructor(props, context) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const children = this.props.children;

    return (
      <PopupWrapperWithIntl
        state={this.state}
        size={this.props.size}
        title={this.props.title}
        showButton={this.props.showButton}
        buttonPress={this.props.buttonPress}
        buttonText={this.props.buttonText}
        dialogClassName={this.props.dialogClassName}
        action={this.props.action}
        module={this.props.module}
        onClose={this.props.onClose}
        hideModal={this.hideModal}
        onButtonPress={this.onButtonPress}
      >
        {children}
      </PopupWrapperWithIntl>
    );
  }

  //Functions

  showModal = () => {
    this.setState({ show: true });
  };

  onButtonPress = () => {
    if (this.props.buttonPress != null) {
      this.props.buttonPress();
    }
  };

  hideModal = (notShowConfirmation) => {
    if (notShowConfirmation) {
      this.setState({ show: false });
    } else if (this.props.onClose) {
      this.props.onClose(this);
    } else {
      this.setState({ show: false });
    }
  };
} //end of class

PopupModalComponent.defaultProps = {
  showButton: true,
  buttonPress: null,
  buttonText: null,
  size: "xl",
  dialogClassName: "",
  action: null,
  module: null,
  onClose: null,
};

PopupModalComponent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  showButton: PropTypes.bool,
  buttonPress: PropTypes.func,
  buttonText: PropTypes.string,
  size: PropTypes.string,
  dialogClassName: PropTypes.string,
};

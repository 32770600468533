/*eslint-disable */

import React, {useMemo} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {versionNumber} from "../../../../app/General/common"

import { injectIntl } from "react-intl";

//export function Footer() {
export function FooterComponent(props) {

  const { intl } = props;
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
          <a
            href={window.location.hostname}
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            Gentell Fastcare  <small>{versionNumber}  </small>
   
          </a>
        </div>
         <div className="nav nav-dark order-1 order-md-2">
          <a
            className="nav-link pr-3 pl-0" href="#" rel="noopener noreferrer"
          >
            { intl.formatMessage( {id:"FOOTER.ABOUT"} ) }
          </a>
          <a
            href="https://fastcare.freshdesk.com/support/solutions/articles/135948"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link px-3"
          >
            { intl.formatMessage( {id:"FOOTER.HELP"} ) }
          </a>
          {/* <a
            href="http://keenthemes.com/metronic"
            target="_blank"
            rel="noopener noreferrer"
            className="nav-link pl-3 pr-0"
          >
            Contact
          </a>*/}
        </div> 
      </div> 
    </div>
  );
}
export const Footer = injectIntl(FooterComponent);

/*eslint-disable */

import { useEffect, useRef } from "react";
import {
  HubConnectionBuilder,
  HubConnectionState,
  HttpTransportType,
} from "@microsoft/signalr";

/**
 * Custom React hook for establishing a SignalR connection to a notification hub.
 *
 * This hook initializes a SignalR connection using a provided URL and authentication token
 * stored in localStorage. It accepts an array of method configurations, each containing a method name
 * and a callback function to be invoked when messages are received for that method.
 *
 * The connection is established when the hook is first run and is closed when the component
 * using this hook is unmounted. If the connection is already open, it will not attempt to reconnect.
 *
 * @param {string} url The base URL of the SignalR hub to connect to.
 * @param {...Object} methods Variable number of method configuration objects. Each object should have
 *                            a `method` property specifying the name of the SignalR method to listen to,
 *                            and a `methodCallback` property which is a function to be called when a message
 *                            is received for that method.
 *
 * @example
 * useNotificationHub('hubrul.com/notificationHub.com',
 *   { method: 'ReceiveMessage', methodCallback: (data) => console.log(data) },
 *   { method: 'AnotherMethod', methodCallback: (data) => console.log(data) }
 * );
 */
const useNotificationHub = (url, ...methods) => {
  const userToken = localStorage
    .getItem("gentell-fastcare-authToken")
  // .replace(/"/g, "");
  const sanitizedUserToken = userToken ? userToken.replace(/"/g, "") : null;
  const urlWithToken = `${url}?access_token=${sanitizedUserToken}`;
  const connectionRef = useRef(null);

  useEffect(() => {
    if (
      connectionRef.current &&
      connectionRef.current.state === HubConnectionState.Connected
    ) {
      console.log("Connection already open.");
      return;
    }
    const ws = new HubConnectionBuilder()
      .withUrl(urlWithToken, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .build();

    connectionRef.current = ws;
    methods.forEach(({ method, methodCallback }) => {
      ws.on(method, (data) => {
        if (method) {
          methodCallback(data);
        }
      });
    });

    ws.start()
      .then(() => {
        console.log("Connection started!");
      })
      .catch((err) => {
        console.error("Error while establishing connection:", err);
      });

    return () => {
      ws.stop()
        .then(() => {
          console.log("Connection stopped!");
        })
        .catch((err) => {
          console.error("Error while stopping connection:", err);
        });
    };
  }, [url, methods]);
};

export default useNotificationHub;

import { useEffect, useRef, useState } from "react";
import LocalStoragePersistence from "../../../infra/persistence/local-storage";
import JWT from "../../../infra/encoder/jwt";
import AxiosTokenApi from "../../../infra/api/axios-token-api";

const _15_MINUTES = 900_000;
const _5_MINUTES = 300_000;
const _4_HOUR = 14_400_000;
const _50_MINUTES = 2_400_000;

const ACTIVITY_KEY = "activity-timestamp";

/**
 * AutoLogoff component
 * @param {Object} props
 * @param {number} props.timeout
 * @returns
 */
export default function AutoLogoff({ timeout = _4_HOUR }) {
  const storage = LocalStoragePersistence.getInstance();
  const [lastActivity, setLastActivity] = useState(Date.now());
  const timerRef = useRef(null);
  const intervalRef = useRef(null);

  function resetActivityTimer() {
    const now = Date.now();
    setLastActivity(now);
    localStorage.setItem(ACTIVITY_KEY, now.toString());
  }

  function logout() {
    storage.removeItem("gentell-fastcare-authToken");
    storage.removeItem("gentell-fastcare-authUser");
    window.location.assign("/logout?se=ex");
  }

  async function refresh() {
    const refreshTokenApi = new AxiosTokenApi(storage, new JWT());
    await refreshTokenApi.refreshToken();
  }

  async function validateToken() {
    const token = storage.getItem("gentell-fastcare-authToken");

    if (token) {
      const jwtToken = new JWT();

      jwtToken.setToken(token);

      if (jwtToken.isExpired()) {
        logout();
      } else if (jwtToken.itWillExpireSoon(_50_MINUTES)) {
        await refresh();
      }
    }
  }

  async function resetTimer() {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(async () => {
      await validateToken();
    }, 1000);

    await validateToken();
  }

  async function checkInactivity() {
    const hasToken = storage.getItem("gentell-fastcare-authToken");

    const now = Date.now();

    const lastActivityTimestamp = parseInt(
      localStorage.getItem(ACTIVITY_KEY),
      10
    );

    await validateToken();

    if (now - lastActivityTimestamp >= timeout && hasToken) {
      logout();
    }
  }

  useEffect(() => {
    function handleActivity() {
      resetActivityTimer();
      resetTimer();
    }

    function handleStorageEvent(event) {
      if (event.key === ACTIVITY_KEY) {
        setLastActivity(parseInt(event.newValue, 10));
        resetTimer();
      }
    }

    window.addEventListener("mouseover", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("scroll", handleActivity);
    window.addEventListener("click", handleActivity);
    window.addEventListener("storage", handleStorageEvent);

    const intervalToCheckInactivity = setInterval(checkInactivity, 1000);

    intervalRef.current = setInterval(validateToken, _5_MINUTES);

    return () => {
      window.removeEventListener("mouseover", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("scroll", handleActivity);
      window.removeEventListener("click", handleActivity);
      window.removeEventListener("storage", handleStorageEvent);

      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      clearInterval(intervalToCheckInactivity);
    };
  }, [timeout]);

  return null;
}

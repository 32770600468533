export const regionTypeEnum = {
  BR: {
    i18n: "pt-BR",
    components: "BR",
  },
  US: {
    i18n: "en",
    components: "US",
  },
  UK: {
    i18n: "en",
    components: "UK",
  },
};

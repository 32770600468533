import { withMultiRegionProperties } from "../../../wrappers/withMultiRegion";
import groupingHashMapBR from "./groupingHashMapBR";
import groupingHashMapUS from "./groupingHashMapUS";

const groupingHashMapMultiRegion = {
  US: groupingHashMapUS,
  BR: groupingHashMapBR,
};

const groupingHashMapUtils = withMultiRegionProperties(
  groupingHashMapMultiRegion
);

export default groupingHashMapUtils;

import React from "react";
import { Button, Form } from "react-bootstrap";
import SignatureCanvas from 'react-signature-canvas';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { updateInputValueInState, showSuccessAlert, uploadImage, getLookupOptions } from "../../General/common";
export default class AddOrderApprovel extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);
        this.state = {
            formValidated: false,
           signatureCredentials:[],
            IsLoading: false,
            processOrder: {
                facilityID: props.facilityId,
                aproveByName: '',
                signatureURL: '',
                reasonId: null,
                otherReason:'',
                fkOtherCredentialsId:null,
                otherCredentials:''
            }
        }

    }
    componentDidMount() {

        this.getAllLookupsValues();
    }
    updateProcessOrder = (obj) => {
        debugger
        if (obj) {
            // setIsLoading(true);
            Api.postRequest(Api.Uri_UpdateProcessQueue, obj).then((res) => {
                if (res.data.success) {
                    // getAllResident(false);
                    this.props.close();
                    showSuccessAlert(
                        res.data.message,
                        "success",
                        "Success!",
                        3000
                    );
                } else {
                    showSuccessAlert(
                        res.data.message,
                        "warning",
                        "Warning!",
                        3000
                    );
                }
                // setIsLoading(false);
                //Common.showSuccessAlertAutoClose("User Added Successfully", "success", "Success!", 3000);
            });
        }
    };

    handleSubmit = async (event) => {

        //1243 is unstageable check
        //var woundIssue = await getLookupOptions(1)

        const form = event.currentTarget.form;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            debugger
            let obj = this.state.processOrder;
            if (this.sigCanvas != undefined) {
                var img = this.sigCanvas.toDataURL();


                if (!this.sigCanvas.isEmpty()) {
                    var fileData = null;
                    await this.urltoFile(img, 'temp.txt', 'text/plain')
                        .then(function (file) {
                            debugger
                            fileData = file;


                        });
                    await uploadImage(fileData).then((r) => {
                        debugger;
                        if (r != "e") {


                            obj.signatureURL = r;
                        }
                        else {
                        }
                        // this.updateProcessOrder(obj);
                    }).then(() => {
                    });
                    this.updateProcessOrder(obj);
                } else {
                    this.updateProcessOrder(obj);
                }
            } else {
                this.updateProcessOrder(obj);
            }

        }
    }
    async upl(file) {

    }
    async urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }
     getAllLookupsValues = async () => {

        // var woundType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundTypeID)
        // this.setState({ woundTypeSelect: woundType })
        var signatureCredentials = await Common.getLookupOptions(Common.LookupsFK.Fk_SignatureCredentials)

        this.setState({ signatureCredentials: signatureCredentials })
      }
    render() {

        return (
            <>
                <Form

                    validated={this.state.formValidated}
                >
                    <div className="row" >
                        {!this.props.approveWithSig &&
                        <>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="countryId">
                                        Reason
                                    </label>
                                    <select
                                        className="form-control form-control-sm"
                                        name="reasonId"
                                        required
                                        // value={Common.replaceNullWithString(
                                        //     this.state.Resid.countryId
                                        // )}
                                        onChange={(e) =>
                                            updateInputValueInState(
                                                this.state.processOrder,
                                                e,
                                                this,
                                                this.state
                                            )
                                        }
                                        id="countryId"
                                    >
                                        <option></option>
                                        {/* <option value="1">Treatment Nurse is not available (neither is the Director of Nursing)</option> */}
                                        <option value="2">Verbal</option>
                                        {/* <option value="2">Email/Verbal Order</option> */}

                                        {/* <option value="3">Other</option> */}


                                    </select>
                                </div>
                            </div>
                        
                        {this.state.processOrder.reasonId == 3 &&
                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="firstName">
                                  Other  Reason
                                    </label>
                                    <input
                                        type="text"
                                        id="otherReason"
                                        className="form-control form-control-sm"
                                        name="otherReason"
                                        // defaultValue={this.state.Resident.firstName}
                                        required
                                        onChange={(e) =>
                                            updateInputValueInState(
                                                this.state.processOrder,
                                                e,
                                                this,
                                                this.state
                                            )
                                        }
                                    />
                                </div>
                            </div>
    }
                        </>
    }
                        {/* {this.props.approveWithSig && */}
                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="firstName">
                                    Name
                                </label>
                                <input
                                    type="text"
                                    id="aproveByName"
                                    className="form-control form-control-sm"
                                    name="aproveByName"
                                    // defaultValue={this.state.Resident.firstName}
                                    required
                                    onChange={(e) =>
                                        updateInputValueInState(
                                            this.state.processOrder,
                                            e,
                                            this,
                                            this.state
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {/* } */}
                    </div>
                    <div className="row" >
                       
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="countryId">
                                        Credentials
                                    </label>
                                    <select
                                        className="form-control form-control-sm"
                                        name="fkOtherCredentialsId"
                                        required
                                        // value={Common.replaceNullWithString(
                                        //     this.state.Resid.countryId
                                        // )}
                                        onChange={(e) =>
                                            updateInputValueInState(
                                                this.state.processOrder,
                                                e,
                                                this,
                                                this.state
                                            )
                                        }
                                        id="fkOtherCredentialsId"
                                    >
                                        <option ></option>
                                    {this.state.signatureCredentials}


                                    </select>
                                </div>
                            </div>
                        
                        {this.state.processOrder.fkOtherCredentialsId == 1357 &&
                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="firstName">
                                  Other  Credentials
                                    </label>
                                    <input
                                        type="text"
                                        id="otherCredentials"
                                        className="form-control form-control-sm"
                                        name="otherCredentials"
                                        // defaultValue={this.state.Resident.firstName}
                                        required
                                        onChange={(e) =>
                                            updateInputValueInState(
                                                this.state.processOrder,
                                                e,
                                                this,
                                                this.state
                                            )
                                        }
                                    />
                                </div>
                            </div>
    }
                    </div>
                    <div className="row" >
                        {this.props.approveWithSig &&
                            <div className="col-lg-6">
                                <div className="bg-light rounded border mt-6">
                                    <div className="shadow bg-white rounded">
                                        <SignatureCanvas
                                            ref={(ref) => { this.sigCanvas = ref }}
                                            penColor='green'
                                            canvasProps={{ width: 550, height: 150, className: 'sigCanvas' }} />
                                    </div>
                                    <p className="text-center"><small>Signature Above</small></p>
                                    <button type="button" onClick={() => this.sigCanvas.clear()} className="btn btn-danger btn-xs ml-3 mb-3">Clear</button>
                                </div>
                            </div>
                        }
                      
                           </div>
                    <div className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Submit</Button>
                        <Button type="button" variant="dark" size="sm" onClick={() => this.props.close()}>Back</Button>
                    </div>
                </Form>
            </>

        )
    }
}
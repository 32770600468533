import React from "react";
import { Button } from "react-bootstrap";
import {
    Card,
    CardBody,
    CardHeader
} from "../../../../_metronic/_partials/controls/Card";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import { ModulesName, UserActions } from "../../../General/roleModuleActions";
import PopupModal from "../../modal/popupmodal";
import AddDropdownValueForm from "./addDropdownValueForm";
import DropdownValueTable from "./dropdownValueTable";
import EditDropdownValueForm from "./editDropdownValueForm";
const toolbar = (
  <div className="card-toolbar">
    <div className="example-tools">
      <Button
        variant="primary"
        size="sm"
        onClick={() => addDropdownModal.showModal()}
      >
        New Value
      </Button>
    </div>
  </div>
);
// Setting AddDropDown
var addDropdownModal = null;
var addDropdown = null;
var editDropdownModal = null;
var editDropdown = null;
export default class DropdownValue extends React.Component {
  rows = [];

  constructor(props) {
    super(props);
    this.state = { headerValueList: [], objectToEdit: null };
  }
  componentDidMount() {
    this.getHeaderValue();
  }

  componentDidUpdate(prevProps) {
    if (this.props.headerID != prevProps.headerID) {
      this.getHeaderValue();
    }
  }
  getHeaderValue() {
    Api.getRequestById(Api.Uri_GetHeaderValuesByID, this.props.headerID)
      .then((response) => {
        this.setState({ headerValueList: response.data.result });
        Common.LoadDataTable("dropdownValue");
      })
      .catch(function(error) {
        Common.LogError(error, "Dropdown", "getHeaderValue");
        
      });
  }
  afterUpdateHeaderValue() {
    editDropdownModal.hideModal();
    this.getHeaderValue();
    Common.showSuccessAlertAutoClose(
      "Data Updated Successfully",
      "success",
      "Success!",
      3000
    );
  }
  onDropDownFormSubmit() {
    addDropdownModal.hideModal();
    this.getHeaderValue();
    Common.showSuccessAlertAutoClose(
      "Data Saved Successfully",
      "success",
      "Success!",
      3000
    );
  }
  showEditPopup = (obj) => {
    this.setState({ objectToEdit: obj });
    editDropdownModal.showModal();
  };

  tabRowRender = () => {
    return this.state.headerValueList.map((object, i) => {
      return (
        <DropdownValueTable
          showEditPopup={this.showEditPopup}
          obj={object}
          key={i}
        />
      );
      // return <RoleTable showEditPopup={()=>{ editRole={...object}; alert(JSON.stringify(editRole));editRoleModal.showModal(); }} obj={object} key={i} />;
    });
  };
  onDropDownFormUpdate() {
    this.getHeaderValue();
  }

  render() {
    return (
      <div>
        <Card className="example example-compact">
          <CardHeader title="Lookup Values" toolbar={toolbar} />
          <CardBody>
            <table
              className="table table-striped table-hover table-sm table-bordered"
              id="dropdownValue"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Active</th>
                  <th data-priority="2" width="130px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{this.tabRowRender()}</tbody>
            </table>
          </CardBody>
        </Card>

        <PopupModal
          size="lg"
          title="Add Lookup Value"
          module={ModulesName.Module_Setup}
          action={UserActions.Create}
          buttonPress={() => addDropdown.onSubmit()}
          ref={(ref) => {
            addDropdownModal = ref;
          }}
        >
          {" "}
          <AddDropdownValueForm
            headerID={this.props.headerID}
            ref={(ref) => {
              addDropdown = ref;
            }}
            onSaveAPI={() => this.onDropDownFormSubmit()}
          />{" "}
        </PopupModal>
        <PopupModal
          size="lg"
          title="Edit Lookup Value"
          module={ModulesName.Module_Setup}
          action={UserActions.Create}
          buttonPress={() => editDropdown.onSubmit()}
          ref={(ref) => {
            editDropdownModal = ref;
          }}
        >
          {" "}
          <EditDropdownValueForm
            ref={(ref) => {
              editDropdown = ref;
            }}
            dropdown={this.state.objectToEdit}
            onUpdateAPI={() => this.afterUpdateHeaderValue()}
          />{" "}
        </PopupModal>
      </div>
    );
  }
}

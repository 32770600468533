import React from "react";
import { injectIntl } from "react-intl";

function CardHeaderBR({ intl, ...props }) {
  return (
    <div className="card-title">
      <span>
        <b className="text-success">
          {intl.formatMessage({ id: "FACILITY_RESIDENT.FACILITY" })}:{" "}
          {props.facility.facilityName}
        </b>{" "}
        , <br />
        <b>
          {intl.formatMessage({ id: "FACILITY.OWNERSHIP_HEADQUARTERS" })}:
        </b>{" "}
        {props.facility.organizationName == ""
          ? "N/A"
          : props.facility.organizationName}
        ,{" "}
        <b>{intl.formatMessage({ id: "FACILITY.PURCHASING_GORUP_CHAIN" })}:</b>{" "}
        {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
        <h3 className="card-label mt-3">
          {intl.formatMessage({
            id: "FACILITY_RESIDENT.DATA_TABLE.RESIDENT_LIST",
          })}
        </h3>
      </span>
    </div>
  );
}

export default injectIntl(CardHeaderBR);

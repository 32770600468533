export default function formatSSNBR(value) {
  value = value.replace(/\D/g, "");

  if (value.length > 11) {
    value = value.substring(0, 11);
  }

  value = value.replace(/^(\d{3})(\d)/, "$1.$2");
  value = value.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
  value = value.replace(/\.(\d{3})(\d{2})$/, ".$1-$2");

  return value;
}

import React from "react";
import { Tab, Tabs } from "react-bootstrap";
// import OstomyResident from "../outs/ostomyresident";
// import TracheostomyResident from "../outs/tracheostomyResident";
// import UrologicalResident from "../outs/urologicalResident";
// import FacilityResident from "../resident/facilityresident";
import BillingQueue from "./billingqueue";

import OstomyFacility from "./../workqueue/ostomyFacility";
import UrologicalFacility from "./../workqueue/urologicalFacility";
import TracheostomyFacility from "./../workqueue/tracheostomyFacility";


import * as Common from "../../General/common";
import { FormattedMessage, injectIntl } from "react-intl";



export function BillingQueueOrders(props) {
    const {intl}  = props;

    return (
        <>
            <Tabs defaultActiveKey="wounds" id="uncontrolled-tab-example">
                <Tab eventKey="wounds" title={
                    <div>

                        {intl.formatMessage({ id: "BILLING.WOUNDS" })}
                  
                        <div>
                            {/* <small>
                                <span className='badge badge-pill badge-primary my-auto '>
                            </span>
                            </small> */}
                        </div>
                    </div>}>
                    <BillingQueue
                        // backPress={props.backPress}
                        // facility={props.facility}
                    />
                </Tab>
                {/* <Tab eventKey="ostomy" title={
                    <div>
                        Ostomy
                        <div>
                        </div>
                    </div>
                }>
                        <OstomyFacility  processStatusID={Common.ProcessStatus.billingQueueID}
                        />
                  
                </Tab>

                <Tab eventKey="urological" title={
                    <div>
                        Urological
                        <div>
                            
                        </div>
                    </div>
                }>
                     <UrologicalFacility  processStatusID={Common.ProcessStatus.billingQueueID}/>
              
                </Tab>

                <Tab eventKey="tracheostomy" title={
                    <div>
                        Tracheostomy
                        <div>
                         
                        </div>
                    </div>
                }>
                   <TracheostomyFacility  processStatusID={Common.ProcessStatus.billingQueueID}/>
             
                </Tab> */}
           
            </Tabs>
        </>
    );
}
export default injectIntl(BillingQueueOrders);

// export default BillingQueueOrders;
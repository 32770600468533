import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";

const placeholderSSNRegions = {
  US: "123-45-6789",
  CA: "123-456-789",
  UK: "12-34-56-78-90",
  BR: "123.456.789-12",
};

export default withMultiRegionFunction(placeholderSSNRegions);

import React from "react";
import { NavLink } from "react-router-dom";
import getMenuItemActive from "./getMenuItemActive";

export default function GenerateMenuItem(
  location,
  layoutProps,
  moduleName,
  routePath,
  messageId,
  submenuItems = null,
  isExternal = false,
  intl
) {
  return (
    <li
      key={moduleName}
      className={`menu-item ${
        submenuItems ? "menu-item-submenu" : "menu-item-rel"
      } ${getMenuItemActive(location, routePath)}`}
      data-menu-toggle={
        submenuItems ? layoutProps.menuDesktopToggle : undefined
      }
      aria-haspopup={submenuItems ? "true" : undefined}
    >
      {isExternal ? (
        <a
          href={routePath}
          className="menu-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="menu-text">
            {intl.formatMessage({ id: messageId })}
          </span>
        </a>
      ) : (
        <NavLink
          className={`menu-link ${submenuItems ? "menu-toggle" : ""}`}
          to={routePath}
        >
          <span className="menu-text">
            {intl.formatMessage({ id: messageId })}
          </span>
          {submenuItems && <i className="menu-arrow"></i>}
        </NavLink>
      )}
      {submenuItems && (
        <div className="menu-submenu menu-submenu-classic">
          <ul className="menu-subnav">
            {submenuItems.map(({ route, textId }) => (
              <li
                key={route}
                className={`menu-item menu-item-rel ${getMenuItemActive(
                  location,
                  route
                )} `}
              >
                <NavLink className="menu-link" to={route}>
                  <span className="menu-text">
                    {intl.formatMessage({ id: textId })}
                  </span>
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      )}
    </li>
  );
}

import React from "react";

const country = String(process.env.REACT_APP_COUNTRY).toUpperCase() || "US";

export function withMultiRegionComponent(ComponentCountries) {
  return (props) => {
    const ComponentCountry =
      ComponentCountries[country] || ComponentCountries.US;
    return <ComponentCountry {...props} />;
  };
}

export function withMultiRegionFunction(FunctionCountries) {
  const functionRegion = FunctionCountries[country] || FunctionCountries.US;
  return functionRegion;
}

export function withMultiRegionProperties(PropertiesCountries) {
  const propertiesRegion =
    PropertiesCountries[country] || PropertiesCountries.US;
  return propertiesRegion;
}

import React from "react";
import { Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import Loader from "react-loader-spinner";
import { Button, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import PopupModal from '../modal/popupmodal';
import CheckPermission from "../../General/CheckPermission";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import { Animated } from "react-animated-css";
import { Switch } from '@material-ui/core';
import Select from 'react-select';
class AddFacilityUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IsLoading: false,
            selectUserOptions: [],
            userList: [],
            selectedUserList: [],
            formValidated: false,
            facilityUser:
            {
                fkOperatorsPurchasingGroupId: props.operatorsPurchasingGroupID != '' ? props.operatorsPurchasingGroupID : null,
                fkOwnershipHeadquartersId: props.ownershipHeadquartersID != '' ? props.ownershipHeadquartersID : null,
                fkFacilityId: props.facilityID != '' ? props.facilityID : null,
                facilityUserList: []
            }

        };
    }
    componentDidMount() {
        this.getAllUsers();


        this.setState({ IsLoading: true });
    }
    handleSelectedUser = selectedUserList => {
        this.setState({ selectedUserList });
    };
    getUserByRoleID(e) {
        Api.getRequestById(
            Api.Uri_GetUserByRoleID,
            this.props.roleID
        ).then((response) => {
            debugger
            if (response.data.result.length > 0) {

                let users = [];
                response.data.result
                    .map((v) => (
                        users.push({ value: v.key, label: v.value })
                        // <option key={v.key} value={v.key}>
                        //   {v.value}
                        // </option>
                    ));
                this.setState({ selectUserOptions: users });
            }
        })
            .catch(function (error) {

            });


    }
    getAllUsers = () => {
        debugger
        Api.getRequestQry(Api.apiUrls.getFacilityUsers,
            "?operatorsPurchasingGroupID=" + this.props.operatorsPurchasingGroupID +
            "&ownershipHeadquartersID=" + this.props.ownershipHeadquartersID +
            "&facilityID=" + this.props.facilityID)
            .then((response) => {
                debugger
                // this.setState({ selectedUserList: response.data.result });
                this.setAllUserList(response.data.result);
                let userArray = [];
                response.data.result.map(usr => {
                    // if (!product.withOtherButtons) {
                    //     return null;
                    // }
                    if(usr.active==true){
                    userArray.push(
                        {
                            value: usr.fkUserId, id: usr.id, label: usr.userName
                        }
                    )
                    }
                }
                )
                this.setState({ selectedUserList: userArray });
                Common.LoadDataTable("repFirmList");
                this.getUserByRoleID();
            })
            .catch(function (error) {

            });

        //    editUserModal.hideModal();
    }
    setAllUserList = (list) => {
        let rows = [];
        debugger
        try {
            for (var i = 0; i < list.length; i++) {
                const obj = list[i];
                rows.push(<tr key={i.toString()}>

                    <td>
                        {obj.userName}
                    </td>

                    <td>
                        <Switch
                            checked={obj.active}
                            // onChange={handleUserStatus(obj.userID)}
                            value="Status"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </td>




                </tr>);
            }

            this.setState({ userList: rows });
            this.setState({ IsLoading: true });

        } catch (error) {
        }
    }
    onSubmit() {
        debugger
        // if (this.form.checkValidity() === false) {
        //     // this.form.preventDefault();
        //     // this.form.stopPropagation();
        //     let _state = this.state;
        //     _state.formValidated = true;
        //     this.setState(_state);
        // } else {
        this.setState({ IsLoading: false });

        const obj = this.state.facilityUser;
        obj.facilityUserList = this.state.selectedUserList
        Api.postRequest(Api.apiUrls.upsertFacilityUser, obj).then((res) => {
            if (res.data.success) {
                
                this.setState({ IsLoading: true });
                Common.showSuccessAlertAutoClose(
                    "Data Saved Successfully",
                    "success",
                    "Success!",
                    3000
                );
                this.getAllUsers();
            } else {
                Common.showSuccessAlert(
                    res.data.message,
                    "error",
                    "Error!",
                    3000
                );
            }
        });
        // }
    }


    render() {
        return (
            <>

                {(this.state.IsLoading == true) ?
                    <div>
                        <Form
                            validated={this.state.formValidated}
                        >
                            <>

                                <Card className="example example-compact">
                                    <CardHeader title="Users List" toolbar={<div className="card-toolbar">
                                        {/* <div className="example-tools">
                                            <Button
                                                variant="primary"
                                                size="sm"
                                            // onClick={() => showAddUserModel()}
                                            >
                                                Add User
                                            </Button>
                                        </div> */}
                                    </div>} />

                                    <CardBody>
                                        <div className="row mt-2" id="changed">

                                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="ThisFacilityBelongsTo">
                                                        Users

                                                    </label>

                                                    {/* <select
                                                    className="form-control form-control-sm"
                                                    name="fkUserId"
                                                >
                                                    <option></option>
                                                    {this.state.selectUserOptions}
                                                </select> */}
                                                    <Select isMulti='true'
                                                        value={this.state.selectedUserList}
                                                        hideSelectedOptions={true}
                                                        onChange={this.handleSelectedUser}
                                                        options={this.state.selectUserOptions} />



                                                </div>
                                            </div>

                                        </div>
                                        <div className="d-flex justify-content-end py-3">
                                            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.onSubmit(e)}>Submit</Button>
                                        </div>
                                        <table
                                            className="table table-striped table-hover table-sm table-bordered"
                                            id="repFirmList"
                                        >
                                            <thead>
                                                <tr>
                                                    <th data-priority="1">User Name</th>
                                                    <th>Status</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.userList}
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>


                            </>
                        </Form>


                    </div> :
                    <div className="d-flex justify-content-center">
                        <Loader
                            type="Puff"
                            color="#00BFFF"
                            height={50}
                            width={50}
                        />
                    </div>
                }
            </>


        );
    }
}

export default AddFacilityUser;

import React from 'react';
import Select from 'react-select';
import {
  Card,
  CardBody,
  CardHeader
} from "../../../../_metronic/_partials/controls/Card";
import * as Api from "../../../General/api";
import DropdownValue from "./dropdownValue";

import { FormattedMessage, injectIntl } from "react-intl";
class Lookup extends React.Component {

  
  
  constructor(props) {



    
    super(props)
    this.state = {
      selectOptions: [],
      id: "",
      name: '',
      showResults: false
    }
  }
  async getOptions() {
    const res = await Api.getRequest(Api.Uri_GetDropdown)
    const data = res.data.result
    // // debugger
    const options = data.map(d => ({
      "value": d.key,
      "label": d.value

    }))

    this.setState({ selectOptions: options })

  }

  handleChange(e) {
    this.setState({ id: e.value, name: e.label, showResults: true })
  }
  componentDidMount() {
    this.getOptions()
  }

  render() {
    const { intl } = this.props;
    return (
      <div >

        <Card className="example example-compact">
          <CardHeader title="Lookups" />
          <CardBody>
            <div className="col-sm-6 col-md-6 col-lg-6col-xl-6">
              <div className="form-group"><div role="alert" className="fade alert alert-primary show"> { intl.formatMessage({ id: "LOOKUPS.ALERT_DESCRIPTION" }) }</div>
                <Select options={this.state.selectOptions} onChange={this.handleChange.bind(this)} /></div></div>
          </CardBody>
        </Card>
        { this.state.showResults ? <DropdownValue headerID={this.state.id} /> : null}
      </div>
    );
  }

}

export default injectIntl(Lookup);  
import React, { useEffect,  useState } from 'react';
import {Button, Modal,Dropdown, DropdownButton } from "react-bootstrap";
import {Card, CardBody, CardHeader} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { ModulesName, UserActions } from "../../General/roleModuleActions"
import { requestResetPassword } from "../../modules/Auth/_redux/authCrud";
import PopupModal from "../modal/popupmodal";
import NewPersonForm from "./newpersonform";
import AddEditUser from "./addEditUser";
import UserRole from './UserRole';
import { Switch } from '@material-ui/core';

var NewUserModal = null;
var AddUser = null;
var UserRoleModal1=null;
var RoleUser1=null;
export default function UserList(props)  {

 
   
    const [userList, setUserList ]= useState([]);
    const [USERID, setUserID ]= useState('');
    const[  user,setUser]=useState({
      userID:null,
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      mobile: '',
      fkMangerId:null,
      userName:'',
      status: true,
      forGentell:1   //props.isForGentell==1?true:false
    
  
  });
  
  useEffect(() => {
    getAllUsers();
}, []);

 const showEditRolePopup =async (ID) => {
   debugger
   await setUserID(ID)
    UserRoleModal1.showModal();

  };
  const onRoleFormSubmit=()=>
  {
    UserRoleModal1.hideModal();
    getAllUsers();
  }
  const showAddUserModel=async()=>{
   await setUser({
      userId:null,
      email: '',
      firstName: '',
      middleName: '',
      lastName: '',
      mobile: '',
      fkMangerId:null,
      userName:'',
      status: true,
      forGentell:props.isForGentell==1?true:false
    
  
  })
  NewUserModal.showModal();

  }
  const showEditPopup =async (obj) => {
  
    // user=obj;
    await setUser(obj);
    NewUserModal.showModal();
  };
  const updateUserStatus = (obj) => {
    var msg="You want to " + (obj.Status?"Activate":"Deactivate" )+ " user.";
  Common.showConfirmation(msg,"",updateStatus,null, obj)
  
  };
  const updateStatus=(obj)=>{
    if(obj){
      Api.postRequest(Api.Uri_UpdateUserStatus, obj).then((res) => {
        if(res.data.success){
          getAllUsers();
          Common.showSuccessAlertAutoClose("User Status Updated Successfully", "success", "Success!", 3000)
        }else{
          Common.showSuccessAlertAutoClose(res.data.message, "error", "Error!", 3000);
        }
       
        //Common.showSuccessAlertAutoClose("User Added Successfully", "success", "Success!", 3000);
      });
    }
  }

const setPasswordEmail =(email)=>{
  requestResetPassword(email)
  .then((response) => {
     debugger
    if(response.status==200){
      Common.showSuccessAlertAutoClose("Set password email sent Successfully", "success", "Success!", 3000)
    }else{
      Common.showSuccessAlertAutoClose(response.data.message, "error", "Error!", 3000);
    }
    
   
   })
.catch(() => {

});
}
  const onUserFormSubmit=()=> {
    NewUserModal.hideModal();
    //getHeaderValue() ;
     Common.showSuccessAlertAutoClose("User Saved successful", "success", "Success!", 3000);
    getAllUsers();
  }

  const UserRoleButton=async (ID)=> {
   await showEditRolePopup(ID);
  }
  // handleEdit = () =>{
  //   showEditPopup(t.obj);
  // }
  const userRolesBadge=(_userRoles)=>{

    let userBadgeRoleUI = [];

    let userRoles = _userRoles.split(',')

    for( var i = 0; i < userRoles.length; i++){
      userBadgeRoleUI.push( <span className="badge badge-warning ml-1" key={i}>{userRoles[i]}</span>  );
    }

    return userBadgeRoleUI;
  }
  const handleUserStatus = userID => event => {
    // // debugger
    let user={};
    user.UserID=userID;
    user.Status=event.target.checked 
    updateUserStatus(user);
    // setState({
    //   Status:event.target.checked 
    // })
  };

  const getAllUsers=() =>{
    debugger
    Api.getRequestQry(Api.apiUrls.getAllUsers,"?isForGentell="+props.isForGentell)
      .then((response) => {
        // setState({ userList: response.data.result });
        setAllUserList(response.data.result);
        Common.LoadDataTable("repFirmList");
      })
      .catch(function(error) {
        
      });

//    editUserModal.hideModal();
  }
  const setAllUserList = (list) => {
    let rows = [];
    try {
        for (var i = 0; i < list.length; i++) {
            const obj = list[i];
            rows.push(<tr key={i.toString()}>
         
          <td>
            {obj.firstName}
          </td>
          <td>
            {obj.lastName}
          </td>
          <td>
            {obj.userName}
          </td>
          <td>
            {obj.email}
          </td>
          <td>  
      <Switch
        checked={obj.status}
        onChange={handleUserStatus(obj.userID)}
        value="Status"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
          {/* {props.obj.status==true?<span className="badge badge-success">Active</span>:<span className="badge badge-danger">In-Active</span>}  */}
          </td>
          <td>
            {obj.mobile}
          </td>
          <td>  
          {obj.roles==""?<span className="badge badge-success"></span>: userRolesBadge(obj.roles)} 
          </td>

          {/* <td>
          <span className="badge badge-warning"> {.props.obj.roles}</span>
          </td> */}
          <td align="center">
            <div className="text-center">

              <DropdownButton
                drop={"down"}
                size="xs"
                variant="primary"
                title={'Actions'}
                id={`dropdown-button-drop-actions`}
                key={'down'}>

                {/* <Dropdown.Item eventKey="1" onClick={() => .editButton(props.obj.userId)} >Edit User </Dropdown.Item> */}
                <Dropdown.Item onClick={()=> showEditPopup(obj) }  eventKey="1" >Edit User</Dropdown.Item>
                <Dropdown.Item eventKey="1" onClick={() => UserRoleButton(obj.userID)} >User Roles </Dropdown.Item>
                <Dropdown.Item eventKey="1" onClick={() => setPasswordEmail(obj.email)} >Set Password Email </Dropdown.Item>
                
                
                {/* <Dropdown.Item eventKey="2" >Add Person</Dropdown.Item>
                <Dropdown.Item eventKey="2" >Add Person</Dropdown.Item>
                <Dropdown.Item eventKey="3" >Edit Ship To Address</Dropdown.Item>
                <Dropdown.Item eventKey="4" >Edit Bill To Address</Dropdown.Item>
                <Dropdown.Item eventKey="5" className="text-danger" >Delete</Dropdown.Item> */}
              </DropdownButton>
            </div>
          </td>
            </tr>);
        }
      
        setUserList( rows );
    } catch (error) {
    }
}
 
    return (
      <>
        <Card className="example example-compact">
          <CardHeader title="Users List" toolbar={  <div className="card-toolbar">
    <div className="example-tools">
      <Button
        variant="primary"
        size="sm"
        onClick={() => showAddUserModel()}
      >
        Add User
      </Button>
    </div>
  </div>} />

          <CardBody>
            <table
              className="table table-striped table-hover table-sm table-bordered"
              id="repFirmList"
            >
              <thead>
                <tr>
                  <th data-priority="1">First Name</th>
                  <th>Last Name</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Phone</th>
                  <th>Role(s)</th>
                  <th data-priority="2" width="130px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {userList}
                </tbody>
            </table>
          </CardBody>
        </Card>
        {
          <PopupModal
            size="lg"
            title="Add User"
            module={ModulesName.Module_Setup}
            action={UserActions.Create}
            buttonPress={() => AddUser.onSubmit()}
            ref={(ref) => {
              NewUserModal = ref;
            }}
          >
            {" "}
            <AddEditUser
              ref={(ref) => {
                AddUser = ref;
              }}
              onSaveAPI={() => onUserFormSubmit()}
              user={user}
            />{" "}
          </PopupModal>
        }
        {/* <PopupModal
          size="lg"
          title="Edit User"
          module={ModulesName.Module_Setup}
          action={UserActions.Update}
          buttonPress={() => editUser.onSubmit()}
          ref={(ref) => {
            editUserModal = ref;
          }}
        >
          {" "}
          <EditUserFrom
            ref={(ref) => {
              editUser = ref;
            }}
            user={state.objectToEdit}
            onSaveAPI={() => onUserFormEdit()}
          />{" "}
        </PopupModal> */}
        
        <PopupModal 
        size="lg" 
        title= {"Assign Role "}
        //To ("+props.obj.firstName +" "+props.obj.lastName+")"}
        module={ModulesName.Module_Setup}
        action={UserActions.Update}
         buttonPress={() => RoleUser1.onButtonPress()} 
         ref={ref => { UserRoleModal1 = ref; }}> 
         <UserRole 
         userID={USERID}
         isForGentell={props.isForGentell}
          ref={ref => { RoleUser1 = ref; } } onSaveAPI={()=>onRoleFormSubmit()} /> 
          </PopupModal>
        <Modal
          size="xl"
          aria-labelledby="addPersonModal"
          centered
          scrollable
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header className="py-3" closeButton>
            <Modal.Title>New Sales Rep</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewPersonForm />
          </Modal.Body>
        </Modal>
      </>
    );
  
}

import React from "react";
import ControlledTabs from "./tabs";

export default class AddUpdateForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }



  render() {
    return (
      <>
        <ControlledTabs />
      </>
    );
  }
}

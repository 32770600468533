import { useFormik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { loginBtUser } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
import * as Common from "../../../General/common";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function SetBTCredentials(props) {

  debugger

  const { intl } = props;
  const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    // email: Yup.string()
    //   // .email("Wrong email format")
    //   // .min(3, "Minimum 3 symbols")
    //   // .max(50, "Maximum 50 symbols")
    //   .required(
    //     intl.formatMessage({
    //       id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //     })
    //   ),
    password: Yup.string()
      // .min(3, "Minimum 3 symbols")
      // .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    
    initialValues,
    validationSchema: LoginSchema,
    
    onSubmit: (values, { setStatus, setSubmitting }) => {
      debugger
      enableLoading();
      setTimeout(() => {
        // var Username='admin';
        //  var Password ='Admin1234';
        //  const body = { Username, Password };
        // var Username = `${values.email}`; //values.email;
        var Password = `${values.password}`; // values.password;
        // // debugger;
        // const body = { Username, Password };
        //  axios.post('https://localhost:44306/api/Account/authenticate',body )

        //var Username='admin';
        // var Password ='Admin1234';
        // const body = { Username, Password };
        //  // // debugger
        // axios.post('https://localhost:44306/api/Account/authenticate', body);
        //axios.post('https://localhost:44306/api/Account/authenticate',body )

        loginBtUser(props.user.userName, Password,props.user.userID)
          //login(Username, Password)

          // .then((accessToken)=> {
          //   disableLoading();

          //   console.log(accessToken)
          //   // // debugger
          //   alert("coming")
          //   //return <Redirect to='/dashboard'/>
          //  //return  <Redirect  to="/dashboard" />
          //  // return <Redirect from="/auth" to="/"/>
          //  // props.login(accessToken);
          // })
          // .then(({ data: { accessToken, user } }) => {
          .then((res) => {

            disableLoading();
            setSubmitting(false);

            if (res.data.success) {
             Common.showSuccessAlert(
              "Your Password updated successfully. Next time Please login into fastcare using this password",
              "success",
              "Success!",
              3000
            );
              props.CloseBtCrendentialsModal(res.data.result);
            } else {
              setStatus(
                intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN",
                })
              );
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 100);
    },
  });

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  // const handleSubmit = (evt) => {
  //   // // debugger;
  //   evt.preventDefault();
  //   alert(`${email}  ${password}`);
  // };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10">
        <h3 className="font-size-h1">
          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          Update Password
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your brightree password
        </p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        //onSubmit={handleSubmit}
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
            <></>
          )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="text"
            disabled={true}
            className={`form-control form-control-solid h-auto py-5 px-6 `}
            name="email"
            defaultValue={props.user.userName}
            // {...formik.getFieldProps("email")}
            // value={email}
            // onChange={(e) => setEmail(e.target.value)}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
      
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span>BT Sign In</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
      {/*end::Form*/}

      
     
    </div>
  );
}

// export default SetBTCredentials;
export default injectIntl(connect(null, auth.actions)(SetBTCredentials));
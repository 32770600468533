import React from "react";

import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";
import residentDetailsUS from "./lang/residentDetailsUS";
import residentDetailsBR from "./lang/residentDetailsBR";
import residentDetailsUK from "./lang/residentDetailsUK";

const ResidentDetailsMultiRegion = withMultiRegionComponent({
  US: residentDetailsUS,
  UK: residentDetailsUK,
  BR: residentDetailsBR,
});

function ResidentDetailsWrapper(props) {
  return <ResidentDetailsMultiRegion {...props} />;
}

export default ResidentDetailsWrapper;

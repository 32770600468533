import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from "react";
import { Col, Form } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
//export function RepFirmForm () {
var newDate = new Date();

export default class EditRoleForm extends React.Component {
  
  constructor(props) {
    super(props);   
    this.state = {
      formValidated: false,
      Role: { ... this.props.role 
      }
    }; 
  }

  onSubmit() {
    if (this.form.checkValidity() === false) {
      let _state = this.state
      _state.formValidated = true;
      this.setState(_state);
    }
    else {
    // e.preventDefault();
    let obj = this.state.Role;
    Api.postRequest("role/update", obj).then((res) => {
      
      this.props.onSaveAPI();
      Common.showSuccessAlertAutoClose("Role Updated Successfully", "success", "Success!", 3000);
    });
    
  }
    //Common.showSuccessAlert(res.data.message, "error", "Error! " + res.data.statusCode);
  }
  render() {
    return (
      <Form  
      ref={(ref)=> {this.form = ref}}
      validated={this.state.formValidated}
      onSubmit={e => this.onSubmit(e)}>
        <Form.Row>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label> Name</Form.Label>
            <Form.Control
             id="roleNameValidation"
              required
              type="text"
              name="roleName" 
              onChange={(e) =>
                Common.updateInputValueInState(
                  this.state.Role,
                  e,
                  this,
                  this.state
                )
              }
              value={this.state.Role.roleName}
              placeholder="Role Name"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Name is Required
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label> Description</Form.Label>
            <Form.Control
             id="descriptionValidation"
              required
              type="text"
              name="description"
              onChange={(e) =>
                Common.updateInputValueInState(
                  this.state.Role,
                  e,
                  this,
                  this.state
                )
              }
              value={this.state.Role.description}
              placeholder="Description"
            />
            <Form.Control.Feedback></Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
            Description is Required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group >
          <FormControlLabel
            control={<Checkbox checked={this.state.Role.forGentell}  
            id="ForGentell"  
            name="forGentell"
             onChange={(e) =>
              Common.updateInputValueInState(
                this.state.Role,
                e,
                this,
                this.state
              )
            } 
           
            />}
            label="Is Gentell Role"
          />
            </Form.Group>
        </Form.Row>
      </Form>
    );
  }
}

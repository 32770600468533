import React from 'react';
import { Badge } from "react-bootstrap";
import $ from 'jquery';

export function Facilityorderslist() {

    //#region loop to  print the rep firm list
    var rows = [];
    for (var i = 1; i < 100; i++) {
        rows.push(<tr key={i.toString()}>
            {/* open facility detail modal on click of facility */}
            <td> <button type="button" className="btn btn-link">Abigail House ** AWS/KALISTHENICS**</button></td>
            {/* open order detail modal on click of order # */}
            <td> <button type="button" className="btn btn-link">357670</button></td>
            {/* open resident information modal on click of name */}
            <td> <button type="button" className="btn btn-link">Irene Gavin</button></td>
            <td>09/29/2020</td>
            <td>Resident Order</td>
            <td>Medicare</td>
            <td align="center"><Badge variant="primary">Internal processing</Badge></td>
        </tr>);
    }
    //#endregion

    //initialize datatable
    $(document).ready(function () {

        if ($.fn.dataTable.isDataTable('#facilityOrdersList')) {
            $('#facilityOrdersList').DataTable = $('#facilityOrdersList').DataTable();
        }
        else {
            $('#facilityOrdersList').DataTable({
                responsive: true,
                pageLength: 15,
                lengthMenu: [[15, 25, 50, -1], [15, 25, 50, "All"]],
            });
        }
    });

    return (
        <>
            <table className="table table-striped table-hover table-sm table-bordered" id="facilityOrdersList">
                <thead>
                    <tr>
                        <th>Facility</th>
                        <th>Order#</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>OrderType</th>
                        <th>Bill</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </>
    );

}

export default Facilityorderslist;
import React, { useState, useEffect, useCallback } from "react";
import { Button, Form } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls/Card";
import Select, { createFilter } from "react-select";
import Loader from "react-loader-spinner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  getRequest,
  Uri_GetFacility,
  getRequestQry,
  Uri_GetFacilityResidentWoundDocumentation,
} from "../../../General/api";
import { showSuccessAlertAutoClose } from "../../../General/common";
import moment from "moment";
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { English } from "flatpickr/dist/l10n/default.js";
import WithGroupedWoundReports from "./withGroupedWoundReports";
import { FormattedMessage, injectIntl } from "react-intl";

const FacilityReport = ({ intl }) => {
  const [facilitySelected, setFacilitySelected] = useState(null);
  const [formValidated, setFormValidated] = useState(false);
  const [facilityList, setFacilityList] = useState([]);
  const [IsLoading, setIsLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [woundEvaluations, setWoundEvaluations] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);

  const successStatusCode = 200;
  const defaultCloseModalTimeout = 3000;

  const acquisitionEnum = {
    1: intl.formatMessage({ id: 'REPORTS.WOUNDS.ADMITTED' }),
    2: intl.formatMessage({ id: 'REPORTS.WOUNDS.ACQUIRED' }),
    3: intl.formatMessage({ id: 'REPORTS.WOUNDS.CLOSED' }),
    4: intl.formatMessage({ id: 'REPORTS.WOUNDS.REOCCURRING' }),
  };

  const getFlatpickrLocale = (locale) => {
    const formattedLocale = locale.split('-')[0];
    switch (formattedLocale) {
      case 'pt':
        return Portuguese;
      default:
        return English;
    }
  };

  const fromDateRef = useCallback((node) => {
    if (node !== null) {
      setFromDate(
        flatpickr(node, {
          allowInput: true,
          mode: "single",
          altInput: true,
          locale: getFlatpickrLocale(intl.locale),
          altFormat: "d-m-Y",
        })
      );
    }
  }, [intl.locale]);

  const toDateRef = useCallback((node) => {
    if (node !== null) {
      setToDate(
        flatpickr(node, {
          allowInput: true,
          mode: "single",
          altInput: true,
          locale: getFlatpickrLocale(intl.locale),
          altFormat: "d-m-Y",
        })
      );
    }
  }, [intl.locale]);

  useEffect(() => {
    getRequest(`${Uri_GetFacility}?isAssessmentView=1`)
      .then((res) => {
        const data = res.data;
        if (
          data.statusCode === successStatusCode &&
          data.result != null &&
          data.result.length > 0
        ) {
          setFacilityList(
            data.result
              .map((facility) => ({
                value: facility.id,
                label: facility.facilityName,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        showSuccessAlertAutoClose(
          intl.formatMessage({ id: 'REPORTS.WOUNDS.ERROR_OCCURRED' }),
          "warning",
          "warning!",
          defaultCloseModalTimeout
        );
      });
  }, [intl]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const fromDateFormatted = moment(fromDate.selectedDates[0]).format(
      "YYYY-MM-DD"
    );
    const toDateFormatted = moment(toDate.selectedDates[0]).format(
      "YYYY-MM-DD"
    );
    getRequestQry(
      Uri_GetFacilityResidentWoundDocumentation,
      `?facilityId=${facilitySelected.value}&fromDate=${fromDateFormatted}&toDate=${toDateFormatted}`
    )
      .then((res) => {
        const data = res.data;
        if (data.statusCode === 200) {
          if (data.result != null) {
            setWoundEvaluations(data.result);
            setShowReport(true);
          } else {
            showSuccessAlertAutoClose(
              intl.formatMessage({ id: 'REPORTS.WOUNDS.RECORD_NOT_FOUND' }),
              "warning",
              "warning!",
              defaultCloseModalTimeout
            );
            setShowReport(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        showSuccessAlertAutoClose(
          intl.formatMessage({ id: 'REPORTS.WOUNDS.ERROR_OCCURRED' }),
          "warning",
          "warning!",
          defaultCloseModalTimeout
        );
      });
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFacilitySelected(null);
    fromDate.clear();
    toDate.clear();
    setWoundEvaluations(null);
    setShowReport(false);
    setFormValidated(false);
  };

  const renderWoundForm = () => {
    return (
      <>
        {!IsLoading ? (
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="FacilityName">
                    <FormattedMessage id="REPORTS.FACILITYREPORT.FACILITY_NAME" defaultMessage="Facility Name" />
                  </label>
                  <Select
                    options={facilityList}
                    id="facilityId"
                    name="facilityId"
                    placeholder={intl.formatMessage({ id: "REPORTS.WOUND.SELECT" })}
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: "start" })}
                    onChange={(e) => setFacilitySelected(e)}
                    value={facilitySelected}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="fromDate">
                    <FormattedMessage id="REPORTS.FACILITYREPORT.FROM_DATE" defaultMessage={intl.formatMessage({ id: "REPORTS.WOUND.TO_DATE" })} />
                  </label>
                  <input
                    type="date"
                    id="fromDate"
                    placeholder={intl.formatMessage({ id: "REPORTS.WOUND.SELECT" })}
                    required
                    className="form-control form-control-md"
                    ref={fromDateRef}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="toDate">
                    <FormattedMessage id="REPORTS.FACILITYREPORT.TO_DATE" defaultMessage="To Date" />
                  </label>
                  <input
                    type="date"
                    id="toDate"
                    placeholder={intl.formatMessage({ id: "REPORTS.WOUND.SELECT" })}
                    required
                    className="form-control form-control-md"
                    ref={toDateRef}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-3">
              <Button
                variant="success"
                className="mr-5 px-10"
                size="sm"
                type="submit"
              >
                <FormattedMessage id="REPORTS.FACILITYREPORT.SEARCH_REPORT" defaultMessage="Search Report" />
              </Button>
              <button
                className="btn btn-danger btn-sm"
                onClick={(e) => handleClearFilters(e)}
              >
                <FormattedMessage id="REPORTS.FACILITYREPORT.CLEAR_FILTER" defaultMessage="Clear filter" />
              </button>
            </div>
          </form>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader type="Puff" color="#00BFFF" height={50} width={50} />
          </div>
        )}
      </>
    );
  };

  async function PrintElem() {
    setIsPrinting(true);
    let doc = new jsPDF("landscape", "pt", "a4", false);

    const elm = document.querySelectorAll("div[id^='printThis']");
    for await (const item of elm) {
      await html2canvas(item, { scale: 2 }).then((canvasItem) => {
        const pixelHeight = 840 * 2;
        for (let i = 0; i <= item.clientHeight * 2; i += pixelHeight) {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = canvasItem.width;
          canvas.height = pixelHeight;
          ctx.drawImage(
            canvasItem,
            0,
            i,
            canvasItem.width,
            pixelHeight,
            0,
            0,
            canvasItem.width,
            pixelHeight
          );
          const imgData = canvas.toDataURL("image/png");
          doc.addImage(imgData, "PNG", 10, 30, 822, 0, undefined, "FAST");
          if (i + pixelHeight < item.clientHeight * 2) {
            doc.addPage();
          }
        }
      });

      if (Array.from(elm).indexOf(item) !== elm.length - 1) {
        await doc.addPage();
      }
    }
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFont("Poppins", "normal", "bold");
    doc.setFontSize(8);
    doc.setTextColor(112, 108, 108);
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text(
        intl.formatMessage({ id: 'REPORTS.FACILITYREPORT.PAGE' }) + ' ' + i + ' ' + intl.formatMessage({ id: 'REPORTS.FACILITYREPORT.OF' }) + ' ' + pageCount,
        doc.internal.pageSize.width - 50,
        doc.internal.pageSize.height - 20
      );
    }

    doc.save(`${facilitySelected.label} - ${intl.formatMessage({ id: 'REPORTS.FACILITYREPORT.WOUND_REPORT' })}.pdf`);
    setIsPrinting(false);
  }

  return (
    <>
      <Card>
        <CardHeader className="Text-center" title={intl.formatMessage({ id: 'REPORTS.FACILITYREPORT.TITLE' })}>
          <CardHeaderToolbar>
            <div className="example-tools"></div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {renderWoundForm()}
          <hr />
          {showReport && (
            <>
              <div className="row">
                <div className="col-12">
                  <button
                    disabled={isPrinting}
                    type="button"
                    onClick={
                      PrintElem
                    }
                    className="mr-3 btn btn-success btn-sm float-right"
                  >
                    {isPrinting ? (
                      <Loader type="Puff" color="#000" height={15} width={15} />
                    ) : (
                      <FormattedMessage id="REPORTS.FACILITYREPORT.PRINT_REPORT" defaultMessage="Print Report" />
                    )}
                  </button>
                </div>
              </div>
              <div id="printit" className="printDiv">
                <WithGroupedWoundReports woundEvaluation={woundEvaluations} />
              </div>
              <canvas
                id="fixedCanvas"
                width="100"
                height="100"
                style={{ display: "none" }}
              ></canvas>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default injectIntl(FacilityReport);

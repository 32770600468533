import React from "react";
import { injectIntl } from "react-intl";
import ReactSelect from "react-select";

function Select({ intl, ...props }) {
  return (
    <ReactSelect
      {...props}
      noOptionsMessage={() =>
        intl.formatMessage({ id: "INPUT_SELECT.NO_OPTIONS" })
      }
    />
  );
}

export default injectIntl(Select);

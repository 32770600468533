/*eslint-disable */
import { FormControlLabel } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Loader from "react-loader-spinner";
import React from "react";
import { Button, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import RBAC from "../../General/roleModuleActions";
import ProductSelection from "../products/productselection";
import { common } from "@material-ui/core/colors";
import moment from "moment";
import ViewWound from "./viewWound";

export default class NewWound extends React.Component {
  // eslint-disable-next-line no-use-before-define
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      formValidated: false,
      IsLoading: false,
      isWorkQueue: props.isWorkQueue ?? false,
      showLastOrderWoundComponent: false,
      lastOrdermodal: false,
      GetCodeProcess: "Calculate",
      woundTypeSelect: [],
      allWoundTypeSelect: [],
      isFacilityStaff:
        props.user.roles.roleList.find(
          (x) =>
            x.roleName == RBAC.Role_Facility_Level1 ||
            x.roleName == RBAC.Role_Facility_Level2
        ) != undefined
          ? true
          : false,
      orderProducts: [],
      selectedWoundTypeSelect: [],
      pressureLocation: [],
      nonPressureLocation: [],
      nonPressureLocationSurgery: [],
      thicknessStage: [],
      nonThicknessStage: [],
      WoundSide: [],
      WoundSevereTissue: [],
      WoundAcquisition: [],
      WoundExudateAmount: [],
      WoundExudateType: [],
      WoundDebridementType: [],
      woundBodyRegionSelect: [],
      filterdWoundBodyRegionSelect: [],
      woundICDCodeSheet: [],
      WoundPrimaryDressing: [],
      WoundSecondaryDressing: [],
      WoundType: "Pressure",
      WoundTypeSelected: "",
      IsThicknessStageTwo: false,
      IsPartialThickness: false,
      IsSloughRequired: false,
      IsNecroticRequired: false,
      ICD10Code: "",
      PushScore: "",
      isReorderAndTissueTypeNotChange:
        props.isReorder && props.resident.isWoundInfoChange != true
          ? true
          : false,
      ReoderAndIsDisableOrientation: false,
      Resident: JSON.parse(JSON.stringify(props.resident)),

      showProduct: props.showProduct,
      orderNote: "",
      orientationLeft: null,
      orientationRight: null,

      orientationUpper: null,
      orientationLower: null,

      orientationInner: null,
      orientationOuter: null,

      orientationAnterior: null,
      orientationPosterior: null,

      orientationMedial: null,
      orientationLateral: null,

      orientationDorsal: null,
      orientationDistal: null,
      orientationProximal: null,
      // orientationLeftRight: null,
      // orientationUpperLower: null,
      // orientationInnerOuter: null,
      // orientationAnteriorPosterior: null,
      // orientationMedialLateral: null,
      // orientationDorsalDistalProximal: null
    };
    this.productSelection = null;
    // this.onValueChange = this.onValueChange.bind(this);
    this.onTissueValueChange = this.onTissueValueChange.bind(this);
  }
  componentDidMount() {
    this.getAllLookupsValues();
    this.getAllWoundType();
  }
  getAllLookupsValues = async () => {
    // var woundType = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundTypeID)
    // this.setState({ woundTypeSelect: woundType })

    var woundIssue = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WoundSevereTissueTypeID
    );
    this.setState({ WoundSevereTissue: woundIssue });
    // var PressureLocation = await Common.getLookupOptions(Common.LookupsFK.Fk_fkPressureLocationID)
    // this.setState({ pressureLocation: PressureLocation })
    // var NonPressureLocation = await Common.getLookupOptions(Common.LookupsFK.Fk_fkNonPressureLocationID)
    // this.setState({ nonPressureLocation: NonPressureLocation })
    var NonPressureLocationSurgery = await Common.getLookupOptions(
      Common.LookupsFK.Fk_fkNonPressureLocationSurgeryID
    );
    this.setState({ nonPressureLocationSurgery: NonPressureLocationSurgery });
    // var PressureThikness = await Common.getLookupOptions(Common.LookupsFK.Fk_fkPressureThiknessID)
    // this.setState({ thicknessStage: PressureThikness })
    var NonPressureThikness = await Common.getLookupOptions(
      Common.LookupsFK.Fk_fkNonPressureThiknessID
    );
    this.setState({ nonThicknessStage: NonPressureThikness });

    var WoundSide = await Common.getLookupOptions(
      Common.LookupsFK.Fk_fkWoundSideID
    );
    this.setState({ WoundSide: WoundSide });

    var WoundAcquisition = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WoundAcquisitionID
    );
    this.setState({ WoundAcquisition: WoundAcquisition });

    // var WoundExudateAmount = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundExudateAmountID)
    // this.setState({ WoundExudateAmount: WoundExudateAmount })

    var WoundExudateType = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WoundExudateTypeID
    );
    this.setState({ WoundExudateType: WoundExudateType });

    var WoundDebridementType = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WoundDebridementTypeID
    );
    this.setState({ WoundDebridementType: WoundDebridementType });

    var WoundPrimaryDressing = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WoundPrimaryDressingID
    );
    this.setState({ WoundPrimaryDressing: WoundPrimaryDressing });

    var WoundSecondaryDressing = await Common.getLookupOptions(
      Common.LookupsFK.Fk_WoundSecondaryDressingID
    );
    this.setState({ WoundSecondaryDressing: WoundSecondaryDressing });
  };
  getAllWoundType() {
    this.setState({ IsLoading: true });
    Api.getRequest(Api.Uri_GetAllWoundType)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            let woundTypes = res.data.result;

            this.setState({ allWoundTypeSelect: woundTypes });
          }
        }
      })
      .catch((ex) => {});
    Api.getRequest(Api.Uri_GetAllICDDropDownList)
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            this.setState({
              woundICDCodeSheet: res.data.result.woundToICDList,
            });
            var woundType = this.props.resident.wound.fkWoundTypeId;
            var bodyRegion = this.props.resident.wound.fkBodyRegionId;
            var fkWoundSevereTissueTypeId = this.props.resident.wound
              .fkWoundSevereTissueTypeId;
            if (woundType > 0) {
              var resident = this.props.resident;
              resident.wound.debridementDate = Common.getInputStringDateUsingMoment(
                moment(resident.wound.debridementDate)
              );
              this.setState({ Resident: resident });
              this.checkWoundType(woundType);
              this.onBodyRegionChangeSetValues(bodyRegion);
              // Diable orientation if orientation in availble reorder
              if (
                this.state.Resident.wound.orientationLeftRight != null ||
                this.state.Resident.wound.orientationInnerOuter != null ||
                this.state.Resident.wound.orientationMedialLateral != null ||
                this.state.Resident.wound.orientationUpperLower != null ||
                this.state.Resident.wound.OrientationAnteriorPosterior !=
                  null ||
                this.state.Resident.wound.OrientationDorsalDistalProximal !=
                  null
              ) {
                this.setState({ ReoderAndIsDisableOrientation: true });
              }

              this.setOrientationValues(
                this.props.resident.wound.fkWoundLocationId,
                false
              );
              var icdCode = this.props.resident.wound.icdcode;

              this.setState({
                ICD10Code: icdCode,
              });

              // this.setState({
              //     pushScore: pushScore
              // })
            }
          }
        }
        this.setState({ IsLoading: false });
      })
      .catch((ex) => {this.setState({ IsLoading: false });});
  }
  onThicknessStageChange = (e) => {
    // this.resetICDDropdowns();
    // if (
    //   this.state.Resident.wound.thicknessIdForBackStagging <= Number(e.target.value) ||
    //   this.state.Resident.wound.thicknessIdForBackStagging == null ||
    //   (this.state.Resident.wound.thicknessIdForBackStagging == 5 &&
    //     Number(e.target.value) != 2)
    // ) {
    // what is the purpose of this???
      Common.updateInputValueInState(
        this.state.Resident.wound,
        e,
        this,
        this.state
      );

      if (e.target.value == "2") {
        //check Thickness stage two to disable slough
        var ICDCodeObj = {};
        ICDCodeObj.sloughPercentage = 0.0;
        ICDCodeObj.necroticPercentage = 0.0;

        this.setState({
          IsThicknessStageTwo: true,
          ICDCode: ICDCodeObj,
        });
      } else if (e.target.value == "5") {
        this.setState({ IsSloughRequired: true, IsNecroticRequired: true });
      } else {
        this.setState({ IsThicknessStageTwo: false });
      }
    // FIXME: should be not able to change stage if is a surgical or non-pressure and user change wound type to pressure and then try to add stage for wound
    // } else {
    //   Common.showSuccessAlert(
    //     "Reverse staging of wound is not allowed",
    //     "warning",
    //     "Warnng!",
    //     3000
    //   );
    // }

    this.onThicknessChangeSetDropdownValues(e.target.value);
  };
  onNonThicknessChange = (e) => {
    if (
      this.state.Resident.wound.thicknessIdForBackStagging <= e.target.value ||
      this.state.Resident.wound.thicknessIdForBackStagging == null
    ) {
      Common.updateInputValueInState(
        this.state.Resident.wound,
        e,
        this,
        this.state
      );

      if (e.target.value == "1246" || e.target.value == "1247") {
        this.setState({ IsNecroticRequired: true });
      } else {
        this.setState({ IsNecroticRequired: false });
      }
    } else {
      Common.showSuccessAlert(
        "Reverse staging of wound is not allowed",
        "warning",
        "Warnng!",
        3000
      );
    }
    if (e.target.value == "1244") {
      var resident = this.state.Resident;
      resident.wound.fkDebridementTypeId = "1027";
      let newDate = "2021-02-12T00:00:00"; //Common.getCurrentDate('-');

      // resident.wound.debridementDate = newDate;
      this.setState({
        IsPartialThickness: true,
        Resident: resident,
      });
    } else {
      this.setState({
        IsPartialThickness: false,
      });
    }
  };
  onWoundTypeChange = (e) => {
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
    let type = this.state.WoundTypeSelected.toLowerCase();
    if (type == "surgical") {
      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => e.target.value == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));
      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });
    }
    //this.onWoundTypeChangeSetDropdownValues( e.target.value);
  };
  onThicknessChangeSetDropdownValues = (thicknessId) => {
    var tissueTypeId = null;
    if (this.state.WoundTypeSelected == "NonPressure") {
      tissueTypeId = this.state.Resident.wound.fkWoundSevereTissueTypeId;
    }
    var bodyRegionOptionTemplate = [];
    bodyRegionOptionTemplate = this.state.woundICDCodeSheet
      .filter(
        (menu) =>
          thicknessId == menu.fkThicknessStageId &&
          tissueTypeId == menu.fkWoundSevereTissueTypeId
      )
      .map((v) => (
        <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
          {v.woundBodyRegionName}
        </option>
      ));
    const bodyRegionUniqueTags = [];
    bodyRegionOptionTemplate.map((item) => {
      var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
      if (!findItem) bodyRegionUniqueTags.push(item);
    });
    this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });
    //End
  };
  onBodyRegionChange = (e) => {
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
    this.onBodyRegionChangeSetValues(e.target.value);

    this.showHideOrientation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  };
  onBodyRegionChangeSetValues = (bodyRegion) => {
    var optionTemplate = [];
    if (this.state.WoundTypeSelected == "NonPressure") {
      optionTemplate = this.state.woundICDCodeSheet
        .filter(
          (menu) =>
            bodyRegion == menu.fkBodyRegionId &&
            menu.fkThicknessStageId ==
              this.state.Resident.wound.fkWoundThicknessId
        )
        .map((v) => (
          <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
            {v.woundLocationName}
          </option>
        ));
    } else {
      var woundTypeId = 0;
      if (this.state.WoundTypeSelected == "Pressure") {
        woundTypeId = Common.WoundType.pressure;
      } else {
        woundTypeId = this.state.Resident.wound.fkWoundTypeId;
      }

      optionTemplate = this.state.woundICDCodeSheet
        .filter(
          (menu) =>
            bodyRegion == menu.fkBodyRegionId &&
            menu.fkThicknessStageId ==
              this.state.Resident.wound.fkWoundThicknessId &&
            menu.fkWoundTypeId == woundTypeId
        )
        .map((v) => (
          <option key={v.fkWoundLocationId} value={v.fkWoundLocationId}>
            {v.woundLocationName}
          </option>
        ));
    }
    const uniqueTags = [];
    optionTemplate.map((item) => {
      var findItem = uniqueTags.find((x) => x.key === item.key);
      if (!findItem) uniqueTags.push(item);
    });
    this.setState({ pressureLocation: uniqueTags });
  };
  onWoundLocationChange = (e) => {
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
    if (this.props.isReorder) {
      this.setOrientationValues(e.target.value, false);
      this.setState({ ReoderAndIsDisableOrientation: false });
    } else {
      this.setOrientationValues(e.target.value, true);
    }
  };
  setOrientationValues = (woundLocation, setOrientation) => {
    var icdCodes = this.state.woundICDCodeSheet.filter(
      (x) =>
        x.fkWoundSevereTissueTypeId ==
          this.state.Resident.wound.fkWoundSevereTissueTypeId &&
        x.fkThicknessStageId == this.state.Resident.wound.fkWoundThicknessId &&
        x.fkBodyRegionId == this.state.Resident.wound.fkBodyRegionId &&
        x.fkWoundLocationId == woundLocation
    );
    this.showHideOrientation(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );

    if (icdCodes) {
      for (var i = 0; i < icdCodes.length; i++) {
        let item = JSON.parse(JSON.stringify(icdCodes[i]));

        // item.orientationLeftRight, item.orientationUpperLower,
        // item.orientationInnerOuter, item.orientationAnteriorPosterior,

        if (item.orientationLeftRight == Common.WoundOrientation.right) {
          this.setState({ orientationRight: item.orientationLeftRight });
        } else if (item.orientationLeftRight == Common.WoundOrientation.left) {
          this.setState({ orientationLeft: item.orientationLeftRight });
        }
        if (item.orientationUpperLower == Common.WoundOrientation.upper) {
          this.setState({ orientationUpper: item.orientationUpperLower });
        } else if (
          item.orientationUpperLower == Common.WoundOrientation.lower
        ) {
          this.setState({ orientationLower: item.orientationUpperLower });
        }
        if (item.orientationInnerOuter == Common.WoundOrientation.inner) {
          this.setState({ orientationInner: item.orientationInnerOuter });
        } else if (
          item.orientationInnerOuter == Common.WoundOrientation.outer
        ) {
          this.setState({ orientationOuter: item.orientationInnerOuter });
        }
        if (
          item.orientationAnteriorPosterior == Common.WoundOrientation.anterior
        ) {
          this.setState({
            orientationAnterior: item.orientationAnteriorPosterior,
          });
        } else if (
          item.orientationAnteriorPosterior == Common.WoundOrientation.posterior
        ) {
          this.setState({
            orientationPosterior: item.orientationAnteriorPosterior,
          });
        }
        if (item.orientationMedialLateral == Common.WoundOrientation.medial) {
          this.setState({ orientationMedial: item.orientationMedialLateral });
        } else if (
          item.orientationMedialLateral == Common.WoundOrientation.lateral
        ) {
          this.setState({ orientationLateral: item.orientationMedialLateral });
        }
        if (
          item.orientationDorsalDistalProximal == Common.WoundOrientation.dorsal
        ) {
          this.setState({
            orientationDorsal: item.orientationDorsalDistalProximal,
          });
        } else if (
          item.orientationDorsalDistalProximal == Common.WoundOrientation.distal
        ) {
          this.setState({
            orientationDistal: item.orientationDorsalDistalProximal,
          });
        } else if (
          item.orientationDorsalDistalProximal ==
          Common.WoundOrientation.proximal
        ) {
          this.setState({
            orientationProximal: item.orientationDorsalDistalProximal,
          });
        }
      }
    }
    // else {
    //     this.showHideOrientation(null, null, null, null, null, null,null, null, null, null, null, null,null);

    // }
    if (setOrientation) {
      this.resetOrientation(this.state.Resident);
    }
  };
  showHideOrientation = (
    orientationLeft,
    orientationRight,
    orientationUpper,
    orientationLower,
    orientationInner,
    orientationOuter,
    orientationAnterior,
    orientationPosterior,
    orientationMedial,
    orientationLateral,
    orientationDorsal,
    orientationDistal,
    orientationProximal
  ) => {
    this.setState({
      orientationLeft: orientationLeft,
      orientationRight: orientationRight,
      orientationUpper: orientationUpper,
      orientationLower: orientationLower,

      orientationInner: orientationInner,
      orientationOuter: orientationOuter,

      orientationAnterior: orientationAnterior,
      orientationPosterior: orientationPosterior,

      orientationMedial: orientationMedial,
      orientationLateral: orientationLateral,

      orientationDorsal: orientationDorsal,
      orientationDistal: orientationDistal,
      orientationProximal: orientationProximal,
    });
  };

  checkWoundType(value) {
    if (value == "5" || value == "23" || value == "24" || value == "25") {
      // this.setState({ WoundType: 'Pressure' })

      this.selectWoundType("pressure");
    } else if (value == "12" || value == "15") {
      this.selectWoundType("surgical");
    } else {
      this.selectWoundType("nonpressure");
    }
  }

  handleSubmit = (event) => {
    //1243 is unstageable check

    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      let obj = this.state.Resident;

      if (
        obj.wound.fkWoundThicknessId == "5" &&
        this.state.WoundTypeSelected == "Pressure"
      ) {
        if (
          (obj.wound.sloughPercentage == null ||
            obj.wound.sloughPercentage == "" ||
            obj.wound.sloughPercentage == 0) &&
          (obj.wound.necroticPercentage == null ||
            obj.wound.necroticPercentage == "" ||
            obj.wound.necroticPercentage == 0)
        ) {
          Common.showSuccessAlert(
            "In Case of unstageable slough or Necrotic is required",
            "warning",
            "Warning!",
            3000
          );
        } else {
          this.URI_UpsertResidentWound();
        }
      } else {
        this.URI_UpsertResidentWound();
      }
    }
  };

  URI_UpsertResidentWound() {
    let obj = this.state.Resident;
    let selectedProducts = null;
    if (
      this.state.showProduct &&
      this.state.Resident.isWoundInfoChange != true
    ) {
      selectedProducts = this.productSelection.getSelectedProductList();

      if (
        selectedProducts.products.length > !0 &&
        selectedProducts.selectedSecondaryProducts.length > !0 &&
        selectedProducts.selectedOtherFillerProducts.length > !0 &&
        selectedProducts.selectedOtherTraditionalProducts.length > !0
      ) {
        Common.showSuccessAlert(
          "Please select atleast one product.",
          "warning",
          "Failed!",
          3000
        );
        return;
      } else if (this.props.isReorder) {
        let _selectedProdWithoutRemaingQty = selectedProducts.products.find(
          (x) =>
            x.remainingQuantity === null ||
            x.remainingQuantity === "" ||
            x.remainingQuantity < 0
        );

        // FIXME: TASK 2120: Remove 'Remaining Quantity' when reordering
        // if (_selectedProdWithoutRemaingQty != undefined) {
        //   Common.showSuccessAlert(
        //     "Remaining quantity is required for all products.",
        //     "warning",
        //     "Warning!",
        //     3000
        //   );
        //   return;
        // }
      }
    }
    if (obj.wound.necroticPercentage == "") {
      obj.wound.necroticPercentage = null;
    }
    if (obj.wound.sloughPercentage == "") {
      obj.wound.sloughPercentage = null;
    }
    if (obj.wound.fkWoundLocationId == null) {
      obj.wound.fkWoundLocationId = 0;
    }

    this.setState({ IsLoading: false });

    if (!obj?.wound?.dateFirstObserved) {
      obj.wound.dateFirstObserved = null;
    }

    if (!selectedProducts && this.props.displayProduct) {
      return;
    }

    Api.postRequest(Api.URI_UpsertResidentWound, obj)
      .then((res) => {
        if (res.data.success) {
          this.setState({ IsLoading: true });
          if (res.data.result != null) {
            if (this.props.isEdit && !this.props.displayProduct) {
              Common.showSuccessAlert(
                "Wound Updated Successfully",
                "success",
                "Success!",
                3000
              );
              this.props.refresh && this.props.refresh();
              if (this.state.Resident.isWoundInfoChange == true) {
                this.props.backPress && this.props.backPress();
              }

              this.props.close();
            } else {
              this.setProductSelectionDetailToSend(res.data.result);
            }

            //this.props.close();
          } else {
            Common.showSuccessAlert(
              res.data.message,
              "warning",
              "Failed!",
              3000
            );
          }
        } else {
          Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
          this.setState({ IsLoading: true });
        }
      })
      .catch((ex) => {
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      });
    this.setState({ IsLoading: true });
  }

  getICD10APICall = (event) => {
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    } else {
      let obj = this.state.Resident.wound;
      if (
        obj.fkWoundThicknessId == "5" &&
        this.state.WoundTypeSelected == "Pressure"
      ) {
        if (
          (obj.sloughPercentage == null ||
            obj.sloughPercentage == "" ||
            obj.sloughPercentage == 0) &&
          (obj.necroticPercentage == null ||
            obj.necroticPercentage == "" ||
            obj.necroticPercentage == 0)
        ) {
          Common.showSuccessAlert(
            "In Case of unstageable slough or Necrotic is required",
            "warning",
            "Warning!",
            3000
          );
          return false;
        }
      }
      this.calculatePushScore(
        this.state.Resident.wound.woundLength *
          this.state.Resident.wound.woundWidth,
        this.state.Resident.wound.fkExudateAmountId,
        this.state.Resident.wound.fkWoundSevereTissueTypeId
      );
      var searchParm = {};
      var tissueTypeId = null;
      if (this.state.WoundTypeSelected == "NonPressure") {
        searchParm.tissueTypeId = obj.fkWoundSevereTissueTypeId;
      }
      searchParm.Fk_BodyRegionID = obj.fkBodyRegionId;
      if (obj.fkWoundLocationId != null) {
        searchParm.Fk_WoundLocationID = obj.fkWoundLocationId;
      }

      // if (obj.fkWoundTypeId == '1017') {
      searchParm.Fk_Thickness_StageID = obj.fkWoundThicknessId;
      // searchParm.Fk_NonPrUThicknessID = "";
      // } else {
      //     searchParm.fk_Thickness_StageID = "";
      //     searchParm.fk_NonPrUThicknessID = obj.fkWoundThicknessId;
      // }

      // searchParm.fk_WoundLateralityID = obj.fkWoundLateralityId;
      if (
        this.state.orientationLeft == null &&
        this.state.orientationRight == null
      ) {
        if (
          this.state.WoundTypeSelected == "Pressure" &&
          obj.fkBodyRegionId == 4 &&
          obj.orientationLeftRight > 0
        ) {
          searchParm.OrientationLeftRight = Common.replaceNullWithString(
            obj.orientationLeftRight
          );
        }
      } else {
        searchParm.OrientationLeftRight = Common.replaceNullWithString(
          obj.orientationLeftRight
        );
      }
      if (
        this.state.orientationUpper == null &&
        this.state.orientationLower == null
      ) {
      } else {
        searchParm.OrientationUpperLower = Common.replaceNullWithString(
          obj.orientationUpperLower
        );
      }
      if (
        this.state.orientationInner == null &&
        this.state.orientationOuter == null
      ) {
      } else {
        searchParm.OrientationInnerOuter = Common.replaceNullWithString(
          obj.orientationInnerOuter
        );
      }
      if (
        this.state.orientationAnterior == null &&
        this.state.orientationPosterior == null
      ) {
      } else {
        searchParm.OrientationAnteriorPosterior = Common.replaceNullWithString(
          obj.orientationAnteriorPosterior
        );
      }
      if (
        this.state.orientationMedial == null &&
        this.state.orientationLateral == null
      ) {
      } else {
        searchParm.OrientationMedialLateral = Common.replaceNullWithString(
          obj.orientationMedialLateral
        );
      }
      if (
        this.state.orientationDorsal == null &&
        this.state.orientationDistal == null &&
        this.state.orientationProximal == null
      ) {
      } else {
        searchParm.OrientationDorsalDistalProximal = Common.replaceNullWithString(
          obj.orientationDorsalDistalProximal
        );
      }

      var queryParm = Common.objToQueryString(searchParm);
      Api.getRequestQry(Api.URI_GetICDCode, queryParm)
        .then((response) => {
          if (response.data.result != null) {
            if (response.data.result.length > 0) {
              var code = response.data.result[0].icdCode;
              // setICD10Code(code)
              this.state.Resident.wound.icdcode = code;
              this.setState({
                ICD10Code: code,
                showProduct: true,
                GetCodeProcess: "Calculated",
              });
            } else {
              Common.showSuccessAlert(
                "ICD10 Code Not Found",
                "warning",
                "Failed!",
                3000
              );
            }
          } else {
            Common.showSuccessAlert(
              "ICD10 Code Not Found",
              "warning",
              "Failed!",
              3000
            );
          }
        })
        .catch(function(error) {});
    }
  };

  setProductSelectionDetailToSend(result) {
    let selectedProducts = this.productSelection.getSelectedProductList();
    // if (selectedProducts.products != null) {
    //     if (selectedProducts.products.length > 0) {
    let proudctObjToSend = {
      orderNote: this.state.orderNote,
      saleOrderId: selectedProducts.salesOrder.saleOrderId,
      isWoundInfoChange: this.state.Resident.isWoundInfoChange,
      // "createdBy": this.props.user.userID,
      // "modifiedBy": this.props.user.userID,
      fkResidentWoundId: result,
      FkDressingChangeFrequencyId:
        selectedProducts.salesOrder.fkDressingChangeFrequencyId,
      OtherAdditionalSecondaryDressing:
        selectedProducts.salesOrder.otherAdditionalSecondaryDressing,
      OtherAdditionalPrimaryDressing:
        selectedProducts.salesOrder.otherAdditionalPrimaryDressing,
      OrderNote: selectedProducts.salesOrder.orderNote,
      saleOrderViewModels: [],
      otherPrimaryProducts: selectedProducts.otherPrimaryProducts,
      otherSecodaryProducts: selectedProducts.selectedSecondaryProducts,
      otherFillerProducts: selectedProducts.selectedOtherFillerProducts,
      otherTraditionalProducts:
        selectedProducts.selectedOtherTraditionalProducts,
      fkLastWoundDetailId: this.props.resident.fkLastWoundDetailId,
      isReorder: this.props.isReorder,
      // "facilityId": this.props.facilityId,
      // "residentId": this.props.Resident.id,
    };

    selectedProducts?.products?.forEach((value) => {
      let obj = {
        fkProductId: value.id,
        saleOrderProductId: value.saleOrderProductId,
        quantity: value.quantity,
        remainingQuantity: value.remainingQuantity,
        isOrderNow: value.isOrderNow,

        productLevel: value.productLevel,
        fkProductCategoryId: value.fkProductCategoryId,
        // "createdBy": this.props.user.userID,
        // "modifiedBy": this.props.user.userID,
      };
      proudctObjToSend.saleOrderViewModels.push(obj);
    });

    this.UpsertSaleOrderApiCall(proudctObjToSend);
    //     }
    // }
  } //
  UpsertSaleOrderApiCall(productObject) {
    this.setState({ IsLoading: false });
    let obj = productObject;
    obj = {
      ...obj,
      createdInQueueId: this.props.queue || Common.ProcessStatus.workQueueID,
    };
    Api.postRequest(Api.URI_UpsertSaleOrder, obj)
      .then((res) => {
        if (res.data.success) {
          this.setState({ IsLoading: true });
          if (res.data.result != null) {
            Common.showSuccessAlert(
              "Sales Order Created Successfully",
              "success",
              "Success!",
              3000
            );
            if (!this.props.isEdit) {
              this.handleAddMoreWound();
            } else {
              this.props.refresh && this.props.refresh();
              this.props.close(true);
            }
          } else {
            this.setState({ IsLoading: true });
            Common.showSuccessAlert(
              res.data.message,
              "warning",
              "Failed!",
              3000
            );
          }
        } else {
          this.setState({ IsLoading: true });
          Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
        }
      })
      .catch((ex) => {
        this.setState({ IsLoading: true });
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      });
    this.setState({ IsLoading: true });
  }
  handleAddMoreWound = () => {
    Common.showConfirmation(
      "Would you like to enter a wound.",
      "",
      this.handleNewWoundModal,
      this.handleCloseModel,
      null,
      "Yes",
      false,
      null,
      "No"
    );
  };
  handleCloseModel = () => {
    this.props.refresh && this.props.refresh();
    this.props.close();
  };
  handleNewWoundModal = async () => {
    this.resetICDDropdowns();
    this.setState({ showProduct: this.props.showProduct });
    this.setState({ WoundTypeSelected: "", ICD10Code: "" });
    this.setState({ IsLoading: false });
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident.wound,
      this,
      this.state,
      null,
      "fkWoundTypeId"
    );
  };
  onWoundTypeValueChange(event) {
    let selectedValue = event.target.value;
    if (this.state.selectedOption != undefined && this.state.WoundTypeSelected != "") {
      Common.showConfirmation(
        "Changing Wound Type will reset all values.  Do you want to proceed?",
        "Confirmation",
        () => {
          this.resetICDDropdowns();
          this.selectWoundType(selectedValue);
        },
        () => {},
        null
      );
    } else {
      this.selectWoundType(selectedValue);
    }
  }
  selectWoundType = (selectedValue) => {
    let type = selectedValue.toLowerCase();

    let _woundType = [];
    let BodyRegionOption = [];
    let bodyRegion = this.state.woundBodyRegionSelect;
    switch (type) {
      case "pressure":
        _woundType = this.state.allWoundTypeSelect.filter(
          (i) => i.type == "pressure"
        );
        // BodyRegionOption = bodyRegion.map(v => (
        //     <option key={v.key} value={v.key}>
        //         {v.value}
        //     </option>
        // ));
        // this.setState({ filterdWoundBodyRegionSelect: BodyRegionOption, WoundTypeSelected: 'Pressure' })
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          null,
          "fkWoundSevereTissueTypeId"
        );
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          5,
          "fkWoundTypeId"
        );
        // this.resetICDDropdowns();
        this.onTissueValueChangeSetDropdownValues(null, "Pressure");
        this.setState({ WoundTypeSelected: "Pressure" });

        //this.onWoundTypeChangeSetDropdownValues(5);

        break;
      case "nonpressure":
        _woundType = this.state.allWoundTypeSelect.filter(
          (i) => i.type == "non-pressure"
        );
        BodyRegionOption = bodyRegion.map((v) => (
          <option key={v.key} value={v.key}>
            {v.value}
          </option>
        ));
        // Common.withOutEventUpdateInputValueInState(this.state.Resident.wound, this, this.state, 2, "fkWoundTypeId")

        // this.resetICDDropdowns();
        this.onTissueValueChangeSetDropdownValues(
          this.state.Resident.wound.fkWoundSevereTissueTypeId,
          "NonPressure"
        );
        this.setState({
          filterdWoundBodyRegionSelect: BodyRegionOption,
          WoundTypeSelected: "NonPressure",
        });
        break;
      case "surgical":
        _woundType = this.state.allWoundTypeSelect.filter(
          (i) => i.type == "surgical"
        );
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          12,
          "fkWoundTypeId"
        );
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          null,
          "fkWoundSevereTissueTypeId"
        );

        this.onTissueValueChangeSetDropdownValues(null, "Surgical");
        this.setState({ WoundTypeSelected: "Surgical" });
        // this.onWoundTypeChangeSetDropdownValues(12);

        break;
    }
    let optionTemplate = _woundType.map((v) => (
      <option key={v.id} value={v.id}>
        {v.name}
      </option>
    ));
    this.setState({ selectedWoundTypeSelect: optionTemplate });

    this.setState({
      selectedOption: selectedValue,
    });
  };
  onTissueValueChange(event) {
    if (this.props.isReorder) {
      if (
        this.state.Resident.wound.thicknessIdForBackStagging == 11 &&
        event.target.value == 1214
      ) {
        Common.showSuccessAlert(
          "Reverse staging of wound is not allowed",
          "warning",
          "Warnng!",
          3000
        );
        return false;
      }
      // In case of reorder we will check most severe tissue value is null then reset values
      if (
        this.state.Resident.wound.fkWoundSevereTissueTypeId == 0 ||
        this.state.Resident.wound.fkWoundSevereTissueTypeId == null
      ) {
        this.resetICDDropdowns();
      }
    } else {
      this.resetICDDropdowns();
    }

    var res = this.state.Resident;
    res.wound.fkWoundSevereTissueTypeId = event.target.value;
    this.setState({
      Resident: res,
      IsThicknessStageTwo: false,
      IsPartialThickness: false,
    });
    if (this.props.isReorder) {
      if (this.state.WoundTypeSelected === "NonPressure") {
        if (event.target.value == "1214") {
          Common.withOutEventUpdateInputValueInState(
            this.state.Resident.wound,
            this,
            this.state,
            10,
            "fkWoundThicknessId"
          );
          this.onThicknessChangeSetDropdownValues(10);
        } else {
          Common.withOutEventUpdateInputValueInState(
            this.state.Resident.wound,
            this,
            this.state,
            11,
            "fkWoundThicknessId"
          );
          this.onThicknessChangeSetDropdownValues(11);
        }
      }
    } else {
      this.onTissueValueChangeSetDropdownValues(
        event.target.value,
        this.state.WoundTypeSelected
      );
    }
  }

  onTissueValueChangeSetDropdownValues = (tissueTypeId, woundType) => {
    //  var woundTypeId=this.state.Resident.wound.fkWoundTypeId;

    if (woundType === "NonPressure") {
      if (tissueTypeId == "1214") {
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          10,
          "fkWoundThicknessId"
        );
        this.onThicknessChangeSetDropdownValues(10);
      } else {
        Common.withOutEventUpdateInputValueInState(
          this.state.Resident.wound,
          this,
          this.state,
          11,
          "fkWoundThicknessId"
        );
        this.onThicknessChangeSetDropdownValues(11);
      }
      var optionTemplate = [];
      optionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => tissueTypeId == menu.fkWoundSevereTissueTypeId)
        .map((v) => (
          <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
            {v.woundThicknessName}
          </option>
        ));
      const uniqueTags = [];
      optionTemplate.map((item) => {
        if (item.key != "null") {
          var findItem = uniqueTags.find((x) => x.key === item.key);
          if (!findItem) uniqueTags.push(item);
        }
      });

      this.setState({ thicknessStage: uniqueTags });

      //Body Region
      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => tissueTypeId == menu.fkWoundSevereTissueTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));
      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });

      // if (uniqueTags.length > 0) {

      // }
    } else if (woundType === "Surgical") {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        11,
        "fkWoundThicknessId"
      );
      var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
      var optionTemplate = [];
      optionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
            {v.woundThicknessName}
          </option>
        ));
      const uniqueTags = [];
      optionTemplate.map((item) => {
        var findItem = uniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) uniqueTags.push(item);
        }
      });

      this.setState({ thicknessStage: uniqueTags });

      //Body Region
      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));
      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });
    } else {
      var woundTypeId = this.state.Resident.wound.fkWoundTypeId;
      var optionTemplate = [];
      optionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkThicknessStageId} value={v.fkThicknessStageId}>
            {v.woundThicknessName}
          </option>
        ));
      const uniqueTags = [];
      optionTemplate.map((item) => {
        var findItem = uniqueTags.find((x) => x.key === item.key);
        if (item.key != "null" && item.key != 10 && item.key != 11) {
          if (!findItem) uniqueTags.push(item);
        }
      });

      this.setState({ thicknessStage: uniqueTags });

      //Body Region
      var bodyRegionOptionTemplate = [];
      bodyRegionOptionTemplate = this.state.woundICDCodeSheet
        .filter((menu) => woundTypeId == menu.fkWoundTypeId)
        .map((v) => (
          <option key={v.fkBodyRegionId} value={v.fkBodyRegionId}>
            {v.woundBodyRegionName}
          </option>
        ));
      const bodyRegionUniqueTags = [];
      bodyRegionOptionTemplate.map((item) => {
        var findItem = bodyRegionUniqueTags.find((x) => x.key === item.key);
        if (item.key != "null") {
          if (!findItem) bodyRegionUniqueTags.push(item);
        }
      });
      this.setState({ woundBodyRegionSelect: bodyRegionUniqueTags });

      this.showHideOrientation(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (
        tissueTypeId == 1298 ||
        tissueTypeId == 1303 ||
        tissueTypeId == 1302
      ) {
        this.setState({ IsSloughRequired: true, IsNecroticRequired: false });
      } else if (tissueTypeId == 1299 || tissueTypeId == 1300) {
        this.setState({ IsNecroticRequired: true, IsSloughRequired: false });
      } else {
        this.setState({ IsSloughRequired: false });
        this.setState({ IsNecroticRequired: false });
      }
      //End
    }
  };
  resetICDDropdowns = () => {
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident.wound,
      this,
      this.state,
      null,
      "fkWoundSevereTissueTypeId"
    );
    Common.withOutEventUpdateInputValueInState(
      this.state.Resident.wound,
      this,
      this.state,
      null,
      "fkWoundLocationId"
    );
    if (!this.props.isReorder) {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        null,
        "fkBodyRegionId"
      );
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        null,
        "fkWoundThicknessId"
      );
      this.setState({
        thicknessStage: [],
        woundBodyRegionSelect: [],
        pressureLocation: [],
      });
    }

    // WoundSevereTissue:[],
    var residentObj = this.state.Resident;
    residentObj.wound.woundLength = null;
    residentObj.wound.woundWidth = null;
    residentObj.wound.woundDepth = null;
    residentObj.wound.fkExudateAmountId = null;
    residentObj.wound.sloughPercentage = null;
    residentObj.wound.necroticPercentage = null;
    residentObj.wound.tunneling = false;
    residentObj.wound.tunnelingDepth = null;
    residentObj.wound.tunnelingClockPosition = null;
    residentObj.wound.undermining = false;
    residentObj.wound.underminingDepth = null;
    residentObj.wound.surgicalDate = moment();
    residentObj.wound.debridementDate = moment();
    residentObj.wound.fkDebridementTypeId = null;

    //this.setState({ Resident: residentObj });

    this.resetOrientation(residentObj);
  };
  // Render Methods
  resetOrientation(residentObj) {
    // var residentObj = this.state.Resident;
    residentObj.wound.orientationLeftRight = null;
    residentObj.wound.orientationAnteriorPosterior = null;
    residentObj.wound.orientationDorsalDistalProximal = null;
    residentObj.wound.orientationInnerOuter = null;
    residentObj.wound.orientationMedialLateral = null;
    residentObj.wound.orientationUpperLower = null;
    residentObj.wound.orientationUpperLower = null;

    this.setState({ Resident: residentObj });
  }

  allowOneDigitAfterDecimal = (e) => {
    if (e.target.value.length > 0 && e.target.value.includes('.')) {
        if (e.target.value.toString().split(".")[1].length > 1) {
            return false
        } else if (e.target.value > 99.9) {
            return false
        }
    } else {
        if (e.target.value > 99.9) {
            return false
        }
    }

    if (e.target.value.length > 1 && e.target.value[0] === '0' && e.target.value[1] !== '.') {
        return false
    }

    Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
  }

  allowTwoDigitAfterDecimal = (e) => {
      if (e.target.value.length > 0 && e.target.value.includes('.')) {
          if (e.target.value.toString().split(".")[1].length > 2) {
              return false
          } else if (e.target.value > 99.99) {
              return false
          }
      } else {
          if (e.target.value > 99.99) {
              return false
          }
      }

      Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)
  }

  isAnyWoundDimensionInvalid() {
      const {
          woundLength,
          woundWidth,
          woundDepth
      } = this.state.Resident.wound;

      const values = [woundLength, woundWidth, woundDepth];

      if (values.some(value => value === null || value === '')) {
          return true;
      }

      const hasMoreDigitThanOne = values.some(value => {
          const valueString = value.toString();
          return valueString.includes('.') && valueString.split('.')[1].length > 1;
      });

      if (hasMoreDigitThanOne) {
          return true;
      }

      return false;
  }

  onDebridementTypeChange = (e) => {
    // if (this.props.isReorder && this.props.resident.wound.fkDebridementTypeId == 1029 && e.target.value != 1027 && e.target.value != 1029) {
    //     Common.showSuccessAlert("You can select sharp only", "warning", "Warning!", 3000);
    //     return false
    // }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      e,
      this,
      this.state
    );
  };
  getLastWoundDetailByWoundId() {
    // Api.getRequestById(Api.URI_GetResidentWoundByResidentID, props.Resident.id)
    Api.getRequestQry(
      Api.URI_GetLastWoundDetailByWoundId,
      "?woundId=" + this.state.Resident.wound.id
    )
      // Api.getRequestQry(Api.URI_GetResidentWounURidByStatusID, "?residentId="+props.Resident.id+"&processStatusID=" + processStatusID)

      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            // setResidentWoundList(res.data.result);
            if (res.data.result.id > 0) {
              this.handleWoundAssessmentModal(
                res.data.result,
                true,
                true,
                true
              );
            } else {
              Common.showSuccessAlert(
                "Last Wound assessment is not available",
                "warning",
                "Warning!",
                3000
              );
            }
          } else {
            Common.showSuccessAlert(
              "Wound assessment is not available",
              "warning",
              "Warning!",
              3000
            );
          }
        }

        //Common.LoadDataTable("woundList"+props.Resident.id)
      })
      .catch((ex) => {
        Common.LogError(ex, "ResidentNote", "Get");
      });
  }
  handleWoundAssessmentModal = async (
    obj,
    isProductShow,
    isReadOnly,
    isProductDisplay,
    isWoundInfoChange = false,
    changeReason = ""
  ) => {
    var product = [];
    if (this.state.showProduct && this.props.displayProduct) {
      product = this.productSelection.getSelectedProductList();
    }

    this.setState({
      showLastOrderWoundComponent: (
        <ViewWound
          refresh={this.props.refresh}
          isEdit={true}
          queue={props.queue || Common.ProcessStatus.workQueueID}
          isReorder={obj.wound.isReorder}
          showProduct={isProductShow}
          isReadOnly={isReadOnly}
          displayProduct={isProductDisplay}
          user={this.props.user}
          resident={obj}
          close={this.handleCloseLastOrdermodalPopup}
        />
      ),
      lastOrdermodal: true,
      orderProducts: product,
    });
  };

  handleCloseLastOrdermodalPopup() {
    try {
      this.setState({ lastOrdermodal: false });
    } catch (error) {
      Common.LogError(error, "WorkqueueWounds", "handleNewWoundModal");
    }
  }
  handleUtdChange = (event) => {
    if (event.target.checked) {
      Common.withOutEventUpdateInputValueInState(
        this.state.Resident.wound,
        this,
        this.state,
        "0.00",
        "woundDepth"
      );
    }
    Common.updateInputValueInState(
      this.state.Resident.wound,
      event,
      this,
      this.state
    );
  };
  renderWoundForm() {
    return (
      <>
        {!this.state.IsLoading ? (
          <div>
            <fieldset className="p-2 mb-2 border rounded">
              <legend className="text-primary fw-500">
                Basic Information{" "}
              </legend>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="ResidentName"
                    >
                      Resident Name
                    </label>
                    <input
                      type="text"
                      id="ResidentName"
                      name="residentName"
                      disabled
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.residentName
                      )}
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">
                      Resident Name Is Required
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="FacilityName"
                    >
                      Facility Name
                    </label>
                    <input
                      type="text"
                      id="FacilityName"
                      name="facilityName"
                      disabled
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.facilityName
                      )}
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">
                      Facility Name Is Required
                    </div>
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="FirstName"
                    >
                      Room / Unit
                    </label>
                    <input
                      type="text"
                      id="RoomUnit"
                      name="roomUnit"
                      disabled
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.wound.roomUnit
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      // defaultValue={Common.replaceNullWithString(this.state.Resident.roomUnit)}

                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="EvaluatorName"
                    >
                      Evaluator Name
                    </label>
                    <input
                      type="text"
                      id="evaluatedBy"
                      name="evaluatedBy"
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.evaluatedBy
                      )}
                      disabled
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>

                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="PhysicanName"
                    >
                      Physican Name
                    </label>
                    <input
                      type="text"
                      id="PhysicanName"
                      disabled
                      name="physicanName"
                      defaultValue={Common.replaceNullWithString(
                        this.state.Resident.physicanName
                      )}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident,
                          e,
                          this,
                          this.state
                        )
                      }
                      className="form-control form-control-sm"
                    />
                    <div className="invalid-feedback">Physican Name</div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset
              disabled={this.state.showProduct == true ? true : false}
              className="p-2 mb-2 border rounded"
            >
              <legend className="text-primary fw-500">Information </legend>

              <label
                className="form-label font-weight-bold"
                htmlFor="woundType"
              >
                Wound Type{" "}
              </label>
              <div className="row">
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="defaultUnchecked1"
                      value="Pressure"
                      checked={
                        this.state.WoundTypeSelected == "Pressure"
                          ? true
                          : false
                      }
                      onChange={(e) => this.onWoundTypeValueChange(e)}
                      disabled={this.state.isReorderAndTissueTypeNotChange}
                    />
                    <label
                      className="custom-control-label font-weight-bold"
                      htmlFor="defaultUnchecked1"
                    >
                      Pressure
                    </label>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="defaultUnchecked2"
                      value="NonPressure"
                      checked={
                        this.state.WoundTypeSelected == "NonPressure"
                          ? true
                          : false
                      }
                      onChange={(e) => this.onWoundTypeValueChange(e)}
                      disabled={this.state.isReorderAndTissueTypeNotChange}
                    />
                    <label
                      className="custom-control-label font-weight-bold"
                      htmlFor="defaultUnchecked2"
                    >
                      {" "}
                      Non Pressure
                    </label>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="custom-control custom-radio">
                    <input
                      type="radio"
                      className="custom-control-input"
                      id="defaultUnchecked3"
                      value="Surgical"
                      checked={
                        this.state.WoundTypeSelected == "Surgical"
                          ? true
                          : false
                      }
                      onChange={(e) => this.onWoundTypeValueChange(e)}
                      disabled={this.state.isReorderAndTissueTypeNotChange}
                    />
                    <label
                      className="custom-control-label font-weight-bold"
                      htmlFor="defaultUnchecked3"
                    >
                      Surgical
                    </label>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2"></div>
                {/* <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 justify-content-end">
                                    <label className="form-label font-weight-bold" htmlFor="icdcode">ICD Code - <span className={this.state.GetCodeProcess == "Calculate" ? "text-danger" : "text-success"}>{this.state.GetCodeProcess}</span></label>
                                    <div className="input-group mb-3">
                                        <input type="text" id="icdcode"
                                            name="icdcode"
                                            aria-describedby="basic-addon2"
                                            onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                                            value={this.state.ICD10Code} className="form-control form-control-sm" disabled />
                                        <div className="input-group-append">
                                            {this.state.showProduct == false ?
                                            
                                                <button type="button" className="btn btn-success btn-xs" onClick={e => this.getICD10APICall(e)}>GET CODE</button>
                                                :
                                                <div></div>
                                                // <button type="button" className="btn btn-danger btn-xs" onClick={e => this.setState({ showProduct: false })}>Edit</button>
                                            }
                                        </div>

                                    </div>
                                </div> */}
              </div>

              <hr></hr>
              <div className="row">
                {/* {this.state.WoundTypeSelected != 'Pressure' && */}
                <>
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className="form-group">
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="woundType"
                      >
                        {this.state.WoundTypeSelected == "NonPressure"
                          ? "Non Pressure Type"
                          : this.state.WoundTypeSelected != "Pressure"
                          ? "Surgical Type"
                          : "Pressure Type"}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="woundType"
                        name="fkWoundTypeId"
                        required
                        disabled={
                          this.state.isReorderAndTissueTypeNotChange &&
                          this.state.WoundTypeSelected != "NonPressure"
                            ? true
                            : false
                        }
                        onChange={(e) => this.onWoundTypeChange(e)}
                        value={Common.replaceNullWithString(
                          this.state.Resident.wound.fkWoundTypeId
                        )}
                      >
                        <option></option>
                        {this.state.selectedWoundTypeSelect}
                      </select>
                    </div>
                  </div>
                </>
                {/* } */}
                {this.state.WoundTypeSelected == "NonPressure" && (
                  <>
                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="IssueType"
                        >
                          Most Severe Tissue Type{" "}
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="IssueType"
                          name="fkWoundSevereTissueTypeId"
                          required
                          onChange={this.onTissueValueChange}
                          // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                          value={Common.replaceNullWithString(
                            this.state.Resident.wound.fkWoundSevereTissueTypeId
                          )}
                        >
                          <option></option>
                          {this.state.WoundSevereTissue}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                {this.state.WoundTypeSelected == "Pressure" && (
                  <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                    <div className={`form-group`}>
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="thicknessStage"
                      >
                        {this.state.WoundTypeSelected == "Pressure"
                          ? "Stage"
                          : "Thickness"}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="fkWoundThicknessId"
                        name="fkWoundThicknessId"
                        required
                        disabled={
                          this.state.isReorderAndTissueTypeNotChange &&
                          this.state.WoundTypeSelected != "Pressure"
                            ? true
                            : false
                        }
                        onChange={(e) => this.onThicknessStageChange(e)}
                        value={Common.replaceNullWithString(
                          this.state.Resident.wound.fkWoundThicknessId
                        )}
                      >
                        <option></option>
                        {this.state.thicknessStage}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="Country"
                    >
                      Body Region
                    </label>
                    <select
                      className="form-control form-control-sm"
                      name="fkBodyRegionId"
                      required
                      onChange={(e) => this.onBodyRegionChange(e)}
                      // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkBodyRegionId
                      )}
                      id="fkBodyRegionId"
                      disabled={this.state.isReorderAndTissueTypeNotChange}
                    >
                      {/* {this.state.filterdWoundBodyRegionSelect} */}
                      <option></option>
                      {this.state.woundBodyRegionSelect}
                    </select>
                  </div>
                </div>
                {this.state.Resident.wound.fkWoundLocationId == 10011 && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="EvaluatorName">
                          Other Body Region
                        </label>
                        <input
                          type="text"
                          id="otherBodyRegion"
                          name="otherBodyRegion"
                          disabled
                          onChange={(e) => {
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            ),
                              this.setState({ isLocationChange: true });
                          }}
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.otherBodyRegion
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                {/* {this.state.WoundType == 'Pressure' &&
                                    <> */}
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="heel"
                    >
                      Wound Location
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fk_PressureLocationID"
                      name="fkWoundLocationId"
                      required={
                        this.state.WoundTypeSelected == "Pressure" &&
                        this.state.Resident.wound.fkBodyRegionId == 4
                          ? false
                          : true
                      }
                      // disabled={this.state.isReorderAndTissueTypeNotChange}
                      // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                      onChange={(e) => this.onWoundLocationChange(e)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkWoundLocationId
                      )}
                    >
                      <option></option>
                      {this.state.pressureLocation}
                    </select>
                  </div>
                </div>

                {this.state.Resident.wound.fkWoundLocationId == 10012 && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="EvaluatorName">
                          Other Wound location
                        </label>
                        <input
                          type="text"
                          id="residentWoundLocation"
                          name="residentWoundLocation"
                          disabled
                          onChange={(e) => {
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            ),
                              this.setState({ isLocationChange: true });
                          }}
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.residentWoundLocation
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <label className="form-label font-weight-bold">
                    Orientation
                  </label>
                  <div className="form-group">
                    {/* {this.state.orientationLeftRight != null &&
                                            <> */}
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationLeftRight"
                            id="inlineRadio1"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.right}
                            required={
                              this.state.orientationLeft ==
                                Common.WoundOrientation.right ||
                              (this.state.orientationRight ==
                                Common.WoundOrientation.right &&
                                true)
                            }
                            checked={
                              this.state.Resident.wound.orientationLeftRight ==
                              Common.WoundOrientation.right
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationRight ==
                              Common.WoundOrientation.right
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="inlineRadio1"
                          >
                            <small>Right</small>
                            {this.state.orientationRight ==
                              Common.WoundOrientation.right && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationLeftRight"
                            id="inlineRadio2"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound.orientationLeftRight ==
                              Common.WoundOrientation.left
                                ? true
                                : false
                            }
                            value={Common.WoundOrientation.left}
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationLeft ==
                              Common.WoundOrientation.left
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="inlineRadio2"
                          >
                            <small>Left</small>
                            {this.state.orientationLeft ==
                              Common.WoundOrientation.left && "*"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* </>
                                        }
                                        {this.state.orientationUpperLower != null &&
                                            <> */}
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationUpperLower"
                            id="Upper"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound.orientationUpperLower ==
                              Common.WoundOrientation.upper
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationUpper ==
                                Common.WoundOrientation.upper ||
                              (this.state.orientationLower ==
                                Common.WoundOrientation.lower &&
                                true)
                            }
                            value={Common.WoundOrientation.upper}
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationUpper ==
                              Common.WoundOrientation.upper
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Upper"
                          >
                            <small>Upper</small>
                            {this.state.orientationUpper ==
                              Common.WoundOrientation.upper && "*"}
                          </label>
                        </div>
                      </div>

                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationUpperLower"
                            id="Lower"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound.orientationUpperLower ==
                              Common.WoundOrientation.lower
                                ? true
                                : false
                            }
                            value={Common.WoundOrientation.lower}
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationUpperLower ==
                              Common.WoundOrientation.lower
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Lower"
                          >
                            <small>Lower</small>
                            {this.state.orientationLower ==
                              Common.WoundOrientation.lower && "*"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* </>
                                        }
                                        {this.state.orientationInnerOuter != null &&
                                            <> */}
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationInnerOuter"
                            id="Inner"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.inner}
                            checked={
                              this.state.Resident.wound.orientationInnerOuter ==
                              Common.WoundOrientation.inner
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationInner ==
                                Common.WoundOrientation.inner ||
                              (this.state.orientationOuter ==
                                Common.WoundOrientation.outer &&
                                true)
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationInner ==
                              Common.WoundOrientation.inner
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Inner"
                          >
                            <small>Inner</small>
                            {this.state.orientationInner ==
                              Common.WoundOrientation.inner && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationInnerOuter"
                            id="Outer"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.outer}
                            checked={
                              this.state.Resident.wound.orientationInnerOuter ==
                              Common.WoundOrientation.outer
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationOuter ==
                              Common.WoundOrientation.outer
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Outer"
                          >
                            <small>Outer</small>
                            {this.state.orientationOuter ==
                              Common.WoundOrientation.outer && "*"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* </>
                                        }
                                        {this.state.orientationAnteriorPosterior != null &&
                                            <> */}
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationAnteriorPosterior"
                            id="Anterior"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.anterior}
                            required={
                              this.state.orientationAnterior ==
                                Common.WoundOrientation.anterior ||
                              (this.state.orientationPosterior ==
                                Common.WoundOrientation.posterior &&
                                true)
                            }
                            checked={
                              this.state.Resident.wound
                                .orientationAnteriorPosterior ==
                              Common.WoundOrientation.anterior
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationAnterior ==
                              Common.WoundOrientation.anterior
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Anterior"
                          >
                            <small>Anterior</small>
                            {this.state.orientationAnterior ==
                              Common.WoundOrientation.anterior && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationAnteriorPosterior"
                            id="Posterior"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            checked={
                              this.state.Resident.wound
                                .orientationAnteriorPosterior ==
                              Common.WoundOrientation.posterior
                                ? true
                                : false
                            }
                            value={Common.WoundOrientation.posterior}
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationPosterior ==
                              Common.WoundOrientation.posterior
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Posterior"
                          >
                            <small>Posterior</small>
                            {this.state.orientationPosterior ==
                              Common.WoundOrientation.posterior && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* </>
                                        }
                                        {this.state.orientationMedialLateral != null &&
                                            <> */}

                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationMedialLateral"
                            id="Medial"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.medial}
                            checked={
                              this.state.Resident.wound
                                .orientationMedialLateral ==
                              Common.WoundOrientation.medial
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationMedial ==
                                Common.WoundOrientation.medial ||
                              (this.state.orientationLateral ==
                                Common.WoundOrientation.lateral &&
                                true)
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationMedial ==
                              Common.WoundOrientation.medial
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Medial"
                          >
                            <small>Medial</small>
                            {this.state.orientationMedial ==
                              Common.WoundOrientation.medial && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationMedialLateral"
                            id="Lateral"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.lateral}
                            checked={
                              this.state.Resident.wound
                                .orientationMedialLateral ==
                              Common.WoundOrientation.lateral
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationLateral ==
                              Common.WoundOrientation.lateral
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Lateral"
                          >
                            <small>Lateral</small>
                            {this.state.orientationLateral ==
                              Common.WoundOrientation.lateral && "*"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* </>
                                        }
                                        {this.state.orientationDorsalDistalProximal != null &&
                                            <> */}
                    <div className="row">
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationDorsalDistalProximal"
                            id="Dorsal"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.dorsal}
                            checked={
                              this.state.Resident.wound
                                .orientationDorsalDistalProximal ==
                              Common.WoundOrientation.dorsal
                                ? true
                                : false
                            }
                            required={
                              this.state.orientationDorsal ==
                                Common.WoundOrientation.dorsal ||
                              this.state.orientationDistal ==
                                Common.WoundOrientation.distal ||
                              (this.state.orientationProximal ==
                                Common.WoundOrientation.proximal &&
                                true)
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationDorsal ==
                              Common.WoundOrientation.dorsal
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Dorsal"
                          >
                            <small>Dorsal</small>
                            {this.state.orientationDorsal ==
                              Common.WoundOrientation.dorsal && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationDorsalDistalProximal"
                            id="Distal"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.distal}
                            checked={
                              this.state.Resident.wound
                                .orientationDorsalDistalProximal ==
                              Common.WoundOrientation.distal
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationDistal ==
                              Common.WoundOrientation.distal
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Distal"
                          >
                            <small>Distal</small>
                            {this.state.orientationDistal ==
                              Common.WoundOrientation.distal && "*"}
                          </label>
                        </div>
                      </div>
                      <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <div className="form-check form-check-inline ml-5">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="orientationDorsalDistalProximal"
                            id="Proximal"
                            onChange={(e) =>
                              Common.updateInputValueInState(
                                this.state.Resident.wound,
                                e,
                                this,
                                this.state
                              )
                            }
                            value={Common.WoundOrientation.proximal}
                            checked={
                              this.state.Resident.wound
                                .orientationDorsalDistalProximal ==
                              Common.WoundOrientation.proximal
                                ? true
                                : false
                            }
                            disabled={
                              this.state.isReorderAndTissueTypeNotChange &&
                              this.state.ReoderAndIsDisableOrientation
                            }
                          />
                          <label
                            className={
                              this.state.orientationProximal ==
                              Common.WoundOrientation.proximal
                                ? "form-check-label font-weight-bold"
                                : "form-check-label"
                            }
                            htmlFor="Proximal"
                          >
                            <small>Proximal</small>
                            {this.state.orientationProximal ==
                              Common.WoundOrientation.proximal && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                    {/* </>
                                        } */}
                    {(!this.state.isReorderAndTissueTypeNotChange ||
                      !this.state.ReoderAndIsDisableOrientation) && (
                      <button
                        type="button"
                        onClick={(e) =>
                          this.resetOrientation(this.state.Resident)
                        }
                        className="btn btn-danger btn-xs"
                      >
                        Reset Orientation
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-1 col-md-1 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="Length"
                    >
                      Length (cm)
                    </label>
                    <input
                      type="number"
                      id="Length"
                      min="0.1"
                      step="0.1"
                      max="99.9"
                      onChange={(e) => this.allowOneDigitAfterDecimal(e)}
                      name="woundLength"
                      required
                      // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.woundLength
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="Width"
                    >
                      Width (cm)
                    </label>
                    <input
                      type="number"
                      id="Width"
                      min="0.1"
                      step="0.1"
                      max="99.9"
                      onChange={(e) => this.allowOneDigitAfterDecimal(e)}
                      name="woundWidth"
                      required
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.woundWidth
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="Depth"
                    >
                      Depth (cm)
                    </label>
                    <input
                      type="number"
                      id="Depth"
                      min="0.1"
                      step="0.1"
                      max="99.9"
                      onChange={(e) => this.allowOneDigitAfterDecimal(e)}
                      name="woundDepth"
                      required
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-"].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      disabled={this.state.Resident.wound.isUtd}
                      // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.woundDepth
                      )}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                {/*  <div className="col-sm-1 col-md-1 col-lg-1 col-xl-1">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="isUtd"
                          checked={this.state.Resident.wound.isUtd}
                          onChange={(e) => this.handleUtdChange(e)}
                          value="gilad"
                        />
                      }
                      label="UTD"
                      className="font-weight-bold"
                    />
                  </div>
                </div> */}
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="fkExudateAmountId"
                    >
                      Exudate Amount
                    </label>
                    <select
                      className="form-control form-control-sm"
                      id="fkExudateAmountId"
                      name="fkExudateAmountId"
                      required
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.fkExudateAmountId
                      )}
                    >
                      <option value=""></option>
                      <option value="1">None</option>
                      <option value="3">Light</option>
                      <option value="4">Moderate</option>
                      <option value="5">Heavy</option>

                      {/* {this.state.WoundExudateAmount} */}
                    </select>
                  </div>
                </div>
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="sloughPercentage"
                    >
                      Slough %
                    </label>
                    <input
                      type="number"
                      min="1"
                      id="sloughPercentage"
                      className="form-control form-control-sm"
                      name="sloughPercentage"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.sloughPercentage
                      )}
                      required={
                        this.state.Resident.wound.fkWoundThicknessId == 5 &&
                        this.state.Resident.wound.necroticPercentage > 0
                          ? false
                          : this.state.IsSloughRequired
                      }
                      disabled={this.state.IsThicknessStageTwo}
                    />
                  </div>
                </div>
                <div className="col-sm-3 col-md-4 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <label
                      className="form-label font-weight-bold"
                      htmlFor="necroticPercentage"
                    >
                      Necrotic %
                    </label>
                    <input
                      type="number"
                      min="1"
                      id="necroticPercentage"
                      className="form-control form-control-sm"
                      name="necroticPercentage"
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={Common.replaceNullWithString(
                        this.state.Resident.wound.necroticPercentage
                      )}
                      required={
                        this.state.Resident.wound.fkWoundThicknessId == 5 &&
                        this.state.Resident.wound.sloughPercentage > 0
                          ? false
                          : this.state.IsNecroticRequired
                      }
                      disabled={this.state.IsThicknessStageTwo}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="tunneling"
                          checked={this.state.Resident.wound.tunneling}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value="gilad"
                        />
                      }
                      label="Tunneling"
                      className="font-weight-bold"
                    />
                  </div>
                </div>
                {this.state.Resident.wound.tunneling && (
                  <>
                    <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="tunnelingDepth"
                        >
                          T Depth (cm)
                        </label>
                        <input
                          type="number"
                          id="tunnelingDepth"
                          min="0"
                          step="0.01"
                          name="tunnelingDepth"
                          required
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                          onChange={(e) => this.allowTwoDigitAfterDecimal(e)}
                          value={Common.replaceNullWithString(
                            this.state.Resident.wound.tunnelingDepth
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          T Clock Position
                        </label>
                        <input
                          type="number"
                          id="TunnelingClockPosition"
                          min="0"
                          max="12"
                          name="tunnelingClockPosition"
                          required
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-", "."].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value={Common.replaceNullWithString(
                            this.state.Resident.wound.tunnelingClockPosition
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-3 col-md-2 col-lg-2 col-xl-2">
                  <div className="form-group">
                    <FormControlLabel
                      control={
                        <Switch
                          name="undermining"
                          checked={this.state.Resident.wound.undermining}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          value="gilad"
                        />
                      }
                      label="Undermining"
                      className="font-weight-bold"
                    />
                  </div>
                </div>
                {this.state.Resident.wound.undermining && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          U Depth (cm)
                        </label>
                        <input
                          type="number"
                          id="underminingDepth"
                          min="0"
                          step="0.01"
                          name="underminingDepth"
                          required
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          // onChange={e => Common.updateInputValueInState(this.state.Resident.wound, e, this, this.state)}
                          onChange={(e) => this.allowTwoDigitAfterDecimal(e)}
                          value={Common.replaceNullWithString(
                            this.state.Resident.wound.underminingDepth
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          {" "}
                          From U Clock Position
                        </label>
                        <input
                          type="number"
                          id="UnderminingClockPosition"
                          name="underminingClockPosition"
                          required
                          min="1"
                          max="12"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.underminingClockPosition
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="underminingDepth font-weight-bold"
                        >
                          To U Clock Position
                        </label>
                        <input
                          type="number"
                          id="UnderminingClockPosition"
                          name="toUnderminingClockPosition"
                          required
                          min="1"
                          max="12"
                          onKeyDown={(evt) =>
                            ["e", "E", "+", "-"].includes(evt.key) &&
                            evt.preventDefault()
                          }
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          defaultValue={Common.replaceNullWithString(
                            this.state.Resident.wound.toUnderminingClockPosition
                          )}
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* </div>
                            <div className="row"> */}
                {this.state.WoundTypeSelected == "Surgical" && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="surgicalDate"
                        >
                          Surgical Date
                        </label>
                        <input
                          type="Date"
                          id="surgicalDate"
                          name="surgicalDate"
                          required
                          min="1900-01-01"
                          max="3999-12-31"
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          // defaultValue={Common.getInputStringDate( moment())}
                          value={Common.getInputStringDateUsingMoment(
                            this.state.Resident.wound.surgicalDate != null
                              ? moment(this.state.Resident.wound.surgicalDate)
                              : ""
                          )}
                          className="form-control form-control-sm"
                          // disabled={this.state.IsPartialThickness}
                          //disabled={this.state.IsThicknessStageTwo}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="row">
                {this.state.WoundTypeSelected != "Surgical" && (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="DebridementDate"
                        >
                          Debridement Date
                        </label>
                        <input
                          type="Date"
                          id="DebridementDate"
                          name="debridementDate"
                          required={this.state.isFacilityStaff ? false : true}
                          min="1900-01-01"
                          max={Common.getInputStringDateUsingMoment(moment())}
                          onChange={(e) =>
                            Common.updateInputValueInState(
                              this.state.Resident.wound,
                              e,
                              this,
                              this.state
                            )
                          }
                          // value={Common.getInputStringDateUsingMoment(this.state.Resident.wound.debridementDate != null ? moment(this.state.Resident.wound.debridementDate) : moment())}
                          value={this.state.Resident.wound.debridementDate}
                          disabled={
                            this.state.Resident.wound.debridementDate != null &&
                            this.state.Resident.isWoundInfoChange != true &&
                            this.props.resident.wound
                              .fkSelectedDebridementTypeId ==
                              Common.debridementType.sharp
                              ? true
                              : false
                          }
                          className="form-control form-control-sm"

                          // disabled={this.state.IsPartialThickness}
                          //disabled={this.state.IsThicknessStageTwo}
                        />
                      </div>
                    </div>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                      <div className="form-group">
                        <label
                          className="form-label font-weight-bold"
                          htmlFor="fkDebridementTypeId"
                        >
                          Debridement Type
                        </label>
                        <select
                          className="form-control form-control-sm"
                          id="fkDebridementTypeId"
                          name="fkDebridementTypeId"
                          required={this.state.isFacilityStaff ? false : true}
                          onChange={(e) => this.onDebridementTypeChange(e)}
                          value={Common.replaceNullWithString(
                            this.state.Resident.wound.fkDebridementTypeId
                          )}
                          disabled={
                            this.state.Resident.isWoundInfoChange != true &&
                            this.props.resident.wound
                              .fkSelectedDebridementTypeId ==
                              Common.debridementType.sharp
                              ? true
                              : false
                          }
                        >
                          <option value=""></option>
                          {this.state.WoundDebridementType}
                        </select>
                      </div>
                    </div>
                  </>
                )}
                <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4"></div>
                {this.state.showProduct && (
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label
                        className="form-label font-weight-bold"
                        htmlFor="underminingDepth font-weight-bold"
                      >
                        Push Score
                      </label>
                      <input
                        type="number"
                        name="pushScore"
                        value={Common.replaceNullWithString(
                          this.state.PushScore
                        )}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-2 col-md-2 col-lg-2 col-xl-2 justify-content-end">
                  <label
                    className="form-label font-weight-bold"
                    htmlFor="icdcode"
                  >
                    ICD Code -{" "}
                    <span
                      className={
                        this.state.GetCodeProcess == "Calculate"
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {this.state.GetCodeProcess}
                    </span>
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      id="icdcode"
                      name="icdcode"
                      aria-describedby="basic-addon2"
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.Resident.wound,
                          e,
                          this,
                          this.state
                        )
                      }
                      value={this.state.ICD10Code}
                      className="form-control form-control-sm"
                      disabled
                    />
                    <div className="input-group-append">
                      {this.state.showProduct == false ? (
                        <button
                          type="button"
                          className="btn btn-success btn-xs"
                          onClick={(e) => this.getICD10APICall(e)}
                        >
                          GET CODE
                        </button>
                      ) : (
                        <div></div>
                      )
                      // <button type="button" className="btn btn-danger btn-xs" onClick={e => this.setState({ showProduct: false })}>Edit</button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            {/* {
                            (this.state.showProduct == false) ?
                                <div>
                                </div>
                                : */}
            <div className="d-flex justify-content-end py-3">
              {this.state.showProduct == false ? (
                <div></div>
              ) : (
                // <button type="button" className="btn btn-success btn-sm" onClick={e => this.getICD10APICall(e)}>GET CODE</button>
                <button
                  type="button"
                  disabled={this.props.isReadOnly ? true : false || this.props.isEdit ? true : false}
                  className="btn btn-danger btn-sm"
                  onClick={(e) =>
                    this.setState({
                      showProduct: false,
                      ICD10Code: "",
                      GetCodeProcess: "Calculate",
                    })
                  }
                >
                  Change Wound Information
                </button>
              )}
            </div>

            {/* } */}
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader type="Puff" color="#00BFFF" height={50} width={50} />
          </div>
        )}
      </>
    );
  }

  calculatePushScore(area, exudateAmount, tissueTypeId) {
    var pushScore = 0;
    if (area <= 0) {
    } else if (area < (0.3).toFixed(2)) {
      pushScore = 1;
    } else if (area >= (0.3).toFixed(2) && area <= (0.6).toFixed(2)) {
      pushScore = 2;
    } else if (area >= (0.7).toFixed(2) && area <= (1.0).toFixed(2)) {
      pushScore = 3;
    } else if (area >= (1.1).toFixed(2) && area <= (2.0).toFixed(2)) {
      pushScore = 4;
    } else if (area >= (2.1).toFixed(2) && area <= (3.0).toFixed(2)) {
      pushScore = 5;
    } else if (area >= (3.1).toFixed(2) && area <= 4.0) {
      pushScore = 6;
    } else if (area >= (4.1).toFixed(2) && area <= 8.0) {
      pushScore = 7;
    } else if (area >= (8.1).toFixed(2) && area <= 12.0) {
      pushScore = 8;
    } else if (area >= (12.1).toFixed(2) && area <= 24.0) {
      pushScore = 9;
    } else if (area > 24) {
      pushScore = 10;
    }
    if (exudateAmount == Common.exudate.light) {
      pushScore = pushScore + 1;
    } else if (exudateAmount == Common.exudate.moderate) {
      pushScore = pushScore + 2;
    } else if (exudateAmount == Common.exudate.heavy) {
      pushScore = pushScore + 3;
    }

    if (tissueTypeId == Common.mostSevereTissue.Epithelial) {
      pushScore = pushScore + 1;
    } else if (tissueTypeId == Common.mostSevereTissue.Granulation) {
      pushScore = pushScore + 2;
    } else if (tissueTypeId == Common.mostSevereTissue.Slough) {
      pushScore = pushScore + 3;
    } else if (tissueTypeId == Common.mostSevereTissue.Necrosis) {
      pushScore = pushScore + 4;
    }
    this.setState({
      PushScore: pushScore,
    });
    // return pushScore;
  }

  renderProductSelectionForm() {
    return (
      <>
        <ProductSelection
          ref={(ref) => (this.productSelection = ref)}
          // orderProducts={this.state.orderProducts?.products ||[]}
          primaryDressing=""
          secondaryDressing=""
          saleNote=""
          // isWoundInfoChange={this.state.Resident.isWoundInfoChange}
          frequencyId={8}
          isReorder={this.props.isReorder}
          isReadOnly={this.props.isReadOnly}
          woundDetailId={this.props.resident.wound.woundDetailId}
          salesOrderId={this.props.resident.fkSalesOrderId}
        />
      </>
    );
  }

  render() {
    return (
      // <Card>
      // <Form
      //validated={this.state.formValidated}
      // >
      //<CardHeader title='Resident' />
      // <CardBody>

      !this.state.lastOrdermodal ? (
        <>
          {/* {
                        this.props.isReorder ?

                            <div className="d-flex justify-content-end py-3">
                                <Button variant="success" className="mr-5 px-10" size="sm" onClick={() => this.getLastWoundDetailByWoundId(this.state.Resident.wound.id)}>View Previous Order </Button>
                            </div>
                            :
                            null
                    } */}
          <Form validated={this.state.formValidated}>
            {this.renderWoundForm()}

            {this.state.showProduct && this.props.displayProduct
              ? this.renderProductSelectionForm()
              : null}

            {(this.state.showProduct || this.props.isEdit) &&
            !this.props.isReadOnly ? (
              <div className="d-flex justify-content-end py-3">
                {this.state.IsLoading ? 
                (
                  <button
                    data-v-aa799a9e=""
                    type="button"
                    disabled="disabled"
                    className="btn btn-outline-primary disabled"
                  >
                    <span
                      data-v-aa799a9e=""
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm"
                    ></span>{" "}
                    Loading...{" "}
                  </button>
                ) : (
                  <Button
                    variant="success"
                    className="mr-5 px-10"
                    size="sm"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    Save
                  </Button>
                )}
              </div>
            ) : null}
          </Form>
        </>
      ) : (
        <>
          {/* Last order View */}
          {/* <PopupModal

                        title="Wound Evaluation" dialogClassName="modal-95w" ref={ref => { this.state.lastOrdermodal = ref; }}> */}
          {this.state.showLastOrderWoundComponent}
          {/* </PopupModal> */}
        </>
      )
      // </CardBody>
      // </Form>

      // </Card>
    );
  }

  onFormSave() {}
} // end of class

/*eslint-enable */

/*eslint-disable */
import React from "react";

export default class PlaceOrder extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <form>
                <div className="row  ">


                    <div className="col-md-6 col-sm-12 mx-auto">
                        <div className="form-group">
                            <label className="form-label" htmlFor="OrderType">Select Order Type</label>
                            <select className="form-control form-control-sm" id="OrderType">
                                <option>Order Products</option>
                                <option>Rep Samples</option>
                            </select>
                        </div>
                    </div>



                </div>
            </form >
        )
    }

    onFormSave() {
        
    }

}// end of class

/*eslint-enable */
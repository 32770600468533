import { Switch, Typography } from "@material-ui/core";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle, Button, Dropdown, DropdownButton
} from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import SelectableContext from "react-bootstrap/SelectableContext";
import { useSelector } from 'react-redux';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import uuid from 'node-uuid'
import ResidentHistoryWounds from "./residentWoundHistory"
export function FacilityResidentHistory(props) {
  //#region Resident modal
  const [residentListUI, setResidentListUI] = useState([])
  //#endregion


  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllResident();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, []);

  //#region Set Resident List
  const getAllResident = () => {
    setIsLoading(true);
    Api.getRequestQry(
      Api.URI_GetResidentHistory,
      "?residentId=" + props.fkResidentId
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setResidentList(res.data.result);

            }
          }
        }

      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);

      });

  };

  const setResidentList = (list) => {
    debugger
    var rows = [];

    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const residentID = obj.id;

      obj.isProcess = true;
      var insurance = "No insurance on file";

      if (obj.residentInsuranceList != null) {
        insurance = "";
        for (var d = 0; d < obj.residentInsuranceList.length; d++) {
          let objIns = JSON.parse(JSON.stringify(obj.residentInsuranceList[d]));
          insurance = insurance + (insurance != "" ? ", " : "") + " " + (objIns.isAddEdit ? "*" : "") + "=>" + (objIns.fkInsuranceLevelId == 1 ? "Primary"
            : objIns.fkInsuranceLevelId == 2 ? "Secondary"
              : objIns.fkInsuranceLevelId == 3 ? "Tertiary"
                : "") + " " + objIns.insuranceName
        }
      }
      var statusBadgeType =
        obj.isProcess == true ? "badge-success" : "badge-warning";

      rows.push(
        <Accordion  key={uuid()} defaultActiveKey={!obj.isProcess ? i.toString() + obj.id : ""}>
          <Card>
            <CardHeader>
              <div
                     className={`row w-100 d-flex border-bottom m-0  ${obj.isProcess ? "bg-light-success border-success"
                      : "border-light-dark"
                      }`}

              >
                <div className="customStyleAccorion" style={{ display: "grid" }}>
                  <AccordionToggle
                    as={Button}
                    variant="link"
                    // className="h5 mb-0 ml-auto"
                    eventKey={i.toString() + obj.id}
                  >

                    <div
                      className={`h5 mb-0 text-dark ${""}`}> {obj.residentName}


                    </div>


                  </AccordionToggle>
                   <small> Is Partial: {obj.isPartial?"Yes":"No"} <br /></small> 
                </div>
                <span className={`badge my-auto mx-4 ml-auto `}>
                  <span className={`badge my-auto mx-4 ml-auto ${statusBadgeType}`} >
                    <span
                      className={`badge badge-success mr-2`}
                    >
                      {obj.isPartial?"Reorder Date" : "Ship Date"}:{" "} {Common.GetDateString(obj.cycleDate) == "01-01-01" ? "N/A" : Common.GetDateString(obj.cycleDate)}
                    </span>
                    </span>
                    <span>
                        <SelectableContext.Provider value={false}>
                          <DropdownButton
                            className="d-inline"
                            drop={"down"}
                            size="xs"
                            variant="success"
                            title={'Actions'}
                            id={`dropdown-button-drop-actions`}
                            key={'down'}>
                         
                            <Dropdown.Item eventKey="8" onClick={() => handleResidentReport(obj)} >Print Report</Dropdown.Item>

                         
                          </DropdownButton>
                        </SelectableContext.Provider>


                      </span>
                  </span>




              </div>


            </CardHeader>
            <AccordionCollapse eventKey={i.toString() + obj.id} >
              <CardBody >
                <fieldset disabled={obj.isProcess || obj.isNoOrder==true?true:false} >
                  <ResidentHistoryWounds 
                  residentId={obj.residentId}
                  processQueueId={obj.id}
                  isPartial={obj.isPartial==true?1:0}
                  cycleDate={obj.cycleDate}

                   />
                </fieldset>
              </CardBody>
            </AccordionCollapse>
          </Card>
        </Accordion>
      );
    }

    //return rows
    setResidentListUI([]);
    setResidentListUI(rows);
  };


  const handleResidentReport = async (resident) => {
    var { data } = await Api.getRequestQry(Api.URI_GetResidentHistoryReport,
      "?facilityId=" + resident.facilityId + "&processQueueId=" + resident.id +
      "&residentId=" + resident.residentId + "&isPartial=" + resident.isPartial+"&partialOrderDate="+resident.cycleDate)
    debugger
    if (data.success) {
      debugger
      if (data.result != null && data.result != '') {
        window.location.assign(data.result)
      }

    } else {
    }
  }



  return (

    <Card className="example example-compact">
      <CardBody>
        <>
        {isLoading ? (
          <div className="row d-flex justify-content-center">
            <span className="mt-5 spinner spinner-primary"></span>
          </div>
        ) : (
          residentListUI
        )}
        </>
      </CardBody>
    </Card>

  );
}

export default FacilityResidentHistory;

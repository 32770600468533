import React, { Component } from 'react';
import { Button, Form } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

class Note extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formValidated: false,
      SaleNote: {

        NoteID: 1,
        CreatedDate: '2020-12-22T08:44:02.830Z',
        CreatedBy: 1,
        Description: '',
        ResidentId: '',
        Subject: '',
        NoteReason: '',
        Status: '',
      }

    }
  }


  handleSubmit = e => {
    // // debugger
    e.preventDefault();
    const obj = this.state.SaleNote;
    Api.postRequest(Api.Uri_CreateNotes, obj).then((res) => {
      Common.showSuccessAlertAutoClose("Note Added Successfully", "success", "Success!", 3000);
      // // debugger
      this.setState({
        formValidated: false,
        SaleNote: {

          NoteID: 1,
          CreatedDate: '2020-12-22T08:44:02.830Z',
          CreatedBy: 1,
          Description: '',
          ResidentId: '',
          Subject: '',
          NoteReason: '',
          Status: '',
        }

      })
    });

  }

  render() {
    return (
      <Card>
        <CardHeader title='Note' />
        <CardBody>
          <Form>
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <label className="form-label" >Resident</label>
                  <input type="text" id="ResidentID"
                    name="ResidentId"
                    onChange={e => Common.updateInputValueInState(this.state.SaleNote, e, this, this.state)}
                    value={this.state.SaleNote.ResidentId}
                    className="form-control form-control-sm" />
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <label className="form-label">Subject</label>
                  <input type="text" id="Subject"
                    name="Subject"
                    onChange={e => Common.updateInputValueInState(this.state.SaleNote, e, this, this.state)}
                    value={this.state.SaleNote.Subject}
                    className="form-control form-control-sm" />
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="Country">Note Reason</label>
                  <select className="form-control form-control-sm"
                    name="NoteReason"
                    onChange={e => Common.updateInputValueInState(this.state.SaleNote, e, this, this.state)}
                    id="NoteReason">
                    <option value="0">Select Note Reason</option>
                    <option value="34">Connect - Patient Opt Out (Patient)</option>
                    <option value="35">Days of Supplies remaining- OUTs (Patient)</option>
                    <option value="36">Doctor Change for Patient (Patient)</option>
                    <option value="33">Insurance Verification (Patient)</option>
                    <option value="22">Inventory Item Addition Request (Patient)</option>
                    <option value="23">Patient Change of Information (Patient)</option>
                    <option value="15">Patient Note (Patient)</option>
                    <option value="21">Payer Addition Request (Patient)</option>
                  </select>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4 col-xl-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="Country">Status</label>
                  <select className="form-control form-control-sm"
                    name="Status"
                    onChange={e => Common.updateInputValueInState(this.state.SaleNote, e, this, this.state)}
                    id="Status">
                    <option value="0">Select Status</option>
                  	<option selected="selected" value="1">New</option>
						<option value="5">Delivered</option>
                  </select>
                </div>
              </div>
            </div>


            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <div className="form-group">
                  <label className="form-label" htmlFor="State">Description</label>
                  <textarea id="Description"
                    rows="3"
                    className="form-control"
                    name="Description"
                    onChange={e => Common.updateInputValueInState(this.state.SaleNote, e, this, this.state)}
                  />
                </div>
              </div>
            </div>

          </Form>
        </CardBody>
        <CardFooter className="d-flex justify-content-end py-3">
          <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)} >Save</Button>
      
        </CardFooter>
      </Card>

    );
  }
}
export default Note;
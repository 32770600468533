import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Button, Col, Form } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader
} from "../../../../_metronic/_partials/controls/Card";
export default class ControlledTabs extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            key: 'EmailSetting',
            AppSettings: []
        };
    }
    componentDidMount() {
        this.loadAction();
    }
    loadAction = () => {
        Api.getRequest(Api.Uri_GetSystemSetting).then((res) => {
            this.setState({
                AppSettings: res.data.result,

            });
        });
    };
    onChangeOnOFFBT=(i,e)=>{
        debugger
        if(e.target.checked){
         
            Common.withOutEventUpdateInputValueInState(this.state.AppSettings[i], this, this.state, "1", "settingValue")
        }else{
            Common.withOutEventUpdateInputValueInState(this.state.AppSettings[i], this, this.state, "0", "settingValue")
            
        }
        
    }
    systemSettingCOntrols = (settingCategory) => {
        if (this.state.AppSettings.length > 0) {
            //.filter(person => person.settingCategory == settingCategory)
            return this.state.AppSettings.map((object, i) => {
                if (object.settingCategory == settingCategory) {
                    if (object.id != 1013  && object.id !=1014) {
                        return (
                            <div className="mt-3 col-sm-6 col-md-6 col-lg-6 col-xl-6" key={i}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="FirstName">{this.state.AppSettings[i].label}
                                    </label>
                                    <input type="text"
                                        name="settingValue"
                                        onChange={e => Common.updateInputValueInState(this.state.AppSettings[i], e, this, this.state)}
                                        value={this.state.AppSettings[i].settingValue}

                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                        );
                    } else {
                        return (
                            <div className="mt-3 col-sm-6 col-md-6 col-lg-6 col-xl-6" key={i}>
                                <div className="form-group">
                                    <FormControlLabel
                                        control={<Switch name="settingValue" checked={this.state.AppSettings[i].settingValue == "1" ? true : false}
                                            onChange={e => this.onChangeOnOFFBT(i,e)}
                                            value="gilad" />}
                                        label={this.state.AppSettings[i].label}
                                    />
                                </div>
                            </div>
                        );

                    }
                }
            });
        }
    };
    handleSubmit = (event) => {
        let obj = this.state;
        Api.postRequest(Api.Uri_UpdateSystemSettings, obj).then((res) => {
            if (res.data.success) {
                Common.showSuccessAlertAutoClose("System Setting Updated Successfully", "success", "Success!", 3000);
            } else {
                Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
            }
        })
            .catch(function (error) {
                Common.showSuccessAlertAutoClose("", "error", "Error!", 3000);
                // Common.LogError(error, "Vacation", "Add");

            });
    }
    render() {
        return (

            <Card className="example example-compact">
                <CardHeader title="System Settings" />
                <CardBody>
                    <Form
                    >
                        <div role="alert" className="fade alert alert-primary show">If you don't know how to change system settings please contact with system admin.</div>
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={this.state.key}
                            onSelect={key => this.setState({ key })}
                        >
                            <Tab eventKey="EmailSetting" title="Email Settings">

                                <div className="row">
                                    {this.systemSettingCOntrols(this.state.key)}
                                </div>
                            </Tab>
                            <Tab eventKey="GeneralSetting" title="General Settings">

                                <div className="row">
                                    {this.systemSettingCOntrols(this.state.key)}
                                </div>
                            </Tab>
                            <Tab eventKey="EnvironmentSetting" title="Environment Settings">

                                <div className="row">
                                    {this.systemSettingCOntrols(this.state.key)}
                                </div>
                            </Tab>
                        </Tabs>
                    </Form>
                </CardBody>

                <CardFooter className="d-flex justify-content-end py-3">
                    <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
                </CardFooter>
            </Card>
        );
    }
}



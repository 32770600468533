import React from "react";

export default function SubHeaderTimerBR({ monthName, day }) {
  return (
    <span
      className="text-primary font-weight-bold"
      id="kt_dashboard_daterangepicker_date"
    >
      {day.toString()} {monthName}
    </span>
  );
}

// ** React Imports
import * as React from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { Fragment, useEffect, useState } from "react";
import { Badge, Table, Button } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import moment from "moment";
import Loader from "react-loader-spinner";
import { connect } from "formik";
import { defaultUrls,blobContainerFacilityReportName, blobContainerResidentName } from "../../constants/endpoints";
import useNotificationHub from "../../Utility/hooks/useNotificationHub";
const FacilityCoverReport = (props) => {
  // ** States
  const [id, setID] = useState(0);
  const [msg, setMsg] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [reportList, setFacilityReportList] = useState([]);
  const [isProcessorFunctionOn, setIsProcessorFunctionOn] = useState(true);
  const [printSelectedOrAll, setCanPrintSelectedOrAll] = useState(false);

  useNotificationHub(defaultUrls.notificationHub, {
    method: "PrintQueue",
    methodCallback: () => {
      getAllFacilityReport(0);
    },
  });

  //  Get All organization Type
  useEffect(() => {
    getAllFacilityReport(0);
  }, []);
  const getAllFacilityReport = async (reportID) => {
    // setLoading(true)
    var parm = "?reportID=" + reportID;
    var { data } = await Api.getRequestQry(Api.Uri_GetFacilityReports, parm);
    debugger;
    if (data.success) {
      var processorFunction = data.message == "1" ? true : false;
      setIsProcessorFunctionOn(processorFunction);
      setFacilityReportList(data.result);
      var repostsForPrint = data.result.filter(
        (x) => x.pdfURL == null || x.pdfURL == ""
      );
      if (repostsForPrint.length > 0) {
        setCanPrintSelectedOrAll(true);
      } else {
        setCanPrintSelectedOrAll(false);
      }
      // setLoading(false)
    } else {
      setFacilityReportList([]);
      // setLoading(false)
    }
  };
  const setSelectedValue = (e, facilityId) => {
    reportList.map((value) => {
      if (value.facilityId == facilityId) {
        value.isSelected = e.target.checked;
      }
    });
    debugger;
  };
  const dowloadReport = async (rpt) => {
    debugger;
    getAllFacilityReport(rpt.id);
    const fileUrl = await Common.verifyIfFileExistsInBlobContainerThenReturnItUrl(rpt.pdfURL);
    window.location = fileUrl;
  };
  const handlePrintAllAndSelectedReleasedFacility = async (isSelected) => {
    var errors = [];
    var reportsForPrint = isSelected
      ? reportList.filter((x) => x.isSelected === true)
      : reportList;

    if (reportsForPrint.length > 0) {
      setLoading(true);

      try {
        await Promise.all(
          reportsForPrint.map(async (report) => {
            let reportName = report.facilityName;

            try {
              const { data } = await Api.postRequest(
                Api.Uri_GetPintedAllAndSelectedReport,
                report
              );

              if (data.statusCode === 200) {
                if (data.result != null && data.result.length > 0) {
                  if (data.result != null) {
                    report.isSelected = false;
                    debugger;
                    const fileUrl = await Common.verifyIfFileExistsInBlobContainerThenReturnItUrl(data.result)
                    window.open(fileUrl, "_blank", "noopener,noreferrer");
                  } else {
                    errors.push(`${reportName}: Something went wrong`);
                  }
                }
              } else {
                errors.push(`${reportName}: ${data.message}`);
              }
            } catch (error) {
              console.error(`API error for ${reportName}:`, error);
              errors.push(`${reportName}: ${error}`);
            }
          })
        );
      } catch (error) {
        console.error("Promise.all error:", error);
        errors.push(`Promise Error: ${error.message}`);
      } finally {
        setLoading(false);
        getAllFacilityReport(0);
        if (errors.length > 0) {
          Common.SweetAlertWithValidation(errors);
        }
      }
    }
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            handlePrintAllAndSelectedReleasedFacility(true);
          }}
          disabled={!printSelectedOrAll}
          className="mr-3"
        >
          Print Selected
        </Button>

        <Button
          variant="primary"
          size="sm"
          disabled={!printSelectedOrAll}
          onClick={() => {
            handlePrintAllAndSelectedReleasedFacility(false);
          }}
        >
          Print All
        </Button>
      </div>
    </div>
  );
  return (
    <Fragment>
      {isLoading == false ? (
        <Card>
          <CardHeader title="Print Manual Facilities" toolbar={toolbar} />
          <CardBody className="pt-2">
            <Table
              responsive
              striped
              hover
              bordered
              size="sm"
              className="w-100"
            >
              <thead>
                <tr>
                  <th>Sr #</th>
                  <th>Facility Name</th>
                  <th>Downloaded By Name</th>

                  <th>Processor</th>
                  <th>Ship Date</th>
                  <th>Print Date</th>
                  <th>Action</th>

                  {/* <th>Modified By</th> */}
                </tr>
              </thead>
              <tbody>
                {reportList?.map((rpt, i) => {
                  return (
                    <tr key={i.toString()}>
                      <td>{i + 1}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-link"
                          disabled={
                            rpt.pdfURL == null || rpt.pdfURL == ""
                              ? true
                              : false
                          }
                          onClick={() => {
                            dowloadReport(rpt);
                          }}
                        >
                          {rpt.facilityName}
                        </button>
                      </td>
                      <td>{rpt.downloadedByName}</td>
                      <td align="center">
                        {rpt.isProcessorOn == true ? (
                          <Badge variant="success">Yes</Badge>
                        ) : (
                          <Badge variant="primary">No</Badge>
                        )}
                      </td>
                      <td>{moment(rpt.billingDate).format("MM/DD/YYYY")}</td>
                      <td>{moment(rpt.createdDate).format("MM/DD/YYYY")}</td>
                      <td>
                        {rpt.pdfURL != null && rpt.pdfURL != "" ? (
                          ""
                        ) : (
                          <>
                            <input
                              type="checkbox"
                              // disabled={isProcessorFunctionOn}
                              name={"outProgram" + rpt.facilityId}
                              onChange={(e) =>
                                setSelectedValue(e, rpt.facilityId)
                              }
                              checked={rpt.isSelected}
                              className="m-1"
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      ) : (
        <div className="d-flex justify-content-center">
          <Loader type="Puff" color="#00BFFF" height={50} width={50} />
        </div>
      )}
    </Fragment>
  );
};

export default FacilityCoverReport;

import React from "react";

export function NewPersonForm () {

        return (
            <form>
                <div className="row">

                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="FirstName">First Name</label>
                            <input type="text" id="FirstName" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="LastName">Last Name</label>
                            <input type="text" id="LastName" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="UserType">User Type</label>
                            <select className="form-control form-control-sm" id="UserType">
                                <option value=''></option>
                                <option>Sales Rep</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Special">Special</label>
                            <select className="form-control form-control-sm" id="Special">
                                <option value=''></option>
                                <option>Read Only</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="ProfessionalCredentials">Professional Credentials (ex: RN LPN)</label>
                            <input type="text" id="ProfessionalCredentials" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Position">Position (e.g. Dir. of Nursing)</label>
                            <input type="text" id="Position" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Email">Email</label>
                            <input type="email" id="Email" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Telephone#">Telephone Number</label>
                            <input type="text" id="Telephone#" className="form-control form-control-sm" />
                            <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Extension1">Extension 1</label>
                            <input type="text" id="Extension1" className="form-control form-control-sm" />
                        </div>
                    </div>
                    
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Fax">Fax </label>
                            <input type="text"  id="Fax" className="form-control form-control-sm" />
                            <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Password">Password</label>
                            <input type="password"  id="Password" className="form-control form-control-sm" />
                            <small>Minimum 8 characters</small>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="ConfirmPassword">Confirm Password</label>
                            <input type="password"  id="ConfirmPassword" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Address">Address</label>
                            <input type="text"  id="Address" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="NPI#">NPI #</label>
                            <input type="date" id="NPI#" className="form-control form-control-sm" />
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Memo">Memo</label>
                            <textarea id="Memo" rows={5} className="form-control form-control-sm" />
                        </div>
                    </div>
                </div>
            </form>
        );
}

export default NewPersonForm;
import * as React from "react";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { useSelector } from 'react-redux';
import {
  Badge,
} from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import AddOrderApprovel from "../resident/addOrderApprovel";
import PopupModal from "../modal/popupmodal";
import WoundProducts from "../products/woundProducts";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import NewWound from './newwound';
import uuid from 'node-uuid'
import { ModulesName, UserActions } from "../../General/roleModuleActions";
var editWoundmodal = null;
export function WoundOrderApprovel(props) {
  const [showAddWoundComponent, setAddWoundComponent] = useState(null);
  const [residentName, setResidentName] = useState(null);

  
  const [orderListUI, setOrderListUI] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showAddOrderApprovel, setIsShowAddOrderApprovel] = useState(false);
  const [approveWithSig, setApproveWithSig] = useState(false);
  const [facility, setFacilityStatusInfo] = useState(props.facility);
  const { user } = useSelector((state) => state.auth);
  var _sampleWoundObj = {
    fkResidentId: null,
    residentName: '',
    facilityName: '',
    roomUnit: '',
    evaluatedBy: Common.replaceNullWithString(user.firstName) + " " + Common.replaceNullWithString(user.lastName),
    physicanName: '',
    createdBy: user.userID,
    modifiedBy: user.userID,
    btPatientBrightreeID: null,
  
    wound:
    {
      id: 0,
      // evaluationDate: '',
      // dateFirstObserved: '',
      // fkAcquisitionId: null,
      fkWoundLocationId: null,
      woundLocation: "",
      fkWoundTypeId: null,
      woundDescription: '',
      fkWoundThicknessId: null,
      //fkWoundSevereTissueTypeId: null,
      fkWoundLateralityId: null,
      woundLength: '',
      woundWidth: '',
      woundDepth: '',
      isUtd:false,
      tunneling: false,
      tunnelingDepth: null,
      tunnelingClockPosition: '',
      undermining: false,
      underminingDepth: null,
      underminingClockPosition: null,
      toUnderminingClockPosition:null,
      icdcode: "",
      fkExudateAmountId: null,
      fkExudateTypeId: null,
      exudateColor: '',
      fkDebridementTypeId: null,
      debridementDate: null,
      surgicalDate: null,
      odor: false,
      Pain: false,
      painMedicationOrdered: false,
      sloughPercentage: null,
      necroticPercentage: null,
      //FkWoundCleanseId :'',
      cleanseDescription: null,
      fkDressingChangeFrequencyId: 8,
      //fkPrimaryDressingId :null,
      //FkAdditionalPrimaryDressingId :'',
      otherAdditionalPrimaryDressing: '',
      //fkSecondaryDressingId :null,
      //FkAdditionalSecondaryDressingId :'',
      otherAdditionalSecondaryDressing: '',
      treatment: '',
      comments: '',
      thicknessIdForBackStagging: null,
      orientationLeftRight: null,
      orientationUpperLower: null,
      orientationInnerOuter: null,
      orientationAnteriorPosterior: null,
      orientationMedialLateral: null,
      orientationDorsalDistalProximal: null,
      isDocumentation: false
      // FkWoundcareProductTypeId :'',

    }
  };
  useEffect(() => {
    // setIsLoading(true);
    getFacilityStatusById();
    getAllFacilityResidentWound();

  }, []);
  const getAllFacilityResidentWound = () => {
    Api.getRequestQry(Api.Uri_GetFacilityResidentWoundOrder, "?facilityID=" + props.facility.id + "&isApproved=" + 2)
      .then((res) => {
        if (res.data.statusCode == 200) {
          // setOrderListUI([]);
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setFacilityList(res.data.result);
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "getAllFacility");


      })
      .then(() => {
        setIsLoading(false);
        // Common.LoadDataTable("facilityWoundTable");
      });
  };

  const getFacilityStatusById = () => {
    Api.getRequestQry(Api.Uri_GetFacilityStatusById, "?facilityID=" + props.facility.id)
      .then((res) => {
        debugger
        if (res.data.statusCode == 200) {
          if (res.data.success) {
            if (res.data.result != null) {
              setFacilityStatusInfo(res.data.result);
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "getAllFacility");


      })
      .then(() => {
        setIsLoading(false);

      });
  };
  const setFacilityList = (list) => {

    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      debugger
      var dateFirstObserved = new Date(obj.dateFirstObserved);
      rows.push(
        <React.Fragment key={uuid()}>
             <tr>

<th>Resident Name</th>
<th>Body Region/Wound Location</th>
<th>{obj.wound.fkWoundTypeId == Common.WoundType.pressure ? 'Stage/Thickness'
                                    : obj.wound.fkWoundTypeId == Common.WoundType.Surgical || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? 'Stage/Thickness' : 'Thickness/Most Severe Tissue'}</th>

<th>ICD10 Code</th>
<th>Wound Size</th>
<th>Evaluated on</th>
<th>Evaluated By</th>
<th>Is Open?</th>
<th>Actions</th>
</tr>
          <tr key={uuid()}>
            <td>
              {obj.residentName}
            </td>
            <td><button className="btn btn-link" onClick={() => handleWoundAssessmentModal(obj)}>
            {obj.wound.orientationLeftRightName==null || obj.wound.orientationLeftRightName==""?"":obj.wound.orientationLeftRightName+" - "} 

              {Common.replaceNullWithString(obj.wound.bodyRegion)}{obj.residentWoundLocation != null ? "/" : ""}{Common.replaceNullWithString(obj.residentWoundLocation)}
              {obj.wound.orientation==null || obj.wound.orientation==""?"":" - "+obj.wound.orientation}

            </button></td>
            {/* <td>{Common.replaceNullWithString(obj.wound.woundLateralityName)}</td> */}
            <td>
            {Common.replaceNullWithString(obj.wound.woundThicknessName)}
                                    {obj.wound.fkWoundTypeId == Common.WoundType.pressure ||
                                     obj.wound.fkWoundTypeId == Common.WoundType.Surgical 
                                     || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? ' '
                                    : obj.wound.woundThicknessName != null && obj.wound.woundSevereTissueName != null ? 
                                    " - " 
                                    +(Common.replaceNullWithString(obj.wound.woundSevereTissueName))
                                    : " "}
                                    {/* {Common.replaceNullWithString(obj.wound.woundSevereTissueName)} */}
              {/* { Common.replaceNullWithString(obj.woundType) == '' && Common.replaceNullWithString(obj.wound.woundThicknessName) == '' ? "N/A" : Common.replaceNullWithString(obj.woundType, "N/A") / Common.replaceNullWithString(obj.wound.woundThicknessName, "N/A") } */}
            </td>
            <td>{Common.replaceNullWithString(obj.wound.icdcode) != '' ? obj.wound.icdcode : 'N/A'}</td>
            <td>  {obj.wound.woundLength + " x " + obj.wound.woundWidth + " x " + obj.wound.woundDepth+" "+obj.wound.isUtd?"U":""}</td>
            <td>{Common.replaceNullWithString(obj.wound.evaluationDate) != '' ? Common.getInputStringDate(new Date(obj.wound.evaluationDate)) : 'N/A'}</td>
            <td>{Common.replaceNullWithString(obj.evaluatedBy) != '' ? obj.evaluatedBy : 'N/A'}</td>

            <td align="center">{obj.wound.isOpen == true ? <Badge variant="success">Yes</Badge> : <Badge variant="primary">No</Badge>} </td>
            <td align="center">
                                <>
                                    <ButtonToolbar className="justify-content-center">
                                        <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                                            {[DropdownButton].map((DropdownType, idx) => (
                                                <DropdownType
                                                    size="xs"
                                                    variant="primary"
                                                    title="Actions"
                                                    id={`dropdown-button-drop-${idx}`}
                                                    key={uuid()}
                                                >
                                                  
                                                        <Dropdown.Item eventKey="8" className="text-danger" onClick={()=>handleResidentWoundStatus(obj)}>{obj.wound.isOpen?'Close Wound': 'Open Wound'}</Dropdown.Item>

                                      
                                                </DropdownType>
                                            ))}
                                        </div>
                                    </ButtonToolbar>
                                </>
                            </td>

          </tr>
          {!obj.wound.isOpen ?
            null :
            <tr key={uuid()}>
              <td colSpan="9">
                <Card className="example example-compact">
                  <CardBody>
                    <WoundProducts ModulesName={ModulesName.Module_MyWorkQueue} isReorder={obj.wound.isReorder} residentWound={obj} />
                  </CardBody>
                </Card>
              </td>
            </tr>
          }
            </React.Fragment>
      );
    }
    setOrderListUI(rows);

  };
  const handleCloseAdd = () => {
    setIsShowAddOrderApprovel(false);
    getAllFacilityResidentWound();
    props.close();

  };
  const getResidentWoundById = (obj) => {
    setIsLoading(false);
    // Api.getRequestById(Api.URI_GetResidentWoundByResidentID, props.Resident.id)
    Api.getRequestQry(Api.URI_GetResidentWoundByResidentID, "?id=" + obj.residentId + "&woundId=" + obj.woundId)
      // Api.getRequestQry(Api.URI_GetResidentWounURidByStatusID, "?residentId="+props.Resident.id+"&processStatusID=" + processStatusID)

      .then((res) => {

        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              debugger
              // setResidentWoundList(res.data.result);
              handleWoundAssessmentModal(res.data.result[0])

            }

          }
        }

        //Common.LoadDataTable("woundList"+props.Resident.id)
      }).catch((ex) => {
        Common.LogError(ex, "ResidentNote", "Get");

      });
  }
  const handleWoundAssessmentModal = (obj) => {
    debugger
    if (obj.wound.isOpen) {
      let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));
      tempObj.fkResidentId = obj.residentId;
      tempObj.residentName = Common.replaceNullWithString(obj.residentName);
      tempObj.facilityName = Common.replaceNullWithString(obj.facilityName);
      tempObj.roomUnit = Common.replaceNullWithString(obj.room) + "/" + Common.replaceNullWithString(obj.unit);
      tempObj.physicanName = Common.replaceNullWithString(obj.physicanName);
      tempObj.btPatientBrightreeID = obj.btPatientBrightreeID;
      tempObj.wound = obj.wound;
      setResidentName(obj.residentName);
      setAddWoundComponent(<NewWound refresh={props.refresh} isEdit={true} isReorder={false} user={user} resident={tempObj} close={handleCloseWoundmodalPopup} />)
      editWoundmodal.showModal()
    } else {
      Common.showSuccessAlert("Wound is not open!", "warning", "Warning!", 3000);
    }

  }
  const handleCloseWoundmodalPopup = () => {
    try {
      getAllFacilityResidentWound();
      editWoundmodal.hideModal(true);
    } catch (error) {
      // Common.LogError(error, "WorkqueueWounds", "handleNewWoundModal");
    }
  }
  const approveOrder = (withSignature) => {
    setIsShowAddOrderApprovel(true);
    setApproveWithSig(withSignature);
    // setsalesOrderID(obj.fK_SalesOrderID);
  }
  const handleResidentWoundStatus =(obj)   => {
    let tempObj=JSON.parse(JSON.stringify(obj));
    tempObj.wound.isOpen=tempObj.wound.isOpen==true?false:true;
    updateResidentWoundStatus (tempObj);
  };
const updateResidentWoundStatus  = (obj) => {
    var msg="You want to " + (obj.wound.isOpen?"Open":"Close" )+ " Wound.";
  Common.showConfirmation(msg,"",updateWoundStatus,null, obj)
  
  };
  const updateWoundStatus=(obj)=>{
    if(obj){
      Api.postRequest(Api.apiUrls.updateResidentWoundStatus, obj).then((res) => {
        if(res.data.success){
          getAllFacilityResidentWound();
          Common.showSuccessAlertAutoClose("Wound Status Updated Successfully", "success", "Success!", 3000)
        }else{
          Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
        }
      });
    }
  }
  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">

        <button
          type="button"
          className="mr-1 btn btn-danger btn-sm"
          disabled={facility.isApproved}
          onClick={() => approveOrder(true)}
        >
          Approve with Signature
        </button>
        <button
          type="button"
          className=" btn btn-danger btn-sm"
          disabled={facility.isApproved}

          onClick={() => approveOrder(false)}
        >
          Approve without Signature
        </button>
      </div>
    </div>
  );
  return (
    <>
      <PopupModal
        onClose={(ref) => {
          Common.showConfirmation('Are you sure you want to close without saving the data?', 'Confirmation', () => { ref.setState({ show: false }); }, () => { }, null);
        }}
        title= {<div className="card-title">
        <span>
          <h3 className="card-label">Wound Evaluation - Resident Name:{" "} {Common.replaceNullWithString(residentName)}</h3>
        
        </span>
      </div> }
        
        dialogClassName="modal-95w" ref={ref => { editWoundmodal = ref; }}> {showAddWoundComponent} </PopupModal>
      {showAddOrderApprovel && <Animated animationIn="fadeInLeft" animationInDuration={1000}>

        <AddOrderApprovel approveWithSig={approveWithSig} facilityId={props.facility.id} close={handleCloseAdd} />

      </Animated>}
      {!showAddOrderApprovel &&
        <Animated animationIn="fadeIn" animationInDuration={1000}>
          <Card className="example example-compact">
            <CardHeader  >

              <div className="card-title ">
                <span>
                  <h3 className="card-label">Resident Orders</h3>
                  <small className="">

                    {facility.isApproved && <b>This facility was already approved {facility.isWithSignature ? "with signature" : "without signature"} by {facility.approvedBy}</b>}

                  </small>
                </span>
              </div>



              {toolbar}

            </CardHeader>
            <CardBody>

              {isLoading ? (
                <div className="row d-flex justify-content-center">
                  <span className="mt-5 spinner spinner-primary"></span>
                </div>
              ) : (
                <table
                  key={uuid()}
                  className="table table-striped table-hover table-sm table-bordered"
                  id="facilityWoundTable"
                >
                   {/* <thead>

                  <tr>

                    <th>Resident Name</th>
                    <th>Body Region/Wound Location</th>
                    <th>Wound Type/Stage/Thickness</th>
                    <th>ICD10 Code</th>
                    <th>Wound Size</th>
                    <th>Evaluated on</th>
                    <th>Evaluated By</th>
                    <th>Is Open?</th>
                    <th>Actions</th>
                  </tr>
                  </thead> */}
                  <tbody >{orderListUI}</tbody>
                </table>
              )}

            </CardBody>
          </Card>
        </Animated>
      }
    </>
  )

}
export default WoundOrderApprovel;
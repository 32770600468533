export default class RoutePaths {
  static Page_Dashboard = "/dashboard";
  static Page_Builder = "/builder";
  static Page_MyPage = "/my-page";
  static Page_Facility = "/facility";
  static Page_FacilityQueue = "/facilityQueue";

  static Page_Resident = "/resident";
  static Page_WorkQueue = "/workqueue";
  static Page_Billing = "/billing";
  static Page_Physician = "/physician";

  //////  Brightree
  static Page_SetupBrightree = "/Brightree";
  static Page_ResidentBrigthree = "/Brightree/Resident";
  static Page_SaleOrder = "/Brightree/SaleOrder";
  static Page_Note = "/Brightree/Note";
  static Page_Insurance = "/Brightree/Insurance";
  //static Page_Physician = "/Brightree/Physician";

  // Setups
  // static Page_Setups_lookupslist = "/lookuplist";
  // static Page_Setups_RoleList="/role";

  static Page_SetupNav = "/setupNav";
  static Page_Lookuplist = "/setupNav/lookuplist";
  static Page_Role = "/setupNav/role";
  static Page_SystemSetting = "/setupNav/systemSetting";
  static Page_ICDCode = "/setupNav/ICDCode";
  static Page_Insurance = "/setupNav/Insurance";
  static Page_NightlySync = "/setupNav/NightlySync";

  // Reports
  static Page_ReportNav = "/reportNav";
  static Page_AssessmentReportNav = "/assessmentReportNav";
  static Page_WoundReport = "/assessmentReportNav/woundReport";
  static Page_QAPIReport = "/assessmentReportNav/qapireport";
  static Page_WoundEvaluationReport =
    "/assessmentReportNav/woundevaluationreport";
  static Page_FacilityReport = "/assessmentReportNav/facilityReport";
  static Page_FacilityCoverReport = "/reportNav/facilityCoverReport";
  static Page_SalesOrderReports = "/reportNav/salesOrderReports";
  static Page_HistoricalReport = "/assessmentReportNav/historicalReport";

  //Calendar
  static Page_vacation = "/setupNav/calendar";

  static Page_UserList = "/setupNav/UserList";
  static Page_EMRCredential = "/setupNav/EMRCredential";
  static Page_FacilityUser = "/setupNav/FacilityUser";
  static Page_UploadUsers = "/setupNav/UploadUsers";

  static Page_ManagementTools = "/setupNav/ManagementTools";
  static Page_WoundAndProductSpecialist = "/setupNav/ManagementTools/WoundAndProductSpecialist";
  static Page_FacilityOwnershipManager = "/setupNav/ManagementTools/FacilityOwnershipManager";
  static Page_FacilityClinicianManager = "/setupNav/ManagementTools/FacilityClinicianManager";

  static Page_PasswordNav = "/passwordNav";
  static Page_PasswordTest = "/passwordNav/test-password";

  /* Products */
  static Page_ProductsNav = "/ProductNav";
  static Page_NewProdcut = "/ProductNav/NewProduct";
  static Page_ProductList = "/ProductNav/ProductList";
  static Page_ProductCategories = "/ProductNav/ProductCategories";
  static Page_PurchasingOrganization = "/ProductNav/PurchasingOrganization";

  /* Orders */
  static Page_OrderNav = "/orderspage";
  static Page_ApproveOrders = "/orderspage/approveorders";
  static Page_ReviewOrders = "/orderspage/revieworders";
  static Page_ProcessOrders = "/orderspage/processorders";
  static Page_RecentOrders = "/orderspage/recentorders";
  static Page_NewTreatmentOrders = "/orderspage/newtreatmentorders";
  static Page_PatientOrders = "/orderspage/PatientOrders";

  /* Post Visit Email */
  static Page_PostVisitEmail = "/postVisitEmail";

  /* Rep Firm */
  static Page_RepFirmNav = "/RepFirm";
  static Page_RepFirmList = "/RepFirm/RepFirmList";
  /* Tablet Data */
  static Page_TabletData = "/TabletData";

  /*User Forms */
  static Page_UserRoleList = "/User/UserRoleList";

  /* EHR Vendors */
  static Page_EHRVendors = "/EHRVendors";
}

import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import React from "react";
import { Form } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from '../../../General/common';

export default class Addconsultantsmodal extends React.Component {
    constructor(props, context) {
        super(props);
        this.state = {
            Name: '',
            Company: '',
            Email: '',
            TelephoneNumber: '',
            Fax: '',
            validated: false,
        };

    }

    componentDidMount() {
        $('#Telephone').mask('000-000-0000');
        $('#Fax').mask('000-000-0000');

        $("#Telephone").change((e) => {
            this.handleChangeInputValue(e)
        });
        $("#Fax").change((e) => {
            this.handleChangeInputValue(e)
        });

    }

    render() {
        return (
            <>
                <Form
                    ref={(ref) => this.formRef = ref}
                    noValidate
                    validated={this.state.validated}
                >
                    <div className="row">

                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Name">Name</label>
                                <input required type="text" id="Name" name="Name"
                                    defaultValue={this.state.Name}
                                    onChange={this.handleChangeInputValue}
                                    className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Company">Company</label>
                                <input required type="text" id="Company" name="Company"
                                    defaultValue={this.state.Company}
                                    onChange={this.handleChangeInputValue}
                                    className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Email">Email</label>
                                <input required type="email" id="Email" name="Email"
                                    defaultValue={this.state.Email}
                                    onChange={this.handleChangeInputValue}
                                    className="form-control form-control-sm" />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Telephone">Telephone Number</label>
                                <input type="tel" id="Telephone" name="TelephoneNumber"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    defaultValue={this.state.TelephoneNumber}
                                    onChange={this.handleChangeInputValue}
                                    className="form-control form-control-sm" />
                                <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                            <div className="form-group">
                                <label className="form-label" htmlFor="Fax">Fax </label>
                                <input type="tel" id="Fax" name="Fax"
                                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                    defaultValue={this.state.Fax}
                                    onChange={this.handleChangeInputValue}
                                    className="form-control form-control-sm" />
                                <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                            </div>
                        </div>

                    </div>

                </Form>
            </>
        );
    }

    //Functions

    onSaveButtonClick(handleCloseAddConsultant) {
        if (this.formRef.checkValidity() === false) {
            //event.preventDefault();
            //event.stopPropagation();
        }
        else {

            // // debugger;
            let obj = {
                "facilityId": this.props.facilityID,
                "name": this.state.Name,
                "company": this.state.Company,
                "email": this.state.Email,
                "fax": this.state.Fax.replace(/\D/g,''),
                "mobile": this.state.TelephoneNumber.replace(/\D/g,''),
                "createdBy": this.props.userID,
                "createdDate": new Date(),
                "modifiedBy": this.props.userID,
                "modifiedDate": new Date(),
            }
            
            Api.postRequest(Api.URI_AddConsultant, obj).then((res) => {
                
                if(res.data.success){
                    if(res.data.result != null){
                        Common.showSuccessAlertAutoClose("Consultant Created Successfully", "success", "Success!", 3000);
                        handleCloseAddConsultant();
                    }
                    else{
                        Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                    }
                }
                else{
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
              }).catch((ex)=>{
                Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
      Common.LogError(ex, "Facility", "AddConsultantModal");

            });
        }
        this.setState({ validated: true });
    }

    handleChangeInputValue = (event) => {
        //console.log(event.target.type, "" + [event.target.name], ":" + event.target.value)
        this.setState({
            [event.target.name]: event.target.value
        })

    }



}//end of class

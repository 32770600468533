import React from "react";
import {  Badge, Button, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";


import Loader from "react-loader-spinner";
import uuid from 'node-uuid'

export default class SyncFacility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
       IsLoading: true,
       logList:[],
      facility:
      {
        brightreeKey:''
      }
    }
}
componentDidMount() {
  this.getSyncLog();

}
onSubmit() {
  if (this.form.checkValidity() === false) {
    let _state = this.state;
    _state.formValidated = true;
    this.setState(_state);
  } else {
  this.setState({ IsLoading: false });
    const obj = this.state.facility;
    Api.getRequestById(Api.Uri_facilityFetchByBrightreeID, obj.brightreeKey)
    .then((res) => {
      if (res.data.success) {
        this.props.onSaveAPI();

        Common.showSuccessAlertAutoClose(
          "Data Saved Successfully",
          "success",
          "Success!",
          3000
        );
      } else {
        Common.showSuccessAlert(
          res.data.message,
          "warning",
          "Error!",
          3000
        );
      }
      this.setState({ IsLoading: true });
    });
  }
}
 handleDownLoadFacility = () => {
  var msg="You want to download all facility.It is long process and may slow the system performance.If Possible please perform this activity in off hours";
Common.showConfirmation(msg,"",this.fetchAllFacility,null, null)

};
fetchAllFacility() {
 debugger
    Api.getRequest(Api.Uri_fetchAllFacility)
    .then((res) => {
      if (res.data.success) {
        // this.props.onSaveAPI();

        Common.showSuccessAlertAutoClose(
          res.data.message,
          "success",
          "Success!",
          3000
        );
      } else {
        Common.showSuccessAlert(
          res.data.message,
          "warning",
          "Error!",
          3000
        );
      }
     
    });
  
}
getSyncLog() {
  debugger
     Api.getRequest(Api.Uri_getSyncLog)
     .then((res) => {
      debugger
       if (res.data.success) {
        if (res.data.result !=null) {
          // setResidentWoundListUI([]);
          this.setLogList(res.data.result);
        
      }
       } 
       
      //  this.setState({ IsLoading: true });
     });
   
 }
 setLogList = (logDetail) => {
  debugger
  let rows = [];
  try {
      for (var i = 0; i < logDetail.syncLogDetailViewModelList.length; i++) {

          const obj = logDetail.syncLogDetailViewModelList[i];
          rows.push(
              <React.Fragment key={i}>
                    
                  <tr key={uuid()}>
                      <td className="justify-content-center"> <b>{i + 1}</b> </td>
                      <td>
                          {Common.replaceNullWithString(obj.facilityName)}
                      </td>
                      <td>
                      {Common.replaceNullWithString(obj.totalResidentSync)}
                       </td>
                       <td align="center">{obj.isSuccess == true ? <Badge variant="success">Success</Badge> 
                       :obj.isSuccess == false ?  <Badge variant="danger">Failed</Badge>:<Badge variant="info">In Process</Badge>} </td>
                      <td >  {obj.syncCount}</td>
                      <td>{Common.replaceNullWithString(obj.exception)}</td>
                      
                
                     
                  </tr>

              </React.Fragment>
          );
      }
      this.setState({logList:rows});
      Common.LoadDataTable("facilityTable");
      // this.setState({ IsLoading: true });


  } catch (error) {
      Common.LogError(error, "WorkqueueWounds", "SetWoundList");
  }
}
render() {
    return (
    <div key={uuid()}>  {
        (this.state.IsLoading == true) ?
            <div>
             <Form
        ref={(ref) => {
          this.form = ref;
        }}
        validated={this.state.formValidated}
        onSubmit={(e) => this.onSubmit(e)}
      >
       <div className="row">
    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="doB">
                      Facility Brighree ID
                    </label>
                    <input
                      type="number"
                      id="brightreeKey"
                      className="form-control form-control-sm"
                      name="brightreeKey"
                      required
                      value={this.state.facility.brightreeKey}
                      onChange={(e) =>
                        Common.updateInputValueInState(
                          this.state.facility,
                          e,
                          this,
                          this.state
                        )
                      }
                    />
                  </div>
                </div>
           
           </div>
           {/* <input type="file" onChange={e=>this.onFileChange(e)} id="input" /> */}
           
           <div className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleDownLoadFacility()}>Download All Facility</Button>
                    </div>
      </Form>
        
            </div> :
            <div className="d-flex justify-content-center">
                <Loader
                    type="Puff"
                    color="#00BFFF"
                    height={50}
                    width={50}
                />
            </div>
    }
  
      <table key={uuid()} className="table table-striped table-hover table-sm table-bordered" id="facilityTable">
                    <thead>
                    <tr key={uuid()}>
                      <th>#</th>
                      <th>Facility Name</th>
                      <th>Total Resident Sync</th>
                      <th>Status</th>
                      <th>Sync Count</th>
                      <th>Exception</th>
                  </tr>
                
                    </thead>
                    <tbody key={uuid()}>

                     
                      {this.state.logList}
              

                    </tbody>
                </table>
        
    </div>


      );
  }
}


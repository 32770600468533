// ** React Imports
import * as React from "react";
import { Fragment, useEffect, useState } from 'react'
import {
    Button, Table
} from 'react-bootstrap';
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const ECPReason = (props) => {
    // ** States
    const [id, setID] = useState(0);
    const [msg, setMsg] = useState('');
    const [fkNoOrderStatusId, setFkNoOrderStatusId] = useState(0);

    const [otherStatus, setOtherStatus] = useState('');

    const [isLoading, setLoading] = useState(false);
    const [isNoOrder, setIsNoOrder] = useState(props.isNoOrder);

    const [msgList, setFacilityMsgList] = useState([])
    const [residentNoOrderStatus, setResidentNoOrderStatus] = useState([])


    const Schema = Yup.object().shape({

        fkProcessorNoOrderReasonId: Yup.number().required("Status is required").typeError("Status is required").min(1, "Reason is required"),


    });
    const formik = useFormik({
        initialValues: {
            fkNoOrderStatus: 0,
        },
        validationSchema: Schema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            handleValidSubmit(values);
            setSubmitting(false);
        },
    });

    //  Get All organization Type 
    useEffect(() => {
        getNoOrderStatus()
    }, []);
    const getNoOrderStatus = () => {
        debugger
        Api.getRequestById(Api.Uri_GetLookupValuesByFkId, Common.LookupsFK.Fk_ECPStatus)
            .then((res) => {
                debugger
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            setResidentNoOrderStatus(res.data.result);
                        }
                    }
                }
            })
            .catch((ex) => {

                Common.LogError(ex, "Resident", "getDropDownValueApiCall");

            });
    }
    const handleValidSubmit = (values) => {
        debugger
        var resident = props.resident;
        resident.fkEcpReasonId = values.fkProcessorNoOrderReasonId;
        Api.postRequest(Api.apiUrls.updateResidentHospice, resident).then((res) => {
            if (res.data.success) {
                Api.postRequest(Api.apiUrls.updateResidentECP, resident).then((res) => {
                    if (res.data.success) {
                        props.refresh()
                        Common.showSuccessAlertAutoClose("Resident ECP Updated Successfully", "success", "Success!", 3000)
                    } else {
                        Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
                    }
                });
            } else {
                Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
            }
        });

    }
    const setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    return (
        <Fragment>

            <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                            ECP Reason
                        </label>
                        <select
                            className="form-control form-control-sm"
                            id="fkProcessorNoOrderReasonId"
                            name="fkProcessorNoOrderReasonId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.fkProcessorNoOrderReasonId}
                        >
                            <option></option>
                            {setOptionsUI(residentNoOrderStatus)}
                        </select>
                        <div style={{ fontSize: ".8rem" }} className="text-danger">
                            {formik.errors.fkProcessorNoOrderReasonId}
                        </div>
                    </div>

                </div>

            </div>
            <div className="d-flex justify-content-end py-3">
                <Button variant="success" className="mr-5 px-10" size="sm" onClick={formik.handleSubmit}>Save</Button>
            </div>

        </Fragment>
    )
}

export default ECPReason

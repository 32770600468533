import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import * as Api from "../../../General/api";
import { requestPassword } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
const initialValues = {
  Email: "",
  Password: "",
  changepassword: "",
};

function SetPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [isPasswordLinkValid, setIsPasswordLink] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [email, setEmail] = useState("");
  const [PasswordLength, setPasswordLength] = useState("");

  const [pageCaption, setPageCaption] = useState("Set");

  const ForgotPasswordSchema = Yup.object().shape({
 
      Password: Yup.string()
      .min(6, "Minimum 6 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      // .matches(
      //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
      //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      // ),
      changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("Password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("Password")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });


  const getUserDetail = () => 
  { 
  
    setIsLoaded(true);
   const parm=new URLSearchParams(props.location.search).get("id")
   setPageCaption(new URLSearchParams(props.location.search).get("pageCaption"))
   Api.getRequestById(Api.Uri_SetPasswordDetalURL, parm)
  
   .then((response) => {
     if(response.data!="Expired"){
      setEmail(response.data.result)
     }else{
      setIsPasswordLink(true);
     }
    
   })
   .catch(function(error) {
     
   });
   
  }
  
  useEffect(() => {
    
    // Update the document title using the browser API
   
    // // debugger
    // setIsLoaded(false);

    if(!isLoaded){
    
      getUserDetail();
    }
    
  });
  
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }
    return "";
  };

  const formik = useFormik({
   
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      requestPassword(email,values.Password,values.changepassword)
        .then(() => setIsRequested(true))
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.Email }
            )
          );
        });
    },
  });
  return (
    <>
    {isPasswordLinkValid&&(  
          
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                <h3 className="font-size-h1">Your {pageCaption} password link has been expired. Please request again if you still want to {pageCaption} your password. Go back to <a href='/auth'>login</a></h3>
                </div>
              </div> )}
      {isRequested && (  
          
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                <h3 className="font-size-h1">You password has been {pageCaption} successfully. Please <a href='/auth'>login</a> to the system using your password you have just {pageCaption}.</h3>
                </div>
              </div> )}
      {!isRequested &&!isPasswordLinkValid && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">{pageCaption} Password ?</h3>
            {/* <div className="text-muted font-weight-bold">
              Enter your email to reset your password
            </div> */}
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* <div className="form-group fv-plugins-icon-container">
              <input
                type="Email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "Email"
                )}`}
                name="Email"
                {...formik.getFieldProps("Email")}
              />
              {formik.touched.Email && formik.errors.Email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.Email}</div>
                </div>
              ) : null}
            </div> */}
            {/* <PasswordStrengthBar password={PasswordLength}/> */}
            <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="Password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="Password"
            {...formik.getFieldProps("Password")}
            // value={PasswordLength}
            // onChange={(e) => setPasswordLength(e.target.value)}
          />
          {formik.touched.Password && formik.errors.Password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.Password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Confirm Password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                Submit
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(SetPassword));

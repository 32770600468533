import { Typography } from "@material-ui/core";
import * as React from "react";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle,
  Button,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import CheckPermission from "@/app/General/CheckPermission";
import RBAC, {
  ModulesName,
  UserActions,
} from "@/app/General/roleModuleActions";
import { showDecisionAlert, showWarningAlert } from "@/index";
import {
  Card,
  CardBody,
  CardHeader,
} from "@/_metronic/_partials/controls/Card";
import * as Api from "@/app/General/api";
import * as Common from "@/app/General/common";
import ResidentAsssessmentWounds from "@/app/pages/resident/residentAsssessmentWounds";
import PopupModal from "@/app/pages/modal/popupmodal";
import Newresident from "@/app/pages/modal/residentsmodals/newresident";
import Insurance from "@/app/pages/resident/insurance";
import ResidentDetails from "@/shared/components/residentDetails";
import ResidentStatus from "@/app/pages/resident/residentStatus";
import AOB from "@/app/pages/resident/aob";
import PatientOrders from "@/app/pages/resident/patientorders";
import ResidentNotes from "@/app/pages/resident/residentnotes";
import WoundNote from "@/app/pages/resident/woundnote";
import moment from "moment";
import SelectableContext from "react-bootstrap/SelectableContext";
import FindResident from "@/app/pages/resident/findResident";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import FacilityResidentHistory from "@/app/pages/resident/residentHistory";
import { useSelector } from "react-redux";
import ViewWoundInformationChangeReason from "@/app/pages/resident/viewWoundInformationChangeReason";
import NewWoundAssessment from "@/app/pages/resident/newwoundassessment";
import { WOUND_TYPES } from "@/app/General/wound-type";
import { injectIntl } from "react-intl";
import AssessmentQueueCardHeaderWrapper from "@/shared/components/asessmentQueue/cardHeader";
var _sampleWoundObj = {
  fkResidentId: 0,
  residentName: "",
  facilityName: "",
  roomUnit: "",
  physicanName: "",
  btPatientBrightreeID: null,
  fkLastWoundDetailId: null,
  isWoundInfoChange: false,
  changeReason: "",
  fkSalesOrderId: 0,
  wound: {
    id: 0,
    woundDetailId: 0,
    fkWoundLocationId: null,
    woundLocation: "",
    fkWoundTypeId: null,
    woundDescription: "",
    fkWoundThicknessId: null,
    fkWoundLateralityId: null,
    woundLength: "",
    woundWidth: "",
    woundDepth: "",
    isUtd: false,
    tunneling: false,
    tunnelingDepth: null,
    tunnelingClockPosition: "",
    undermining: false,
    underminingDepth: null,
    underminingClockPosition: null,
    icdcode: "",
    fkExudateAmountId: null,
    fkExudateTypeId: null,
    exudateColor: "",
    fkDebridementTypeId: null,
    debridementDate: null,
    surgicalDate: null,
    odor: false,
    Pain: false,
    painMedicationOrdered: false,
    sloughPercentage: null,
    necroticPercentage: null,
    cleanseDescription: null,
    fkDressingChangeFrequencyId: 8,
    otherAdditionalPrimaryDressing: "",
    otherAdditionalSecondaryDressing: "",
    treatment: "",
    comments: "",
    thicknessIdForBackStagging: null,
    orientationLeftRight: null,
    orientationUpperLower: null,
    orientationInnerOuter: null,
    orientationAnteriorPosterior: null,
    orientationMedialLateral: null,
    orientationDorsalDistalProximal: null,
    isDocumentation: false,
    isPartailOrder: false,
  },
};

var UpsertNewresident,
  insuranceModal,
  residentHistoryModal,
  woundAmendModal,
  residentStatusModal,
  addStatusRef,
  residentInfoModal,
  AddResidentModal,
  patientOrdersModal,
  ResidentNotesModal,
  woundAssessmentModel,
  AOBModal,
  WoundNoteModal = null;

export function AllFacilityResidentUK(props) {
  //#region Resident modal
  const [showModalResident, setShowResident] = useState(false);
  const [isResidentInfoEdit, setisResidentInfoEdit] = useState(false);
  const [residentListState, setResidentListState] = useState([]);
  const [residentListUI, setResidentListUI] = useState([]);
  const [Resident, setResident] = useState("");
  const [ResidentID, setResidentID] = useState("");
  const [ResidentName, setResidentName] = useState("");
  const FacilityId = props.facility.id;
  const [facilityName, setFacilityName] = useState("");
  const [chain, setChainName] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const { user } = useSelector((state) => state.auth);
  const [showAddWoundComponent, setAddWoundComponent] = useState(null);
  const handleCloseResident = () => setShowResident(false);
  const handleShowResident = () => setShowResident(true);
  const { intl } = props;
  //#endregion
  const [residentLoading, setResidentLoading] = useState(false);
  const displatWarningAlert = () => showWarningAlert("Coming soon!!!", "Alert");
  const displayDecisionAlert = (title, message) =>
    showDecisionAlert(
      function() {},
      function() {},
      title == null ? intl.formatMessage({ id: "BUTTON.ARE_YOU_SURE" }) : title,
      message == null
        ? intl.formatMessage({ id: "RESIDENT.YOU_WANT_TO_DEACTIVATE_RESIDENT" })
        : message
    );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllResident();
  }, []);

  //#region Set Resident List
  const getAllResident = () => {
    setIsLoading(true);
    if (props.isPartail) {
      Api.getRequestQry(
        Api.URI_GetAllBillingQueueResidentByFacilityID,
        "?facilityID=" +
          FacilityId +
          "&isPartailOrder=" +
          1 +
          "&partailOrderDate=" +
          props.facility.cycleDate +
          "&processStatusId=" +
          props.facility.fkProcessStatusId
      )
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                setResidentList(res.data.result);
              }
            }
          }
        })
        .catch((ex) => {})
        .then(() => {
          setIsLoading(false);
          Common.LoadDataTable("residentsList");
        });
    } else {
      Api.getRequestQry(
        Api.URI_GetAllResidentByFacilityID,
        "?facilityID=" + FacilityId + "&residentStatusId=" + 36
      )
        .then((res) => {
          if (res.data.statusCode == 200) {
            if (res.data.result != null) {
              if (res.data.result.length > 0) {
                setResidentList(res.data.result);
              }
            }
          }
        })
        .catch((ex) => {})
        .then(() => {
          setIsLoading(false);
          Common.LoadDataTable("residentsList");
        });
    }
  };

  const handleShowAddResident = (obj) => {
    setResident(obj);
    AddResidentModal.showModal();
  };
  const handleResidentStatus = async (resident) => {
    await setResident(resident);
    residentStatusModal.showModal();
  };
  const handleResidentHistory = async (residentId) => {
    await setResidentID(residentId);
    residentHistoryModal.showModal();
  };
  const handleNewWound = (newWoundDetail) => {
    debugger;
    setResident(newWoundDetail);
    Common.showConfirmation(
      intl.formatMessage({
        id: "FACILITY_RESIDENT.WOULD_YOU_LIKE_TO_ENTER_A_WOUND",
      }),
      intl.formatMessage({ id: "FACILITY_RESIDENT.ARE_YOU_SURE" }),
      handleNewWoundDocumentModal,
      null,
      newWoundDetail,
      intl.formatMessage({ id: "TEXT.YES" }),
      false,
      null,
      intl.formatMessage({ id: "TEXT.NO" })
    );
  };
  const handleNewWoundDocumentModal = async (obj) => {
    debugger;
    let tempObj = JSON.parse(JSON.stringify(_sampleWoundObj));

    tempObj.wound.isDocumentation = true;
    tempObj.wound.evaluationDate = new Date();
    tempObj.fkResidentId = obj.id;
    tempObj.residentName =
      Common.replaceNullWithString(obj.firstName) +
      " " +
      Common.replaceNullWithString(obj.lastName);
    tempObj.facilityName = Common.replaceNullWithString(
      props.facility.facilityName
    );
    tempObj.roomUnit =
      Common.replaceNullWithString(obj.room) +
      "/" +
      Common.replaceNullWithString(obj.unit);
    tempObj.physicanName = Common.replaceNullWithString(
      obj.primaryPhysicianName
    );
    tempObj.btPatientBrightreeID = obj.btPatientBrightreeID;

    await setAddWoundComponent(
      <NewWoundAssessment
        isEdit={false}
        canView={true}
        user={user}
        isReorder={false}
        resident={tempObj}
        close={handleCloseWoundAssessmentmodalPopup}
      />
    );
    woundAssessmentModel.showModal();
  };
  const handleCloseWoundAssessmentmodalPopup = () => {
    try {
      woundAssessmentModel.hideModal();
    } catch (error) {
      Common.LogError(error, "WorkqueueWounds", "handleNewWoundAsessmentModal");
    }
  };
  const setResidentList = (list, isShowProduct) => {
    var rows = [];

    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + ", " + obj.firstName;
      const residentID = obj.id;
      const dob = moment(obj.doB).format("MM/DD/YYYY");
      var insurance = "No insurance on file";

      if (obj.residentInsuranceList != null) {
        insurance = "";
        for (var d = 0; d < obj.residentInsuranceList.length; d++) {
          let objIns = JSON.parse(JSON.stringify(obj.residentInsuranceList[d]));
          insurance =
            insurance +
            (insurance != "" ? ", " : "") +
            " " +
            (objIns.isAddEdit ? "*" : "") +
            "=>" +
            (objIns.fkInsuranceLevelId == 1
              ? "Primary"
              : objIns.fkInsuranceLevelId == 2
              ? "Secondary"
              : objIns.fkInsuranceLevelId == 3
              ? "Tertiary"
              : "N/A") +
            " " +
            objIns.insuranceName;
        }
      }
      // else{
      //   insurance="Not Insured"
      // }
      // var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
      // var dob = new Date(obj.birthdate);
      // let randomCount = Math.floor(Math.random() * status.length);
      // var randStatus = resStatus[residentID];
      var statusBadgeType =
        obj.isProcess == true ? "badge-success" : "badge-warning";

      rows.push(
        <Accordion
          key={i.toString()}
          defaultActiveKey={!obj.isProcess ? i.toString() + obj.id : ""}
        >
          <Card>
            <CardHeader>
              <div
                className={`w-100 d-flex border-bottom m-0 ${
                  obj.isProcess
                    ? "bg-light-success border-success"
                    : "border-light-dark"
                }`}
              >
                <div
                  className="customStyleAccorion"
                  style={{ display: "grid" }}
                >
                  <AccordionToggle
                    as={Button}
                    variant="link"
                    // className="h5 mb-0"
                    eventKey={i.toString() + obj.id}
                  >
                    <div
                      title={
                        obj.fkPrimaryPhysicianId == null
                          ? intl.formatMessage({
                              id:
                                "FACILITY_RESIDENT.PRIMARY_PHYSICIAN_IS_NOT_ASSIGNED",
                            })
                          : ""
                      }
                      className={`h5 mb-0 text-dark ${
                        obj.fkPrimaryPhysicianId == null
                          ? "bg-light-danger border-danger"
                          : ""
                      }`}
                    >
                      {" "}
                      {name}
                    </div>
                  </AccordionToggle>

                  {obj.fkPrimaryPhysicianId == null ? (
                    <small>
                      {intl.formatMessage({
                        id:
                          "FACILITY_RESIDENT.PRIMARY_PHYSICIAN_IS_NOT_ASSIGNED",
                      })}
                      <br />
                    </small>
                  ) : (
                    ""
                  )}
                  {/* <small>  <label className="form-label font-weight-bold mr-2" htmlFor="fkDebridementTypeId">DOB:</label>{obj.doB!=null?dob:"N/A" }</small> <br /> */}
                  <small> {insurance}</small>
                </div>
                {user.roles.roleList.find(
                  (x) => x.roleName == RBAC.Role_Gentell_IntakeProcessor
                ) == undefined && (
                  <span className={`badge my-auto mx-4 ml-auto `}>
                    <span>
                      <SelectableContext.Provider value={false}>
                        <DropdownButton
                          className="d-inline"
                          drop={"down"}
                          size="xs"
                          variant="primary"
                          title={intl.formatMessage({ id: "FACILITY.ACTIONS" })}
                          id={`dropdown-button-drop-actions`}
                          key={"down"}> 
                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              setResident(obj);
                              setisResidentInfoEdit(false);
                              setResidentID(obj.id);
                              residentInfoModal.showModal();
                            }}
                          >
                            {intl.formatMessage({
                              id: "FACILITY_RESIDENT.RESIDENT_INFO",
                            })}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="8"
                            onClick={() => handleResidentStatus(obj)}
                          >
                            {intl.formatMessage({
                              id: "FACILITY_RESIDENT.SET_RESIDENT_STATUS",
                            })}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="81"
                            onClick={() => handleResidentHistory(obj.id)}
                          >
                            {intl.formatMessage({
                              id: "FACILITY_RESIDENT.VIEW_HISTORY",
                            })}
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="81"
                            onClick={() => handleResidentHistory(obj.id)}
                          >
                            {intl.formatMessage({
                              id: "FACILITY_RESIDENT.VIEW_WOUND_AMEND_HISTORY",
                            })}
                          </Dropdown.Item>
                        </DropdownButton>
                      </SelectableContext.Provider>
                    </span>
                  </span>
                )}
                <></>
              </div>
            </CardHeader>
            <AccordionCollapse eventKey={i.toString() + obj.id}>
              <CardBody>
                <fieldset>
                  <ResidentAsssessmentWounds
                    isPartail={props.isPartail}
                    facility={props.facility}
                    isProcessor={
                      user.roles.roleList.find(
                        (x) => x.roleName == RBAC.Role_Gentell_IntakeProcessor
                      ) == undefined
                        ? false
                        : true
                    }
                    refresh={() => {
                      getAllResident();
                    }}
                    Resident={obj}
                  />
                </fieldset>
              </CardBody>
            </AccordionCollapse>
          </Card>
        </Accordion>
      );
    }

    //return rows
    setResidentListUI([]);
    setResidentListUI(rows);
  };

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
        {user.roles.roleList.find(
          (x) => x.roleName == RBAC.Role_Gentell_IntakeProcessor
        ) == undefined && (
          <CheckPermission
            permission={UserActions.Create}
            module={ModulesName.Module_Residents}
            yes={() => (
              <Button
                variant="primary"
                size="sm"
                className="mr-5"
                onClick={() => {
                  handleShowAddResident(null);
                }}
              >
                {intl.formatMessage({ id: "RESIDENT.NEW_RESIDENT" })}
              </Button>
            )}
            no={() => (
              <Button
                variant="primary"
                size="sm"
                className="mr-5"
                disabled="true"
              >
                {intl.formatMessage({ id: "RESIDENT.NEW_RESIDENT" })}
              </Button>
            )}
          />
        )}
        <CheckPermission
          permission={UserActions.View}
          module={ModulesName.Module_Residents}
          yes={() => (
            <Button
              className="mr-2"
              variant="warning"
              size="sm"
              onClick={showAdvanceSearchSection}
            >
              {intl.formatMessage({ id: "RESIDENT.RESIDENT" })}
            </Button>
          )}
          no={() => null}
        />
        <button
          type="button"
          className="mr-5 btn btn-dark btn-sm"
          onClick={() => props.backPress()}
        >
          {intl.formatMessage({ id: "FACILITY_RESIDENT.BACK_FACILITY_LIST" })}
        </button>
      </div>
    </div>
  );

  return (
    <>
      {ShowAdvancedSearch && (
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={800}
          animationOut="fadeOut"
          isVisible={ShowAdvancedSearch}
        >
          <FindResident
            createWoundType={WOUND_TYPES.ASSESSMENT}
            user={user}
            facility={props.facility}
            refresh={() => {
              getAllResident();
            }}
            facilityId={FacilityId}
            closeSearch={() => {
              showAdvanceSearchSection();
            }}
          />
        </Animated>
      )}
      <Card className="example example-compact">
        <CardHeader>
          <AssessmentQueueCardHeaderWrapper {...props} />
          {toolbar}
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
            residentListUI
          )}
        </CardBody>
      </Card>
      <PopupModal
        title="Insurances"
        showButton={false}
        ref={(ref) => {
          insuranceModal = ref;
        }}
      >
        {" "}
        <Insurance residentID={ResidentID} />{" "}
      </PopupModal>
      <PopupModal
        title={intl.formatMessage({
          id: "FACILITY_RESIDENT_RESIDENT_INFO.TITLE",
        })}
        showButton={false}
        dialogClassName="modal-90w"
        ref={(ref) => {
          residentInfoModal = ref;
        }}
      >
        {" "}
        {isResidentInfoEdit && (
          <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          >
            <div className="row d-flex align-items-center justify-content-end">
              <Button
                className="mb-4 mr-4 "
                onClick={() => {
                  setisResidentInfoEdit(!isResidentInfoEdit);
                }}
              >
                {intl.formatMessage({ id: "BUTTON.GO_BACK_TO_DETAIL" })}
              </Button>
            </div>
            <Newresident
              ref={(ref) => {
                UpsertNewresident = ref;
              }}
              intl={intl}
              FacilityId={FacilityId}
              Resident={Resident}
              facility={props.facility}
              setResidentLoading={setResidentLoading}
              close={() => {
                getAllResident(false);
                residentInfoModal.hideModal(true);
              }}
            />
            <div className="row d-flex align-items-center justify-content-end">
              <Button
                className="mb-4 btn-success ml-2"
                disabled={residentLoading}
                onClick={() => {
                  UpsertNewresident.handleSubmit();
                }}
              >
                <span style={{ display: "flex", alignItems: "center" }}>
                  {residentLoading && (
                    <span className="mr-8 spinner spinner-white"></span>
                  )}
                  <span>
                    {intl.formatMessage({ id: "BUTTON.SAVE_CHANGES.TITLE" })}
                  </span>
                </span>
              </Button>
            </div>
          </Animated>
        )}
        {!isResidentInfoEdit && (
          <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          >
            {" "}
            <div className="row d-flex align-items-center justify-content-end">
              <Button
                className="mb-4 mr-4"
                onClick={() => {
                  setisResidentInfoEdit(!isResidentInfoEdit);
                }}
              >
                {intl.formatMessage({ id: "BUTTON.EDIT.TITLE" })}
              </Button>
            </div>
            <ResidentDetails
              resident={Resident}
              getAllResident={() => {
                getAllResident(false);
              }}
            />{" "}
          </Animated>
        )}{" "}
      </PopupModal>

      {/** Patient Orders Modal */}
      <PopupModal
        title="Resident Orders"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          patientOrdersModal = ref;
        }}
      >
        {" "}
        <PatientOrders />{" "}
      </PopupModal>

      <PopupModal
        title={
          <div className="card-title">
            <span>
              <h3 className="card-label">
                {intl.formatMessage({
                  id: "FACILITY_RESIDENT.WOUND_AMEND_MESSAGES",
                })}
              </h3>
              <small className="">
                <b>
                  {intl.formatMessage({ id: "WOUND_ASSESSMENT.FACILITY" })}:
                </b>{" "}
                {props.facility.facilityName},{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY.OWNERSHIP_HEADQUARTERS",
                  })}
                  :
                </b>{" "}
                {props.facility.organizationName == ""
                  ? "N/A"
                  : props.facility.organizationName}
                ,{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY_RESIDENT.DATA_TABLE.PURCHASING",
                  })}
                  :
                </b>{" "}
                {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
              </small>
            </span>
          </div>
        }
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          woundAmendModal = ref;
        }}
      >
        {" "}
        <ViewWoundInformationChangeReason
          residentId={ResidentID}
          woundId={0}
        />{" "}
      </PopupModal>

      {/** Wound Location Modal */}

      {/** Wound Note Modal */}
      <PopupModal
        title={intl.formatMessage({ id: "FACILITY_QUEUE.ADD_WOUND_NOTE" })}
        size="lg"
        showButton={true}
        ref={(ref) => {
          WoundNoteModal = ref;
        }}
      >
        {" "}
        <WoundNote />{" "}
      </PopupModal>

      {/** Wound Note Modal */}
      <PopupModal
        title="Assignment of Benefits"
        dialogClassName="modal-90w"
        showButton={true}
        ref={(ref) => {
          AOBModal = ref;
        }}
      >
        {" "}
        <AOB />{" "}
      </PopupModal>

      {/* Resdinet Notes Modal */}
      {/** Wound Detail Modal */}
      <PopupModal
        title={
          <div className="card-title">
            <span>
              <h3 className="card-label">
                {intl.formatMessage({ id: "FACILITY_RESIDENT.RESIDENT_NOTES" })}
              </h3>
              <small className="">
                <b>{intl.formatMessage({ id: "RESIDENT.RESIDENT" })}:</b>{" "}
                {ResidentName},{" "}
                <b>{intl.formatMessage({ id: "RESIDENT.FACILITY" })}:</b>{" "}
                {facilityName},{" "}
                <b>{intl.formatMessage({ id: "RESIDENT.CHAIN" })}:</b> {chain},{" "}
                <b>{intl.formatMessage({ id: "RESIDENT.GROUP" })}:</b>{" "}
                {organizationName},{" "}
              </small>
            </span>
          </div>
        }
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          ResidentNotesModal = ref;
        }}
      >
        {" "}
        <ResidentNotes
          //   userID={user.userID}
          FacilityId={FacilityId}
          ResidentID={ResidentID}
        />{" "}
      </PopupModal>

      {/* Add/edit resident modal */}

      <PopupModal
        title={
          <div className="card-title">
            <span>
              <h3 className="card-label">
                {intl.formatMessage({ id: "FACILITY_RESIDENT.ADD_RESIDENT" })}
              </h3>
              <small className="">
                <b>{intl.formatMessage({ id: "RESIDENT.FACILITY" })}:</b>{" "}
                {props.facility.facilityName},{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY.OWNERSHIP_HEADQUARTERS",
                  })}
                  :
                </b>{" "}
                {props.facility.organizationName == ""
                  ? "N/A"
                  : props.facility.organizationName}
                ,{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY_QUEUE.OPERATORS_PURCHASING_GROUP",
                  })}
                  :
                </b>{" "}
                {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
              </small>
            </span>
          </div>
        }
        dialogClassName="modal-97w"
        showButton={true}
        ref={(ref) => {
          AddResidentModal = ref;
        }}
        buttonDisabled={residentLoading}
        buttonPress={() => UpsertNewresident.handleSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Create}
      >
        <Newresident
          ref={(ref) => {
            UpsertNewresident = ref;
          }}
          FacilityId={FacilityId}
          Resident={Resident}
          handleNewWound={handleNewWound}
          user={user}
          facility={props.facility}
          setResidentLoading={setResidentLoading}
          close={() => {
            getAllResident();
            setResidentLoading(false);
            AddResidentModal.hideModal(true);
          }}
        />
      </PopupModal>
      <PopupModal
        title={
          <div className="card-title">
            <span>
              <h3 className="card-label">
                {intl.formatMessage({
                  id: "FACILITY_RESIDENT.RESIDENT_STATUS",
                })}
              </h3>
              <small className="">
                <b>
                  {intl.formatMessage({ id: "WOUND_ASSESSMENT.FACILITY" })}:
                </b>{" "}
                {props.facility.facilityName},{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY_RESIDENT.DATA_TABLE.OWNERSHIP_HEADQUARTERS",
                  })}
                  :
                </b>{" "}
                {props.facility.organizationName == ""
                  ? "N/A"
                  : props.facility.organizationName}
                ,{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY_RESIDENT.DATA_TABLE.PURCHASING",
                  })}
                  :
                </b>{" "}
                {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
              </small>
            </span>
          </div>
        }
        ref={(ref) => {
          residentStatusModal = ref;
        }}
        showButton={true}
        buttonPress={() => addStatusRef.onSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Update}
      >
        {" "}
        <ResidentStatus
          ref={(ref) => {
            addStatusRef = ref;
          }}
          intl={intl}
          resident={Resident}
          close={() => {
            getAllResident();
            residentStatusModal.hideModal();
            //  props.afterAuthorize();
            //  setOrderApproved(true)
          }}
        />{" "}
      </PopupModal>

      <PopupModal
        // title="Sales Order Message"
        title={
          <div className="card-title">
            <span>
              <h3 className="card-label">
                {intl.formatMessage({
                  id: "FACILITY_RESIDENT.RESIDENT_HISTORY",
                })}
              </h3>
              <small className="">
                <b>
                  {intl.formatMessage({ id: "FACILITY_RESIDENT.FACILITY" })}:
                </b>{" "}
                {props.facility.facilityName},{" "}
                <b>
                  {intl.formatMessage({
                    id: "FACILITY.OWNERSHIP_HEADQUARTERS",
                  })}
                  :
                </b>{" "}
                {props.facility.organizationName == ""
                  ? "N/A"
                  : props.facility.organizationName}
                ,{" "}
                <b>
                  {intl.formatMessage({ id: "FACILITY.DATA_TABLE.OWNERSHIP" })}:
                </b>{" "}
                {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
              </small>
            </span>
          </div>
        }
        showButton={false}
        ref={(ref) => {
          residentHistoryModal = ref;
        }}
      >
        {" "}
        <FacilityResidentHistory fkResidentId={ResidentID} />{" "}
      </PopupModal>
      <PopupModal
        onClose={(ref) => {
          getAllResident();
          ref.setState({ show: false });
        }}
        title={
          <div className="card-title">
            <span>
              <h3 className="card-label">
                {intl.formatMessage({
                  id: "WOUND_ASSESSMENT.WOUND_ASSESSMENT",
                })}{" "}
              </h3>
            </span>
          </div>
        }
        dialogClassName="modal-95w"
        ref={(ref) => {
          woundAssessmentModel = ref;
        }}
      >
        {" "}
        {showAddWoundComponent}{" "}
      </PopupModal>
    </>
  );
}

export default injectIntl(AllFacilityResidentUK, { forwardRef: true });

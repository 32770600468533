import React from "react";
import { Document,pdfjs ,Page} from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/build/pdf.worker.js`;

const DocumentViewer = ({ fileUrl }) => (
    <Document
      file={{
        url: fileUrl,
      }}
    >
      <Page pageNumber={1} />
    </Document>
  );
  export default DocumentViewer;
import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";
import formatSSNBR from "./formatSSNBR.fn";
import formatSSNUK from "./formatSSNUK.fn";
import formatSSNUS from "./formatSSNUS.fn";

const ssnRegions = {
  BR: formatSSNBR,
  UK: formatSSNUK,
  US: formatSSNUS,
};

const formatSSNRegion = withMultiRegionFunction(ssnRegions);

export default function formatSSN(value) {
  return formatSSNRegion(value);
}

/* eslint-disable no-unused-vars */
import React from "react";
import { createFilter } from "react-select";
import Select from "@/shared/components/atoms/Select";
import { Button, Form } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls/Card";
import Loader from "react-loader-spinner";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  getRequest,
  Uri_GetFacility,
  getRequestQry,
  Uri_GetResidentByFacilityId,
  getRequestById,
  URI_GetResidentWoundByResidentID,
  Uri_GetWoundDocumentationByWoundID,
} from "../../General/api";
import {
  showSuccessAlertAutoClose,
  LogError,
  getInputStringDateUsingMoment2,
  showSuccessAlert,
  calculatePushScore,
} from "../../General/common";
import uuid from "node-uuid";
import moment from "moment";
import { woundFilterType } from "../../General/wound-filter-type.enum";
import { documentationFilterType } from "../../General/documentation-filter.enum";
import { injectIntl } from "react-intl";
import getOrientationName from "../../General/common/getOrientationName.common";

class WoundEvaluationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValidated: false,
      facilityList: [],
      facilitySelect: null,
      facilityForPDFName: null,
      facilityResidentList: [],
      residentSelect: null,
      residentWoundList: [],
      woundSelected: null,
      IsLoading: false,
      showReport: false,
      wound: {
        FacilityId: null,
        ResidentId: null,
        WoundId: null,
      },
      woundEvaluations: {
        residentName: "",
        facilityName: "",
        facilityAddress: "",
        room: "",
        physicianName: "",
        woundLocation: "",
        bodyRegionName: "",
        orientation: "",
        woundType: "",
        acquisition: "",
        woundEvaluationDetail: [],
      },
    };
  }

  componentDidMount() {
    this.getAllFacility();
  }

  getAllFacility() {
    getRequest(Uri_GetFacility + "?isAssessmentView=1")
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              let facilityList = res.data.result
                .map((facility) => ({
                  value: facility.id,
                  label: facility.facilityName,
                }))
                .sort((a, b) => a.label.localeCompare(b.label));
              this.setState({ facilityList: facilityList, IsLoading: true });
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
        showSuccessAlertAutoClose(
          this.props.intl.formatMessage({
            id: "REPORTS.WOUNDEVALUATION.ERROR_OCCURRED",
          }),
          "warning",
          this.props.intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
          3000
        );
      });
  }

  onFacilityChange = (facility) => {
    this.setState({
      facilityResidentList: [],
      residentWoundList: [],
      facilitySelect: facility,
      residentSelect: null,
      woundSelected: null,
    });

    if (facility) {
      getRequestQry(
        Uri_GetResidentByFacilityId,
        `?facilityId=${facility.value}&woundFilter=${woundFilterType.Both}`
      )
        .then((res) => {
          if (
            res.data.statusCode === 200 &&
            res.data.result != null &&
            res.data.result.length > 0
          ) {
            const data = res.data.result;
            let optionTemplate = data
              .map((resident) => ({
                value: resident.key,
                label: resident.value,
              }))
              .sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ facilityResidentList: optionTemplate });
          }
        })
        .catch((error) => {
          console.log(error);
          showSuccessAlertAutoClose(
            this.props.intl.formatMessage({
              id: "REPORTS.WOUNDEVALUATION.ERROR_OCCURRED",
            }),
            "warning",
            this.props.intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
            3000
          );
        });
    }
  };

  onResidentChange = (resident) => {
    this.setState({
      residentWoundList: [],
      residentSelect: resident,
      woundSelected: null,
    });

    if (resident) {
      getRequestQry(
        URI_GetResidentWoundByResidentID,
        `?id=${resident.value}&isDocumentationOnly=${documentationFilterType.Both}`
      )
        .then((res) => {
          if (
            res.data.statusCode === 200 &&
            res.data.result != null &&
            res.data.result.length > 0
          ) {
            let residentWoundList = res.data.result
              .map((element) => {
                let orientationName = getOrientationName({
                  orientationLeftRight: element?.wound?.orientationLeftRight,
                  orientationUpperLower: element?.wound?.orientationUpperLower,
                  orientationInnerOuter: element?.wound?.orientationInnerOuter,
                  orientationAnteriorPosterior:
                    element?.wound?.orientationAnteriorPosterior,
                  orientationMedialLateral:
                    element?.wound?.orientationMedialLateral,
                  orientationDorsalDistalProximal:
                    element?.wound?.orientationDorsalDistalProximal,
                });

                orientationName = orientationName
                  ? orientationName
                      .split(",")
                      .map((x) => this.props.intl.formatMessage({ id: x }))
                      .join(", ")
                      .replace(/(.*),/, "$1 - ")
                  : "";

                return {
                  value: element.wound.id,
                  label: `[${
                    element.wound.isOpen
                      ? this.props.intl.formatMessage({
                          id: "REPORTS.WOUNDEVALUATION.OPEN",
                        })
                      : this.props.intl.formatMessage({
                          id: "REPORTS.WOUNDEVALUATION.CLOSED",
                        })
                  }] ${this.props.intl.formatDate(
                    element.wound.evaluationDate,
                    {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    }
                  )} - ${orientationName} ${
                    element.wound.bodyRegion ? element.wound.bodyRegion : ""
                  }${
                    element.wound.bodyRegion && element.residentWoundLocation
                      ? "/"
                      : ""
                  }${
                    element.residentWoundLocation
                      ? element.residentWoundLocation
                      : ""
                  }`,
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ residentWoundList: residentWoundList });
          }
        })
        .catch((error) => {
          console.log(error);
          showSuccessAlertAutoClose(
            this.props.intl.formatMessage({
              id: "REPORTS.WOUNDEVALUATION.ERROR_OCCURRED",
            }),
            "warning",
            this.props.intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
            3000
          );
          LogError(error, "ResidentWound", "Get");
        });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let obj = this.state.wound;
    this.setState({ facilityForPDFName: this.state.facilitySelect.label });

    getRequestQry(Uri_GetWoundDocumentationByWoundID, "?woundID=" + obj.woundId)
      .then((res) => {
        if (res.data.statusCode === 200) {
          if (res.data.result != null) {
            let tempObj = this.state.woundEvaluations;
            tempObj.residentName = res.data.result.residentName;
            tempObj.physicianName = res.data.result.physicianName;
            tempObj.woundLocation = res.data.result.woundLocation;
            tempObj.bodyRegionName = res.data.result.bodyRegionName;
            tempObj.skinUlcerTreatmentValues =
              res.data.result.skinUlcerTreatmentValues;
            tempObj.woundId = this.state.wound.woundId;

            const orientationEnum = {
              LEFT: 2,
              RIGHT: 1,
            };

            const getOrientation = (orientation) => {
              switch (orientation) {
                case orientationEnum.LEFT:
                  return (
                    this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.LEFT",
                    }) + " - "
                  );
                case orientationEnum.RIGHT:
                  return (
                    this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.RIGHT",
                    }) + " - "
                  );
                default:
                  return "";
              }
            };

            tempObj.orientation = getOrientation(
              res.data.result.woundEvaluationDetail.find(
                (wound) => !!wound.orientationLeftRight
              )?.orientationLeftRight
            );

            tempObj.woundType = res.data.result.woundType;
            tempObj.woundEvaluationDetail =
              res.data.result.woundEvaluationDetail;
            var evaluationDateArray = [];
            var uvc = 0;

            let formattedData = res.data.result.woundEvaluationDetail.sort(
              (a, b) => new Date(a.evaluationDate) - new Date(b.evaluationDate)
            );

            formattedData.forEach((wound) => {
              uvc = uvc + 10;
              evaluationDateArray.push({
                name: getInputStringDateUsingMoment2(
                  moment.utc(wound.evaluationDate).local()
                ),
                uv: uvc,
              });
            });

            tempObj.woundType = res.data.result.woundType;
            tempObj.woundEvaluationDetail =
              res.data.result.woundEvaluationDetail;
            evaluationDateArray = [];
            uvc = 0;
            res.data.result.woundEvaluationDetail.forEach((wound) => {
              uvc = uvc + 10;
              evaluationDateArray.push({
                name: getInputStringDateUsingMoment2(
                  moment.utc(wound.evaluationDate).local()
                ),
                uv: uvc,
              });
            });

            tempObj.woundEvaluationDetail.skinUlcerTreatmentValues =
              res.data.result.skinUlcerTreatmentValues;

            tempObj.evaluationDateArray = evaluationDateArray;
            this.setState({ woundEvaluations: tempObj, showReport: true });
          } else {
            showSuccessAlert(
              this.props.intl.formatMessage({
                id: "REPORTS.WOUNDEVALUATION.RECORD_NOT_FOUND",
              }),
              "warning",
              this.props.intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
              3000
            );
            this.setState({ showReport: false });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        showSuccessAlertAutoClose(
          this.props.intl.formatMessage({
            id: "REPORTS.WOUNDEVALUATION.ERROR_OCCURRED",
          }),
          "warning",
          this.props.intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
          3000
        );
      });
  };

  handleClearFilters = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      facilitySelect: null,
      residentSelect: null,
      woundSelected: null,
      facilityResidentList: [],
      residentWoundList: [],
      showReport: false,
      wound: {
        FacilityId: null,
        ResidentId: null,
        WoundId: null,
      },
      woundEvaluations: {
        residentName: "",
        facilityName: "",
        facilityAddress: "",
        room: "",
        orientation: "",
        physicianName: "",
        woundLocation: "",
        bodyRegionName: "",
        woundType: "",
        acquisition: "",
        woundEvaluationDetail: [],
      },
    });
  };

  renderWoundForm() {
    return (
      <>
        {this.state.IsLoading ? (
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <div className="row">
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="FacilityName">
                    {this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.FACILITY_NAME",
                    })}
                  </label>
                  <Select
                    options={this.state.facilityList}
                    noOptionsMessage={() =>
                      this.props.intl.formatMessage({
                        id: "REPORTS.WOUNDEVALUATION.NO_DATA_FOUND",
                      })
                    }
                    id="facilityId"
                    name="facilityId"
                    placeholder={this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.SELECT",
                    })}
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: "start" })}
                    onChange={(e) => this.onFacilityChange(e)}
                    value={this.state.facilitySelect}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="PatientName">
                    {this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.RESIDENT_NAME",
                    })}
                  </label>
                  <Select
                    options={this.state.facilityResidentList}
                    id="residentId"
                    noOptionsMessage={() =>
                      this.props.intl.formatMessage({
                        id: "REPORTS.WOUNDEVALUATION.NO_DATA_FOUND",
                      })
                    }
                    name="residentId"
                    required
                    placeholder={this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.SELECT",
                    })}
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: "start" })}
                    onChange={(e) => this.onResidentChange(e)}
                    value={this.state.residentSelect}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-group">
                  <label className="form-label" htmlFor="WoundType">
                    {this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.WOUND",
                    })}
                  </label>
                  <Select
                    options={this.state.residentWoundList}
                    id="woundId"
                    name="woundId"
                    noOptionsMessage={() =>
                      this.props.intl.formatMessage({
                        id: "REPORTS.WOUNDEVALUATION.NO_DATA_FOUND",
                      })
                    }
                    placeholder={this.props.intl.formatMessage({
                      id: "REPORTS.WOUNDEVALUATION.SELECT",
                    })}
                    required
                    isSearchable
                    isClearable
                    filterOption={createFilter({ matchFrom: "start" })}
                    onChange={(e) => {
                      if (!e) {
                        this.setState({ woundSelected: e });
                      } else {
                        this.setState({
                          wound: { ...this.state.wound, woundId: e.value },
                        });
                        this.setState({ woundSelected: e });
                      }
                    }}
                    value={this.state.woundSelected}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end py-3">
              <Button
                type="submit"
                variant="success"
                className="mr-5 px-10"
                size="sm"
              >
                {this.props.intl.formatMessage({
                  id: "REPORTS.WOUNDEVALUATION.SEARCH_REPORT",
                })}
              </Button>
              <button
                className="btn btn-danger btn-sm"
                onClick={(e) => this.handleClearFilters(e)}
              >
                {this.props.intl.formatMessage({
                  id: "REPORTS.WOUNDEVALUATION.CLEAR_FILTER",
                })}
              </button>
            </div>
          </form>
        ) : (
          <div className="d-flex justify-content-center">
            <Loader type="Puff" color="#00BFFF" height={50} width={50} />
          </div>
        )}
      </>
    );
  }

  render() {
    const { intl } = this.props;
    async function PrintElem(state) {
      let doc = new jsPDF("landscape", "pt", "a4", false);

      const elm = document.querySelector("#printit");

      await html2canvas(elm, { scale: 2 }).then((canvasItem) => {
        const pixelHeight = 775 * 2;
        for (let i = 0; i <= elm.clientHeight * 2; i += pixelHeight) {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = canvasItem.width;
          canvas.height = pixelHeight;
          ctx.drawImage(
            canvasItem,
            0,
            i,
            canvasItem.width,
            pixelHeight,
            0,
            0,
            canvasItem.width,
            pixelHeight
          );
          const imgData = canvas.toDataURL("image/png");
          doc.addImage(imgData, "PNG", 10, 30, 822, 0, undefined, "FAST");
          if (i + pixelHeight < elm.clientHeight * 2) {
            doc.addPage();
          }
        }
      });

      const pageCount = doc.internal.getNumberOfPages();
      doc.setFont("Poppins", "normal", "bold");
      doc.setFontSize(8);
      doc.setTextColor(112, 108, 108);
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.text(
          "Page " + i + " of " + pageCount,
          doc.internal.pageSize.width - 50,
          doc.internal.pageSize.height - 20
        );
      }

      doc.save(
        `${state.facilityForPDFName} - ${this.props.intl.formatMessage({
          id: "REPORTS.WOUNDEVALUATION.TITLE",
        })}.pdf`
      );
    }

    return (
      <>
        <Card>
          <CardHeader
            className="Text-center"
            title={this.props.intl.formatMessage({ id: "REPORTS.WOUNDEVALUATION.TITLE" })}
          >
            <CardHeaderToolbar>
              <div className="example-tools"></div>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            {this.renderWoundForm()}
            <hr />
            {this.state.showReport && (
              <>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="button"
                      onClick={() => PrintElem(this.state)}
                      className="mr-3 btn btn-success btn-sm float-right"
                    >
                      {this.props.intl.formatMessage({
                        id: "REPORTS.WOUNDEVALUATION.PRINT_REPORT_BUTTON",
                      })}
                    </button>
                  </div>
                </div>
                <div className="wound-evaluation-report">
                  <div
                    id="printit"
                    className="printDiv wound-evaluation-report_content"
                  >
                    <div className="mt-5">
                      <h2>{this.state.woundEvaluations.facilityName}</h2>
                      <span>{this.state.woundEvaluations.facilityAddress}</span>
                    </div>
                    <div className="mt-5">
                      <table className="w-100">
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id: "REPORTS.WOUNDEVALUATION.RESIDENT",
                                })}
                              </span>
                              <span>
                                {this.state.woundEvaluations.residentName}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id: "REPORTS.WOUNDEVALUATION.ROOM",
                                })}
                              </span>
                              <span>{this.state.woundEvaluations.room}</span>
                            </td>
                            <td>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id: "REPORTS.WOUNDEVALUATION.PHYSICIAN",
                                })}
                              </span>
                              <span>
                                {this.state.woundEvaluations.physicianName}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id: "REPORTS.WOUNDEVALUATION.SITE",
                                })}
                                #
                              </span>
                              <span>{this.state.woundEvaluations.woundId}</span>
                            </td>
                            <td>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id:
                                    "REPORTS.WOUNDEVALUATION.BODY_REGION_LOCATION",
                                })}
                              </span>
                              <span>
                                {this.state.woundEvaluations.orientation}
                                {(this.state.woundEvaluations.bodyRegionName !=
                                  null &&
                                this.state.woundEvaluations.bodyRegionName != ""
                                  ? this.state.woundEvaluations.bodyRegionName +
                                    " / "
                                  : "") +
                                  this.state.woundEvaluations.woundLocation}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id: "REPORTS.WOUNDEVALUATION.TYPE_OF_WOUND",
                                })}
                              </span>
                              <span>
                                {this.state.woundEvaluations.woundType}
                              </span>
                            </td>
                            <td>
                              <span className="font-weight-bolder mr-2">
                                {this.props.intl.formatMessage({
                                  id: "REPORTS.WOUNDEVALUATION.ACQUISITION",
                                })}
                              </span>
                              <span>
                                {this.state.woundEvaluations.acquisition}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-5">
                      <table className="table table-striped table-sm w-100">
                        <thead className="thead_style_cust">
                          <tr>
                            <th
                              width="10%"
                              style={{
                                borderBottom: "1px solid black",
                                backgroundColor: "#fff",
                              }}
                            ></th>
                            <th
                              width="14%"
                              style={{
                                border: "solid #000",
                                borderWidth: "0 1px 1px 1px",
                              }}
                            >
                              {this.props.intl.formatMessage({
                                id: "REPORTS.WOUNDEVALUATION.MEASUREMENTS",
                              })}
                            </th>
                            <th
                              width="12%"
                              style={{
                                border: "solid #000",
                                borderWidth: "0 1px 1px 1px",
                              }}
                            >
                              {this.props.intl.formatMessage({
                                id: "REPORTS.WOUNDEVALUATION.EXUDATE",
                              })}
                            </th>
                            <th
                              width="12%"
                              style={{
                                border: "solid #000",
                                borderWidth: "0 1px 1px 1px",
                              }}
                            >
                              {this.props.intl.formatMessage({
                                id: "REPORTS.WOUNDEVALUATION.WOUND_BED",
                              })}
                            </th>
                            <th
                              width="12%"
                              style={{
                                border: "solid #000",
                                borderWidth: "0 1px 1px 1px",
                              }}
                            >
                              {this.props.intl.formatMessage({
                                id: "REPORTS.WOUNDEVALUATION.PERIWOUND",
                              })}
                            </th>
                            <th
                              width="40%"
                              style={{
                                border: "solid #000",
                                borderWidth: "0 1px 1px 1px",
                              }}
                            >
                              {this.props.intl.formatMessage({
                                id: "REPORTS.WOUNDEVALUATION.COMMENTS",
                              })}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="body_style_cust">
                          {this.state.woundEvaluations.woundEvaluationDetail?.map(
                            (wound) => {
                              return (
                                <tr
                                  key={uuid()}
                                  style={{ border: "1px solid black" }}
                                >
                                  <td>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.DATE",
                                          })}
                                        </span>{" "}
                                        {this.props.intl.formatDate(wound.evaluationDate, {
                                          year: "numeric",
                                          month: "2-digit",
                                          day: "2-digit",
                                        })}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.THICKNESS_STAGE",
                                          })}
                                        </span>{" "}
                                        {wound.woundThicknessName}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.TISSUE_TYPE",
                                          })}
                                        </span>{" "}
                                        {wound.woundSevereTissueName}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.LENGTH",
                                          })}
                                        </span>{" "}
                                        {wound.woundLength}{" "}
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.WIDTH",
                                          })}
                                        </span>{" "}
                                        {wound.woundWidth}{" "}
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.DEPTH",
                                          })}
                                        </span>{" "}
                                        {wound.woundDepth}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.TUNNELING",
                                          })}
                                        </span>
                                        {wound.tunneling
                                          ? this.props.intl.formatMessage({
                                              id: "TEXT.YES",
                                            })
                                          : this.props.intl.formatMessage({
                                              id: "TEXT.NO",
                                            })}{" "}
                                      </p>
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.DEPTH",
                                          })}
                                        </span>
                                        {wound.tunnelingDepth}
                                      </p>
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.CLOCK_POSITION",
                                          })}
                                        </span>{" "}
                                        {wound.tunnelingClockPosition}
                                      </p>
                                    </div>
                                    {wound.undermining && (
                                      <div className="border_bottom_cus py-2">
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.UNDERMINING",
                                            })}
                                          </span>{" "}
                                          {wound.undermining ? "Yes" : "No"}
                                        </p>
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.DEPTH",
                                            })}
                                          </span>{" "}
                                          {wound.underminingDepth}
                                        </p>
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.FROM_CLOCK_POSITION",
                                            })}
                                          </span>{" "}
                                          {wound.underminingClockPosition}
                                        </p>
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.TO_CLOCK_POSITION",
                                            })}
                                          </span>{" "}
                                          {wound.toUnderminingClockPosition}
                                        </p>
                                      </div>
                                    )}
                                  </td>
                                  <td>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.COLOR",
                                          })}
                                        </span>{" "}
                                        {wound.exudateColor}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.EXUDATE_TYPE",
                                          })}
                                        </span>{" "}
                                        {wound.exudateTypeName}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.DEBRIDEMENT",
                                          })}
                                        </span>{" "}
                                        {wound.debridementTypeName}
                                      </p>
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.LAST_DEBRIDEMENT_DATE",
                                          })}
                                        </span>{" "}
                                        {wound.debridementDate != null
                                          ? this.props.intl.formatDate(
                                              wound.debridementDate,
                                              {
                                                year: "numeric",
                                                month: "2-digit",
                                                day: "2-digit",
                                              }
                                            )
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.AMOUNT",
                                          })}
                                        </span>{" "}
                                        {wound.exudateAmountName}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.ODOR",
                                          })}
                                        </span>
                                        {wound.odor
                                          ? this.props.intl.formatMessage({
                                              id: "TEXT.YES",
                                            })
                                          : this.props.intl.formatMessage({
                                              id: "TEXT.NO",
                                            })}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id: "REPORTS.WOUNDEVALUATION.PAIN",
                                          })}
                                        </span>
                                        {wound.pain
                                          ? this.props.intl.formatMessage({
                                              id: "TEXT.YES",
                                            })
                                          : this.props.intl.formatMessage({
                                              id: "TEXT.NO",
                                            })}{" "}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.PAIN_MEDICATION_ORDERED",
                                          })}
                                        </span>{" "}
                                        {wound.painMedicationOrdered
                                          ? this.props.intl.formatMessage({
                                              id: "TEXT.YES",
                                            })
                                          : this.props.intl.formatMessage({
                                              id: "TEXT.NO",
                                            })}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.PUSH_SCORE",
                                          })}{" "}
                                        </span>
                                        {calculatePushScore(
                                          wound.woundLength * wound.woundWidth,
                                          wound.amount,
                                          wound.fkWoundSevereTissueTypeId
                                        )}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.WOUND_BED_DESCRIPTION",
                                          })}
                                        </span>
                                      </p>
                                      {wound.granulationPerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.GRANULATION",
                                            })}{" "}
                                            %:
                                          </span>
                                          {wound.granulationPerc}{" "}
                                        </p>
                                      )}
                                      {wound.hyperGranulationPerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.HYPERGRANULATION",
                                            })}{" "}
                                            %:
                                          </span>
                                          {wound.hyperGranulationPerc}{" "}
                                        </p>
                                      )}
                                      {wound.sloughPerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.SLOUGH",
                                            })}{" "}
                                            %:
                                          </span>
                                          {wound.sloughPerc}{" "}
                                        </p>
                                      )}
                                      {wound.necroticPerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.NECROTIC_ESCHAR",
                                            })}{" "}
                                            %:
                                          </span>
                                          {wound.necroticPerc}{" "}
                                        </p>
                                      )}
                                      {wound.epithelialPerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.EPITHELIAL",
                                            })}{" "}
                                            %:
                                          </span>
                                          {wound.epithelialPerc}{" "}
                                        </p>
                                      )}
                                      {wound.musclePerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.MUSCLE_TENDON_BONE",
                                            })}{" "}
                                            %:
                                          </span>
                                          {wound.musclePerc}{" "}
                                        </p>
                                      )}
                                      {wound.otherPerc > 0 && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {wound.otherWoundDesc ||
                                              wound.otherPerc}{" "}
                                            %:
                                          </span>
                                          {wound.otherPerc}{" "}
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.WOUND_EDGES",
                                          })}
                                        </span>{" "}
                                        {wound.woundEdgesName}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.SURROUNDING_SKIN",
                                          })}
                                        </span>{" "}
                                        {wound.wounSurroundingSkinName}
                                      </p>
                                      {wound.wounSurroundingSkinName ===
                                        "Other" && (
                                        <p className="mb-0">
                                          <span className="font-weight-bolder">
                                            {this.props.intl.formatMessage({
                                              id:
                                                "REPORTS.WOUNDEVALUATION.SURROUNDING_SKIN_OTHER",
                                            })}
                                          </span>{" "}
                                          {wound.otherSurroundingSkin}
                                        </p>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.SKIN_ULCER_TREATMENT",
                                          })}
                                        </span>{" "}
                                        {this.state.woundEvaluations?.skinUlcerTreatmentValues?.join(
                                          "- "
                                        )}
                                      </p>
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.TREATMENT",
                                          })}
                                        </span>{" "}
                                        {wound.treatment}{" "}
                                      </p>
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.GENERAL_COMMENTS",
                                          })}
                                        </span>
                                        {wound.comments}
                                      </p>
                                    </div>
                                    <div className="border_bottom_cus py-2">
                                      <p className="mb-0">
                                        <span className="font-weight-bolder">
                                          {this.props.intl.formatMessage({
                                            id:
                                              "REPORTS.WOUNDEVALUATION.SIGNATURE",
                                          })}
                                        </span>{" "}
                                        {wound.evaluatedBy}
                                      </p>
                                      <p className="mb-0">
                                        [
                                        {this.props.intl.formatMessage({
                                          id: "REPORTS.WOUNDEVALUATION.ESIGNED",
                                        })}
                                        ]{" "}
                                        {this.props.intl.formatDate(
                                          wound.evaluationDate,
                                          {
                                            year: "numeric",
                                            month: "2-digit",
                                            day: "2-digit",
                                          }
                                        )}
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default injectIntl(WoundEvaluationReport);

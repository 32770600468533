import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";


var prods = {};
const basicAccessories= [{ id: "6", productName: "Skin Preps Wipes" ,buttonLabel:"Skin Preps Wipes",sku:"Generic A6010"}, { id: "7", productName: "Adhesive Remover",buttonLabel:"Adhesive Remover",sku:"GEN-15610" }, { id:"12", productName: "Rings" ,buttonLabel:"Rings",sku:"Generic A6196"}];
const otherAccessories = [{ id: "13", productName: "Night Drainage Bag" ,buttonLabel:"Night Drainage Bag" ,sku:"GEN-13500" }, { id: "20", productName: "Adhesive Remover" ,buttonLabel: "Adhesive Remover",sku: "Generic A6199"}, { id: "21", productName: "Rings" ,buttonLabel:"Rings",sku:"Generic A6196"}];
export default class AccessoriesSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            primaryList: [],
            secondaryList: [],
            selectedProducts: [],

            orderProducts: [],
       
        }
    }

    componentDidMount() {
        debugger
        prods = {};
        if (this.props.orderProducts != null) {
            this.setState({ orderProducts: this.props.orderProducts });
        }
        //this.setProductData(data);
        // this.setFrequencyLookupsUI();
        this.GetAllProductsApiCall();
    }

     handleChangeOtherPrimaryProducts = selectedOtherPrimaryProducts => {
        this.setState({ selectedOtherPrimaryProducts });
      };

      handleChangeOtherSecodaryProducts = selectedSecondaryProducts => {
        this.setState({ selectedSecondaryProducts });
      };
    render() {
        return (
            <>
                <div className="row mt-2" id="changed">

                    <div className="col-md-6" >
                        <h4>Basic Accessories</h4>
                        {this.renderProductUI(basicAccessories)}

                    </div>

                    <div className="col-md-6" >
                        <h4>Other Accessories</h4>
                        {this.renderProductUI(otherAccessories)}

                    </div>

                </div>


         
            </>

        );
    }



    setOrderProducts(orderProducts) {
         debugger
        // const productListByLevel = this.groupBy(this.state.orderProducts, "productLevelName", "productCategoryName");

        // var primaryList = [];
        // var secondaryList = [];
        // if (productListByLevel != null) {


        //     primaryList = productListByLevel["Primary"] != null ? productListByLevel["Primary"] : [];
        //     secondaryList = productListByLevel["Secondary"] != null ? productListByLevel["Secondary"] : [];
        
        // }

        // Object.entries(orderProducts).forEach(([key, value]) => {

           this.state.orderProducts.map((product) => {
                if (!product.withOtherButtons) {
                let e = { target: { value: product.quantity } }
                this.setSelectedProducts(product);
                this.onchangeQuantity(e, product.id)
                }
            })
        // })

        // Object.entries(secondaryList).forEach(([key, value]) => {

        //     value.list.map((product) => {
        //         if (!product.withOtherButtons) {
        //         let e = { target: { value: product.quantity } }
        //         this.setSelectedProducts(product);
        //         this.onchangeQuantity(e, product.id)
        //         }
        //     })
        // })
    }

    setSelectedProducts(product) {
        debugger
        if (prods[product.id]) {
            prods[product.id] = false;
            const _selectedprod = this.state.selectedProducts.filter(obj => {
                return obj.id != product.id
            })

            this.setState({ selectedProducts: _selectedprod });
        }
        else {
            prods[product.id] = true;

            let _selectedProd = JSON.parse(JSON.stringify(this.state.selectedProducts));
            _selectedProd.push({ id: product.id, quantity: 1,sku:product.sku, saleOrderProductId: product.ostomyAccessoriesId });
            this.setState({ selectedProducts: _selectedProd })
        }

    }

    onchangeQuantity(event, id) {
        // // debugger;
        const _selectedprod = this.state.selectedProducts.map(obj => {
            if (obj.id == id) {
                obj.quantity = event.target.value;
            }
            return obj
        })

        this.setState({ selectedProducts: _selectedprod });

    }

    getSelectedProductQuantity(id) {
        let quantity = 1;
        this.state.selectedProducts.map(obj => {
            if (obj.id == id) {
                quantity = obj.quantity;
            }
        })

        return quantity;
    }

    renderProductUI(list) {
        const _list = list;
        let productUIArray = [];

        //  Object.entries(_list).forEach(([key, value]) => {


             productUIArray.push(
                
                <div className="row mb-10" >
                    

                    <div className="col-md-8">

                        {
                            list.map((product) => {
                           
                                const btnColor = prods[product.id] ? "btn-success" : "btn-outline-info";
                                return product.sku != null ?
                                    prods[product.id] ?

                                        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={
                                            <Popover id="popover-basic"  >
                                                <div>
                                                    <Card>
                                                        <CardBody>
                                                        <label className="form-label text text-bold">{product.productName}</label>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label" htmlFor="DebridementType">Quantity</label>
                                                                        <input type="number" id="quantity"
                                                                            name="quantity"
                                                                            onChange={(e) => this.onchangeQuantity(e, product.id)}
                                                                            defaultValue={this.getSelectedProductQuantity(product.id)}
                                                                            className="form-control form-control-sm" />

</div>
                                                                </div>
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        {/* TASK 2120: Remove 'Remaining Quantity' when reordering
                                                                        <label className="form-label" htmlFor="DebridementType">Remaining Quantity</label>
                                                                        <input type="number" id="RemainingQuantity"
                                                                            name="RemainingQuantity"
                                                                            // onChange={(e) => this.onchangeQuantity(e, product.id)}
                                                                            //onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                                                                            // defaultValue={this.getSelectedProductQuantity(product)}
                                                                            className="form-control form-control-sm" /> */}

                                                                        <button type="button" className="btn btn-danger mt-5" size="sm" onClick={() => this.setSelectedProducts(product)}>Remove Product</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>

                                                    </Card>
                                                </div>
                                            </Popover>
                                        }>
                                            <button type="button" title={product.productName} className={`mr-4 mt-2 btn position-relative ${btnColor}`}
                                                id={product.sku}
                                            //onClick={() => this.setSelectedProducts(product)}
                                            >
                                                {Common.replaceNullWithString(product.buttonLabel)}
                                                <span className="badge rounded-pill badge-notification position-absolute top-0 left-100 ml-1 translate-middle bg-warning">{this.getSelectedProductQuantity(product.id)}</span>
                                            </button>
                                        </OverlayTrigger>


                                        :
                                        <button type="button" title={product.productName} className={`mr-2 mt-2 btn  ${btnColor}`}
                                            id={product.sku}
                                            onClick={() => this.setSelectedProducts(product)}
                                        >
                                            {Common.replaceNullWithString(product.buttonLabel)}
                                        </button>

                                    :
                                    <label className="form-label" htmlFor="Status">{Common.replaceNullWithString(product.buttonLabel)}</label>
                             })
                        }

                    </div>
                </div>
            );
        //  })

        return productUIArray;


    }
  



    //#region Get/init Product

    groupBy(objectArray, property, subProperty) {
        return objectArray.reduce((acc, obj) => {
            var key = obj[property];
            var subkey = obj[subProperty];

            if (!acc[key]) {
                acc[key] = [];
            }

            let tempAcc = acc[key];

            if (!tempAcc[subkey]) {
                tempAcc[subkey] = { name: subkey, description: obj["productCategoryDescription"], list: [] };
            }

            tempAcc[subkey].list.push(obj);

            return acc;
        }, {});
    }

    setProductData(data) {
        const productListByLevel = this.groupBy(data, "productLevelName", "productCategoryName");

        if (productListByLevel != null) {

     
            this.setState({
                primaryList: productListByLevel["Primary"] != null ? productListByLevel["Primary"] : [],
                secondaryList: productListByLevel["Secondary"] != null ? productListByLevel["Secondary"] : [],
            })
        }
    }



  async  GetAllProductsApiCall() {
        // var WoundDressingChangeFrequency = await Common.getLookupOptions(Common.LookupsFK.Fk_WoundDressingChangeFrequencyID)
        // this.setState({ WoundDressingChangeFrequency: WoundDressingChangeFrequency })
        Api.getRequest(Api.URI_GetAllProducts).then((res) => {
             debugger
            if (res.data.success) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        //this.setProductData(res.data.result);
                        if (this.props.orderProducts != null) {
                            this.setOrderProducts();
                        }
                    }

                }
                else {
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            // // debugger
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }

    //#endregion

    getSelectedProductList = () => {
 debugger
        var productsList = {}
        productsList.products = [];
        productsList.products = this.state.selectedProducts;


        return productsList;
    }
}//end
import React from "react";

import CreateResidentFormInputUS from "./lang/createResidentFormInputUS";
import createResidentFormInputBR from "./lang/createResidentFormInputBR";
import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";

const CreateResidentMultiRegion = withMultiRegionComponent({
  US: CreateResidentFormInputUS,
  BR: createResidentFormInputBR,
});

function CreateResidentWrapper(props) {
  return <CreateResidentMultiRegion {...props} />;
}

export default CreateResidentWrapper;

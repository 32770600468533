import React, { useMemo, useCallback } from "react";
import { Grid } from "@material-ui/core";
import {
  exudate,
  calculatePushScore,
} from "../../../General/common";
import moment from "moment";
import { Badge } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import groupingHashMapUtils from "../../../../shared/utilities/woundType/groupingHashMapUtils";

const WithGroupedWoundReports = ({ woundEvaluation, intl, children }) => {
  const GetWoundTypeGroupAndItems = useCallback(
    (woundType) => {
      let result = ["", ["", ""]];
      Object.keys(groupingHashMapUtils).forEach((key) => {
        const value = groupingHashMapUtils[key];

        if (value.includes(woundType)) {
          result = [key, value];
        }
      });
      return result;
    },
    [woundEvaluation]
  );

  const foundExistingGroups = useMemo(() => {
    const foundGroups = woundEvaluation?.map((report) => {
      const [group, items] = GetWoundTypeGroupAndItems(report.woundType);
      return group;
    });
    const result = [...new Set(foundGroups)];

    if (!foundGroups) return [];
    return result;
  }, [woundEvaluation]);

  const orientationEnum = {
    LEFT: 2,
    RIGHT: 1,
  };

  const acquisitionEnum = {
    1: intl.formatMessage({ id: "REPORTS.WOUNDS.ADMITTED" }),
    2: intl.formatMessage({ id: "REPORTS.WOUNDS.ACQUIRED" }),
    3: intl.formatMessage({ id: "REPORTS.WOUNDS.CLOSED" }),
    4: intl.formatMessage({ id: "REPORTS.WOUNDS.REOCCURRING" }),
  };

  const getHorizontalOrientation = (orientation) => {
    switch (orientation) {
      case orientationEnum.LEFT:
        return intl.formatMessage({ id: "REPORTS.WOUNDS.LEFT" }) + " - ";
      case orientationEnum.RIGHT:
        return intl.formatMessage({ id: "REPORTS.WOUNDS.RIGHT" }) + " - ";
      default:
        return "";
    }
  };

  const getVerticalOrientation = (orientation) => {
    switch (orientation) {
      case orientationEnum.LEFT:
        return intl.formatMessage({ id: "REPORTS.WOUNDS.LEFT" }) + " - ";
      case orientationEnum.RIGHT:
        return intl.formatMessage({ id: "REPORTS.WOUNDS.RIGHT" }) + " - ";
      default:
        return "";
    }
  };

  return (
    <>
      {woundEvaluation?.map(
        ({
          woundType,
          facilityName,
          toDate,
          fromDate,
          woundEvaluationDetail,
        }) => {
          return (
            <div id={`printThis-${woundType}`} className="printDiv_content">
              <Grid
                className="registerTitle WoundGroupheader"
                container
                spacing={3}
              >
                <Grid item xs={4}>
                  <span className="font-weight-bolder">
                    <FormattedMessage
                      id="REPORTS.WOUNDS.FACILITY_NAME"
                      defaultMessage="Facility Name"
                    />
                    : {facilityName}
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span className="font-weight-bolder">
                    <FormattedMessage
                      id="REPORTS.WOUNDS.WOUND_REPORT"
                      defaultMessage="Wound Report"
                    />
                    :
                  </span>
                </Grid>
                <Grid item xs={4}>
                  <span className="font-weight-bolder">
                    {" "}
                    <FormattedMessage
                      id="REPORTS.WOUNDS.FROM"
                      defaultMessage="From"
                    />
                    :{" "}
                    {intl.formatDate(fromDate, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}{" "}
                    <FormattedMessage
                      id="REPORTS.WOUNDS.TO"
                      defaultMessage="To"
                    />
                    :{" "}
                    {intl.formatDate(toDate, {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })}{" "}
                  </span>
                </Grid>
              </Grid>

              <div className="mt-5 mb-7 woundType WoundGroupheader">
                <p className="mb-1 fs_1_1">
                  <span className="font-weight-bolder">
                    <FormattedMessage
                      id="REPORTS.WOUNDS.WOUND_TYPE"
                      defaultMessage="Wound Type"
                    />
                    :
                  </span>{" "}
                  {woundType}
                </p>
              </div>
              <div className="mt-5">
                <table className="table table-striped table-bordered w-100">
                  <thead className="thead-light WoundGroupheader">
                    <tr className="tableHeader">
                      <th width="9%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.NAME"
                            defaultMessage="Name"
                          />
                        </strong>
                      </th>
                      <th width="6%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.UNIT_ROOM"
                            defaultMessage="Unit/Room"
                          />
                        </strong>
                      </th>
                      <th width="9%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.DATE_FIRST_OBSERVED"
                            defaultMessage="Date First Observed"
                          />
                        </strong>
                      </th>
                      <th width="30%">
                        <div className="facility-report_content">
                          <div className="facility-report_rotate-text">
                            <strong>
                              <FormattedMessage
                                id="REPORTS.WOUNDS.ADMITTED"
                                defaultMessage="Admitted"
                              />
                            </strong>
                          </div>
                          <div className="facility-report_rotate-text">
                            <strong>
                              <FormattedMessage
                                id="REPORTS.WOUNDS.ACQUIRED"
                                defaultMessage="Acquired"
                              />
                            </strong>
                          </div>
                          <div className="facility-report_rotate-text">
                            <strong>
                              <FormattedMessage
                                id="REPORTS.WOUNDS.CLOSED"
                                defaultMessage="Closed"
                              />
                            </strong>
                          </div>
                          <div className="facility-report_rotate-text">
                            <strong>
                              <FormattedMessage
                                id="REPORTS.WOUNDS.REOCCURRING"
                                defaultMessage="Reoccurring"
                              />
                            </strong>
                          </div>
                        </div>
                      </th>
                      <th width="9%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.LOCATION"
                            defaultMessage="Location"
                          />
                        </strong>
                      </th>
                      <th width="9%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.TYPE"
                            defaultMessage="Type"
                          />
                        </strong>
                      </th>
                      <th width="1%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.LENGTH"
                            defaultMessage="Length"
                          />
                        </strong>
                      </th>
                      <th width="1%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.WIDTH"
                            defaultMessage="Width"
                          />
                        </strong>
                      </th>
                      <th width="1%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.DEPTH"
                            defaultMessage="Depth"
                          />
                        </strong>
                      </th>
                      <th width="1%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.STAGE_THICKNESS"
                            defaultMessage="Stage/Thickness"
                          />
                        </strong>
                      </th>
                      <th width="1%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.EXUDATE"
                            defaultMessage="Exudate"
                          />
                        </strong>
                      </th>
                      <th width="1%">
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.PUSH"
                            defaultMessage="Push"
                          />
                        </strong>
                      </th>
                    </tr>
                    <tr className="tableHeader">
                      <th colSpan={1}>
                        <FormattedMessage
                          id="REPORTS.WOUNDS.WOUND_STATUS"
                          defaultMessage="Wound Status"
                        />
                      </th>
                      <th colSpan={3}>
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.WOUND_BED_PERI_WOUND"
                            defaultMessage="Wound bed Peri-wound Wound Edge Description"
                          />
                        </strong>
                      </th>
                      <th colSpan={9}>
                        <strong>
                          <FormattedMessage
                            id="REPORTS.WOUNDS.TREATMENT"
                            defaultMessage="Treatment"
                          />
                        </strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {woundEvaluationDetail.map((wound, index) => {
                      let exudateId = 0;
                      const exudateMap = {
                        Light: exudate.light,
                        Moderate: exudate.moderate,
                        Heavy: exudate.heavy,
                      };

                      const horizontalOrientation = getHorizontalOrientation(
                        wound.orientationLeftRight
                      );
                      exudateId = exudateMap[wound.exudateAmountName]
                        ? exudateMap[wound.exudateAmountName]
                        : 0;
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{wound.residentName}</td>
                            <td>{wound.roomUnit ? wound.roomUnit : "N/A"}</td>
                            <td>
                              {intl.formatDate(
                                moment(wound.dateFirstObserved),
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                            </td>
                            <td>
                              <div className="facility-report_count-content">
                                {wound.acquisition === acquisitionEnum[1] ? (
                                  <span>1</span>
                                ) : (
                                  <span>0</span>
                                )}
                                {wound.acquisition === acquisitionEnum[2] ? (
                                  <span>1</span>
                                ) : (
                                  <span>0</span>
                                )}
                                {wound.acquisition === acquisitionEnum[3] ? (
                                  <span>1</span>
                                ) : (
                                  <span>0</span>
                                )}
                                {wound.acquisition === acquisitionEnum[4] ? (
                                  <span>1</span>
                                ) : (
                                  <span>0</span>
                                )}
                              </div>
                            </td>
                            <td>
                              {horizontalOrientation}
                              {wound.woundBodyRegion} / {wound.woundLocation}
                            </td>
                            <td>{wound.woundType}</td>
                            <td>{wound.woundLength}</td>
                            <td>{wound.woundWidth}</td>
                            <td>{wound.woundDepth}</td>
                            <td>{wound.woundThicknessName}</td>
                            <td>{wound.exudateAmountName}</td>
                            <td>
                              {calculatePushScore(
                                wound.woundLength * wound.woundWidth,
                                exudateId,
                                wound.fkWoundSevereTissueTypeId
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span className="text-sm mb-2">
                                {/* {  intl.formatDate(moment(wound?.modifiedDate), {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })} */}

                                {wound?.modifiedDate
                                  ? intl.formatDate(wound?.modifiedDate, {
                                      year: "numeric",
                                      month: "2-digit",
                                      day: "2-digit",
                                    })
                                  : ""}
                              </span>
                              <Badge
                                variant={wound?.isOpen ? "success" : "danger"}
                              >
                                {wound?.isOpen
                                  ? intl.formatMessage({
                                      id: "REPORTS.WOUNDS.OPEN",
                                    })
                                  : intl.formatMessage({
                                      id: "REPORTS.WOUNDS.CLOSED",
                                    })}
                              </Badge>
                            </td>
                            <td colSpan={3}>
                              {wound.granulationPerc > 0 && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.GRANULATION_PERC"
                                      defaultMessage="Granulation %"
                                    />
                                    :
                                  </span>
                                  {wound.granulationPerc}{" "}
                                </p>
                              )}
                              {wound.hyperGranulationPerc > 0 && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.HYPERGRANULATION_PERC"
                                      defaultMessage="HyperGranulation %"
                                    />
                                    :
                                  </span>
                                  {wound.hyperGranulationPerc}{" "}
                                </p>
                              )}
                              {wound.sloughPerc > 0 && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.SLOUGH_PERC"
                                      defaultMessage="Slough %"
                                    />
                                    :
                                  </span>
                                  {wound.sloughPerc}{" "}
                                </p>
                              )}
                              {wound.necroticPerc > 0 && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.NECROTIC_ESCHAR_PERC"
                                      defaultMessage="Necrotic/Eschar %"
                                    />
                                    :
                                  </span>
                                  {wound.necroticPerc}{" "}
                                </p>
                              )}
                              {wound.epithelialPerc > 0 && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.EPITHELIAL_PERC"
                                      defaultMessage="Epithelial %"
                                    />
                                    :
                                  </span>
                                  {wound.epithelialPerc}{" "}
                                </p>
                              )}
                              {wound.musclePerc > 0 && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.MUSCLE_TENDON_BONE_PERC"
                                      defaultMessage="Muscle, Tendon or Bone %"
                                    />
                                    :
                                  </span>
                                  {wound.musclePerc}{" "}
                                </p>
                              )}
                              {wound.otherPerc > 0 && (
                                <p className="mb-0">
                                  <span>{wound.otherWoundDesc} %:</span>
                                  {wound.otherPerc}{" "}
                                </p>
                              )}

                              <p className="mb-0">
                                <span>
                                  <FormattedMessage
                                    id="REPORTS.WOUNDS.SURROUNDING_SKIN"
                                    defaultMessage="Surrounding Skin"
                                  />
                                  :
                                </span>{" "}
                                {wound.wounSurroundingSkinName}
                              </p>
                              {wound.wounSurroundingSkinName === "Other" && (
                                <p className="mb-0">
                                  <span>
                                    <FormattedMessage
                                      id="REPORTS.WOUNDS.SURROUNDING_SKIN_OTHER"
                                      defaultMessage="Surrounding Skin Other"
                                    />
                                    :
                                  </span>{" "}
                                  {wound.otherSurroundingSkin}
                                </p>
                              )}
                              <p className="mb-0">
                                <span>
                                  <FormattedMessage
                                    id="REPORTS.WOUNDS.WOUND_EDGES"
                                    defaultMessage="Wound Edges"
                                  />
                                  :
                                </span>{" "}
                                {wound.woundEdgesName}
                              </p>
                            </td>
                            <td colSpan={8}>{wound.treatment}</td>
                          </tr>
                          <br />
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        }
      )}
    </>
  );
};

export default injectIntl(WithGroupedWoundReports);

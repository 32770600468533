/*eslint-disable */

import React from "react";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import PopupModal from '../modal/popupmodal';

import NewWound from './newwound';
import WoundLocation from './woundlocation';
import WoundNote from './woundnote';
import ICDReport from '../modal/reports/icdreport';
var ICDReportModal = null;
var WoundLocationModal = null;
var WoundNoteModal = null;
export default class Wounds extends React.Component {
    constructor(props) {
        super(props);
        // // debugger;
        this.state = {
            showModalAddWound: false,
            NewWoundComponent: '',
            woundList: [],
            Resident:
            {
                fkResidentId: props.Resident.id,
                residentName: Common.replaceNullWithString(props.Resident.firstName) + " " + Common.replaceNullWithString(props.Resident.lastName),
                facilityName: Common.replaceNullWithString(props.Resident.facilityName),
                chain: Common.replaceNullWithString(props.Resident.chain),
                organizationName: Common.replaceNullWithString(props.Resident.organizationName),

                roomUnit: Common.replaceNullWithString(props.Resident.room) + "/" + Common.replaceNullWithString(props.Resident.unit),
                evaluatedBy: Common.replaceNullWithString(props.user.firstName) + " " + Common.replaceNullWithString(props.user.lastName),
                physicanName: Common.replaceNullWithString(props.Resident.primaryPhysicianName),
                createdBy: props.user.userID,
                btPatientBrightreeID: props.Resident.btPatientBrightreeID,
                modifiedBy: props.user.userID,
                wound:
                {
                    id: 0,
                    //evaluationDate: null,
                    // dateFirstObserved: null,
                    fkAcquisitionId: null,
                    fkWoundLocationId: null,
                    woundLocation: "",
                    fkWoundTypeId: null,
                    woundDescription: '',
                    fkWoundThicknessId: null,
                    fkWoundSevereTissueTypeId: null,
                    fkWoundLateralityId: null,
                    woundLength: '',
                    woundWidth: '',
                    woundDepth: '',
                    isUtd:false,
                    tunneling: false,
                    tunnelingDepth: null,
                    tunnelingClockPosition: '',
                    undermining: false,
                    underminingDepth: null,
                    underminingClockPosition: null,
                    toUnderminingClockPosition:null,
                    icdcode: "",
                    fkExudateAmountId: null,
                    fkExudateTypeId: null,
                    exudateColor: '',
                    fkDebridementTypeId: null,
                    debridementDate:null,
                    surgicalDate:null,
                    odor: false,
                    Pain: false,
                    fkBodyRegionId: null,
                    painMedicationOrdered: false,
                    sloughPercentage: null,
                    necroticPercentage: null,
                    //FkWoundCleanseId :'',
                    cleanseDescription: null,
                    fkDressingChangeFrequencyId: f8,
                    //fkPrimaryDressingId :null,
                    //FkAdditionalPrimaryDressingId :'',
                    otherAdditionalPrimaryDressing: '',
                    //fkSecondaryDressingId :null,
                    //FkAdditionalSecondaryDressingId :'',
                    otherAdditionalSecondaryDressing: '',
                    treatment: '',
                    comments: '',
                    woundDetailId: 0,
                    thicknessIdForBackStagging: null,
                    // FkWoundcareProductTypeId :'',
                    TreatmentDescription: null,
                    generalComment: null,
                    fkWoundEdgesId: null,
                    fkWoundBedDescriptionId: null,
                    fkWoundSurroundingSkinId: null,
                    fkWoundSkinAndUlcerTreatmentId: null,
                orientationLeftRight:null,
                orientationUpperLower:null,
                orientationInnerOuter:null,
                orientationAnteriorPosterior:null,
                orientationMedialLateral:null,
                orientationDorsalDistalProximal:null
                }
            }


        };

    }
    componentDidMount() {
        this.GetResidentWound()
    }

    //#region  add person


    handleCloseAdd = () => {
        this.setState({ showModalAddWound: false })
        this.GetResidentWound();

    };

    handleShowAddWound = () => {

        this.setState({
            NewWoundComponent: <NewWound isEdit={false} user={this.props.user} resident={this.state.Resident} close={this.handleCloseAdd} />,
            showModalAddWound: true
        })
        // this.setState({showModalAddWound:true})

    };
    //#region loop to  print the physician firm list

    GetResidentWound = () => {
        // // debugger
        Api.getRequestById(Api.URI_GetResidentWoundByResidentID, this.props.Resident.id).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setResidentWoundList(res.data.result);
                    }
                }
            }

            Common.LoadDataTable("woundList")
        }).catch((ex) => {
           // Common.LogError(error, "Wound", "GetResidentWound");
        });
    }
    edtitResidentWound = (obj) => {
        // // debugger
        var test = {};
        test.residentName = obj.residentName;

        this.setState(
            {
                //Resident:test,
                NewWoundComponent: <NewWound isEdit={true} user={this.props.user} resident={obj} close={this.handleCloseAdd} />,
                showModalAddWound: true
            })
    }
    
    showResidentWoundHistory = (obj) => {
        // debugger
        obj.wound.woundDetailId = 0;
        this.setState(
            {
                Resident: obj,

            })
        ICDReportModal.showModal()
    }

    setResidentWoundList = (list) => {

        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {

                const obj = list[i];
                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.residentWoundLocation)}</td>
                    <td>{Common.replaceNullWithString(obj.woundType) ? obj.woundType : "N/A"}</td>
                    <td>{Common.replaceNullWithString(obj.wound.icdcode) != '' ? obj.wound.icdcode : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.orderInfo) != '' ? obj.orderInfo : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.wound.evaluationDate) != '' ? new Date(obj.wound.evaluationDate).toDateString() : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.evaluatedBy) != '' ? obj.evaluatedBy : 'N/A'}</td>

                    <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey="2" onClick={() => { this.edtitResidentWound(obj) }}>Edit Wound</Dropdown.Item>
                                <Dropdown.Item eventKey="4" onClick={() => { this.showResidentWoundHistory(obj) }}>Wound Assessment</Dropdown.Item>
                                <Dropdown.Item eventKey="5" onClick={() => WoundNoteModal.showModal()}>Notes</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </td>
                </tr>);
            }
            this.setState({ woundList: rows });

        } catch (error) {
            Common.LogError(error, "Wound", "setResidentWoundList");

        }
    }

    //#endregion



    render() {
        return (


            <>
                {this.state.showModalAddWound && <Animated animationIn="fadeInLeft" animationInDuration={1000}>

                    {this.state.NewWoundComponent}

                </Animated>}

                {!this.state.showModalAddWound && <Animated animationIn="fadeIn" animationInDuration={1000}>

                    <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                        <div className="d-flex align-items-center">
                            <div className="alert-icon">
                                <i className="fas fa-info-circle mr-4 text-primary"></i>
                            </div>
                            <div className="flex-1">
                                <span className="h5 m-0 fw-700">Use buttons to do more actions </span>
                            </div>
                            <Button variant="primary" className="mr-5" size="sm" onClick={this.handleShowAddWound}>Add New Wound</Button>
                        </div>
                    </div>
                    <table className="table table-striped table-hover table-sm table-bordered" id="woundList">
                        <thead>
                            <tr>
                                <th>Location Name</th>
                                <th>Wound Type</th>
                                <th>ICD10 Code </th>
                                <th>Order Info</th>
                                <th>Evaluated on</th>
                                <th>Evaluated By</th>
                                <th width="130px">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.woundList}
                        </tbody>
                    </table>


                </Animated>}
                {/** Wound Location Modal */}
                <PopupModal title="Wound Information" dialogClassName="modal-90w" showButton={false} ref={ref => { WoundLocationModal = ref; }}> <WoundLocation /> </PopupModal>

                {/** Wound Note Modal */}
                <PopupModal title="Add Wound Note" size="lg" showButton={true} ref={ref => { WoundNoteModal = ref; }}> <WoundNote /> </PopupModal>

                {/** Wound Detail Modal */}
                <PopupModal
                    title={<div className="card-title">
                        <span>
                            <h3 className="card-label">Resident Wound Documentation</h3>
                            <small className="">
                                <b>Resident:</b>{" "} {this.state.Resident.residentName},{" "}
                                <b>Facility:</b> {this.state.Resident.facilityName},{" "}
                                <b>Chain:</b>{" "} {this.state.Resident.chain},{" "}
                                <b>Group:</b>{" "} {this.state.Resident.organizationName},{" "}

                            </small>
                        </span>
                    </div>}
                    showButton={false} ref={ref => { ICDReportModal = ref; }}> <ICDReport user={this.props.user} residentWound={this.state.Resident} /> </PopupModal>
            </>
        );
    }
}
/*eslint-disable */
import React from "react";
import { Switch } from "react-router-dom";
import { ContentRoute } from "../../../_metronic/layout";
import RoutePaths from "../../constants/routepaths";
import facilityCoverReport from "./FacilityCoverReport";
import salesOrderReports from "./salesOrderReport";

export default function ReportNav() {
  // debugger;
  return (
    <>
      <Switch>
      
        <ContentRoute
          from={RoutePaths.Page_FacilityCoverReport}
          component={facilityCoverReport}
        />
        <ContentRoute
          from={RoutePaths.Page_SalesOrderReports}
          component={salesOrderReports}
        />
      </Switch>
    </>
  );
}

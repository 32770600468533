import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import { ModulesName } from "@/app/General/roleModuleActions";
import { injectIntl } from "react-intl";
import GenerateMenuItem from "./GenerateMenuItem";
import menuConfig from "@/shared/components/header/menuConfig";

function HeaderMenu(props) {
  const { intl } = props;

  const layoutProps = props.layoutProps;

  const location = useLocation();

  const menuList = menuConfig.reduce(
    (acc, { module, route, messageId, submenuItems, isExternal }) => {
      acc[module] = GenerateMenuItem(
        location,
        layoutProps,
        module,
        route,
        messageId,
        submenuItems,
        isExternal,
        intl
      );
      return acc;
    },
    {}
  );

  const getUserModules = () => {
    let menus = [];
    const userRoles = props.user.roles.moduleList;
    const excludeModuleList = [
      ModulesName.Module_Physician,
      ModulesName.Module_Products,
      ModulesName.Module_TabletData,
    ];
    userRoles.map((module) => {
      let menu = menuList[module.moduleName];
      if (!excludeModuleList.includes(module.moduleName)) {
        if (menu) {
          menus.push(menu);
        }
      }
    });
    return menus;
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {getUserModules()}
      </ul>
    </div>
  );
}

export default injectIntl(
  connect((state) => ({ user: state.auth.user }))(HeaderMenu)
);

import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";
import ssnWithoutSpecialCharBR from "./ssnWithoutSpecialCharBR.fn";
import ssnWithoutSpecialCharUS from "./ssnWithoutSpecialCharUS.fn";

const ssnWithoutSpecialCharCountry = {
  BR: ssnWithoutSpecialCharBR,
  US: ssnWithoutSpecialCharUS,
};

export default function ssnWithoutSpecialChar(ssn) {
  return withMultiRegionFunction(ssnWithoutSpecialCharCountry)(ssn);
}

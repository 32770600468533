import * as React from "react";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import * as Api from "../../General/api";
import * as Common from "../../General/common";




class ResidentStatus extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        isLoading:false,
        residentStatusList:[],
        resident:props.resident
    }
}
   


componentDidMount() {
    this.getResidentStatus();
}

  getResidentStatus = () => {
    debugger
        Api.getRequestById(Api.Uri_GetLookupValuesByFkId, Common.LookupsFK.Fk_ResidentStatusID)
            .then((res) => {
                debugger
                if (res.data.statusCode == 200) {
                    if (res.data.result != null) {
                        if (res.data.result.length > 0) {
                            this.setState({residentStatusList:res.data.result});
                        }
                    }
                }
            })
            .catch((ex) => {

                Common.LogError(ex, "Resident", "getDropDownValueApiCall");

            });
    }
    //  onChangeResidentStatus = async(e) => {
    //     debugger
    //     var resident=this.state.resident;
    //     resident.fkResidentStatusId=e.target.value;
    //    await this.setState({resident:resident})
    // }

     setOptionsUI = (options) => {
        return options.map((option) => (
            <option key={option.key} value={option.key}>
                {option.value}
            </option>
        ));
    }
    // const handleResidentActiveInActive =(obj)   => {
    //     if(obj.fkResidentStatusId==Common.ResidentStatusID.open)
    //     {
    //       obj.fkResidentStatusId=Common.ResidentStatusID.closed;
    //     }else{
    //       obj.fkResidentStatusId=Common.ResidentStatusID.open;
    
    //     }
    //    // obj.active=obj.active==true?false:true;
    //     updateResidentActiveInActive(obj);
    //   };
    onSubmit = () => {        
        var msg=`${this.props.intl?.formatMessage({id: "FACILITY_RESIDENT_ALERT.YOU_WANT"})}` + (this.state.resident.fkResidentStatusId==Common.ResidentStatusID.open?`${this.props.intl?.formatMessage({id: "CONFIRMATION.OPEN"})}`:`${this.props.intl?.formatMessage({id: "CONFIRMATION.CHANGE"})}` );
        Common.showConfirmation(msg,this.props.intl?.formatMessage({id: "NEW_WOUND_ASSESSMENT.ARE_YOU_SURE_TITLE"}),this.updateActiveInActive,null,this.state.resident)      
      };
       updateActiveInActive=(obj)=>{
        if(obj){
          Api.postRequest(Api.apiUrls.updateResidentStatus, obj).then((res) => {
            if(res.data.success){
            //   getAllResident();
            this.props.close();
              Common.showSuccessAlertAutoClose(this.props.intl?.formatMessage({id: "SUCCESS.RESIDENT_STATUS_UPDATED"}), "success",this.props.intl?.formatMessage({id: "SUCCESS.TITLE"}), 3000)
            }else{
              Common.showSuccessAlert(res.data.message, "warning", "Warning!", 3000);
            }
          });
        }
      }
      render() {
        return (
        <>
            <div className="row">
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                            {this.props.intl?.formatMessage({id: "FACILITY_RESIDENT.RESIDENT_STATUS"})}
                        </label>
                        <select
                        className="form-control form-control-sm"
                        id="fkResidentStatusId"
                        name="fkResidentStatusId"
                        required
                        value={Common.replaceNullWithString(
                          this.state.resident.fkResidentStatusId
                        )}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.resident,
                            e,
                            this,
                            this.state
                          )
                        }
                      >
                            {this.setOptionsUI(this.state.residentStatusList)}
                        </select>
                    
                    </div>

                </div>
            </div>


        </>
    )

}
 }
export default ResidentStatus;
// src/appInsightsSetup.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import {createBrowserHistory} from "history"
import {} from "react-router-dom"

const instrumentationKey = process.env.REACT_APP_INSIGHTS_INSTRUMENTATIONKEY;


const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin({ history: browserHistory });

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey:instrumentationKey, // Use environment variable for the instrumentation key
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory }
    },
    enableAjaxErrorStatusText: true,
    enableAutoRouteTracking: true,
    distributedTracingMode: 2,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    enableCorsCorrelation: true,
  }
});

appInsights.loadAppInsights();

export { reactPlugin, appInsights };
import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import RoutePaths from "./constants/routepaths";
import { default as Resident } from "./pages//resident/resident";
import BillingQueueOrders from "./pages/billing/billingQueueOrders";
import SetupBrightree from "./pages/Brightree/SetupBrightree";
import { BuilderPage } from "./pages/builderpage";
import { DashboardPage } from "./pages/dashboardpage";
import EHRVendors from "./pages/ehrvendors/ehrvendors";
import { default as Facility } from "./pages/facility/facility";
import { default as FacilityQueue } from "./pages/facilityQueue/facilityQueue";
import PostVisitEmail from "./pages/facility/postVisitEmail/postVisitEmail";

import { MyPage } from "./pages/mypage";
import Physician from "./pages/physician/physician";
import SetupNav from "./pages/Setups/setupNav";
import ReportNav from "./pages/reports/reportsNav";
import AssessmentReportNav from "./pages/reports/assessmentReportsNav";

// import WorkQueue from './pages/workqueue/workqueue';
import WorkQueueWoundOrders from "./pages/workqueue/workQueueWoundOrders";
import { useSelector } from "react-redux";
import getCurrentPageByUserRoles from "@/shared/functions/router/getCurrentPageByUserRoles";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);

const ProductNavPage = lazy(() => import("./pages/products/productnav"));

const OrdersPage = lazy(() => import("./pages/orders/orderspage"));

const RepFirmNavPage = lazy(() => import("./pages/repfirm/repfirmnav"));

const TabletDataListPage = lazy(() => import("./pages/tabletdata/tabletlist"));

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Route
          exact
          path="/"
          render={() => <Redirect exact to={getCurrentPageByUserRoles(user)} />}
        />
        <ContentRoute
          path={RoutePaths.Page_Dashboard}
          component={DashboardPage}
        />
        <ContentRoute path={RoutePaths.Page_Builder} component={BuilderPage} />
        <ContentRoute path={RoutePaths.Page_MyPage} component={MyPage} />
        <ContentRoute
          path={RoutePaths.Page_WorkQueue}
          component={WorkQueueWoundOrders}
        />
        <ContentRoute
          path={RoutePaths.Page_Billing}
          component={BillingQueueOrders}
        />
        <ContentRoute path={RoutePaths.Page_Facility} component={Facility} />
        <ContentRoute
          path={RoutePaths.Page_FacilityQueue}
          component={FacilityQueue}
        />

        <ContentRoute path={RoutePaths.Page_Resident} component={Resident} />
        <ContentRoute path={RoutePaths.Page_Physician} component={Physician} />
        <ContentRoute
          path={RoutePaths.Page_ProductsNav}
          component={ProductNavPage}
        />
        <ContentRoute
          path={RoutePaths.Page_RepFirmNav}
          component={RepFirmNavPage}
        />
        <ContentRoute
          path={RoutePaths.Page_TabletData}
          component={TabletDataListPage}
        />
        <ContentRoute
          path={RoutePaths.Page_EHRVendors}
          component={EHRVendors}
        />
        <ContentRoute path={RoutePaths.Page_SetupNav} component={SetupNav} />
        <ContentRoute path={RoutePaths.Page_ReportNav} component={ReportNav} />
        <ContentRoute
          path={RoutePaths.Page_PostVisitEmail}
          component={PostVisitEmail}
        />
        <ContentRoute
          path={RoutePaths.Page_AssessmentReportNav}
          component={AssessmentReportNav}
        />

        <ContentRoute
          path={RoutePaths.Page_SetupBrightree}
          component={SetupBrightree}
        />
        <Route path={RoutePaths.Page_OrderNav} component={OrdersPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/e-commerce" component={ECommercePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}

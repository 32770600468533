import React from "react";
import {
    Button,
    Modal,
} from "react-bootstrap";
export default class GenericModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            show: this.props.show
        };

        this.handleShow = () => {
            this.props.onPress();
            //this.setState({ show: true });
        };

        this.handleHide = () => {
            this.props.onPress();
            //this.setState({ show: false });
        };
    }

    render() {
        return (
            <>
                <Modal
                    size="lg"
                    show={this.props.show}
                    onHide={this.handleHide}
                    aria-labelledby="example-custom-modal-styling-title"
                >
                    <Modal.Header className="py-3" closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            About
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            <h5>Welcome to Gentell Fastcare</h5> The revolutionary system for delivering wound care expertise and products 24/7
                        </p>
                        <p>
                            With a Gentell Fastcare tablet computer, you can document wounds at the bed side,
                            send the information to a Gentell Certified Wound Care Specialist, and receive a recommended course of treatment
                            within an hour. Fastcare requires very little data entry because the tablet's camera takes a high-resolution
                            image of the patient's facesheet for the billing department.
                        </p>
                        <p>
                            Best of all, your patients will get better treatment than ever before because you won't have to wait days or weeks
                            for a visit from a wound specialist. Gentell specialists are available whenever you need them, and you can even
                            send messages and questions about specific wounds. Your comments and questions are attached to the patient record
                            for easy reference.
                        </p>
                        <p>
                            Fastcare provides timely wound care expertise and enables you to order wound care products as you need them.
                            Fastcare tablets and the Fastcare service is available to qualified Gentell customers at no additional charge.
                            For more information, please contact your Gentell Representative.
                        </p>
                        <h5>Fastcare Support</h5>
                        <p>
                            Please refer to the Help button for more information about how to use Fastcare.
                        </p>
                        <p>
                            For support, please contact Gentell or your Gentell Sales Representative.
                        </p>
                        <div class="alert border border-success bg-custom text-dark mb-0" role="alert">
                            <h6>Gentell</h6>
                            <p>
                                1000 Floral Vale Blvd.<br></br>
                                Suite 400<br></br>
                                Yardley, PA 19067
                            </p>
                            <p className="mb-0">
                                215-788-2700<br />
                                <a href="https://www.gentell.com" rel="noopener noreferrer" target="_blank">www.gentell.com</a>
                            </p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="py-1">
                        <Button variant="danger" size="sm" onClick={this.handleHide}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

import React from "react";

export function Qapireport () {

        return (
            <form>
                <div className="row">

                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="OrganizationType">Report Type</label>
                            <select className="form-control form-control-sm" id="OrganizationType">
                                <option value=''>Select ---</option>
                                <option>months by week</option>
                                <option>quarter by months</option>
                                <option>year by quarter</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="EndMonthforReport">End Month for Report (1-12)</label>
                            <input type="text" id="EndMonthforReport" className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Year">Year (20YY)</label>
                            <input type="text" id="Year" className="form-control form-control-sm" />
                        </div>
                    </div>
                    
                </div>
            </form>
        );
}

export default Qapireport;
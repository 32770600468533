import React from "react";

export default class NewEHRVendor extends React.Component {

    // constructor(props) {
    //     super(props);

    // }

    render() {

        return (
            <form>
                <div className="row  ">


                    <div className="col-md-6 col-sm-12 mx-auto">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Name">Name</label>
                            <input type="text" id="Name" className="form-control form-control-sm" />
                        </div>

                        <div className="form-group">
                            <label className="form-label" htmlFor="fullName">Full Name</label>
                            <input type="text" id="fullName" className="form-control form-control-sm" />
                        </div>
                    </div>



                </div>
            </form >
        )
    }
    
    onFormSave() {
        
    }

}// end of class
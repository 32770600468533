import { useEffect, useState } from 'react';

export function useUnloadPrompt(handleSave, handleCloseModal, enablePrompt) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true); // Component is mounted

    const handleBeforeUnload = (event) => {
      if (enablePrompt && !isModalOpen && isMounted) {
        const message = 'You have unsaved changes. Do you want to leave?';
        event.preventDefault();
        event.returnValue = message;
        setIsModalOpen(true);
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      setIsMounted(false); // Component is unmounted
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [enablePrompt, isModalOpen, isMounted]);

  const handleSaveAndCloseModal = () => {
    if (isMounted) {
      handleSave();
      setIsModalOpen(false);
    }
  };

  const confirmAndCloseModal = () => {
    if (isMounted) {
      handleCloseModal();
      setIsModalOpen(false);
    }
  };

  return {
    isModalOpen,
    handleSaveAndCloseModal,
    confirmAndCloseModal,
  };
}
import React from "react";
import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";
import SubHeaderTimerUS from "./lang/subHeaderTimerUS";
import SubHeaderTimerUK from "./lang/subHeaderTimerUK";
import SubHeaderTimerBR from "./lang/subHeaderTimerBR";

const SubHeaderTimerMultiRegion = withMultiRegionComponent({
  US: SubHeaderTimerUS,
  UK: SubHeaderTimerUK,
  BR: SubHeaderTimerBR,
});

function SubHeaderTimerWrapper(props) {
  return <SubHeaderTimerMultiRegion {...props} />;
}

export default SubHeaderTimerWrapper;

import { faIR } from "date-fns/locale";
import * as React from "react";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

import { FormattedMessage, injectIntl } from "react-intl";

//export default function CloseWoundComponent(props) {
export function CloseWoundComponent(props) {

  const { intl } = props;

  const [orderListUI, setOrderListUI] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    getAllResidentCloseWound();

  }, []);
  const getAllResidentCloseWound = () => {
    debugger
    // Api.getRequestQry(Api.Uri_GetFacilityResidentWoundOrder, "?facilityID=" + props.facility.id + "&isApproved=" + 2)
    Api.getRequestQry(Api.URI_GetResidentWoundByResidentID, "?id=" + props.resident.id + "&woundId=" + 0 + "&isOpen=" + 0+ "&isCycleCompleted=" + 2)
      .then((res) => {
        if (res.data.statusCode == 200) {
          // setOrderListUI([]);
          setOrderListUI([]);
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setFacilityList(res.data.result);
            }
          }
        }
      })
      .catch((ex) => {
        Common.LogError(ex, "Workqueue", "getAllFacility");


      })
      .then(() => {
        setIsLoading(false);

      });
  };
  const handleResidentWoundStatus = (obj) => {
    let tempObj = JSON.parse(JSON.stringify(obj));
    tempObj.wound.isOpen = tempObj.wound.isOpen == true ? false : true;
    updateResidentWoundStatus(tempObj);
  };
  const updateResidentWoundStatus = (obj) => {
    var msg = "Você gostaria de " + (obj.wound.isOpen ? "Abrir" : "Fechar") + " Ferida.";
    Common.showConfirmation(msg, "", updateWoundStatus, null, obj)

  };
  const updateWoundStatus = (obj) => {
    if (obj) {
      setIsLoading(true)
      Api.postRequest(Api.apiUrls.updateResidentWoundStatus, obj).then((res) => {
        if (res.data.success) {
          props.close();
          getAllResidentCloseWound();
          Common.showSuccessAlertAutoClose("Status da Ferida Atualizado com Sucesso", "success", "Successo!", 3000)
          setIsLoading(false)
        } else {
          Common.showSuccessAlert(res.data.message, "error", "Erro!", 3000);
          setIsLoading(false)
        }
      });
    }
  }
  const setFacilityList = (list) => {

    var rows = [];
    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      debugger
      var dateFirstObserved = new Date(obj.dateFirstObserved);
      rows.push(
        <React.Fragment key={i}>
          <tr>
          <th>{ intl.formatMessage({ id: "CLOSE_WOUNDS.DATA_TABLE.NUM" }) }</th>
          <th>{ intl.formatMessage({ id: "CLOSE_WOUNDS.DATA_TABLE.BODY_REGION_WOUND_LOCATION" }) }</th>
            <th>{obj.wound.fkWoundTypeId == Common.WoundType.pressure ? intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.STAGE_THICKNESS" })
              : obj.wound.fkWoundTypeId == Common.WoundType.Surgical || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.STAGE_THICKNESS" }) : intl.formatMessage({ id: "WORKQUEUEWOUNDS.DATA_TABLE.THICKNESS_MOST_SEVER_TISSUE" }) }</th>

            <th>{ intl.formatMessage({ id: "CLOSE_WOUNDS.DATA_TABLE.ICD10_CODE" }) }</th>
            <th>{ intl.formatMessage({ id: "CLOSE_WOUNDS.DATA_TABLE.WOUND_SIZE" }) }</th>
            <th>{ intl.formatMessage({ id: "CLOSE_WOUNDS.DATA_TABLE.EVALUATED_ON" }) }</th>
            <th>{ intl.formatMessage({ id: "CLOSE_WOUNDS.DATA_TABLE.EVALUATED_BY" }) }</th>
            <th>{ intl.formatMessage({ id: "DATA_TABLE.ACTIONS" }) }</th>
          </tr>
          <tr >
            <td className="justify-content-center"> <b>{i + 1}</b> </td>
            <td>
              {obj.wound.orientationLeftRightName == null || obj.wound.orientationLeftRightName == "" ? "" : obj.wound.orientationLeftRightName + " - "}
              {Common.replaceNullWithString(obj.wound.bodyRegion)}{obj.residentWoundLocation != null ? "/" : ""}{Common.replaceNullWithString(obj.residentWoundLocation)}
              {obj.wound.orientation == null || obj.wound.orientation == "" ? "" : " - " + obj.wound.orientation}
            </td>
            <td>
              {Common.replaceNullWithString(obj.wound.woundThicknessName)}
              {obj.wound.fkWoundTypeId == Common.WoundType.pressure ||
                obj.wound.fkWoundTypeId == Common.WoundType.Surgical
                || obj.wound.fkWoundTypeId == Common.WoundType.tubeSize ? ' '
                : obj.wound.woundThicknessName != null && obj.wound.woundSevereTissueName != null ?
                  " - "
                  + (Common.replaceNullWithString(obj.wound.woundSevereTissueName))
                  : " "}

            </td>
            <td>{Common.replaceNullWithString(obj.wound.icdcode) != '' ? obj.wound.icdcode : 'N/A'}</td>
            <td >  {obj.wound.woundLength + " x " + obj.wound.woundWidth + " x " + obj.wound.woundDepth +" " +(obj.wound.isUtd == true?"U":"")}</td>
            <td>{Common.replaceNullWithString(obj.wound.evaluationDate) != '' ? Common.getInputStringDate(new Date(obj.wound.evaluationDate)) : 'N/A'}</td>
            <td>{Common.replaceNullWithString(obj.evaluatedBy) != '' ? obj.evaluatedBy : 'N/A'}</td>
            <td align="center">
              <>
                <ButtonToolbar className="justify-content-center">
                  <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                    {[DropdownButton].map((DropdownType, idx) => (
                      <DropdownType
                        size="xs"
                        variant="primary"
                        title={ intl.formatMessage({ id: "DATA_TABLE.ACTIONS" }) }
                        id={`dropdown-button-drop-${idx}`}
                      >

                        <Dropdown.Item eventKey="8" className="text-danger" onClick={() => handleResidentWoundStatus(obj)}>{obj.wound.isOpen ? intl.formatMessage({ id: 'CLOSE_WOUNDS.CLOSE_WOUND' }) : intl.formatMessage({ id: 'CLOSE_WOUNDS.OPEN_WOUND' }) }</Dropdown.Item>


                      </DropdownType>
                    ))}
                  </div>
                </ButtonToolbar>
              </>
            </td>


          </tr>
        </React.Fragment>
      );
    }
    setOrderListUI(rows);
    Common.LoadDataTable("facilityTable");
  };



  return (
    <>

      <Animated animationIn="fadeIn" animationInDuration={1000}>
        <Card className="example example-compact">
          {/* <CardHeader  >
  
              <div className="card-title ">
                <span>
                  <h3 className="card-label">Resident Wounds</h3>
                </span>
              </div>
 
        </CardHeader> */}
          <CardBody>

            {isLoading ? (
              <div className="row d-flex justify-content-center">
                <span className="mt-5 spinner spinner-primary"></span>
              </div>
            ) : (
              <table
                className="table table-striped table-hover table-sm table-bordered"
                id="facilityTable"
              >
                {/* <thead>
                <tr>
                <th>Wound#</th>
                            <th>Body Region/Wound Location</th>
                            <th>Wound Type/Stage/Thickness</th>
                            <th>ICD10 Code</th>
                            <th>Wound Size</th>
                            <th>Evaluated on</th>
                            <th>Evaluated By</th>
                            <th>Actions</th>
                </tr>
              </thead> */}
                <tbody>{orderListUI}</tbody>
              </table>
            )}

          </CardBody>
        </Card>
      </Animated>
    </>
  )

}
export const CloseWound = injectIntl(CloseWoundComponent);
export default CloseWound;


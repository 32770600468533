import { axiosInstance } from "../../app/General/api";
import { ApiBaseUrl } from "../../app/constants/endpoints";

export default class AxiosApi {
  _axiosInstance;

  constructor() {
    this._axiosInstance = axiosInstance;
    this._axiosInstance.defaults.baseURL = ApiBaseUrl;
  }

  /**
   * Get request
   * @param {string} uri
   * @param {object} params
   * @param {object} query
   * @template T
   * @returns {Promise<T>}
   */
  async get(uri, params, query) {
    const response = await this._axiosInstance.get(uri, { params, query });

    return response.data;
  }

  /**
   * Post request
   * @param {string} uri
   * @param {object} data
   * @template T
   * @returns {Promise<T>}
   */
  async create(uri, data) {
    const response = await this._axiosInstance.post(uri, data);

    return response.data;
  }  
}

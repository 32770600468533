/*eslint-disable */

import React from "react";
import { Animated } from "react-animated-css";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import NewICDCode from './upsertICDCode';
import $ from "jquery";
import {
    Card,
    CardBody,
    CardHeader
  } from "../../../../_metronic/_partials/controls/Card";
var ICDReportModal = null;
var WoundLocationModal = null;
var WoundNoteModal = null;
export default class ICDList extends React.Component {
    constructor(props) {
        super(props);
        // // debugger;
        this.state = {
            showModalAddWound: false,
            NewWoundComponent: '',
            woundList: [],

            woundICD:
            {
                woundToIcdid: 0,
                fkWoundTypeId: null,
                fkWoundLocationId: null,
                fkNonPrUthicknessId:null,
                fkThicknessStageId: null,
                fkWoundLateralityId: null,
                iCDCode: "",
                isSlough: false,
                isNecrotic: false,
                isPressure :false,
                description :'',
                fkBodyRegionId:null,
                orientationLeftRight:null,
                orientationUpperLower:null,
                orientationInnerOuter:null,
                orientationAnteriorPosterior:null,
                orientationMedialLateral:null,
                orientationDorsalDistalProximal:null,
                fkWoundSevereTissueTypeId:null
            }



        };

    }
    componentDidMount() {
        this.GetICDCodes()
    }

    //#region  add person


    handleCloseAdd = () => {
        this.setState({ showModalAddWound: false })
        this.GetICDCodes();

    };

    handleShowAddWound = () => {

        this.setState({
            NewWoundComponent: <NewICDCode icd={this.state.woundICD} close={this.handleCloseAdd} />,
            showModalAddWound: true
        })
        // this.setState({showModalAddWound:true})

    };



    //#region loop to  print the physician firm list

    GetICDCodes = () => {
        // // debugger
        Api.getRequest(Api.URI_GetAllICDCode).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setICDCodeList(res.data.result);
                    }
                }
            }

            // Common.LoadDataTable("woundList")
        }).catch((ex) => {
        Common.LogError(ex, "ICD", "GETICDCode");
        });
    }
    edtitResidentWound = (obj) => {
       
        // var test = {};
        // test.residentName = obj.residentName;

        this.setState(
            {
                //Resident:test,
                NewWoundComponent: <NewICDCode icd={obj} close={this.handleCloseAdd} />,
                showModalAddWound: true
            })
    }
    showResidentWoundHistory = (obj) => {
        // debugger
        obj.wound.woundDetailId = 0;
        this.setState(
            {
                Resident: obj,

            })
        ICDReportModal.showModal()
    }
    setICDCodeList = (list) => {

        let rows = [];
        try {
            for (var i = 0; i < list.length; i++) {

                const obj = list[i];
                rows.push(<tr key={i.toString()}>
                    <td>{Common.replaceNullWithString(obj.woundLocationName)}</td>
                    <td>{Common.replaceNullWithString(obj.woundThicknessName) ? obj.woundThicknessName : "N/A"}</td>
                    <td>{Common.replaceNullWithString(obj.woundBodyRegionName) != '' ? obj.woundBodyRegionName : 'N/A'}</td>
                    <td>{Common.replaceNullWithString(obj.woundLocationName) != '' ? obj.woundLocationName : 'N/A'}</td>

                    <td>{Common.replaceNullWithString(obj.icdCode) != '' ? obj.icdCode : 'N/A'}</td>

                    <td align="center">
                        <div className="text-center">

                            <DropdownButton
                                drop={"down"}
                                size="xs"
                                variant="primary"
                                title={'Actions'}
                                id={`dropdown-button-drop-actions`}
                                key={'down'}>

                                {/* <Dropdown.Item eventKey={"1" + i.toString()} onClick={() => handleShowAdd(obj)}>Edit</Dropdown.Item> */}
                                <Dropdown.Item eventKey="2" onClick={() => { this.edtitResidentWound(obj) }}>Edit ICD Code</Dropdown.Item>
                            </DropdownButton>
                        </div>
                    </td>
                </tr>);
            }
            this.setState({ woundList: rows });
            $("#woundList").DataTable({
                "order": [],
                responsive: true,
                pageLength: 50,
                destroy: true,
                columnDefs: [
                    { responsivePriority: 1, targets: 0 }
                ],
                dom: "<'row mb-3'<'col-sm-12 col-md-6 d-flex align-items-center justify-content-start'f><'col-sm-12 col-md-6 d-flex align-items-center justify-content-end'B>>" +
                    "<'row'<'col-sm-12'tr>>" +
                    "<'row'<'col-sm-12 col-md-5'i><'col-sm-12 col-md-7'p>>",
                "buttons": [
                    {
                        extend: 'csvHtml5',
                        text: 'CSV',
                        titleAttr: 'Generate CSV',
                        className: 'btn btn-sm btn-primary mr-2'
                    },
                    {
                        extend: 'copyHtml5',
                        text: 'Copy',
                        titleAttr: 'Copy to clipboard',
                        className: 'btn btn-sm btn-light'
                    }
                ]
            });

        } catch (error) {
        Common.LogError(error, "ICD", "setICDCodeList");
        }
    }

    //#endregion



    render() {
        return (
            <>
             <Card className="example example-compact">
          <CardHeader title="ICD Sheet" />
          <CardBody>
                {this.state.showModalAddWound && <Animated animationIn="fadeInLeft" animationInDuration={1000}>

                    {this.state.NewWoundComponent}

                </Animated>}

                {!this.state.showModalAddWound && <Animated animationIn="fadeIn" animationInDuration={1000}>

                    <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                        <div className="d-flex align-items-center">
                            <div className="alert-icon">
                                <i className="fas fa-info-circle mr-4 text-primary"></i>
                            </div>
                            <div className="flex-1">
                                <span className="h5 m-0 fw-700">Use buttons to do more actions </span>
                            </div>
                            <Button variant="primary" className="mr-5" size="sm" onClick={this.handleShowAddWound}>Add New ICD Code</Button>

                        </div>
                    </div>
                    <table className="table table-striped table-hover table-sm table-bordered" id="woundList">
                        <thead>
                            <tr>
                                <th>Wound Location</th>
                                <th>Wound Thickness</th>
                                <th>Body Region </th>
                                <th>Wound Location </th>
                                <th>ICD Code</th>
                                <th width="130px">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.woundList}
                        </tbody>
                    </table>


                </Animated>}
                {/** Wound Location Modal */}
              

                </CardBody>
        </Card>
            </>
        );
    }
}



/*eslint-disable */
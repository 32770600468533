//#region Imports
import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "../../../../_metronic/_partials/controls/Card";
import { apiUrls } from '../../../constants/endpoints';
import * as API from '../../../General/api';
import * as common from "../../../General/common";
import { ModulesName, UserActions } from "../../../General/roleModuleActions";
import PopupModal from "../../modal/popupmodal";
import AddVacation from './AddVacation';
//#endregion

var addVacationModal = null;
var addVacation = null;

export default function VacationList() {
    const toolbar = (
        <div className="card-toolbar">
            <div className="example-tools">
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => {
                        setSelectedVacation(null);
                        addVacationModal.showModal();
                    }}
                >
                    Add Vacation</Button>
            </div>
        </div>
    );

    //#region STATE VARS 
    const [st_vacations, setVacations] = useState([]);
    const [st_rows, setRows] = useState([]);
    const [st_selectedVacation, setSelectedVacation] = useState(null);
    const [st_isLoading, setisLoading] = useState(false);
    //#endregion

    //#region USE EFFECTS
    useEffect(() => {
        getVacationsFromAPI();
    }, []);
    //#endregion

    //#region FUNCTIONS

    const editHandler = (vacation) => {
        setSelectedVacation(vacation);
        addVacationModal.showModal();
    }

    const deleteHandler = (vacation) => {
        setisLoading(true);
        let obj = {
            id: vacation.id,
            active: false
        };
        try {
            API.postRequest(apiUrls.upsertvacation, obj, false).then((raw) => {
                const response = raw.data;
                if (response.success) {
                    common.showSuccessAlert(response.message, "success", "Success!!");
                    getVacationsFromAPI();
                } else {
                    common.showSuccessAlert(response.message, "error", "Error!!");
                    setisLoading(false);
                }
            })
        } catch (ex) {
            
            setisLoading(false);
            common.LogError(ex, "Vacation", "delete");

        }
    }

    const setUIList = (list) => {
        // debugger
        let temp = [];
        list.forEach(vacation => {
            temp.push(<tr key={vacation.id}>
                <td>{common.GetDateString(vacation.fromDate)}</td>
                <td>{common.GetDateString(vacation.toDate)}</td>
                <td align="center">
                    <div className="text-center">
                        <DropdownButton
                            drop={"down"}
                            size="xs"
                            variant="primary"
                            title={'Actions'}
                            id={`dropdown-button-drop-actions`}
                            key={'down'}>
                            <Dropdown.Item eventKey="1" onClick={() => { editHandler(vacation) }}>Edit</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => { deleteHandler(vacation) }}>Delete</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </td>
            </tr>)
        });
        setRows(temp);
    }

    const getVacationsFromAPI = () => {
        addVacationModal.hideModal(); // hide modal on add/edit
        // setSelectedVacation(null);
        setisLoading(true);
        try {
            API.getRequest(apiUrls.getallvacations, false).then((raw) => {
                const response = raw.data;
                if (response.success) {
                    setVacations(response.result);
                    setUIList(response.result);
                }
                setisLoading(false);
            });
        } catch (ex) {
            
            setisLoading(false);
            common.LogError(ex, "Vacation", "getVacationsFromAPI");
        }
    }
    //#endregion

    return (<>
        <div>
            <Card className="example example-compact">
                <CardHeader title="Vacations" toolbar={st_isLoading ? null : toolbar} />
                <CardBody>
                    <div>
                        {st_isLoading ? <div className="row d-flex justify-content-center">
                            <span className="mt-5 spinner spinner-primary"></span>
                        </div>
                            :
                            <table className="table table-striped table-hover table-sm table-bordered" id="calendarList">
                                <thead>
                                    <tr>
                                        <th data-priority="1">From (Date)</th>
                                        <th>To (Date)</th>
                                        <th data-priority="2" width="130px">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>{st_rows}</tbody>
                            </table>
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
        <PopupModal
            size="lg"
            title={st_selectedVacation ? "Edit Vacation" : "Add Vacation"}
            module={ModulesName.Module_Setup}
            action={UserActions.Create}
            buttonPress={() => addVacation.onSubmit()}
            ref={(ref) => {
                addVacationModal = ref;
            }}
        >
            {" "}
            <AddVacation
                ref={(ref) => {
                    addVacation = ref;
                }}
                vacation={st_selectedVacation}
                refresh={getVacationsFromAPI}
                onSaveAPI={() => this.onUserFormSubmit()}
            />{" "}
        </PopupModal>
    </>);
}

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Select, { createFilter } from "react-select";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Button } from "react-bootstrap";
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import { getRequestQry, getRequest, Uri_GetQAPIReport, Uri_GetFacility } from '../../General/api';
import { showSuccessAlertAutoClose } from '../../General/common'
import flatpickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import { English } from "flatpickr/dist/l10n/default.js";
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/plugins/monthSelect/style.css';
import { FormattedMessage, injectIntl } from "react-intl";

const QAPIReport = ({ intl }) => {
  const [facilitySelected, setFacilitySelected] = useState(null);
  const [searchResultData, setSearchResultData] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [scheduleType, setScheduleType] = useState();
  const [yearlyOptions, setYearlyOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [facilitySelectedNameDisplay, setFacilitySelectedNameDisplay] = useState(null);
  const [scheduleTypeSelectedNameDisplay, setScheduleTypeSelectedNameDisplay] = useState(null);
  const monthPicker = useRef(null);
  const quarterlyPicker = useRef(null);
  const yearlyPicker = useRef(null);
  const defaultCloseModalTimeout = 3000;
  const successStatusCode = 200;
  const idForYearToDate = 0;

  const scheduleTypeEnum = {
    1: intl.formatMessage({ id: 'REPORTS.QAPI.MONTHLY' }),
    2: intl.formatMessage({ id: 'REPORTS.QAPI.QUARTERLY' }),
    3: intl.formatMessage({ id: 'REPORTS.QAPI.YEARLY' }),
    4: intl.formatMessage({ id: 'REPORTS.QAPI.YEAR_TO_DATE' }),
  }

  const quarterlyEnum = {
    1: intl.formatMessage({ id: 'REPORTS.QAPI.Q1' }),
    2: intl.formatMessage({ id: 'REPORTS.QAPI.Q2' }),
    3: intl.formatMessage({ id: 'REPORTS.QAPI.Q3' }),
    4: intl.formatMessage({ id: 'REPORTS.QAPI.Q4' }),
  }

  const reportColumnsEnum = {
    1: intl.formatMessage({ id: 'REPORTS.QAPI.COUNT' }),
    2: intl.formatMessage({ id: 'REPORTS.QAPI.ADMITTED' }),
    3: intl.formatMessage({ id: 'REPORTS.QAPI.ACQUIRED' }),
    4: intl.formatMessage({ id: 'REPORTS.QAPI.REOCCURRING' }),
    5: intl.formatMessage({ id: 'REPORTS.QAPI.IMPROVED' }),
    6: intl.formatMessage({ id: 'REPORTS.QAPI.UNCHANGED' }),
    7: intl.formatMessage({ id: 'REPORTS.QAPI.DECLINED' }),
    8: intl.formatMessage({ id: 'REPORTS.QAPI.CLOSED' }),
  }

  const barsColors = {
    1: '#FFCB66',
    2: '#86C2FF',
    3: '#ABD5FF',
    4: '#C3E1FF',
    5: '#76DCD7',
    6: '#8FE2DE',
    7: '#B7EDEA',
    8: '#E0E0E0',
    9: '#FFE6B3',
  }

  useEffect(() => {
    getRequest(`${Uri_GetFacility}?isAssessmentView=1`)
      .then(response => {
        const responseData = response.data;
        if (responseData.statusCode !== successStatusCode || !responseData.result) {
          showSuccessAlertAutoClose(intl.formatMessage({ id: 'REPORTS.QAPI.RECORD_NOT_FOUND' }), 'warning', intl.formatMessage({id: "WARNING.TITLE"}), defaultCloseModalTimeout)
        }

        setFacilityList(responseData.result.map(facility => ({ value: facility.id, label: facility.facilityName })).sort((a, b) => a.label.localeCompare(b.label)));
        setIsLoading(false);

      }).catch(error => {
        console.log(error)
        showSuccessAlertAutoClose(intl.formatMessage({ id: 'REPORTS.QAPI.ERROR_OCCURRED' }), 'warning',  intl.formatMessage({id: "WARNING.TITLE"}), defaultCloseModalTimeout)
        setIsLoading(false);
      });
  }, [intl]);

  useEffect(() => {
    const minYear = 2020;
    const currentYear = moment().year();
    const options = [];
    for (let i = minYear; i <= currentYear; i++) {
      options.push({ value: i, label: i.toString() });
    }

    options.sort((a, b) => b.value - a.value)

    setYearlyOptions(options);
  }, [])

  const getFlatpickrLocale = (locale) => {
    const formattedLocale = locale.split('-')[0]; 
    switch (formattedLocale) {
      case 'pt':
        return Portuguese;
      default:
        return English;
    }
  };

  const monthPickerRef = useCallback((node) => {
    if (node !== null) {
      console.log('Locale:', intl.locale);
      console.log('Flatpickr Locale:', getFlatpickrLocale(intl.locale));

      monthPicker.current = flatpickr(node, {
        allowInput: true,
        mode: "single",
        altInput: true,
        locale: getFlatpickrLocale(intl.locale),
        plugins: [monthSelectPlugin({
          shorthand: true,
          altFormat: "F", 
        })],
        onReady(a, v, flatpickrInstance) {
          flatpickrInstance.calendarContainer.classList.add('flatpickr-disable-year-select')
        }
      });
    }
  }, [intl.locale]);

  function PrintReport() {
    setIsPrinting(true);
    let elementProcessed = 0;
    let heightFromTop = 10;
    let elementsHeight = 380;
    let titleHeight = 75;
    let sheetWidth = 790;
    let widthFromLeft = 30;
    let doc = new jsPDF('l', 'pt', 'a4', false);
    const data = document.querySelectorAll("div[id^='tableForPrint'], #titleForPrint");
    Array.from(data).forEach((item, index) => {
      htmlToImage.toPng(item, { quality: 3, scale: 3 }).then(dataUrl => {
        if (elementProcessed > 1) {
          doc.addPage();
          heightFromTop = 30;
        }

        var img = new Image();
        img.src = dataUrl;
        if (index === 0)
          doc.addImage(img, 'PNG', widthFromLeft, heightFromTop, sheetWidth, titleHeight, index, 'FAST');
        else
          doc.addImage(img, 'PNG', widthFromLeft, heightFromTop, sheetWidth, elementsHeight, index, 'FAST');

        elementProcessed++;

        if (elementProcessed === 1)
          heightFromTop += 70;

        if (elementProcessed === data.length) {
          const pageCount = doc.internal.getNumberOfPages();
          doc.setFont('Poppins', 'normal', 'bold');
          doc.setFontSize(8);
          doc.setTextColor(112, 108, 108);
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(intl.formatMessage({ id: 'REPORTS.QAPI.PAGE' }) + ' ' + i + ' ' + intl.formatMessage({ id: 'REPORTS.QAPI.OF' }) + ' ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 20);
          }
          doc.save(intl.formatMessage({ id: 'REPORTS.QAPI' }));
          setIsPrinting(false);
        }

      });
    });
  }

  const handleSearch = (e) => {
    e.preventDefault();

    setIsSearching(true);
    setFacilitySelectedNameDisplay(facilitySelected.label);

    const facilityIdForSearch = facilitySelected.value;
    let valueForSearch = null;
    let labelForSearch = null;

    switch (scheduleType.label) {
      case scheduleTypeEnum[1]:
        valueForSearch = Number(monthPicker.current.currentMonth) + 1;
        setScheduleTypeSelectedNameDisplay(monthPicker.current?.selectedDates?.[0]?.toLocaleString('pt-BR', { month: 'long' }));
        break;
      case scheduleTypeEnum[2]:
        valueForSearch = quarterlyPicker.current.state.selectValue?.[0]?.value || quarterlyPicker.current.state.selectValue?.value || quarterlyPicker.current.state.value.value;
        labelForSearch = quarterlyPicker.current.state.selectValue?.[0]?.label || quarterlyPicker.current.state.selectValue?.label || quarterlyPicker.current.state.value.label;
        setScheduleTypeSelectedNameDisplay(labelForSearch);
        break;
      case scheduleTypeEnum[3]:
        valueForSearch = yearlyPicker.current.state.selectValue?.[0]?.value || yearlyPicker.current.state.selectValue?.value || yearlyPicker.current.state.value.value;
        labelForSearch = yearlyPicker.current.state.selectValue?.[0]?.label || yearlyPicker.current.state.selectValue?.label || yearlyPicker.current.state.value.label;
        setScheduleTypeSelectedNameDisplay(labelForSearch);
        break;
      case scheduleTypeEnum[4]:
        valueForSearch = idForYearToDate;
        setScheduleTypeSelectedNameDisplay(intl.formatMessage({ id: 'REPORTS.QAPI.YEAR_TO_DATE' }));
        break;
      default:
        break;
    }

    getRequestQry(Uri_GetQAPIReport, `?facilityId=${facilityIdForSearch}&scheduleType=${scheduleType.value}&scheduleId=${valueForSearch}`)
      .then(response => {
        const responseData = response.data;
        if (responseData.statusCode !== successStatusCode || !responseData.result || responseData.result?.length === 0) {
          showSuccessAlertAutoClose(intl.formatMessage({ id: 'REPORTS.QAPI.RECORD_NOT_FOUND' }), 'warning', intl.formatMessage({id: "WARNING.TITLE"}), defaultCloseModalTimeout)
        }

        responseData.result.forEach(data => {
          data.qapiReportCounts = data.qapiReportCounts.sort((a, b) => new Date(`${a.year}-${a.month}`) - new Date(`${b.year}-${b.month}`));
        })

        setSearchResultData(responseData.result);
        setIsSearching(false);
        setSearched(true);

      }).catch(error => {
        console.log(error)
        showSuccessAlertAutoClose(intl.formatMessage({ id: 'REPORTS.QAPI.ERROR_OCCURRED' }), 'warning',  intl.formatMessage({id: "WARNING.TITLE"}), defaultCloseModalTimeout)
      })
  }

  const mapColumnValue = (reportCount, reportColumn, colorColumn) => {
    switch (reportColumn) {
      case reportColumnsEnum[1]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.count}</td>;
      case reportColumnsEnum[2]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Admitted || 0}</td>;
      case reportColumnsEnum[3]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Acquired || 0}</td>;
      case reportColumnsEnum[4]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Reoccurring || 0}</td>;
      case reportColumnsEnum[5]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Improved || 0}</td>;
      case reportColumnsEnum[6]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Unchanged || 0}</td>;
      case reportColumnsEnum[7]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Declined || 0}</td>;
      case reportColumnsEnum[8]:
        return <td style={{ backgroundColor: colorColumn }}>{reportCount.qapiWoundStatus?.Closed || 0}</td>;
      default:
        break;
    }
  }

  const handleDataBars = (qapiReportCounts) => {
    let labels = [];
    let datasets = [];
    const height = 50;

    const reportCount = [];
    const closedCount = [];

    const admitted = [];
    const acquired = [];
    const reoccurring = [];

    const improved = [];
    const unchanged = [];
    const declined = [];

    qapiReportCounts.forEach((report) => {
      labels.push(`${report.month}/${report.year}`);

      admitted.push((report.qapiWoundStatus.Admitted || ''));
      acquired.push((report.qapiWoundStatus.Acquired || ''));
      reoccurring.push((report.qapiWoundStatus.Reoccurring || ''));

      improved.push(report.qapiWoundStatus.Improved || '');
      unchanged.push(report.qapiWoundStatus.Unchanged || '');
      declined.push(report.qapiWoundStatus.Declined || '');

      reportCount.push(report.count !== 0 ? report.count : '');

      closedCount.push(report.qapiWoundStatus.Closed !== 0 ? report.qapiWoundStatus.Closed : '');
    });

    const dataCount = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.COUNT' }),
      borderWidth: 0,
      data: reportCount,
      backgroundColor: barsColors[1],
      stack: 'Stack 1'
    }

    const dataAdmitted = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.ADMITTED' }),
      borderWidth: 0,
      data: admitted,
      backgroundColor: barsColors[5],
      stack: 'Stack 0'
    }

    const dataAcquired = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.ACQUIRED' }),
      borderWidth: 0,
      data: acquired,
      backgroundColor: barsColors[6],
      stack: 'Stack 0'
    }

    const dataReoccurring = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.REOCCURRING' }),
      borderWidth: 0,
      data: reoccurring,
      backgroundColor: barsColors[7],
      stack: 'Stack 0'
    }

    const dataImproved = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.IMPROVED' }),
      borderWidth: 0,
      data: improved,
      backgroundColor: barsColors[2],
      stack: 'Stack 2'
    }

    const dataUnchanged = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.UNCHANGED' }),
      borderWidth: 0,
      data: unchanged,
      backgroundColor: barsColors[3],
      stack: 'Stack 2'
    }

    const dataDeclined = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.DECLINED' }),
      borderWidth: 0,
      data: declined,
      backgroundColor: barsColors[4],
      stack: 'Stack 2'
    }

    const dataClosed = {
      label: intl.formatMessage({ id: 'REPORTS.QAPI.CLOSED' }),
      borderWidth: 0,
      data: closedCount,
      backgroundColor: barsColors[8],
      stack: 'Stack 3'
    }

    datasets = [dataCount, dataAdmitted, dataAcquired, dataReoccurring, dataImproved, dataUnchanged, dataDeclined, dataClosed]

    return { datasets, labels, height };
  }

  const createResultDateTable = (searchData, index) => {
    const elementId = `tableForPrint-${index}`;
    return (
      <div id={elementId}>
        <h3 className='my-8 text-primary'>
          <FormattedMessage id="REPORTS.QAPI.AGGREGATE_INFORMATION" defaultMessage="Aggregate Information for" /> {searchData.woundType || intl.formatMessage({ id: 'REPORTS.QAPI.WOUND_TYPE' })}
        </h3>
        {searchData.qapiReportCounts && searchData.qapiReportCounts.length > 0 &&
          <div className='row'>
            <div className='col-lg-6'>
              <table className='table table-bordered table-sm w-100'>
                <thead>
                  <tr>
                    <th></th>
                    {searchData.qapiReportCounts.map(header => <th key={header.month}>{`${header.month}/${header.year}`}</th>)}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ backgroundColor: barsColors[1] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.COUNT" defaultMessage="Count" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[1], barsColors[1]))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: barsColors[5] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.ADMITTED" defaultMessage="Admitted" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[2], barsColors[5]))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: barsColors[6] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.ACQUIRED" defaultMessage="Acquired" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[3], barsColors[6]))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: barsColors[7] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.REOCCURRING" defaultMessage="Reoccurring" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[4], barsColors[7]))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: barsColors[2] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.IMPROVED" defaultMessage="Improved" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[5], barsColors[2]))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: barsColors[3] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.UNCHANGED" defaultMessage="Unchanged" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[6], barsColors[3]))}
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: barsColors[4] }} className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.DECLINED" defaultMessage="Declined" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[7], barsColors[4]))}
                  </tr>
                  <tr>
                    <td className='font-weight-bolder'>
                      <FormattedMessage id="REPORTS.QAPI.CLOSED" defaultMessage="Closed" />
                    </td>
                    {searchData.qapiReportCounts.map(reportCount => mapColumnValue(reportCount, reportColumnsEnum[8]))}
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='col-lg-6'>
              <div>
                <Bar data={handleDataBars(searchData.qapiReportCounts)}
                  options={{
                    maintainAspectRatio: true,
                    responsive: true,
                    scales: {
                      yAxes: [{
                        ticks: {
                          beginAtZero: true,
                          suggestedMin: 0,
                          suggestedMax: 0,
                          stepSize: 10,
                        },
                        stacked: true,
                      }],
                      xAxes: [{
                        stacked: true
                      }]
                    },
                    plugins: {
                      datalabels: {
                        color: '#000',
                        align: 'center',
                        anchor: 'center',
                      },
                    }
                  }}
                  plugins={[ChartDataLabels]} />
              </div>
            </div>
          </div>
        }
      </div>)
  }

  const handleClearFilters = (e) => {
    setFacilitySelected(null);
    setScheduleType(null);
    setFacilitySelectedNameDisplay(null);
    setScheduleTypeSelectedNameDisplay(null);
    setSearchResultData([]);
    setSearched(false);
    if (monthPicker.current)
      monthPicker.current.clear();
    if (quarterlyPicker.current)
      quarterlyPicker.current.select.clearValue();
    if (yearlyPicker.current)
      yearlyPicker.current.select.clearValue();
  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: 'REPORTS.QAPI.TITLE' })}></CardHeader>
        <CardBody>
          {
            isLoading ? (
              <div className="row d-flex justify-content-center">
                <span className="mt-5 spinner spinner-primary"></span>
              </div>
            ) : (
              <>
                <form onSubmit={(e) => handleSearch(e)}>
                  <div className='row'>
                    <div className='col-lg-4'>
                      <div className="form-group">
                        <label className="form-label" htmlFor="FacilityName">
                          <FormattedMessage id="REPORTS.QAPI.FACILITY_NAME" defaultMessage="Facility Name" />
                        </label>
                        <Select
                          options={facilityList}
                          id="facilityId"
                          placeholder={intl.formatMessage({ id: 'REPORTS.QAPI.SELECT' })}
                          name="facilityId"
                          required
                          isSearchable
                          isClearable
                          filterOption={createFilter({ matchFrom: 'start' })}
                          onChange={e => {
                            e ? setFacilitySelected({ value: e?.value, label: e?.label }) : setFacilitySelected(null);
                            setScheduleType(null);
                          }}
                          value={facilitySelected}
                        />
                      </div>
                    </div>
                    <div className='col-lg-4'>
                      <div className="form-group">
                        <label className="form-label" htmlFor="scheduleType">
                          <FormattedMessage id="REPORTS.QAPI.SCHEDULE_TYPE" defaultMessage="Schedule Type" />
                        </label>
                        <Select
                          options={Object.keys(scheduleTypeEnum).map(key => ({ value: key, label: scheduleTypeEnum[key] }))}
                          id="scheduleType"
                          placeholder={intl.formatMessage({ id: 'REPORTS.QAPI.SELECT' })}
                          name="scheduleType"
                          required
                          isClearable
                          onChange={e => e ? setScheduleType({ value: e.value, label: e.label }) : setScheduleType(null)}
                          value={scheduleType}
                        />
                      </div>
                    </div>
                    {
                      scheduleType && scheduleType.label === scheduleTypeEnum[1] &&
                      <div className='col-lg-4'>
                        <div className='form-group'>
                          <label className="form-label" htmlFor="monthPicker">
                            <FormattedMessage id="REPORTS.QAPI.MONTH" defaultMessage="Month" />
                          </label>
                          <input 
                            type="date" 
                            id='monthPicker' 
                            placeholder={intl.formatMessage({ id: 'REPORTS.QAPI.SELECT' })} 
                            required
                            className="form-control form-control-md bg-none color-secondary" 
                            ref={monthPickerRef} />
                        </div>
                      </div>
                    }
                    {
                      scheduleType && scheduleType.label === scheduleTypeEnum[2] &&
                      <div className='col-lg-4'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='quarterlyPicker'>
                            <FormattedMessage id="REPORTS.QAPI.QUARTERLY" defaultMessage="Quarterly" />
                          </label>
                          <Select
                            options={Object.keys(quarterlyEnum).map(key => ({ value: key, label: quarterlyEnum[key] }))}
                            id='quarterlyPicker'
                            placeholder={intl.formatMessage({ id: 'REPORTS.QAPI.SELECT' })}
                            name='quarterlyPicker'
                            required
                            ref={quarterlyPicker}
                          />
                        </div>
                      </div>
                    }
                    {
                      scheduleType && scheduleType.label === scheduleTypeEnum[3] &&
                      <div className='col-lg-4'>
                        <div className='form-group'>
                          <label className='form-label' htmlFor='yearlyPicker'>
                            <FormattedMessage id="REPORTS.QAPI.YEARLY" defaultMessage="Yearly" />
                          </label>
                          <Select
                            options={yearlyOptions}
                            id='yearlyPicker'
                            placeholder={intl.formatMessage({ id: 'REPORTS.QAPI.SELECT' })}
                            name='yearlyPicker'
                            required
                            ref={yearlyPicker}
                          />
                        </div>
                      </div>
                    }
                  </div>
                  <div className='d-flex justify-content-end py-3'>
                    <div className='form-group mt-6'>
                      <Button type='submit' variant="success" className="mr-5 px-10" size="sm">
                        <FormattedMessage id="REPORTS.QAPI.SEARCH_REPORT" defaultMessage="Search Report" />
                      </Button>
                      <button type="button" className='btn btn-danger btn-sm' onClick={e => handleClearFilters(e)}>
                        <FormattedMessage id="REPORTS.QAPI.CLEAR_FILTER" defaultMessage="Clear filter" />
                      </button>
                    </div>
                  </div>
                </form>
                <hr />
                {searched && searchResultData && searchResultData.length === 0 && (<div className='text-center mt-5'>
                  <FormattedMessage id="REPORTS.QAPI.NO_DATA_FOUND" defaultMessage="No data found!" />
                </div>)}
                {searched && !isSearching && searchResultData && searchResultData.length > 0 && (
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <button type="button" onClick={PrintReport} disabled={isPrinting} className="mr-3 btn btn-success btn-sm float-right">
                          {!isPrinting ? (<FormattedMessage id="REPORTS.QAPI.PRINT_REPORT" defaultMessage="Print Report" />) : (<FormattedMessage id="REPORTS.QAPI.LOADING" defaultMessage="Loading..." />)}
                        </button>
                      </div>
                    </div>
                    <div className='qapi-report'>
                      <div className='qapi-report_content'>
                        <div className='pb-8px' id='titleForPrint'>
                          <h2 className='text-center mb-10 mt-5 text-primary'>
                            <FormattedMessage id="REPORTS.QAPI.TITLE" defaultMessage="QAPI (Quality Assurance Performance Improvement) Report" />
                          </h2>
                          <div className='d-flex align-items-center flex-column'>
                            <div className='mb-3 h4'>
                              <span className='font-weight-bolder mr-3'>
                                <FormattedMessage id="REPORTS.QAPI.FACILITY_NAME" defaultMessage="Facility Name" />:
                              </span>
                              <span>{facilitySelectedNameDisplay}</span>
                            </div>
                            <div className='h4'>
                              <span className='font-weight-bolder mr-3'>
                                <FormattedMessage id="REPORTS.QAPI.SUMMARY_REPORT_FOR" defaultMessage="Summary Report For:" />
                              </span>
                              <span>{scheduleTypeSelectedNameDisplay}</span>
                            </div>
                          </div>
                        </div>
                        {
                          searchResultData?.length > 0 && searchResultData.map((searchData, index) => createResultDateTable(searchData, index))
                        }
                      </div>
                    </div>
                  </>
                )}
                {
                  isSearching && (
                    <div className="row d-flex justify-content-center">
                      <span className="mt-5 spinner spinner-primary"></span>
                    </div>
                  )

                }
                <canvas id='c' width='100' height='100' style={{ display: 'none' }}></canvas>
              </>
            )
          }
        </CardBody>
      </Card>
    </>
  );
}

export default injectIntl(QAPIReport);

import { withMultiRegionFunction } from "@/shared/wrappers/withMultiRegion";

const patternSSNRegions = {
  US: "\\d{3}-?\\d{2}-?\\d{4}",
  CA: "d{3}-?d{3}-?d{3}",
  UK: "(?:d{2}-?d{2}-?d{2}-?d{2}-?d{2}|(?:d{6}|(?:d{4}-?d{4}-?d{4}-?d{4})))",
  BR: "^[0-9]{3}\\.[0-9]{3}\\.[0-9]{3}-[0-9]{2}$",
};

export default withMultiRegionFunction(patternSSNRegions);

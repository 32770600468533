export default function getOrientationName({
  orientationLeftRight,
  orientationUpperLower,
  orientationInnerOuter,
  orientationAnteriorPosterior,
  orientationMedialLateral,
  orientationDorsalDistalProximal,
}) {
  const updateOrientation = (condition, value, current) =>
    condition ? (current ? `${current},${value}` : value) : current;

  let orientation = "";

  if (orientationLeftRight !== null) {
    orientation = updateOrientation(
      orientationLeftRight === 1,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_RIGHT",
      orientation
    );
    orientation = updateOrientation(
      orientationLeftRight === 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_LEFT",
      orientation
    );
    orientation = updateOrientation(
      orientationLeftRight > 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_BILATERAL",
      orientation
    );
  }

  if (orientationUpperLower !== null) {
    orientation = updateOrientation(
      orientationUpperLower === 1,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_UPPER",
      orientation
    );
    orientation = updateOrientation(
      orientationUpperLower === 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_LOWER",
      orientation
    );
  }

  if (orientationInnerOuter !== null) {
    orientation = updateOrientation(
      orientationInnerOuter === 1,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_INNER",
      orientation
    );
    orientation = updateOrientation(
      orientationInnerOuter === 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_OUTER",
      orientation
    );
  }

  if (orientationAnteriorPosterior !== null) {
    orientation = updateOrientation(
      orientationAnteriorPosterior === 1,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_ANTERIOR",
      orientation
    );
    orientation = updateOrientation(
      orientationAnteriorPosterior === 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_POSTERIOR",
      orientation
    );
  }

  if (orientationMedialLateral !== null) {
    orientation = updateOrientation(
      orientationMedialLateral === 1,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_MEDIAL",
      orientation
    );
    orientation = updateOrientation(
      orientationMedialLateral === 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_LATERAL",
      orientation
    );
  }

  if (orientationDorsalDistalProximal !== null) {
    orientation = updateOrientation(
      orientationDorsalDistalProximal === 1,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_DORSAL",
      orientation
    );
    orientation = updateOrientation(
      orientationDorsalDistalProximal === 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_DISTAL",
      orientation
    );
    orientation = updateOrientation(
      orientationDorsalDistalProximal > 2,
      "NEW_WOUND_ASSESSMENT.ORIENTATION_PROXIMAL",
      orientation
    );
  }

  console.log("orientation", orientation);

  return orientation;
}

import React, { useMemo } from "react";
import objectPath from "object-path";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import HeaderMenu from "./HeaderMenu";
import { versionNumber, envinmentName } from "../../../../../app/General/common";
import { Badge } from "react-bootstrap";
import SVG from "react-inlinesvg";

export function HeaderMenuWrapper() {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            config: uiService.config,
            ktMenuClasses: uiService.getClasses("header_menu", true),
            rootArrowEnabled: objectPath.get(
                uiService.config,
                "header.menu.self.root-arrow"
            ),
            menuDesktopToggle: objectPath.get(uiService.config, "header.menu.desktop.toggle"),
            headerMenuAttributes: uiService.getAttributes("header_menu"),
            headerSelfTheme: objectPath.get(uiService.config, "header.self.theme"),
            ulClasses: uiService.getClasses("header_menu_nav", true),
            disabledAsideSelfDisplay:
                objectPath.get(uiService.config, "aside.self.display") === false
        };
    }, [uiService]);
    const getHeaderLogo = () => {
        let result = "logo-light.png";
        if (layoutProps.headerSelfTheme && layoutProps.headerSelfTheme !== "dark") {
            result = "logo-dark.png";
        }
        return toAbsoluteUrl(`/media/logos/${result}`);
    };

    return <>
        {/*begin::Header Menu Wrapper*/}
        <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
            {layoutProps.disabledAsideSelfDisplay && (
                <>
                    {/*begin::Header Logo*/}
                    <div className="header-logo">
                        {envinmentName != '' &&
                            <div className="badge badge-danger animationContainer">
                                <span className="testAnimation">{"      " + envinmentName + " Test Site      -      CAUTION! Do not enter real world data. Data may be lost and cannot be recovered. Go to fastcare.gentell.com for production Fastcare."}
                                </span>

                            </div>
                        }
                        <Link to="/">
                            <SVG className="gentellHeaderLogo"
                                src={toAbsoluteUrl("/media/svg/logos/gentell-fastcare.svg")}
                                ></SVG>
                        </Link>
                        <small>{versionNumber}  </small>
                    </div>

                    {/*end::Header Logo*/}
                </>
            )}
            {/*begin::Header Menu*/}
            <HeaderMenu layoutProps={layoutProps} />
            {/*end::Header Menu*/}
        </div>
        {/*Header Menu Wrapper*/}
    </>
}

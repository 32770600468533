import { Switch, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle, Button, Dropdown, DropdownButton
} from "react-bootstrap";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import FindResident from "./findResident";
import CheckPermission from "../../General/CheckPermission";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import WorkQueueWounds from "../../pages/resident/workqueuewounds";
import PopupModal from "../modal/popupmodal";
import SkipResidentMsg from './skipResidentMsg';
import Newresident from "../modal/residentsmodals/newresident";
import ResidentDetails from "@/shared/components/residentDetails";
import ECPReason from "./ecpResidentReason";
import moment from 'moment';
import ResidentStatus from "../resident/residentStatus";
import SelectableContext from "react-bootstrap/SelectableContext";
import uuid from 'node-uuid'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ViewWoundInformationChangeReason from './viewWoundInformationChangeReason';
import SkipWoundMsg from './skipWoundMsg';
import { injectIntl } from 'react-intl'

import Checkbox from '@material-ui/core/Checkbox';
var UpsertNewresident, woundAmendModal, residentStatusModal, noOrderModal, addStatusRef,
  residentInfoModal, skipECPModal, skipHospiceModal, showSkipWoundMsgModal = null;
export function BillingResident(props) {
  //#region Resident modal
  const [showModalResident, setShowResident] = useState(false);
  const [isResidentInfoEdit, setisResidentInfoEdit] = useState(false);
  const { intl } = props;
  // const [isBTQueue, setIsBTQueue] = useState(false);

  const [isNoOrder, setIsNoOrder] = useState(false);
  const [isProcessorNoOrder, setIsProcessorNoOrder] = useState(false);


  const [Resident, setResident] = useState("");
  const [ResidentID, setResidentID] = useState("");
  const FacilityId = props.facility.id;
  const handleCloseResident = () => setShowResident(false);
  const handleShowResident = () => setShowResident(true);
  //#endregion
  const [residentListUI, setResidentListUI] = useState([]);
  const displatWarningAlert = () => showWarningAlert("Coming soon!!!", "Alert");
  const displayDecisionAlert = (title, message) =>
    showDecisionAlert(
      function () { },
      function () { },
      title == null ? "Are you sure?" : title,
      message == null ? "You want to deactivate this Resident." : message
    );
  const [residentLoading, setResidentLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllResident();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, []);

  //#region Set Resident List
  const getAllResident = async () => {
    // // debugger
    setIsLoading(true);
    var isPartailOrder = props.isPartailOrder ? 1 : 0;
    var partailOrderBillingDate = props.isPartailOrder ? props.facility.cycleDate : '';
    var processStatusId = props.isPartailOrder ? props.processStatusId : Common.ProcessStatus.billingQueueID;

    Api.getRequestQry(
      Api.URI_GetAllBillingQueueResidentByFacilityID,
      "?facilityID=" + FacilityId + "&isPartailOrder=" + isPartailOrder + "&partailOrderDate=" + partailOrderBillingDate +
      "&processStatusId=" + processStatusId
    )
      .then((res) => {
        // // debugger;
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setResidentList(res.data.result);
            }
          }
        }
      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);
        Common.LoadDataTable("residentsList");
      });
  };

  const [residentStatus, setResidentStatus] = useState({});

  const checkAllProccessed = () => { };
  const handleResidentStatus = (residentObj) => (event) => {
    debugger
    if (residentObj.isProcessorNoOrder == true && event.target.checked == false) {
      handleResidentNoOrderStatus(residentObj)
    } else {
      let resident = {};
      resident.ResidentID = residentObj.id;
      resident.Status = event.target.checked;
      resident.isPartial = props.isPartailOrder ? 1 : 0;
      if (props.isPartailOrder) {
        resident.partailOrderDate = props.facility.cycleDate
      }
      updateResidentStatus(resident);
    }

    // this.setState({
    //   Status:event.target.checked
    // })
  };
  const updateResidentStatus = (obj) => {
    // var msg = "You want to " + (obj.Status ? "proceed." : "unprocessed.");
    // Common.showConfirmation(msg, "", updateStatus, null, obj);
    var msg = (obj.Status ? intl.formatMessage({ id: "DO_YOU_WANT_TO_PROCESS_THIS_RESIDENT" }) : intl.formatMessage({ id: "DO_YOU_WANT_TO_MOVE_BACK_TO_REVIEW_EDIT_MODE" }));
    Common.showConfirmation(msg, intl.formatMessage({ id: "ARE_YOU_SURE" }), updateStatus, null, obj);
  };
  const updateStatus = async (obj) => {
    if (obj) {
      setIsLoading(true);
      var { data } = await Api.postRequest(Api.Uri_UpdateBillingSaleOrderStatus, obj)
      if (data.success) {
        await getAllResident();
        Common.showSuccessAlertAutoClose(
          data.message,
          "success",
          intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
          3000
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        Common.showSuccessAlert(
          data.message,
          "warning",
          intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
          3000
        );
      }

      //Common.showSuccessAlertAutoClose("User Added Successfully", "success", "Success!", 3000);

    }
  };
  const handleUnReleaseFacility = () => {
    var msg = intl.formatMessage({ id: "WORKQUEUE.YOU_WANT_TO_UNRELEASE" });
    Common.showConfirmation(msg, "", unReleaseFacility, null, FacilityId)
    // handleReleaseFacility(FacilityId);
  };
  const unReleaseFacility = (facilityId) => {
    setIsLoading(true);
    var obj = {};
    obj.FacilityID = facilityId;
    Api.postRequest(Api.Uri_UnReleaseBillingFacility, obj)
      .then((res) => {
        if (res.data.success) {

          Common.showSuccessAlertAutoClose(

            res.data.message,
            "success",
            intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
            3000
          );
          props.backPress();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
            3000
          );
        }
      })
      .catch((ex) => {

        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          intl.formatMessage({ id: "BUTTON.FAILED.TITLE" }),
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const releaseFacility = () => {
    // // debugger;
    var msg = intl.formatMessage({ id: "WORKQUEUE.YOU_WANT_TO_RELEASE" });
    Common.showConfirmation(msg, "", handleReleaseFacility, null, FacilityId)
  };
  const handleResidentStatusActive = async (resident) => {
    await setResident(resident)
    residentStatusModal.showModal();
  }

  const handleResidentECP = (obj) => {
    debugger
    let tempObj = JSON.parse(JSON.stringify(obj));
    if (tempObj.isEcp == false && tempObj.onHospice == true) {
      tempObj.isEcp = tempObj.isEcp ? false : true;
      tempObj.onHospice = !tempObj.onHospice;
      //Common.showSuccessAlert("Must remove Hospice Retail before can set Equal Care.", "error", "Error!", 3000);
      var msg = "Equal Care cannot be set at the same time as Hospice Retail. Would you like to remove Hospice Retail?";
      Common.showConfirmation(msg, "", updateECPandHospice, null, tempObj)
    } else {
      tempObj.isEcp = tempObj.isEcp ? false : true;
      var msg = "" + (tempObj.isEcp ? "You want to set equal care." : "You want to remove  equal care.");
      Common.showConfirmation(msg, "", updateECP, null, tempObj)

    }
  };
  const updateECP = (obj) => {
    if (obj) {

      if (obj.isEcp != false) {
        setResident(obj)

        skipECPModal.showModal()
        getAllResident()

      }
      else {
        Api.postRequest(Api.apiUrls.updateResidentECP, obj).then((res) => {
          if (res.data.success) {
            getAllResident();
            Common.showSuccessAlertAutoClose("Resident ECP Updated Successfully", "success", "Success!", 3000)
          } else {
            Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
          }
        });
      }
    }
  }

  const updateECPandHospice = (obj) => {
    if (obj) {

      if (obj.isEcp != false) {
        setResident(obj)
        getAllResident();
        skipECPModal.showModal()
      }
      else {
        Api.postRequest(Api.apiUrls.updateResidentECP, obj).then((res) => {
          if (res.data.success) {
            Api.postRequest(Api.apiUrls.updateResidentHospice, obj).then((res) => {
              if (res.data.success) {
                getAllResident();
                Common.showSuccessAlertAutoClose("Resident ECP and Hospice Retail Updated Successfully", "success", "Success!", 3000)
              } else {
                Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
              }
            });
          } else {
            Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
          }
        });
      }
    }
  }

  const handleResidentHospice = (obj) => {
    debugger
    let tempObj = JSON.parse(JSON.stringify(obj));
    if (tempObj.isEcp == true && tempObj.onHospice == false) {
      tempObj.isEcp = tempObj.isEcp ? false : true;
      tempObj.onHospice = !tempObj.onHospice;
      var msg = "Hospice Retail cannot be set at the same time as Equal Care. Would you like to remove Equal Care?";
      Common.showConfirmation(msg, "", updateECPandHospice, null, tempObj)
    } else {
      tempObj.onHospice = !tempObj.onHospice;
      var msg = "" + (tempObj.onHospice ? "You want to set Hospice Retail." : "You want to remove Hospice Retail.");
      Common.showConfirmation(msg, "", updateHospice, null, tempObj)
    }
  };
  const updateHospice = (obj) => {
    if (obj) {


      Api.postRequest(Api.apiUrls.updateResidentHospice, obj).then((res) => {
        if (res.data.success) {
          getAllResident();
          Common.showSuccessAlertAutoClose("Resident Hospice Retail Updated Successfully", "success", "Success!", 3000)
        } else {
          Common.showSuccessAlert(res.data.message, "error", "Error!", 3000);
        }
      });
    }

  }

  const handleReleaseFacility = (facilityID) => {
    setIsLoading(true);
    var obj = {};
    obj.FacilityID = facilityID;
    Api.postRequest(Api.Uri_UpdateBillingFacilityStatus, obj)
      .then(async (res) => {
        if (res.data.success) {

          Common.showSuccessAlertAutoClose(

            res.data.message,
            "success",
            intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
            3000
          );
          const fileUrl = await Common.verifyIfFileExistsInBlobContainerThenReturnItUrl(res.data.result);
          debugger;
          if (fileUrl != null && fileUrl != "") {
            window.location.assign(fileUrl)
          }
          props.backPress();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
            3000
          );
        }
      })
      .catch((ex) => {

        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          intl.formatMessage({ id: "BUTTON.FAILED.TITLE" }),
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const releasePartailFacility = () => {
    var msg = "Do you want to release partial orders.";
    Common.showConfirmation(msg, "", handleReleaseFacilityPartailOrder, null, FacilityId)
    // handleReleaseFacility(FacilityId);
  };
  const handleReleaseFacilityPartailOrder = (facilityID) => {
    setIsLoading(true);
    Api.getRequestQry(
      Api.apiUrls.releaseFacilityPartailOrders,
      "?facilityId=" + facilityID + "&queueID=" + Common.ProcessStatus.billingQueueID + "&partailOrderDate=" + props.facility.cycleDate
    )
      .then((res) => {
        if (res.data.success) {

          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }),
            3000
          );
          if (res.data.result != null && res.data.result != "") {
            window.location.assign(res.data.result)
          }
          props.backPress();
        } else {
          Common.showSuccessAlert(
            res.data.message,
            "warning",
            intl.formatMessage({ id: "BUTTON.WARNING.TITLE" }),
            3000
          );
        }
      })
      .catch((ex) => {

        Common.showSuccessAlertAutoClose(
          intl.formatMessage({ id: "SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          intl.formatMessage({ id: "BUTTON.FAILED.TITLE" }),
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  const handleResidentNoOrderStatus = (obj) => {

    let tempObj = JSON.parse(JSON.stringify(obj));

    tempObj.isNoOrder = tempObj.isProcessorNoOrder == true ? false : true;
    tempObj.isProcessorNoOrder = true;
    var msg = "" + (tempObj.isNoOrder ? intl.formatMessage({ id: "YOU_WANT_TO_NO_ORDER" }) : intl.formatMessage({ id: "ORDERING_FOR_THIS_RESIDENT_WAS_SKIPPED" }));
    Common.showConfirmation(msg, "", updateNoOrderStatus, null, tempObj)

  };
  const updateNoOrderStatus = (obj) => {
    if (obj) {
      setIsNoOrder(obj.isNoOrder);
      setIsProcessorNoOrder(obj.isProcessorNoOrder);

      if (obj.isNoOrder) {
        setResidentID(obj.id);
        noOrderModal.showModal()
      }

      else {
        Api.postRequest(Api.apiUrls.updateResidentNoOrderStatus, {
          msg: '', fkResidentId: obj.id, isNoOrder: obj.isNoOrder, isProcessorNoOrder: obj.isProcessorNoOrder
        }).then((res) => {
          if (res.data.success) {
            getAllResident();
            Common.showSuccessAlertAutoClose(intl.formatMessage({ id: "SUCCESS.RESIDENT_STATUS_UPDATED" }), "success", intl.formatMessage({ id: "BUTTON.SUCCESS.TITLE" }), 3000)
          } else {
            Common.showSuccessAlert(res.data.message, "error", intl.formatMessage({ id: "BUTTON.ERROR.TITLE" }), 3000);
          }
        });
      }
    }
  }

  const setResidentList = (list) => {
    var rows = [];

    //const statusBadgeType = ["badge-warning", "badge-success"];
    //const status = ["Pending", "Processed"];

    var resStatus = residentStatus;

    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + ", " + obj.firstName;
      const residentID = obj.id;
      const dob = moment(obj.doB).format('MM/DD/YYYY');

      if (resStatus[residentID] == null) {
        resStatus[residentID] = false;
      }

      var insurance = "No insurance on file";

      if (obj.residentInsuranceList != null) {
        insurance = "";
        for (var d = 0; d < obj.residentInsuranceList.length; d++) {
          let objIns = JSON.parse(JSON.stringify(obj.residentInsuranceList[d]));
          insurance = insurance + (insurance != "" ? ", " : "") + " " + (objIns.isAddEdit ? "*" : "") + "=>" + (objIns.fkInsuranceLevelId == 1 ? intl.formatMessage({ id: "FACILITY_RESIDENT.PRIMARY" })
            : objIns.fkInsuranceLevelId == 2 ? intl.formatMessage({ id: "FACILITY_RESIDENT.SECONDARY" })
              : objIns.fkInsuranceLevelId == 3 ? intl.formatMessage({ id: "FACILITY_RESIDENT.TERTIARY" })
                : "") + " " + objIns.insuranceName
        }
      }
      // else {
      //   insurance = "N/A"
      // }
      var statusBadgeType =
        obj.isProcess == true || obj.isProcessorNoOrder == true ? "badge-success" : "badge-warning";

      rows.push(
        <Accordion key={uuid()} defaultActiveKey={!obj.isProcess ? i.toString() + obj.id : ""}>
          <Card>

            <CardHeader>
              <div
                className={`row w-100 d-flex border-bottom m-0  ${obj.isProcess || obj.isProcessorNoOrder == true
                  ? obj.isProcessorNoOrder == true ? "bg-light-warning border-warning" : "bg-light-success border-success"
                  : "border-light-dark"
                  }`}
              >
                <div className="customStyleAccorion" style={{ display: "grid" }}>

                  <AccordionToggle
                    as={Button}
                    variant="link"
                    // className="h5 mb-0"
                    eventKey={i.toString() + obj.id}
                  >
                    <div title={obj.fkPrimaryPhysicianId == null ? intl.formatMessage({ id: "FACILITY_RESIDENT.PRIMARY_PHYSICIAN_IS_NOT_ASSIGNED" }) : ""}
                      className={`h5 mb-0 text-dark ${obj.fkPrimaryPhysicianId == null ? "bg-light-danger border-danger" : ""}`}>
                      {name} - BT ID: {obj.btPatientBrightreekey}
                      {/* <FormControlLabel
                        label="Equal Care:"
                        labelPlacement='start'
                        onChange={e => handleResidentECP(obj, e)}
                        control={
                          <Checkbox checked={obj.isEcp} value="checkedA" />
                        }
                      /> */}
                    </div>


                  </AccordionToggle>
                  {obj.fkPrimaryPhysicianId == null ? <small> {intl.formatMessage({ id: "FACILITY_RESIDENT.PRIMARY_PHYSICIAN_IS_NOT_ASSIGNED" })} <br /></small> : ""}
                  {/* <small>  <label className="form-label font-weight-bold mr-2" htmlFor="fkDebridementTypeId">DOB:</label>{obj.doB != null ? dob : "N/A"}</small> <br /> */}
                  <small> {insurance}</small>
                </div>
                {!props.isReleasedFacility &&
                  <>
                    <span className={`badge my-auto mx-4 ml-auto `}>
                      {obj.isEcp &&
                        <span
                          className={`badge badge-success mr-2`}
                        >
                          ECP
                        </span>
                      }
                      {obj.onHospice &&
                        <span
                          className={`badge badge-success mr-2`}
                        >
                          HOS
                        </span>
                      }
                      <span
                        className={`badge my-auto mx-4 ml-auto ${statusBadgeType}`}
                      >
                        {obj.isProcess == true || obj.isProcessorNoOrder == true ? obj.isProcessorNoOrder == true ? intl.formatMessage({ id: "FACILITY_RESIDENT.SKIPPED" }) : intl.formatMessage({ id: "FACILITY_RESIDENT.PROCESSED" }) : intl.formatMessage({ id: "FACILITY_RESIDENT.PENDING" })}
                      </span>

                      <Switch
                        checked={obj.isProcess || obj.isProcessorNoOrder == true ? true : false}
                        onChange={handleResidentStatus(obj)}
                        disabled={!props.isProcessorFunctionOn}
                        value="checkedB"
                        color="primary"
                      />
                      {props.isProcessorFunctionOn &&
                        <span>
                          <SelectableContext.Provider value={false}>
                            <DropdownButton
                              className="d-inline"
                              drop={"down"}
                              size="xs"
                              variant="primary"
                              title={'Actions'}
                              id={`dropdown-button-drop-actions`}
                              key={'down'}>
                              <CheckPermission
                                permission={UserActions.Update}
                                module={ModulesName.Module_Residents}
                                yes={() => (
                                  <Dropdown.Item eventKey="1" onClick={() => {
                                    setResident(obj); setisResidentInfoEdit(false);
                                    setResidentID(obj.id); residentInfoModal.showModal();
                                  }}>{intl.formatMessage({ id: "BILLING.RESIDENT_INFO" })}</Dropdown.Item>
                                )}
                                no={() => null}
                              />
                              <Dropdown.Item eventKey="8" onClick={() => handleResidentStatusActive(obj)}>{intl.formatMessage({ id: "FACILITY_RESIDENT_BUTTON.SET_RESIDENT_STATUS" })}</Dropdown.Item>

                              <Dropdown.Item eventKey="81" onClick={() => handleResidentECP(obj)}>{obj.isEcp ? 'Remove Equal Care' : 'Set Equal Care'}</Dropdown.Item>
                              <Dropdown.Item eventKey="8" onClick={() => handleResidentHospice(obj)}>{obj.onHospice ? 'Remove Hospice Retail' : 'Set Hospice Retail'}</Dropdown.Item>



                              {(props.facility.fkProcessStatusId == Common.ProcessStatus.billingQueueID ||
                                props.facility.fkProcessStatusId == Common.ProcessStatus.workQueueID) &&
                                <CheckPermission
                                  userRole={[RBAC.Role_Gentell_SeniorBiller, RBAC.Role_Gentell_IntakeProcessor,
                                  RBAC.Role_Gentell_Administrator, RBAC.Role_Gentell_WoundCareSpecialist, RBAC.Role_Gentell_RegionalManager]}

                                  yes={() => (
                                    <>
                                      <Dropdown.Item eventKey="8"
                                        // disabled={obj.wound.isPartailOrder ? true : false}
                                        onClick={() => handleResidentNoOrderStatus(obj)}>{obj.isProcessorNoOrder ?
                                          intl.formatMessage({ id: "FACILITY_RESIDENT.ORDER_PRODUCTS" }) : intl.formatMessage({ id: "FACILITY_RESIDENT.NO_ORDER" })
                                        }
                                      </Dropdown.Item>
                                    </>
                                  )}
                                  no={() => null}
                                />
                              }
                              <Dropdown.Item eventKey="2" onClick={() => { setResidentID(obj.id); showSkipWoundMsgModal.showModal(); }} >{intl.formatMessage({ id: "FACILITY_RESIDENT_BUTTON.VIEW_SKIP_WOUND_MESSAGES" })}</Dropdown.Item>

                              <Dropdown.Item eventKey="2" onClick={() => { setResidentID(obj.id); woundAmendModal.showModal(); }} >{intl.formatMessage({ id: "FACILITY_RESIDENT.VIEW_WOUND_AMEND_DETAILS" })}</Dropdown.Item>

                            </DropdownButton>

                          </SelectableContext.Provider>


                        </span>
                      }
                    </span>
                  </>

                }
                {/* <>
                  {obj.isProcess &&
                    <span className={`badge my-auto `}>
                      <button className="btn btn-info btn-xs mb-3" size="sm" onClick={() => { getAllResident(true) }}>Show Product</button>
                    </span>
                  }
                </> */}
              </div>
            </CardHeader>


            <AccordionCollapse eventKey={i.toString() + obj.id}>
              <CardBody>
                <fieldset disabled={obj.isProcess}>
                  <WorkQueueWounds
                    facility={props.facility}
                    refresh={props.refresh} isShowProduct={true}
                    Resident={obj}
                    isPartailOrder={props.isPartailOrder}
                    isProcess={obj.isProcess}
                    isBillingQueue={true}
                    isProcessorFunctionOn={props.isProcessorFunctionOn}
                    isReleasedFacility={props.isReleasedFacility}
                    isBTQueue={props.processStatusId == Common.ProcessStatus.brighreeQueueID ? true : false}
                    backPress={props.backPress}
                    queue={Common.ProcessStatus.billingQueueID}
                  />
                </fieldset>
              </CardBody>
            </AccordionCollapse>
          </Card>
        </Accordion>
      );
    }

    setResidentListUI(rows);
  };

  // for (var i = 0; i < 3; i++) {
  //     var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
  //     var dob = new Date(obj.birthdate);
  //     let randomCount = Math.floor(Math.random() * status.length);
  //     var randStatus = status[randomCount];
  //     var randStatusBadgeType = statusBadgeType[randomCount];
  //     //${randStatus == "Processed" ? "bg-light-success" :""}
  //     rows.push(
  //         <Accordion>
  //             <Card>
  //                 <CardHeader>
  //                     <div className={`w-100 d-flex border-bottom  ${randStatus == "Processed" ? "bg-light-success border-success" :"border-light-dark"}`}>
  //                         <AccordionToggle as={Button} variant="link" className="h5 mb-0" eventKey={i.toString()}>
  //                             {obj.first_name + " " + obj.last_name}
  //                         </AccordionToggle>
  //                         <span className={`badge my-auto mx-4 ml-auto ${randStatusBadgeType}`}>{randStatus}</span>
  //                     </div>
  //                 </CardHeader>
  //                 <AccordionCollapse eventKey={i.toString()}>
  //                     <CardBody><WorkQueueWounds /></CardBody>
  //                 </AccordionCollapse>
  //             </Card>
  //         </Accordion >
  //     );
  // }

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">

        {!props.isPartailOrder && !props.isReleasedFacility && <>
          <button
            type="button"
            className="mr-5 btn btn-success btn-sm"
            onClick={() => {
              handleUnReleaseFacility();
            }}
          >

            {intl.formatMessage({ id: "WORKQUEUE.UNRELEASE_FACILITY" })}
          </button>
          <CheckPermission
            permission={UserActions.Update}
            module={ModulesName.Module_BillingQueue}
            yes={() => (
              <button
                type="button"
                disabled={!props.isProcessorFunctionOn}
                className="mr-5 btn btn-success btn-sm"
                onClick={() => {
                  releaseFacility();
                }}
              >
                {props.isBTOn == false ? intl.formatMessage({ id: "BILLING_QUEUE.ACTIONS.PRINT_ORDERS" }) : intl.formatMessage({ id: "BILLING_QUEUE.ACTIONS.RELEASE_TO_BRIGHTREE" })}
              </button>
            )}
            no={() => null}
          />

        </>
        }{
          props.isPartailOrder && !props.isReleasedFacility &&
          <CheckPermission
            permission={UserActions.Update}
            module={ModulesName.Module_BillingQueue}
            yes={() => (
              <button
                type="button"
                className="mr-5 btn btn-success btn-sm"
                onClick={() => {
                  releasePartailFacility();
                }}
              >

                {props.isBTOn == false ? intl.formatMessage({ id: "BILLING_QUEUE.ACTIONS.PRINT_ORDERS" }) : intl.formatMessage({ id: "RELEASE_PARTIAL_SHIPMENT_TO_BRIGHTREE" })}
              </button>
            )}
            no={() => null}
          />}

        <button
          type="button"
          className="mr-5 btn btn-dark btn-sm"
          onClick={() => props.backPress()}
        >

          {intl.formatMessage({ id: "FACILITY_RESIDENT.BACK_FACILITY_LIST" })}
        </button>


      </div>
    </div>
  );

  //initialize datatable
  //   $(document).ready(function() {
  //     if ($.fn.dataTable.isDataTable("#residentsList")) {
  //       $("#residentsList").DataTable = $("#residentsList").DataTable();
  //     } else {
  //       $("#residentsList").DataTable({
  //         responsive: true,
  //         pageLength: 25,
  //         lengthMenu: [
  //           [25, 50, 100, -1],
  //           [25, 50, 100, "All"],
  //         ],
  //       });
  //     }
  //   });

  return (
    <>
      {ShowAdvancedSearch && (
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={800}
          animationOut="fadeOut"
          isVisible={ShowAdvancedSearch}
        >
          <Card>
            <CardHeader title="Search Resident" />
            <CardBody>
              <div
                className="alert border-primary bg-light-primary fade show mb-5"
                role="alert"
              >
                <div className="d-flex align-items-center">
                  <div className="alert-icon">
                    <i className="fas fa-info-circle mr-4 text-primary"></i>
                  </div>
                  <div className="flex-1">
                    <span>
                      {intl.formatMessage({ id: "FIND_PATIENTS_DIRECTLY" })}
                      <ol>
                        <li>{intl.formatMessage({ id: "USE_THE_BEGINNING_OF_FIRST_OR_LAST_NAME" })}</li>
                        <li>{intl.formatMessage({ id: "USE_FULL_DATE_OF_BIRTH_IN_MM_DD_YYYY_FORMAT" })}</li>
                        <li>{intl.formatMessage({ id: "USE_FULL_SSN_AS_NNN_NN_NNNN_OR_NNNNNNNNN_FORMAT" })}</li>
                      </ol>
                      {intl.formatMessage({ id: "RESIDENT.OTHERWISE_YOU_WILL_GET" })}
                    </span>
                  </div>
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="PatientName">
                        {intl.formatMessage({ id: "RESIDENT.PACIENT_NAME" })}
                      </label>
                      <input
                        type="text"
                        id="PatientName"
                        className="form-control form-control-sm"
                      />
                      <small>{intl.formatMessage({ id: "RESIDENT.USE_3_OR_MORE_LETTERS" })}</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="DOB">
                        {intl.formatMessage({ id: "RESIDENT.DOB" })}
                      </label>
                      <input
                        type="text"
                        id="DOB"
                        className="form-control form-control-sm"
                      />
                      <small>{intl.formatMessage({ id: "RESIDENT.USE_MM_DD_YYYY" })}</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="SSN">
                        {intl.formatMessage({ id: "RESIDENT.SSN" })}
                      </label>
                      <input
                        type="text"
                        id="SSN"
                        className="form-control form-control-sm"
                      />
                      <small>{intl.formatMessage({ id: "RESIDENT.MUST_BE_NNN_NN_NNNN" })}</small>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button variant="success" className="mr-5 px-10" size="sm">
                {intl.formatMessage({ id: "RESIDENT.FIND" })}
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={showAdvanceSearchSection}
              >
                {intl.formatMessage({ id: "RESIDENT.CANCEL" })}
              </Button>
            </CardFooter>
          </Card>
        </Animated>
      )}

      <Card className="example example-compact">
        <CardHeader>
          <div className="card-title">
            <div className="row">
              <div className="col-sm-8 col-md-8 col-lg-8 col-xl-8">
                <span>

                  {intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.TITLE" })} : <b className="text-success">{props.facility.facilityName}</b>,

                  {intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.CHAIN" })}: {" "} {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
                  {intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.OWNERSHIP" })}:  {" "} {props.facility.organizationName == "" ? "N/A" : props.facility.organizationName},{" "}

                  <br />
                  {intl.formatMessage({ id: "FACILITY_RESIDENT.SHIP_DATE" })}:{" "}
                  {props.facility.cycleDate == "1/1/1" ? intl.formatMessage({ id: "FACILITY_RESIDENT.N_A" }) : Common.GetDateString(props.facility.cycleDate) + (props.facility.isSecondaryCycle ? " (S)" : "")}{" "}

                  <FormControlLabel

                    label={<Typography >
                      <h4 className="mt-4"> {intl.formatMessage({ id: "FACILITY_RESIDENT.FACILITY.PART_B" })}:</h4>
                    </Typography>
                    }
                    labelPlacement='start'
                    control={
                      <Checkbox className="mt-2" checked={props.facility.medicarePartBbyGentell} value="checkedA" />
                    }
                  />

                  <FormControlLabel
                    label={<Typography >
                      <h4 className="mt-4"> {intl.formatMessage({ id: "FACILITY_RESIDENT.FACILITY.EQUAL_CARE" })}:</h4>
                    </Typography>
                    }
                    labelPlacement='start'
                    control={
                      <Checkbox className="mt-2" checked={props.facility.isEcp} value="checkedB" />
                    }
                  />
                  <FormControlLabel
                    label={<Typography >
                      <h4 className="mt-4">{intl.formatMessage({ id: "FACILITY_RESIDENT.FACILITY.OUTS" })}:</h4>
                    </Typography>
                    }
                    labelPlacement='start'
                    control={
                      <Checkbox className="mt-2" checked={props.facility.outProgram} value="checkedC" />
                    }
                  />
                </span>
                <h3 className="card-label mt-3">{intl.formatMessage({ id: "FACILITY_RESIDENT.RESIDENT_LIST.TITLE" })}</h3>

              </div>

              <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">

                {toolbar}
              </div>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
            residentListUI
          )}
        </CardBody>
      </Card>
      <PopupModal
        title={intl.formatMessage({ id: "FACILITY_RESIDENT.RESIDENT_INFO.TITLE" })}
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          residentInfoModal = ref;
        }}
      >
        {" "}
        {
          isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >
            <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4 ' onClick={() => { setisResidentInfoEdit(!isResidentInfoEdit); }}>{intl.formatMessage({ id: "FACILITY_RESIDENT.BUTTON.GO_BACK_TO_DETAIL" })}</Button>
            </div>
            <Newresident
              ref={(ref) => {
                UpsertNewresident = ref;
              }}
              FacilityId={FacilityId}
              setResidentLoading={setResidentLoading}
              Resident={Resident}
              facility={props.facility}
              close={() => {
                getAllResident(false);
                setResidentLoading(false);
                residentInfoModal.hideModal();
              }}
            />
            <Button className='mb-4 btn-success ml-2' disabled={residentLoading} onClick={() => { UpsertNewresident.handleSubmit() }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {residentLoading && <span className="mr-8 spinner spinner-white"></span>}
                <span>{'Save Changes'}</span>
              </span>
            </Button>
          </Animated>

        }

        {
          !isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >       <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4' onClick={() => { setisResidentInfoEdit(!isResidentInfoEdit); }}>{intl.formatMessage({ id: "FACILITY_RESIDENT.BUTTON.EDIT" })}</Button>
            </div>
            <ResidentDetails resident={Resident} getAllResident={() => { getAllResident(false) }} />{" "}
          </Animated>
        }
        {" "}
      </PopupModal>
      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">{intl.formatMessage({ id: "FACILITY_RESIDENT.RESIDENT_STATUS.TITLE" })}</h3>
            <small className="">
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.TITLE" })}:</b> {props.facility.facilityName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.OWNERSHIP" })}:</b> {" "} {props.facility.organizationName == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.organizationName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.CHAIN" })}:</b>{" "} {props.facility.chain == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        // dialogClassName="modal-90w"
        ref={(ref) => {
          residentStatusModal = ref;
        }}
        showButton={true}
        buttonPress={() => addStatusRef.onSubmit()}
        module={ModulesName.Module_Residents}
        action={UserActions.Update}
      >
        {" "}
        <ResidentStatus
          ref={(ref) => {
            addStatusRef = ref;
          }}
          resident={Resident}
          close={() => {
            getAllResident();
            residentStatusModal.hideModal();
            //  props.afterAuthorize();
            //  setOrderApproved(true)
          }}
        />{" "}
      </PopupModal>

      <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">{intl.formatMessage({ id: "FACILITY_RESIDENT.WOUND_AMEND_MESSAGES" })}</h3>
            <small className="">
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.TITLE" })}:</b> {props.facility.facilityName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.OWNERSHIP" })}:</b> {" "} {props.facility.organizationName == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.organizationName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.CHAIN" })}:</b>{" "} {props.facility.chain == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          woundAmendModal = ref;
        }}
      >
        {" "}
        <ViewWoundInformationChangeReason
          residentId={ResidentID}
          woundId={0}
        />{" "}
      </PopupModal>

      <PopupModal
        // title="Sales Order Message"
        title={<div className="card-title">
          <span>
            <h3 className="card-label">{intl.formatMessage({ id: "FACILITY_RESIDENT.SKIP_RESIDENT_MESSAGE" })}</h3>
            <small className="">
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.TITLE" })}:</b> {props.facility.facilityName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.OWNERSHIP" })}:</b> {" "} {props.facility.organizationName == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.organizationName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.CHAIN" })}:</b>{" "} {props.facility.chain == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        showButton={false}
        ref={(ref) => {
          noOrderModal = ref;
        }}

      >
        {" "}
        <SkipResidentMsg msg={null} fkResidentId={ResidentID} isNoOrder={isNoOrder} isProcessorNoOrder={isProcessorNoOrder} onSave={() => { noOrderModal.hideModal(); getAllResident() }} />
        {" "}
      </PopupModal>

      <PopupModal
        title={intl.formatMessage({ id: "ECP_REASON" })}
        ref={(ref) => {
          skipECPModal = ref;
        }}
      >
        {" "}
        <ECPReason
          refresh={() => { getAllResident(); skipECPModal.hideModal() }}
          resident={Resident} />
        {" "}

      </PopupModal>
      <PopupModal
        // title="Sales Order Message"
        title={<div className="card-title">
          <span>
            <h3 className="card-label">{intl.formatMessage({ id: "FACILITY_RESIDENT.SKIP_WOUNDS_MESSAGE" })}</h3>
            <small className="">
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.TITLE" })}:</b> {props.facility.facilityName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.OWNERSHIP" })}:</b> {" "} {props.facility.organizationName == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.organizationName},{" "}
              <b>{intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.CHAIN" })}:</b>{" "} {props.facility.chain == "" ? intl.formatMessage({ id: "FACILITY_RESIDENT_FACILITY.NA" }) : props.facility.chain},{" "}
            </small>
          </span>
        </div>}
        showButton={false}
        ref={(ref) => {
          showSkipWoundMsgModal = ref;
        }}

      >
        {" "}
        <SkipWoundMsg fkResidentId={ResidentID} isNoOrder={isNoOrder} isProcessorNoOrder={isProcessorNoOrder} onSave={() => { showSkipWoundMsgModal.hideModal() }} />
        {" "}
      </PopupModal>
    </>
  );
}

export default injectIntl(BillingResident);

/*eslint-disable */

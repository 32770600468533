import { useFormik } from "formik";
import React, { useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { requestResetPassword } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";

const initialValues = {
  Email: "",
};

function ForgotPassword(props) {
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [SetPasswordResponse,setPasswordResponse]=useState("");
  const ForgotPasswordSchema = Yup.object().shape({
    Email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.WRONG_EMAIL_FORMAT" }))
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MINUMUM_SYMBOLS" }))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAXIMUM_SYMBOLS" }))
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // // debugger
      requestResetPassword(values.Email)
          .then((response) => {
            // // debugger
          setIsRequested(true);
            setPasswordResponse(response.data)
            
           
           })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" },
              { name: values.Email }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {SetPasswordResponse}
                </div>
              </div> }
      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1"> <FormattedMessage id="AUTH.FORGOT.TITLE" /> </h3>
            <div className="text-muted font-weight-bold">
              
              <FormattedMessage id="AUTH.FORGOT.DESC" />
              
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                type="Email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "Email"
                )}`}
                name="Email"
                placeholder={intl.formatMessage(
                  { id: "FORGOT_PASSWORD.EMAIL.TITLE" }
                )} 
                {...formik.getFieldProps("Email")}
              />
              {formik.touched.Email && formik.errors.Email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.Email}</div>
                </div>
              ) : null}
            </div>
          
            <div className="form-group d-flex flex-wrap flex-center">
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={formik.isSubmitting}
              >
                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                
              </button>
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="BUTTON.CANCEL.TITLE" />

                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));

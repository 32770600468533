import React from "react";
import cardHeaderBR from "./lang/cardHeaderBR";
import cardHeaderUS from "./lang/cardHeaderUS";
import { withMultiRegionComponent } from "@/shared/wrappers/withMultiRegion";

const CardHeaderMultiRegion = withMultiRegionComponent({
  US: cardHeaderUS,
  BR: cardHeaderBR,
});

export default function AssessmentQueueCardHeaderWrapper(props) {
  return <CardHeaderMultiRegion {...props} />;
}

/* eslint-disable no-loop-func */
import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import React from 'react';
import { Animated } from "react-animated-css";
import { Badge, Button, Form, Dropdown, DropdownButton, ButtonToolbar } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import CheckPermission from "../../General/CheckPermission";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import NewWound from './newwound';
import PopupModal from "../modal/popupmodal";
import RBAC,{ ModulesName,UserActions } from '../../General/roleModuleActions';
import Newresident from "../modal/residentsmodals/newresident";
import ResidentStatus from "../resident/residentStatus";
import ResidentDetails from "@/shared/components/residentDetails";
import CloseWound from '../resident/closeWound';
import moment from 'moment';
import NewWoundAssessment from '../resident/newwoundassessment';
import { WOUND_TYPES } from '../../General/wound-type';

import { injectIntl } from "react-intl";

var editWoundmodal, UpsertNewresident,residentStatusModal, 
AddResidentModal,addStatusRef,closeWoundModal,woundAssessmentModel,residentInfoModal = null;

var _sampleWoundObj = {
  fkResidentId: 0,
  residentName: null,
  facilityName: null,
  roomUnit: null,
  // evaluatedBy: Common.replaceNullWithString(user.firstName) + " " + Common.replaceNullWithString(user.lastName),
  physicanName: null,
  // createdBy: user.userID,
  // modifiedBy: user.userID,
  btPatientBrightreeID: null,
  wound:
  {
    id: 0,
    // evaluationDate: '',
    // dateFirstObserved: '',
    // fkAcquisitionId: null,
    fkWoundLocationId: null,
    woundLocation: "",
    fkWoundTypeId: null,
    woundDescription: '',
    fkWoundThicknessId: null,
    //fkWoundSevereTissueTypeId: null,
    fkWoundLateralityId: null,
    woundLength: '',
    woundWidth: '',
    woundDepth: '',
    isUtd: false,
    tunneling: false,
    tunnelingDepth: null,
    tunnelingClockPosition: '',
    undermining: false,
    underminingDepth: null,
    underminingClockPosition: null,
    toUnderminingClockPosition:null,
    icdcode: "",
    fkExudateAmountId: null,
    fkExudateTypeId: null,
    exudateColor: '',
    fkDebridementTypeId: null,
    debridementDate: null,
    surgicalDate: null,
    odor: false,
    Pain: false,
    painMedicationOrdered: false,
    sloughPercentage: null,
    necroticPercentage: null,
    //FkWoundCleanseId :'',
    cleanseDescription: null,
    fkDressingChangeFrequencyId: null,
    //fkPrimaryDressingId :null,
    //FkAdditionalPrimaryDressingId :'',
    otherAdditionalPrimaryDressing: '',
    //fkSecondaryDressingId :null,
    //FkAdditionalSecondaryDressingId :'',
    otherAdditionalSecondaryDressing: '',
    treatment: '',
    comments: '',
    isPartailOrder:false,
    thicknessIdForBackStagging: null,
    orientationLeftRight: null,
    orientationUpperLower: null,
    orientationInnerOuter: null,
    orientationAnteriorPosterior: null,
    orientationMedialLateral: null,
    orientationDorsalDistalProximal: null
    // FkWoundcareProductTypeId :'',

  }
}
// export default class FindResident extends React.Component {
export class FindResident extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      formValidated: false,
      residentListUI: [],
      resident:"",
      ResidentFirstName:'',
      ResidentLastName:'',
      addWoundComponent: null,
      addWoundAssessmentComponent: null,
      isResidentInfoEdit:false,
      Resident:"",
      ResidentID:"",
      findResident:
      {
        residentName: null,
        dob: null,
        ssn: ""

      },
      residentLoading: false



    }

  }

  componentDidMount() {

  }
  handleShowAddResident = (obj) => {
    // setResident(obj);
    AddResidentModal.showModal();
  };

  handleShowUpdateResident = (obj) => {
    // setResident(obj);
    this.setState({isResidentInfoEdit:false,
                   Resident:obj,
                  })
                  residentInfoModal.showModal();
  };
  // const [isResidentInfoEdit, setisResidentInfoEdit] = useState(false);
  // const [Resident, setResident] = useState("");
  // const [ResidentID, setResidentID] = useState("");

  handleNewWoundModal = (obj) => {
    let residenObj = JSON.parse(JSON.stringify(_sampleWoundObj));
    residenObj.fkResidentId = obj.id;
    residenObj.residentName = Common.replaceNullWithString(obj.firstName) + " " + Common.replaceNullWithString(obj.lastName);
    residenObj.facilityName = Common.replaceNullWithString(obj.facilityName);
    residenObj.roomUnit = Common.replaceNullWithString(obj.room) + "/" + Common.replaceNullWithString(obj.unit);
    residenObj.physicanName = Common.replaceNullWithString(obj.primaryPhysicianName);
    residenObj.evaluatedBy= Common.replaceNullWithString(this.props.user.firstName) + " " + Common.replaceNullWithString(this.props.user.lastName);
    residenObj.btPatientBrightreeID = obj.btPatientBrightreeID;
    if(obj.fkResidentStatusId==Common.ResidentStatusID.open){
    this.setState({ addWoundComponent: <NewWound refresh={this.props.refresh}  isEdit={false} 
      isReorder={false} 
      showProduct={false}
      isReadOnly={false}
      user={this.props.user}
      displayProduct={true}
      queue={this.props.queue || Common.ProcessStatus.workQueueID}
      resident={residenObj} close={() => this.handleCloseWoundmodalPopup()} />,
      ResidentFirstName:obj.firstName,
      ResidentLastName:obj.lastName

     })

    editWoundmodal.showModal()
  }else{
    Common.showSuccessAlert("Resident is not open please update resident status to add/update new wound", "warning", "Atenção!", 3000);

}
  }
    handleNewWoundDocumentModal =async (obj) => {
      let residenObj = JSON.parse(JSON.stringify(_sampleWoundObj));

      residenObj = {
        ...residenObj,
        wound: {
          ...residenObj.wound,
          isDOcumentation: true,
        },
      };

      residenObj.fkResidentId = obj.id;
      residenObj.residentName = Common.replaceNullWithString(obj.firstName) + " " + Common.replaceNullWithString(obj.lastName);
      residenObj.facilityName = Common.replaceNullWithString(obj.facilityName);
      residenObj.roomUnit = Common.replaceNullWithString(obj.room) + "/" + Common.replaceNullWithString(obj.unit);
      residenObj.physicanName = Common.replaceNullWithString(obj.primaryPhysicianName);
      residenObj.evaluatedBy= Common.replaceNullWithString(this.props.user.firstName) + " " + Common.replaceNullWithString(this.props.user.lastName);
      residenObj.btPatientBrightreeID = obj.btPatientBrightreeID;
      if(obj.fkResidentStatusId==Common.ResidentStatusID.open){
    this.setState({ addWoundAssessmentComponent:<NewWoundAssessment isEdit={false} canView={true} 
        user={this.props.user} 
        isReorder={false}
        resident={residenObj} close={this.handleCloseWoundAssessmentmodalPopup} 
  
        />,
        ResidentFirstName:obj.firstName,
        ResidentLastName:obj.lastName
      })
        woundAssessmentModel.showModal()
      }else{
        Common.showSuccessAlert("Resident is not open please update resident status to add/update new wound", "warning", "Atenção!", 3000);
    
    }
}
  handleCloseWoundmodalPopup = () => {
    try {
      editWoundmodal.hideModal(true);
    } catch (error) {
      Common.LogError(error, "WorkqueueWounds", "handleNewWoundModal");
    }
  }
   handleCloseWoundAssessmentmodalPopup = () => {
    try {
        woundAssessmentModel.hideModal();
    } catch (error) {
        Common.LogError(error, "WorkqueueWounds", "handleNewWoundAsessmentModal");
    }
  }

  handleNewWound = (newWoundDetail) => {
    Common.showConfirmation(
      "Would you like to enter a wound.",
      "",
      this.props.createWoundType === WOUND_TYPES.ASSESSMENT ? this.handleNewWoundDocumentModal : this.handleNewWoundModal,
      null,
      newWoundDetail,
      "Yes",
      false,
      null,
      "No"
    );
  }

  handleSubmit = (event) => {
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state
      _state.formValidated = true;
      this.setState(_state);
    }
    else {
      this.setState({ residentListUI: [] });
      this.findResident();
    }
  }
   handleResidentActiveStatus =  (resident) => {
     this.setState({resident:resident})
    residentStatusModal.showModal();
  }
  changeResidentLoading = (val) => {
    this.setState({ residentLoading: val });
  };
  findResident() {

    var dob = this.state.findResident.dob == '' ? null : this.state.findResident.dob;
    var name = this.state.findResident.residentName == '' ? null : this.state.findResident.residentName;
    var ssn = this.state.findResident.ssn == '' ? null : this.state.findResident.ssn.replace(/[^0-9]/g, "").substr(0, 9) 


    Api.getRequestQry(
      Api.URI_GetAllResidentByFacilityID,
      "?facilityID=" + this.props.facilityId + (name != null ? "&residentName=" + name : "") +
      (ssn != null ? "&ssn=" + ssn : "") + (dob == null ? "" : "&dob=" + dob),
    )
      .then((res) => {
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              this.setResidentList(res.data.result);
            }else{
              Common.showSuccessAlert("Resident not found", "warning", "Atenção!", 3000);
      
                }
          }else{
        Common.showSuccessAlert("Resident not found", "warning", "Atenção!", 3000);

          }
        }else{
          Common.showSuccessAlert("Resident not found", "warning", "Atenção!", 3000);
  
            }

      }).catch((ex) => {

        Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
      });
  }
  setResidentList = (list) => {
    var rows = [];
    const statusBadgeType = {
      Open: "primary",
      Discharged: "secondary",
      Closed: "success",
      Deceased: "danger",
      "Leave of Absence": "warning",
    };

    for (var i = 0; i < list.length; i++) {
      let obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + " " + obj.firstName;
      const gender = Common.replaceNullWithString(obj.genderName);
      const ssn = Common.replaceNullWithString(obj.ssn);
      var dob = new Date(obj.doB);
      const date =
        dob.getMonth() + 1 + "/" + dob.getDate() + "/" + dob.getFullYear();
      let randStatusBadgeType = statusBadgeType[obj.residentStatus];
      const residentStatus = Common.replaceNullWithString(obj.residentStatus);

      rows.push(
        <tr key={i.toString()}>
          <td>

            {name}

          </td>
          <td>{gender}</td>
          <td>{ssn}</td>
          <td>{date}</td>
          <td>
            <Badge variant={randStatusBadgeType}>{residentStatus}</Badge>{" "}
          </td>
          <td align="center">
            <>
              <ButtonToolbar className="justify-content-center">
                <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                  {[DropdownButton].map((DropdownType, idx) => (
                    <DropdownType
                      size="xs"
                      variant="primary"
                      title={this.props.intl.formatMessage({id:"DATA_TABLE.ACTIONS"})}
                      id={`dropdown-button-drop-${idx}`}
                      key={idx}
                    >
                      <CheckPermission
                        permission={UserActions.Update}
                        module={ModulesName.Module_Residents}
                        yes={() => (
                          <>
                            <Dropdown.Item eventKey="1" onClick={() => { this.handleShowUpdateResident(obj)  }}>Resident Info</Dropdown.Item>
                            <Dropdown.Item eventKey="8" onClick={() => this.handleResidentActiveStatus(obj)}>Set Resident Status</Dropdown.Item>
                            {/* <Dropdown.Item eventKey="8" className="text-danger" onClick={()=>handleResidentActiveStatus(obj)}>{obj.fkResidentStatusId==Common.ResidentStatusID.open?'Close Resident': 'Open Resident'}</Dropdown.Item> */}
                          </>
                        )}
                        no={() => null}
                      />
                      {/* <CheckPermission
                        permission={UserActions.Create}
                        module={ModulesName.Module_Wound}
                        yes={() => (
                          <><Dropdown.Item eventKey="0" onClick={() => this.handleNewWoundModal(obj)}>Add New Wound</Dropdown.Item> </>
                        )}
                        no={() => null}
                      /> */}
                      <CheckPermission
                        userRole={[RBAC.Role_Facility_Level1, RBAC.Role_Facility_Level2]}

                        yes={() => (
                          <Dropdown.Item eventKey="0" onClick={() => {
                            switch (this.props.createWoundType) {
                              case WOUND_TYPES.ASSESSMENT:
                                this.handleNewWoundDocumentModal(obj);
                                break;
                              case WOUND_TYPES.EVALUATION:
                              default:
                                this.handleNewWoundModal(obj);
                                break;
                            }
                          }}>Add New Wound</Dropdown.Item>
                        )}
                        no={() => <Dropdown.Item eventKey="0" onClick={() => {
                          switch (this.props.createWoundType) {
                            case WOUND_TYPES.ASSESSMENT:
                              this.handleNewWoundDocumentModal(obj);
                              break;
                            case WOUND_TYPES.EVALUATION:
                            default:
                              this.handleNewWoundModal(obj);
                              break;
                          }
                        }}>Add New Wound</Dropdown.Item>}
                      />

                      <Dropdown.Item eventKey="89" onClick={() => { this.setState({resident:obj}); closeWoundModal.showModal() }}>Show Closed Wound</Dropdown.Item>
                    </DropdownType>
                  ))}
                </div>

              </ButtonToolbar>
            </>
          </td>
        </tr>
      );
    }

    this.setState({ residentListUI: rows });
  };
  formatSocialSecurity(val){
    
   val = val.replace(/\D/g, '');
   val = val.replace(/^(\d{3})/, '$1-');
   val = val.replace(/-(\d{2})/, '-$1-');
   val = val.replace(/(\d)-(\d{4}).*/, '$1-$2');
   return val;
 } 
  render() {
    return (
      <>
        <PopupModal onClose={(ref) => {
          Common.showConfirmation('Are you sure you want to close without saving the data?', 'Warning',
           () => { ref.setState({ show: false }); }, () => { }, null,"Exit Without Saving");

        }} 
        title= {<div className="card-title">
        <span>
          <h3 className="card-label">Wound Evaluation - First Name:{" "} {Common.replaceNullWithString(this.state.ResidentFirstName)},{" "}Last Name: {Common.replaceNullWithString(this.state.ResidentLastName)}</h3>
        
        </span>
        </div> }
        dialogClassName="modal-95w" ref={ref => { editWoundmodal = ref; }}> {this.state.addWoundComponent} </PopupModal>
        <Form
          validated={this.state.formValidated}
        >

          <Card>
            <CardHeader title={this.props.intl.formatMessage({ id: "FACILITY_RESIDENT.FIND_RESIDENT" })} />
            <CardBody>
              <div
                className="alert border-primary bg-light-primary fade show mb-5"
                role="alert"
              >
                <div className="d-flex align-items-center">
                  <div className="alert-icon">
                    <i className="fas fa-info-circle mr-4 text-primary"></i>
                  </div>
                  <div className="flex-1">
                    <span>
                      {this.props.intl.formatMessage({id: "FIND_RESIDENT.FIND_PATIENTS_DIRECTLY"})}:
                      <ol>
                        <li>{this.props.intl.formatMessage({id: "FIND_RESIDENT.USE_BEGINNING_OF_FIRST_OR_LAST_NAME"})}</li>
                        <li>{this.props.intl.formatMessage({id: "FIND_RESIDENT.USE_FULL_DATE_DOB"})}</li>
                        <li>{this.props.intl.formatMessage({id: "FIND_RESIDENT.USE_FULL_SSN_AS"})}</li>
                      </ol>
                      {this.props.intl.formatMessage({id: "FIND_RESIDENT.OTHERWISE_YOU_WILL_GET"})}
                    </span>
                  </div>
                </div>
              </div>
              {/* <form> */}
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="PatientName">
                      {this.props.intl.formatMessage({id: "FIND_RESIDENT.PACIENT_NAME"})}
                      </label>
                      <input
                        type="text"
                        id="PatientName"
                        name="residentName"
                        onChange={e => Common.updateInputValueInState(this.state.findResident, e, this, this.state)}
                        className="form-control form-control-sm"
                      />
                      <small>{this.props.intl.formatMessage({id: "FIND_RESIDENT.USE_3_OR_MORE_LETTERS"})}</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="DOB">
                      {this.props.intl.formatMessage({id: "FIND_RESIDENT.DOB"})}
                      </label>
                      {/* <input
                        type="date"
                        id="DOB"
                        name="dob"
                        onChange={e => Common.updateInputValueInState(this.state.findResident, e, this, this.state)}
                        defaultValue={this.state.findResident.dob!=null?Common.getInputStringDate(
                          new Date(this.state.findResident.dob)
                        ):null}
                        className="form-control form-control-sm"
                      /> */}
                          <input
                        type="date"
                        id="doB"
                        className="form-control form-control-sm"
                        name="dob"
                        min='1900-01-01'
                        max={Common.getInputStringDateUsingMoment(moment())}
                        defaultValue={this.state.findResident.dob!=null?Common.getInputStringDate(
                          new Date(this.state.findResident.dob)
                        ):""}
                        onChange={(e) =>
                          Common.updateInputValueInState(
                            this.state.findResident,
                            e,
                            this,
                            this.state
                          )
                        }
                      />
                      <small>{this.props.intl.formatMessage({id: "FIND_RESIDENT.USE_MM_DD_YYYY"})}</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="SSN">
                      {this.props.intl.formatMessage({id: "FIND_RESIDENT.SSN"})}
                      </label>
                      <input
                        type="text"
                        id="SSN"
                        name="ssn"
                        value={this.formatSocialSecurity(
                          this.state.findResident.ssn
                        )}
                        onChange={e => Common.updateInputValueInState(this.state.findResident, e, this, this.state)}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
              {/* </form> */}
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>
              {this.props.intl.formatMessage({id: "FIND_RESIDENT.FIND"})}
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={this.props.closeSearch}
              >
                {this.props.intl.formatMessage({id: "FIND_RESIDENT.CLOSE_SEARCH"})}
              </Button>
            </CardFooter>
          </Card>
          <Card className="example example-compact">
            <CardHeader  >
              <div className="card-title">
                <span>
                  <h3 className="card-label">{this.props.intl.formatMessage({id: "FIND_RESIDENT.RESIDENT_LIST"})}</h3>

                </span>
              </div>


              <div className="card-toolbar">
                <div className="example-tools">
                  <CheckPermission
                    permission={UserActions.Create}
                    module={ModulesName.Module_Residents}
                    yes={() => (
                      <Button
                        variant="primary"
                        size="sm"
                        className="mr-5"
                        onClick={() => {
                          this.handleShowAddResident(null);
                        }}
                      >
                        {this.props.intl.formatMessage({id: "FIND_RESIDENT.NEW_RESIDENT"})}
                      </Button>
                    )}
                    no={() => (
                      <Button variant="primary" size="sm" className="mr-5" disabled="true">
                        {this.props.intl.formatMessage({id: "FIND_RESIDENT.NEW_RESIDENT"})}
                      </Button>
                    )}
                  />

                </div>
              </div>

            </CardHeader>
            <CardBody>
              {/* {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : */}
              {/* ( */}
              <table
                className="table table-striped table-hover table-sm table-bordered"
                id="residentsList"
              >
                <thead>
                  <tr>
                    <th>{this.props.intl.formatMessage({id: "FIND_RESIDENT.NAME"})}</th>
                    <th>{this.props.intl.formatMessage({id: "FIND_RESIDENT.GENDER"})}</th>
                    <th>{this.props.intl.formatMessage({id: "FIND_RESIDENT.SSN"})}</th>
                    <th>{this.props.intl.formatMessage({id: "FIND_RESIDENT.NEW_RESIDENT"})}</th>
                    <th>{this.props.intl.formatMessage({id: "FIND_RESIDENT.STATUS"})}</th>
                    <th width="20%">{this.props.intl.formatMessage({id: "DATA_TABLE.ACTIONS"})}</th>
                  </tr>
                </thead>
                <tbody>{this.state.residentListUI}</tbody>
              </table>
              {/* )} */}
            </CardBody>
          </Card>


        </Form>
        {/* Add/edit resident modal */}
        <PopupModal title="Wound Assessment" dialogClassName="modal-95w" ref={ref => { woundAssessmentModel = ref; }}> {this.state.addWoundAssessmentComponent} </PopupModal>
        <PopupModal
          title={<div className="card-title">
            <span>
              <h3 className="card-label">{this.props.intl.formatMessage({id: "FIND_RESIDENT.ADD_RESIDENT"})}</h3>
              <small className="">
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.FACILITY"})}:</b> {this.props.facility.facilityName},{" "}
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.OWNERSHIP_HEADQUARTERS"})}:</b>  {" "} {this.props.facility.organizationName == "" ? "N/A" : this.props.facility.organizationName},{" "}
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.PURCHASING_GROUP_CHAIN"})}:</b>  {" "} {this.props.facility.chain == "" ? "N/A" : this.props.facility.chain},{" "}
              </small>
            </span>
          </div>}
          dialogClassName="modal-97w"
          showButton={true}
          ref={(ref) => {
            AddResidentModal = ref;
          }}
          buttonDisabled={this.state.residentLoading}
          buttonPress={() => UpsertNewresident.handleSubmit()}
          module={ModulesName.Module_Residents}
          action={UserActions.Create}
          onClose={(ref)=>{
            Common.showConfirmation( this.props.intl.formatMessage({id : "BUTTON.CLOSE_WITHOUT_SAVING_DATA.TITLE"}) , this.props.intl.formatMessage({id : "BUTTON.CLOSE_WITHOUT_SAVING_DATA.TITLE"}),
            ()=>{ ref.setState({ show: false }); },() => {},null, this.props.intl.formatMessage({id : "BUTTON.EXIT_WITHOUT_SAVING.TITLE"}) );
       }}
        >
          <Newresident
            ref={(ref) => {
              UpsertNewresident = ref;
            }}
            //   userID={user.userID}
            FacilityId={this.props.facility.id}
            facility={this.props.facility}
            setResidentLoading={this.changeResidentLoading}
            handleNewWound={this.handleNewWound}
            close={() => {
              this.findResident();
              AddResidentModal.hideModal(true);
              this.changeResidentLoading(false)
            }}
          />
        </PopupModal>
        <PopupModal
        title={<div className="card-title">
          <span>
            <h3 className="card-label">Resident Close Wound</h3>
            <small className="">
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.FACILITY"})}:</b> {this.props.facility.facilityName},{" "}
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.OWNERSHIP_HEADQUARTERS"})}:</b>  {" "} {this.props.facility.organizationName == "" ? "N/A" : this.props.facility.organizationName},{" "}
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.PURCHASING_GROUP_CHAIN"})}:</b>  {" "} {this.props.facility.chain == "" ? "N/A" : this.props.facility.chain},{" "}
              </small>
           
          </span>
        </div>}
        dialogClassName="modal-95w" ref={ref => { closeWoundModal = ref; }}>
        <CloseWound resident={this.state.resident}     close={() => {
       
       closeWoundModal.hideModal();
       this.props.refresh()
          //  props.afterAuthorize();
          //  setOrderApproved(true)
        }} />
      </PopupModal>
        <PopupModal
      
      title={<div className="card-title">
        <span>
          <h3 className="card-label">{this.props.intl.formatMessage({id: "FIND_RESIDENT.RESIDENT_STATUS"})}</h3>
          
              <small className="">
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.FACILITY"})}:</b> {this.props.facility.facilityName},{" "}
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.OWNERSHIP_HEADQUARTERS"})}:</b>  {" "} {this.props.facility.organizationName == "" ? "N/A" : this.props.facility.organizationName},{" "}
                <b>{this.props.intl.formatMessage({id: "FIND_RESIDENT.PURCHASING_GROUP_CHAIN"})}:</b>  {" "} {this.props.facility.chain == "" ? "N/A" : this.props.facility.chain},{" "}
              </small>
            </span>
      </div>}
      // dialogClassName="modal-90w"

      ref={(ref) => {
        residentStatusModal = ref;
      }}
      showButton={true}
      buttonPress={() => addStatusRef.onSubmit()}
      module={ModulesName.Module_Residents}
      action={UserActions.Update}
    >
      {" "}
      <ResidentStatus
        ref={(ref) => {
          addStatusRef = ref;
      }}
        intl={this.props.intl}
        resident={this.state.resident}
        close={() => {
          this.findResident();
          residentStatusModal.hideModal();
          //  props.afterAuthorize();
          //  setOrderApproved(true)
        }}
      />{" "}
    </PopupModal>

    <PopupModal
        title="Resident Info"
        dialogClassName="modal-90w"
        showButton={false}
        ref={(ref) => {
          residentInfoModal = ref;
        }}
        onClose={(ref) => {
          Common.showConfirmation( this.props.intl.formatMessage({id : "BUTTON.CLOSE_WITHOUT_SAVING_DATA.TITLE"}) , this.props.intl.formatMessage({id : "BUTTON.CLOSE_WITHOUT_SAVING_DATA.TITLE"}),
          ()=>{ ref.setState({ show: false }); },() => {},null, this.props.intl.formatMessage({id : "BUTTON.EXIT_WITHOUT_SAVING.TITLE"}) );
        }}
      >
        {" "}
        {
          this.state.isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >
            <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4 ' onClick={() => { this.setState({isResidentInfoEdit:!this.state.isResidentInfoEdit}); }}> { this.props.intl.formatMessage({id : "BUTTON.GO_BACK_TO_DETAIL"}) } </Button>
            </div>
            <Newresident
              ref={(ref) => {
                UpsertNewresident = ref;
              }}
              FacilityId={this.props.facility.id}
              Resident={this.state.Resident}
              facility={this.props.facility}
              setResidentLoading={this.changeResidentLoading}
              close={() => {
                this.findResident();
                residentInfoModal.hideModal(true);
                this.changeResidentLoading(false);
              }}
            />
            <div className="row d-flex align-items-center justify-content-end">
            <Button className='mb-4 btn-success ml-2' disabled={this.state.residentLoading} onClick={() => { UpsertNewresident.handleSubmit() }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
        {this.state.residentLoading && <span className="mr-8 spinner spinner-white"></span>}
        <span>{'Save Changes'}</span>
    </span>
              </Button>
            </div>
          </Animated>

        }

        {
          !this.state.isResidentInfoEdit && <Animated
            animationIn="fadeIn"
            animationInDuration={800}
            animationOut="fadeOut"
          // isVisible={isResidentInfoEdit}
          >       <div className="row d-flex align-items-center justify-content-end">
              <Button className='mb-4 mr-4' onClick={() => { this.setState({isResidentInfoEdit:!this.state.isResidentInfoEdit}) }}> { this.props.intl.formatMessage({id : "BUTTON.EDIT.TITLE"}) } </Button>
            </div>
            <ResidentDetails resident={this.state.Resident} getAllResident={() => { this.findResident() }} />{" "}
          </Animated>
        }
        {" "}
      </PopupModal>
      </>
    );
  }
}
export default injectIntl(FindResident);
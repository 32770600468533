import * as $ from "jquery";
import React from "react";
import { Form } from "react-bootstrap";
import { apiUrls } from "../../../constants/endpoints";
import * as API from "../../../General/api";
import * as common from "../../../General/common";
const defaultContactObj = {
    "facilityContactID": 0,
    "facilityID": 0,
    "name": "",
    "email": "",
    "fax": "",
    "mobile": "",
    "fposition": "",
    "active": "true"
};

export default class AddContactmodal extends React.Component {

    constructor(props) {
        super(props);
        // debugger
        this.state = {
            contact: props.contact ? props.contact : defaultContactObj,
            isLoading: false,
            validated: false
        }
    }

    // componentDidMount() {
    //     $('#mobile').mask('000-000-0000');
    //     $('#fax').mask('000-000-0000');
    // }

    setIsLoading(val) {
        this.setState({ isLoading: val });
    }

    triggerSubmitInForm() {
        $("#submit").click();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.isLoading) return false;
         debugger;
        if (this.form.checkValidity() !== false)
            this.onSubmit();

        this.setState({ validated: true });
        return false;
    }

    onSubmit = () => {
        try {
             debugger;
            this.setIsLoading(true);
            var obj = { ... this.state.contact };
            obj.mobile =
            common.replaceNullWithString(obj.mobile) != ""
                ? obj.mobile.replace(/[^0-9]/g, "").substr(0, 10)
                : null;
                obj.fax =
                common.replaceNullWithString(obj.fax) != ""
                    ? obj.fax.replace(/[^0-9]/g, "").substr(0, 10)
                    : null;
            obj.facilityId = this.props.FacilityID;
            obj.active = true;
            API.postRequest( apiUrls.upsertfacilitycontact , obj, false).then(raw => {
                const response = raw.data;
                // debugger
                if (response.success) {
                    common.showSuccessAlert(response.message, "success", "Success!!");
                    this.props.refresh();
                    this.props.changeDetect(true);
                }
                else {
                    common.showSuccessAlert(response.message, "error", "Error!!");
                }
                this.setIsLoading(false);
            });
        } catch (ex) {
            
        }
    }
    handlePhone = (event) => {
        debugger
    }
    render() {
        return <>
            {this.state.isLoading ?
                <div className="row d-flex justify-content-center">
                    <span className="mt-5 spinner spinner-primary"></span>
                </div> : null}
            <Form
                ref={(ref) => { this.form = ref }}
                noValidate
                validated={this.state.validated}
                id='mainform' onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="name">Name</label>
                            <input
                             
                                className="form-control form-control-sm"
                                name="name"
                                defaultValue={this.state.contact.name}
                                required
                                onChange={(e) => {
                                    common.updateInputValueInState(
                                        this.state.contact,
                                        e,
                                        this,
                                        this.state
                                    );
                                    this.props.changeDetect(true)
                                }
                                }
                                type="text" id="name" />
                            <div className="invalid-feedback">Please provide valid value for Name</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="fposition">Position</label>
                            <input
                                className="form-control form-control-sm"
                                name='fposition'
                                defaultValue={this.state.contact.fPosition}
                                onChange={(e) =>
                                   {common.updateInputValueInState(
                                        this.state.contact,
                                        e,
                                        this,
                                        this.state
                                    );
                                    this.props.changeDetect(true)}
                                   }
                                type="text" id="fposition" 
                            />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Email">Email</label>
                            <input
                                className="form-control form-control-sm"
                                defaultValue={this.state.contact.email}
                                maxLength={254}
                                minLength={3}
                                onChange={(e) => {
                                    common.updateInputValueInState(
                                        this.state.contact,
                                        e,
                                        this,
                                        this.state
                                    );
                                    this.props.changeDetect(true)
                                }
                                }
                                required type="email" id="email" name='email' />
                            <div className="invalid-feedback">Please provide valid value for Email</div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Telephone#">Telephone Number</label>
                            <input
                                className="form-control form-control-sm"
                                required={true}
                                // value={this.state.contact.mobile}
                                value={common.replaceNullOrEmptyWithString(common.getFormattedPhoneNum(this.state.contact.mobile), "")}
                                onChange={(e) => {
                                    common.updateInputValueInState(
                                        this.state.contact,
                                        e,
                                        this,
                                        this.state
                                    );
                                    this.props.changeDetect(true);
                                }}
                                type="text" 
                                name='mobile' 
                                id="mobile"  />
                            <div className="invalid-feedback">Please provide valid value for Telephone Number</div>
                            <small>nnn-nnn-nnnn</small>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Fax">Fax </label>
                            <input
                                className="form-control form-control-sm"
                                // defaultValue={this.state.contact.fax}
                                value={common.replaceNullOrEmptyWithString(common.getFormattedPhoneNum(this.state.contact.fax), "")}
                                onChange={(e) =>
                                    {common.updateInputValueInState(
                                        this.state.contact,
                                        e,
                                        this,
                                        this.state
                                    );
                                    this.props.changeDetect(true);
                                    }
                                
                                }
                                type="text" id="fax" name='fax'  />
                            <small>nnn-nnn-nnnn</small>
                        </div>
                    </div>
                </div>
                <button type='submit' id='submit' className='d-none'></button>
            </Form>
        </>
    }
}
import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls/Card";
import Select, { createFilter } from "react-select";
import jsPDF from "jspdf";
import * as Api from "../../General/api";
import html2canvas from "html2canvas";
import moment from "moment";
import HttpResponse from "../../General/validate-response";
import DatePicker from "react-datepicker";
import { showSuccessAlertAutoClose } from "../../General/common";

/** @typedef {import('../../jsdoc/historical-report.jsdoc').HistoricalReportModel} HistoricalReportModel */
/** @typedef {import('../../jsdoc/historical-report.jsdoc').WoundNameViewModel} WoundNameViewModel */
/** @typedef {import('../../jsdoc/historical-report.jsdoc').ResidentNameViewModel} ResidentNameViewModel */

export default function SalesOrderReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [facilityList, setFacilityList] = useState([]);
  const [facilityName, setFacilityName] = useState();
  const [residentsList, setResidentsList] = useState([]);
  const [residentName, setResidentName] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [fromDate, setfromDate] = useState(new Date());
  const [toDate, settoDate] = useState(new Date());

  const searchReport = async (e) => {
    e.preventDefault();

    const requiredFields = [facilityName, fromDate, toDate];

    const isAllFieldsFilled = requiredFields.every(Boolean);

    if (!isAllFieldsFilled) {
      return alert("Please fill all required fields");
    }

    setIsLoadingReport(true);

    const payload = {
      facilityId: facilityName.value,
      residentId: residentName.value,
      fromDate: moment(fromDate).format("YYYY-MM-DD"),
      toDate: moment(toDate).format("YYYY-MM-DD"),
    };

    const { data: response } = await Api.postRequest(
      `${Api.URI_GetSaleOrderByResident}`,
      payload
    );

    setIsLoadingReport(false);

    const data = HttpResponse.getData(response);

    if (!HttpResponse.isOkAndHasASuccessData(response)) {
      return showSuccessAlertAutoClose(
        data?.message,
        "warning",
        "warning",
        3000
      );
    }

    const { result: pdfUrl } = data;

    window.open(pdfUrl, "_blank");
  };

  useEffect(() => {
    const handleFacilitiesMatchingInNewFastCare = async () => {
      const response = await Api.getRequest(
        `${Api.Uri_GetFacility}?isAssessmentView=2`
      )
        .then(HttpResponse.handle)
        .catch(HttpResponse.handle);

      if (!HttpResponse.isOk(response)) return;

      /** @type {FacilityViewModel[]} */
      const facilities = Object.values(HttpResponse.getData(response));

      const facilitiesMapped = facilities.map((facility) => ({
        value: facility.id,
        label: facility.facilityName,
      }));

      const facilitiesSorted = facilitiesMapped.sort((a, b) =>
        a.label?.localeCompare(b.label)
      );

      setFacilityList(facilitiesSorted);

      setIsLoading(false);
    };

    const errorWrapper = (error) => {
      console.error(error);
      setIsLoading(false);
    };

    handleFacilitiesMatchingInNewFastCare().catch(errorWrapper);
  }, []);

  useEffect(() => {
    const handleResidents = async () => {
      const response = await Api.getRequestQry(
        `${Api.Uri_GetResidentByFacilityId}`,
        `?facilityId=${facilityName.value}&woundFilter=3`
      )
        .then(HttpResponse.handle)
        .catch(HttpResponse.handle);

      if (!HttpResponse.isOk(response)) return;

      /** @type {ResidentNameViewModel[]} */
      const residents = Object.values(HttpResponse.getData(response));

      const residentsMapped = residents.map((resident) => ({
        value: resident.key,
        label: resident.value,
      }));

      setResidentsList(residentsMapped);
    };

    const isAllFieldsFilled = [facilityName].every(Boolean);

    if (isAllFieldsFilled) handleResidents();
  }, [facilityName]);

  const changeFacilityName = (event) => {
    setResidentsList([]);

    setFacilityName(event);
  };

  const handleClearFilters = () => {
    setFacilityName(null);
    setResidentName(null);
    setResidentsList([]);
    setShowReport(false);
  };

  return (
    <>
      <Card>
        <CardHeader title="Sales Order Report"></CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
            <>
              <form onSubmit={(e) => searchReport(e)}>
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="facilityName">
                        Facility Name
                      </label>
                      <Select
                        options={facilityList}
                        id="facilityId"
                        name="facilityId"
                        required
                        isSearchable
                        isClearable
                        filterOption={createFilter({ matchFrom: "start" })}
                        onChange={(e) => {
                          changeFacilityName(e);
                          setResidentName(null);

                          setResidentsList([]);
                        }}
                        value={facilityName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="residentName">
                        Resident Name
                      </label>
                      <Select
                        options={[
                          ...(residentsList?.length > 1 ? [
                            {
                              value: 0,
                              label: "All",
                            },
                          ] : []),
                          ...residentsList,
                        ]}
                        id="residentId"
                        name="residentId"
                        required
                        isClearable
                        isSearchable
                        filterOption={createFilter({ matchFrom: "start" })}
                        onChange={(e) => {
                          setResidentName(e);
                        }}
                        value={residentName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="fromDate">
                        From date
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={fromDate}
                        onChange={(date) => setfromDate(date)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="toDate">
                        To date
                      </label>
                      <DatePicker
                        className="form-control"
                        selected={toDate}
                        onChange={(date) => settoDate(date)}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-content-end py-3">
                      <button
                        type="submit"
                        className="btn btn-success btn-sm mr-5"
                      >
                        Print Report
                      </button>
                      <button
                        className="btn btn-danger btn-sm mr-1"
                        onClick={handleClearFilters}
                      >
                        Clear filter
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <hr />
              {isLoadingReport && (
                <div className="row d-flex justify-content-center">
                  <span className="mt-5 spinner spinner-primary"></span>
                </div>
              )}
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

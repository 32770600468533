import React from 'react';
import { useUnloadPrompt } from './useUnloadPrompt'; // Adjust the path as necessary

const UnloadComponent = () => {
  const handleSave = () => {
    console.log('Saving changes...');
    // implement actual save logic here (i have logic in use unload as a stand in)
  };

  const handleCloseModal = () => {
    console.log('Closing modal...');
  };

  const { isModalOpen, handleSaveAndCloseModal, confirmAndCloseModal } = useUnloadPrompt(
    handleSave,
    handleCloseModal,
    true // this just gives you the choice of using it
  );

  return (
    <div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h2>Unsaved Changes</h2>
            <p>You have unsaved changes. Do you want to save before leaving?</p>
            <button onClick={handleSaveAndCloseModal}>Save</button>
            <button onClick={confirmAndCloseModal}>Don't Save</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UnloadComponent;
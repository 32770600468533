import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as Common from "../../../General/common";
import * as auth from "../_redux/authRedux";
class Logout extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let sessionExpired = "";
    try {
      sessionExpired = new URLSearchParams(this.props.location.search).get("se");
      if (Common.replaceNullWithString(sessionExpired) === "ex") {
        Common.showSuccessAlert("", "warning", "Session Expired");
      }
      if (Common.replaceNullWithString(sessionExpired) === "apid") {
        // Common.showSuccessAlert("We are facing some issue and working hard to fix it as soon as possible. Please try again later. Thanks", "warning", "Internal Issue");
      }
    } catch (error) {
    }
    localStorage.removeItem("gentell-fastcare-authUser");
    localStorage.removeItem("gentell-fastcare-authToken");
    this.props.logout();
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);

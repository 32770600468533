import RoutePaths from "@/app/constants/routepaths";
import { ModulesName } from "@/app/General/roleModuleActions";

const menuConfigUS = [
  {
    module: ModulesName.Module_MyWorkQueue,
    route: RoutePaths.Page_WorkQueue,
    messageId: "HEADER.MY_WORK_QUEUE",
  },
  {
    module: ModulesName.Module_BillingQueue,
    route: RoutePaths.Page_Billing,
    messageId: "HEADER.PROCESSORS_QUEUE",
  },
  {
    module: ModulesName.Module_Facility,
    route: RoutePaths.Page_Facility,
    messageId: "HEADER.FACILITY",
  },
  {
    module: ModulesName.Module_FacilityQueue,
    route: RoutePaths.Page_FacilityQueue,
    messageId: "HEADER.ASSESSMENT_QUEUE",
  },
  {
    module: ModulesName.Module_Products,
    route: RoutePaths.Page_ProductsNav,
    messageId: "HEADER.PRODUCTS",
    submenuItems: [
      { route: RoutePaths.Page_ProductList, textId: "HEADER.PRODUCT_LIST" },
      {
        route: RoutePaths.Page_ProductCategories,
        textId: "HEADER.CATEGORIES",
      },
      {
        route: RoutePaths.Page_PurchasingOrganization,
        textId: "HEADER.PURCHASING_ORGANIZATION",
      },
    ],
  },
  {
    module: ModulesName.Module_TabletData,
    route: RoutePaths.Page_TabletData,
    messageId: "HEADER.TABLET_DATA",
  },
  {
    module: ModulesName.Module_Recap,
    route: "https://www.surveymonkey.com/r/recap2021",
    messageId: "HEADER.RECAP",
    isExternal: true,
  },
  {
    module: ModulesName.Module_EHRVendors,
    route: RoutePaths.Page_EHRVendors,
    messageId: "HEADER.EHR_VENDORS",
  },
  {
    module: ModulesName.Module_Setup,
    route: RoutePaths.Page_SetupNav,
    messageId: "HEADER.ADMIN",
    submenuItems: [
      { route: RoutePaths.Page_Lookuplist, textId: "HEADER.LOOKUPS" },
      { route: RoutePaths.Page_Role, textId: "HEADER.ROLES" },
      {
        route: RoutePaths.Page_SystemSetting,
        textId: "HEADER.SYSTEM_SETTINGS",
      },
      { route: RoutePaths.Page_ICDCode, textId: "HEADER.ICD_CODE" },
      { route: RoutePaths.Page_Insurance, textId: "HEADER.SYNC_INSURANCE" },
      { route: RoutePaths.Page_NightlySync, textId: "HEADER.NIGHTLY_SYNC" },
      { route: RoutePaths.Page_UserList, textId: "HEADER.USERS" },
      {
        route: RoutePaths.Page_EMRCredential,
        textId: "HEADER.ERM_CREDENTIALS",
      },
      {
        route: RoutePaths.Page_WoundAndProductSpecialist,
        textId: "HEADER.WOUND_AND_PRODUCT_SPECIALIST",
      },
    ],
  },
  {
    module: ModulesName.Module_AssessmentReports,
    route: RoutePaths.Page_AssessmentReportNav,
    messageId: "HEADER.ASSESSMENT_REPORTS",
    submenuItems: [
      {
        route: RoutePaths.Page_WoundEvaluationReport,
        textId: "HEADER.WOUND_EVALUATION_REPORT",
      },
      {
        route: RoutePaths.Page_FacilityReport,
        textId: "HEADER.WOUND_REPORT",
      },
      { route: RoutePaths.Page_WoundReport, textId: "HEADER.TREND_REPORT" },
      { route: RoutePaths.Page_QAPIReport, textId: "HEADER.QAPI_REPORT" },
      {
        route: RoutePaths.Page_HistoricalReport,
        textId: "HEADER.HISTORICAL_REPORT",
      },
    ],
  },
  {
    module: ModulesName.Module_Reports,
    route: RoutePaths.Page_ReportNav,
    messageId: "HEADER.REPORTS",
    submenuItems: [
      {
        route: RoutePaths.Page_FacilityCoverReport,
        textId: "HEADER.PRINT_MANUAL_REPORTS",
      },
      {
        route: RoutePaths.Page_SalesOrderReports,
        textId: "HEADER.PRINT_SALES_ORDER_REPORTS",
      },
    ],
  },
  {
    module: ModulesName.Module_Physician,
    route: RoutePaths.Page_Physician,
    messageId: "HEADER.PHYSICIAN",
  },
];

export default menuConfigUS;

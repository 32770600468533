import 'jquery-mask-plugin/dist/jquery.mask.min.js';
import React from 'react';
import { Button, Form } from "react-bootstrap";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

export default class AddInsurancmodal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            formValidated: false,
            insurances: [],
            insuranceLevels: [],
            Insurance: { ...props.insurance }

        }
    }

    componentDidMount() {
        // // debugger

        this.getAllDropdowns();
    }

    render() {
        return (
            <Form
                validated={this.state.formValidated}
            >

                <>
                    <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                        <legend className="text-primary fw-500">Insurance Information </legend>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="State">Insurance</label>
                                    <select className="form-control form-control-sm"
                                        name="fkInsuranceId"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                    >
                                        <option></option>
                                        {this.setAllinsurances(this.state.insurances)}
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="State">Insurance Level</label>
                                    <select className="form-control form-control-sm"
                                        name="fkInsuranceLevelId"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                    >
                                        <option value="0">[None]</option>
                                        <option value="1">Primary</option>
                                        <option value="2">Secondary</option>
                                        <option value="3">Tertiary</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="SSN">Start Date</label>
                                    <input type="date" id="Title"
                                        name="startDate"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="LastName">End Date</label>
                                    <input type="date" id="LastName"
                                        name="endDate"
                                        required
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                    <div className="invalid-feedback">End Date Is Required</div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label"

                                        htmlFor="FirstName">Policy No</label>
                                    <input type="text" id="FirstName"
                                        name="policyNo"
                                        required
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                    <div className="invalid-feedback">Policy No Is Required</div>
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="FirstName">Pay Pct %</label>
                                    <input type="number" id="MiddleName"
                                        name="payPct"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>


                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="suffix">Deductible</label>
                                    <input type="number" id="suffix"
                                        name="deductible"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="suffix">Group No</label>
                                    <input type="text" id="suffix"
                                        name="groupNo"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="suffix">Group Name</label>
                                    <input type="text" id="suffix"
                                        name="groupName"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Status">Claim Filling Code</label>
                                    <select className="form-control form-control-sm"
                                        id="ClaimFillingCode"
                                        name="claimFillingCode"
                                        onChange={e => Common.updateInputValueInState(this.state.Insurance, e, this, this.state)}
                                    >
                                        <option value="0">Select Claim Filling Code</option>
                                        <option value="09">Self-pay</option>
                                        <option value="10">Central Certification</option>
                                        <option value="11">Other Non-Federal Programs</option>
                                        <option value="12">PPO</option>
                                        <option value="13">POS</option>
                                        <option value="14">EPO</option>
                                        <option value="15">Indemnity Insurance</option>
                                        <option value="16">HMO Medicare Risk</option>
                                        <option value="AM">Automobile Medical</option>
                                        <option value="BL">Blue Cross/Blue Shield</option>
                                        <option value="CH">Champus</option>
                                        <option value="CI">Commercial Insurance Co.</option>
                                        <option value="DS">Disability</option>
                                        <option value="HM">HMO</option>
                                        <option value="LI">Liability</option>
                                        <option value="LM">Liability Medical</option>
                                        <option value="MB">Medicare Part B</option>
                                        <option value="MC">Medicaid</option>
                                        <option value="OF">Other Federal Program</option>
                                        <option value="TV">Title V</option>
                                        <option value="VA">Veteran Administration Plan</option>
                                        <option value="WC">Workers' Compensation</option>
                                        <option value="ZZ">Unknown</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </fieldset>

                </>

                <div className="d-flex justify-content-end py-3">
                    <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
                    <Button variant="danger" size="sm" onClick={() => this.props.close()}>Cancel</Button>
                </div>

            </Form>
        );
    }

    //Functions

    //#region Get States
    async getAllDropdowns() {
        Api.getRequest(Api.Uri_GetInsurances).then((res) => {

            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        this.setState({ insurances: res.data.result })
                    }
                }
            }

        }).catch((ex) => {
        });
    }

    setAllinsurances(insurances) {
        return insurances.map(insurance => (
            <option
                key={insurance.key}
                value={insurance.key}
            >
                {insurance.value}
            </option>
        ))
    }
    //#endregion

    handleSubmit = (event) => {
        // // debugger
        const form = event.currentTarget.form;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {
            this.URI_UpsertResidentInsurance();
        }
    }

    URI_UpsertResidentInsurance() {
        let obj = this.state.Insurance;
        // // debugger 
        Api.postRequest(Api.URI_UpsertResidentInsurance, obj).then((res) => {
            // // debugger
            if (res.data.success) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Insurance Created Successfully", "success", "Success!", 3000);
                    this.props.close();
                }
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            // // debugger
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }
}//end of class

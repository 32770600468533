export const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL; 
export const blobStorageUrl = process.env.REACT_APP_BLOB_BASE_URL;
export const blobContainerResidentName = process.env.REACT_APP_BLOB_CONTAINER_RESIDENT_NAME;
export const blobContainerFacilityReportName = process.env.REACT_APP_BLOB_CONTAINER_FACILITY_REPORT_NAME;
export const blobAccount = process.env.REACT_APP_BLOB_ACCOUNT_NAME;
export const blobSas = process.env.REACT_APP_BLOB_SAS;
export const appInsightsInstrumentationKey = process.env.APPINSIGHTS_INSTRUMENTATIONKEY;
export const appInsightsConnectionString = process.env.APPINSIGHTS_CONNECTION_STRING;

//Dev & UAT & Production Old
//export const blobAccount = "attachmentsfcprod";
//export const blobSas ="?sv=2020-10-02&st=2022-03-16T08%3A22%3A25Z&se=2025-12-31T09%3A22%3A00Z&sr=c&sp=racwl&sig=3OPzHkBLCzSli8g5Q9eUXa%2FStlwq4qMXFCr21ynHZkE%3D";
//export const blobContainerName = "fcproduction";
//export const blobStorageUrl = `https://${blobAccount}.blob.core.windows.net/${blobContainerName}`;

export const defaultUrls = {
  defaultResidentPicture: "/template/media/images/nopatientpic.jpg",
  notificationHub: ApiBaseUrl + "NotificationHub",
};

export const apiUrls = {
  upsertResidentImage: ApiBaseUrl + "Resident/UpsertResidentImage",
  getallfacilitycontactsbyfacilityid:
    ApiBaseUrl + "FacilityContact/getallfacilitycontactsbyfacilityid",
  upsertfacilitycontact:
    ApiBaseUrl + "FacilityContact/upsertfacilitycontact",
  getvacationbyid: ApiBaseUrl + "Vacation/getvacationbyid",
  getallvacations: ApiBaseUrl + "Vacation/getallvacations",
  upsertvacation: ApiBaseUrl + "Vacation/upsertvacation",
  searcDoctors: "Physician/SearchDoctors",
  doctorsSearch: "Physician/DoctorsSearch",

  SearchInsurance: ApiBaseUrl + "Resident/SearchInsurance",
  //User paths
  getAllUsers: "User/GetAllUser",
  getAllEMR: "Facility/GetEMR",
  upsertEMR: "Facility/UpsertEMR",
  fetchFacilityGroups: "Facility/FetchFacilityGroups",
  updateFacilityStatus: "Facility/UpdateFacilityStatus",
  updateResidentStatus: "Resident/UpdateResidentStatus",
  updateResidentWoundStatus: "Wound/UpdateResidentWoundStatus",
  updateResidentWoundPartailStatus: "Process/UpdateResidentWoundPartailStatus",
  updateResidentWoundOrderNowStatus:
    "Process/UpdateResidentWoundOrderNowStatus",

  updateResidentWoundNoOrderStatus: "Process/UpdateResidentWoundNoOrderStatus",
  updateFacilityProcessor: "Process/UpdateFacilityProcessor",

  updateResidentNoOrderStatus: "resident/UpdateResidentNoOrderStatus",
  updateResidentECP: "resident/updateResidentECP",
  updateResidentHospice: "resident/updateResidentHospice",
  createWoundInfoChangeDetail: "wound/createWoundInfoChangeDetail",



  releaseFacilityPartailOrders: "Process/ReleaseFacilityPartailOrders",
  updateSkipOrHoldFacilityStatus: "Process/UpdateSkipOrHoldFacilityStatus",


  upsertFacilityUser: "Facility/UpsertFacilityUser",
  upsertFacilityClinician: "Facility/UpsertFacilityClinician",

  getFacilityUsers: "Facility/GetFacilityUsers",
  upsertResidentAOB: ApiBaseUrl + "Resident/UpsertAobForm",
  createRecap: "EmailUpdate",

};

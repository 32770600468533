import React from "react";
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";
import { Col, Form } from "react-bootstrap";
import DropdownValue from "./dropdownValue";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

//export function RepFirmForm () {
class EditDropdownValueForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formValidated: false,
            DropDownValue: { ... this.props.dropdown 
            }
        }
  
    }
    onSubmit () {
    
            if (this.form.checkValidity() === false) {
                let _state = this.state
                _state.formValidated = true;
                this.setState(_state);
              }
              else {
                // e.preventDefault();  
        let obj = this.state.DropDownValue;
        // const obj = this.state.DropDownValue;
        Api.postRequest(Api.Uri_EditHeaderValues, obj)
            .then(res => {
                if (res.data.success) {
                    this.props.onUpdateAPI();
                } else {
                    Common.showSuccessAlertAutoClose(res.data.message, "error", "Error!", 3000);
                }
               
            });
              }

    }
    render() {
        return (
            <Form
            ref={(ref)=> {this.form = ref}}
            validated={this.state.formValidated}
            onSubmit={e => this.onSubmit(e)}
            >
                <Form.Row>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Dropdown Value Text</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="valueText"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={this.state.DropDownValue.valueText}
                            placeholder="Dropdown Value Text"
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Dropdown Value Text Required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Dropdown Value Alias</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            name="valueAlias"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={!this.state.DropDownValue.valueAlias?"":this.state.DropDownValue.valueAlias}
                            placeholder="Dropdown Value Alias"
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">Dropdown Value Alias Required</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Order No</Form.Label>
                        <Form.Control
                            type="number"
                            name="orderNo"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={!this.state.DropDownValue.valueAlias?"":this.state.DropDownValue.orderNo}
                            placeholder="Order No"
                        />
                       
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                        <Form.Label  >Brightree ID</Form.Label>
                        <Form.Control
                            type="number"
                            name="valueBTID"
                            onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)}
                            value={!this.state.DropDownValue.valueBTID?"":this.state.DropDownValue.valueBTID}
                            placeholder="Brightree ID"
                        />
                       
                    </Form.Group>
                </Form.Row>
                <Form.Row>
                    <Form.Group>
                        <FormControlLabel
                            control={<Switch name="active" checked={this.state.DropDownValue.active} onChange={e => Common.updateInputValueInState(this.state.DropDownValue, e, this, this.state)} value="gilad" />}
                            label="Active"
                        />
                    </Form.Group>
                </Form.Row>

            </Form>
        );
    }
}

export default EditDropdownValueForm;
import axios from 'axios';
import React, { Component } from 'react';
import { Button, Col, Form } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const instance = axios.create();

class Note extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formValidated: false,
      Insurance: {
        InsuranceName: '',
        PayorLevel: '',
        ResidentId: '',
        PayorContact: '',
        PolicyNumber: '',
        GroupNumber: '',
        GroupName: '',
        Relationship: 1,
        CreatedDate: '2020-12-22T08:44:02.830Z',
        CreatedBy: 1,
        ModifiedBy: 1,
        ModifiedDate: '2020-12-22T08:44:02.830Z',

      }

    }
  }

  handleSubmit = e => {
    // // debugger
    e.preventDefault();
    const form = e.currentTarget.form;
    const obj = this.state.Insurance;
    if (form.checkValidity() === false) {
      let _state = this.state;
      _state.formValidated = true;
      this.setState(_state);
    }

    else {
      Api.postRequest(Api.Uri_CreateInsurance, obj).then((res) => {
        // 
        // this.props.onSaveAPI();
        Common.showSuccessAlertAutoClose("Insurance Added Successfully", "success", "Success!", 3000);
        this.setState({
          formValidated: false,
          Insurance: {
            InsuranceName: '',
            PayorLevel: '',
            ResidentId: '',
            PayorContact: '',
            PolicyNumber: '',
            GroupNumber: '',
            GroupName: '',
            Relationship: 1,
            CreatedDate: '2020-12-22T08:44:02.830Z',
            CreatedBy: 1,
            ModifiedBy: 1,
            ModifiedDate: '2020-12-22T08:44:02.830Z',

          }

        })
      });

    }
  }
  render() {
    return (
      <Card>
        <Form
          validated={this.state.formValidated}
        >
          <CardHeader title='Insurance' />
          <CardBody>

            <Form.Row>
              <Form.Group as={Col} md="4">
                <Form.Label>Resident</Form.Label>

                <Form.Control
                  id="Patient"
                  required
                  type="text"
                  name="ResidentId"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state
                    )
                  }
                  value={this.state.Insurance.ResidentId}
                  placeholder="Resident Id"
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Patient is Required
            </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4">
                <Form.Label> Insurance Name</Form.Label>
                <Form.Control
                  id="InsuranceName"
                  required
                  type="text"
                  name="InsuranceName"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state
                    )
                  }
                  value={this.state.Insurance.InsuranceName}
                  placeholder="Insurance Name"
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Insurance Name is Required
            </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" >
                <Form.Label> Group Name</Form.Label>
                <Form.Control
                  id="GroupName"
                  required

                  type="text"
                  name="GroupName"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state,

                    )
                  }
                  value={this.state.Insurance.GroupName}
                  placeholder="Group Name"
                />
                <Form.Control.Feedback></Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Group Name is Required
            </Form.Control.Feedback>
              </Form.Group>
              {/* <Form.Group as={Col} md="4" >
                <Form.Label> Insurance ID</Form.Label>
                <Form.Control
                  id="PolicyNumber"
                  required
                  type="text"
                  name="PolicyNumber"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state
                    )
                  }
                  value={this.state.Insurance.PolicyNumber}
                  placeholder="Insurance ID"
                />
                <Form.Control.Feedback type="invalid">
                  Insurance ID is Required
            </Form.Control.Feedback>
              </Form.Group> */}
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="4"  >
                <label className="form-label" htmlFor="Resident"> Payor Level </label>
                <select className="form-control " id="Resident"
                  name="PayorLevel"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state
                    )
                  }>
                  <option value="0">[None]</option>
                  <option value="1">Primary</option>
                  <option value="2">Secondary</option>
                  <option value="3">Tertiary</option>
                </select>
              </Form.Group>
              <Form.Group as={Col} md="4" >
                <Form.Label> Payor Contact</Form.Label>
                <Form.Control
                  id="PayorContact"
                  //required
                  type="text"
                  name="PayorContact"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state
                    )
                  }
                  value={this.state.Insurance.PayorContact}
                  placeholder="Payor Contact"
                />
              </Form.Group>
              <Form.Group as={Col} md="4" >
                <Form.Label> Group Number </Form.Label>
                <Form.Control
                  id="GroupNumber"
                  // required
                  minLength={11}
                  maxLength={11}
                  type="text"
                  name="GroupNumber"
                  onChange={(e) =>
                    Common.updateInputValueInState(
                      this.state.Insurance,
                      e,
                      this,
                      this.state,

                    )
                  }
                  value={this.state.Insurance.GroupNumber}
                  placeholder="Group Number"
                />
              </Form.Group>
            </Form.Row>
            </CardBody>
          <CardFooter className="d-flex justify-content-end py-3">
            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
          
          </CardFooter>
        </Form>
      </Card>

    );
  }
}
export default Note;
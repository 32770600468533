import React, { useCallback, useEffect, useState } from 'react';
// import { Animated } from "react-animated-css";
import Switch from '@material-ui/core/Switch';
import { Button, Col, Row, Badge, ButtonToolbar, Dropdown, DropdownButton, Form } from "react-bootstrap";
import Select from 'react-select';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import AccessoriesSelection from './accessoriesSelection';

export default class AddOstomy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ostomy: props.ostomy,

      procedures: [{ value: "1", label: "Colostomy" }, { value: "2", label: "Urostomy" }, { value: "3", label: "lleostomy" }],
      diagColostomyCodes: [],
      orderProducts:props.ostomy.saleOrderViewModels
    }
    this.productSelection = null;
  }

  componentDidMount() {
   
    if(this.props.ostomy.ostomyId>0){
      this.setDiagnosisCodeOptionsForEdit(this.props.ostomy.fkProcedureId);
    }
  }
  setDiagnosisCodeOptions = (e) => {
    debugger
    Common.updateInputValueInState(this.state.ostomy, e, this, this.state)
    let type = e.target.value.toLowerCase();
    let optionTemplate = [];
    if (type == 1) {
      optionTemplate = [
        <option value="Z93.3">
          Colostomy Z93.3
      </option>,
        <option value="Z43.3">
          Encounter for Colostomy Z43.3
     </option>
      ]
    } else if (type == 2) {
      optionTemplate = [
        <option value="Z93.6">
          Urostomy Z93.6
      </option>,
        <option value="Z43.6">
          Encounter for Urostomy Z43.6
     </option>
      ]
    } else {
      optionTemplate = [
        <option value="Z93.2">
          lleostomy Z93.2
      </option>,
        <option value="Z43.2">
          Encounter for lleostomy Z43.2
     </option>
      ]
    }



    this.setState({ diagColostomyCodes: optionTemplate })
  }
  setDiagnosisCodeOptionsForEdit = (type) => {
  
    let optionTemplate = [];
    if (type == 1) {
      optionTemplate = [
        <option value="Z93.3">
          Colostomy Z93.3
      </option>,
        <option value="Z43.3">
          Encounter for Colostomy Z43.3
     </option>
      ]
    } else if (type == 2) {
      optionTemplate = [
        <option value="Z93.6">
          Urostomy Z93.6
      </option>,
        <option value="Z43.6">
          Encounter for Urostomy Z43.6
     </option>
      ]
    } else {
      optionTemplate = [
        <option value="Z93.2">
          lleostomy Z93.2
      </option>,
        <option value="Z43.2">
          Encounter for lleostomy Z43.2
     </option>
      ]
    }



    this.setState({ diagColostomyCodes: optionTemplate })
  }
  handleSubmit = (event) => {
    //1243 is unstageable check

    // let selectedProducts = this.productSelection.getSelectedProductList();
    const form = event.currentTarget.form;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state
      _state.formValidated = true;
      this.setState(_state);
    }
    else {



      this.URI_UpsertResidentOstomy();


    }
  }

  URI_UpsertResidentOstomy() {
    let obj = this.state.ostomy;


    let selectedProducts = this.productSelection.getSelectedProductList();
    debugger
    if (selectedProducts.products.length <= 0) {
      Common.showSuccessAlertAutoClose("Please select atleast one product.", "warning", "Failed!", 3000);
      return;
    }
    obj.saleOrderViewModels = [];
    selectedProducts.products.forEach((value) => {
      let objProduct = {
        "fkProductId": value.id,
        "quantity": value.quantity,
        "sku": value.sku,
        "saleOrderProductId": value.saleOrderProductId
        // "createdBy": this.props.user.userID,
        // "modifiedBy": this.props.user.userID,
      }
      obj.saleOrderViewModels.push(objProduct);

    });
    Api.postRequest(Api.URI_UpsertOstomySaleOrderInBrightreeSOAP, obj).then((res) => {

      if (res.data.success) {
        if (res.data.result != null) {


          Common.showSuccessAlertAutoClose("Ostomy Order Created/Updated Successfully", "success", "Success!", 3000);
          // this.props.refresh && this.props.refresh();
          this.props.close();


          //this.props.close();
        }
        else {
          Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
        }
      }
      else {
        Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
      }
    }).catch((ex) => {

      // // // debugger
      Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
    });
  }
  render() {
    return (
      <>
        <Form >
          {/* <Form> */}
          <div className='row'>
            <div className='col-md-12'>
              <div>

                <fieldset className="p-2 mb-2 border rounded">

                  <legend className="text-primary fw-500">
                    Information{" "}
                  </legend>
                  {/* <div className='d-flex justify-content-left'>
                    <div className="form-group mt-0 mt-sm-3 mt-md-8 mt-xl-3">
                      <label className="checkbox text-danger">
                        <input
                          type="checkbox"
                          className="m-1"
                        />
                        <span className="mr-3 " />
                          Reorder
                        </label>
                    </div>
                  </div> */}
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                          Type Of Procedure
                        </label>
                        <select className="form-control form-control-sm"
                          id="fkProcedureId"
                          name="fkProcedureId"
                          onChange={e => this.setDiagnosisCodeOptions(e)}
                          value={Common.replaceNullWithString(this.state.ostomy.fkProcedureId)}
                        >
                          <option value=""></option>
                          <option value="1">Colostomy</option>
                          <option value="2">Urostomy</option>
                          <option value="3">lleostomy</option>

                          {/* {this.state.procedures} */}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="Status">
                          Diagnosis Code (ICD10)
                        </label>
                        {/* <Select  hideSelectedOptions={true}  options={this.state.diagUrostomyCodes} /> */}
                        <select className="form-control form-control-sm"
                          id="diagnosisCode"
                          name="diagnosisCode"
                          onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                          value={Common.replaceNullWithString(this.state.ostomy.diagnosisCode)}
                        >
                          <option value=""></option>

                          {this.state.diagColostomyCodes}
                        </select>
                      </div>
                    </div>


                    <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="phone1">
                          Stoma Size
                        </label>
                        <input type="number" min="0" step=".01" id="stomaSize" className="form-control form-control-sm"
                          name='stomaSize'
                          onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                          defaultValue={Common.replaceNullWithString(this.state.ostomy.stomaSize)}

                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-3 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="phone1">
                          Avg Wear Time (Days)
                        </label>
                        <input type="number" min="0" step=".01" id="avgWearTimeInDays" className="form-control form-control-sm"
                          name='avgWearTimeInDays'
                          onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                          defaultValue={Common.replaceNullWithString(this.state.ostomy.avgWearTimeInDays)}

                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2 col-xl-2">
                      <div className="form-group">
                        <label className="form-label" htmlFor="phone1">
                          Surgery Date
                        </label>
                        <input type="Date" id="surgeryDate"
                          name="surgeryDate"
                          required
                          onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                          defaultValue={Common.getInputStringDate2(new Date(this.state.ostomy.surgeryDate))}
                          className="form-control form-control-sm"

                        />
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div className='col-md-6'>
              <fieldset className="p-2 mb-2 border rounded">
                <legend className="text-primary fw-500">
                  Pouches/Bags{" "}
                </legend>
                <div className='row'>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Status">
                        Item#
                        </label>
                      {/* <Select hideSelectedOptions={true} options={this.state.itemNumber} /> */}
                      <input type="text" min="0" step=".01" id="pouches" className="form-control form-control-sm"
                        name='pouches'
                        onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                        defaultValue={Common.replaceNullWithString(this.state.ostomy.pouches)}

                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Status">
                        Qty
                        </label>
                      <input type="number" min="0" step=".01" id="pouchesQuantity" className="form-control form-control-sm"
                        name='pouchesQuantity'
                        onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                        defaultValue={Common.replaceNullWithString(this.state.ostomy.pouchesQuantity)}

                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="quantity">
                        Remaining Quantitiy
                                                </label>
                      <input
                        type="number"
                        name='pouchesRemainingQuantity'
                        onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                        defaultValue={Common.replaceNullWithString(this.state.ostomy.pouchesRemainingQuantity)}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className='col-md-6'>
              <fieldset className="p-2 mb-2 border rounded">
                <legend className="text-primary fw-500">
                  Wafers (for Two Piece System){" "}
                </legend>
                <div className='row'>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Status">
                        Item#
                        </label>
                      {/* <Select hideSelectedOptions={true} options={this.state.itemNumber} /> */}
                      <input type="text" min="0" step=".01" id="wafers" className="form-control form-control-sm"
                        name='wafers'
                        onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                        defaultValue={Common.replaceNullWithString(this.state.ostomy.wafers)}

                      />

                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Status">
                        Qty
                        </label>
                      <input type="number" min="0" step=".01" id="wafersQuantity" className="form-control form-control-sm"
                        name='wafersQuantity'
                        onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                        defaultValue={Common.replaceNullWithString(this.state.ostomy.wafersQuantity)}

                      />
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="quantity">
                        Remaining Quantitiy
                                                </label>
                      <input
                        type="number"
                        name='wafersRemainingQuantity'
                        onChange={e => Common.updateInputValueInState(this.state.ostomy, e, this, this.state)}
                        defaultValue={Common.replaceNullWithString(this.state.ostomy.wafersRemainingQuantity)}
                        className="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>

            <fieldset className="p-2 mb-10 border rounded">
              <legend className="text-primary fw-500">
                Accessories{" "}
              </legend>

              <AccessoriesSelection ref={(ref) => this.productSelection = ref } orderProducts={this.state.orderProducts} 

              />

            </fieldset>

          </div>
          <div className="d-flex justify-content-end py-3">
            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Process</Button>
          </div>
        </Form >
      </>

    )
  }

}


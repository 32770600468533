import React, { Component } from 'react';
import { Button, Form } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";

class Resident extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formValidated: false,
      Resident:
      {
        ResidentId: 1,
        FirstName: '',
        MiddleName: '',
        LastName: '',
        BirthDate: '',
        SSN: '',
        DischargeDate: '',
        AdmissionDate: '',
        BranchOffice: 0,
        ModifiedBy: 1,
        ModifiedDate: '2020-12-22T08:44:02.830Z',
    
        Billing:
        {
          BillingPhone: '',
          BillingMobile: '',
          BillingEmailAddress: '',
          address:
          {
             Address1 :'',
             Address2 :'',
             Address3 :'',
             City  :''   ,
             StateID  :'AL',
             CountryID :1,
             PostalCode :'',
          },

        },


        Shipping:
        {
          ShippingPhone: '',
          address:
          {
             Address1 :'',
             Address2 :'',
             Address3 :'',
             City  :''   ,
             StateID  :'AL',
             CountryID :1,
             PostalCode :'',
          },
        },
        Responsible:
        {
          ResponsiblePartyLastName: '',
          ResponsiblePartyMiddleName: '',
          ResponsiblePartyFirstName: '',
          ResponsiblePartyPhone: '',
          ResponsiblePartyMobile: '',
          address:
          {
             Address1 :'',
             Address2 :'',
             Address3 :'',
             City  :''   ,
             StateID  :'AL',
             CountryID :1,
             PostalCode :'',
          },


        }

      }

    }
  }
  handleSubmit = (event) => {
    // // debugger
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state
      _state.formValidated = true;
      this.setState(_state);
    }
    else {
      let obj = this.state.Resident;
      // // debugger
      Api.postRequest(Api.Uri_CreateResident, obj).then((res) => {
        Common.showSuccessAlertAutoClose("Resident Created Successfully", "success", "Success!", 3000);
      });
    }
  }
  render() {
    return (
      <Card>
        <Form
          validated={this.state.formValidated}
        >
          <CardHeader title='Resident' />
          <CardBody>
            <>

              <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                <legend className="text-primary fw-500">Basic Information </legend>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Status">Branch Office</label>
                      <select className="form-control form-control-sm"
                        id="BranchOffice"
                        name="BranchOffice"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                      >
                        <option value="0">All</option>
                        <option value="104">Gentell</option>
                        <option value="103">Kalisthenics</option>
                        <option value="102">Wound Care Concepts</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label"

                        htmlFor="FirstName">First Name</label>
                      <input type="text" id="FirstName"
                        name="FirstName"
                        required
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        value={this.state.Resident.FirstName}
                        className="form-control form-control-sm text-uppercase" />
                      <div className="invalid-feedback">First Name Is Required</div>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="FirstName">Middle Name</label>
                      <input type="text" id="MiddleName"
                        name="MiddleName"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm text-uppercase" />
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="LastName">Last Name</label>
                      <input type="text" id="LastName"
                        name="LastName"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm text-uppercase" />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="DOB">DOB</label>
                      <input type="date" id="DOB"
                        required
                        name="BirthDate"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                         <div className="invalid-feedback">DOB Is Required</div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                    <div className="form-group">
                      <label className="form-label" htmlFor="SSN">SSN</label>
                      <input type="number" id="SSN"
                        name="SSN"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="OriginalAdmissionDate">Date of Admission</label>
                      <input type="date" id="OriginalAdmissionDate"
                      required
                        name="AdmissionDate"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                          <div className="invalid-feedback">Date of Admission Is Required</div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="OriginalAdmissionDate">Date of Discharge</label>
                      <input type="date" id="OriginalAdmissionDate"
                      required
                        name="DischargeDate"
                        onChange={e => Common.updateInputValueInState(this.state.Resident, e, this, this.state)}
                        className="form-control form-control-sm" />
                          <div className="invalid-feedback">Date of Discharge Is Required</div>
                    </div>
                  </div>
                </div>
              </fieldset>
             
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                    <legend className="text-primary fw-500">Billing Information</legend>
                    <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 1</label>
                      <input id="address1"
                        name="Address1"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 2</label>
                      <input id="address1"
                        name="Address2"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 3</label>
                      <input id="address1"
                        name="Address3"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="City">City</label>
                      <input type="text" id="City"
                        name="City"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">State</label>
                      <select className="form-control form-control-sm"
                        name="StateID"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                      >
                        	<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="DC">District of Columbia</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option  value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>
									<option value="PR">Puerto Rico</option>
									<option value="MP">Northern Mariana Islands</option>
									<option value="GU">Guam</option>
									<option value="AS">AMERICAN SAMOA</option>
									<option value="VI">Virgin Islands</option>
									<option value="AE">Armed Forces (Africa, Europe, Canada, Middle East)</option>
									<option value="AA">Armed Forces Americas</option>
									<option value="AP">Armed Forces Pacific</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Country">Country</label>
                      <select className="form-control form-control-sm"
                        name="CountryID"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                        id="State">
                          	<option  value="1">United States</option>
                        	<option value="2">Canada</option>
									<option value="3">Mexico</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Phone1">Postal code</label>
                      <input type="text" id="ResponsiblePartyPostalCode"
                        name="PostalCode"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Billing.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Phone1">Phone</label>
                          <input type="text" id="Phone1"
                            name="BillingPhone"
                            onChange={e => Common.updateInputValueInState(this.state.Resident.Billing, e, this, this.state)}
                            className="form-control form-control-sm" />
                          <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Phone1">Mobile</label>
                          <input type="text" id="Phone1"
                            name="BillingMobile"
                            onChange={e => Common.updateInputValueInState(this.state.Resident.Billing, e, this, this.state)}
                            className="form-control form-control-sm" />
                          <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Phone1">Email</label>
                          <input type="text" id="Phone1"
                            name="BillingEmailAddress"
                            onChange={e => Common.updateInputValueInState(this.state.Resident.Billing, e, this, this.state)}
                            className="form-control form-control-sm" />
                        </div>
                      </div>
             {/* End Address */}
                    </div>
                  </fieldset>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <fieldset className="p-2 bg-light border border-dark rounded">
                    <legend className="text-primary fw-500">Shipping Information </legend>
                    <div className="row">
                    </div>
                    <div className="row">
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 1</label>
                      <input id="address1"
                        name="Address1"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 2</label>
                      <input id="Address2"
                        name="Address2"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 3</label>
                      <input id="Address3"
                        name="Address3"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="City">City</label>
                      <input type="text" id="City"
                        name="City"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">State</label>
                      <select className="form-control form-control-sm"
                        name="StateID"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                      >
                        	<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="DC">District of Columbia</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option  value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>
									<option value="PR">Puerto Rico</option>
									<option value="MP">Northern Mariana Islands</option>
									<option value="GU">Guam</option>
									<option value="AS">AMERICAN SAMOA</option>
									<option value="VI">Virgin Islands</option>
									<option value="AE">Armed Forces (Africa, Europe, Canada, Middle East)</option>
									<option value="AA">Armed Forces Americas</option>
									<option value="AP">Armed Forces Pacific</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Country">Country</label>
                      <select className="form-control form-control-sm"
                        name="CountryID"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                        id="State">
                          	<option  value="1">United States</option>
                        	<option value="2">Canada</option>
									<option value="3">Mexico</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Phone1">Postal code</label>
                      <input type="text" id="PostalCode"
                        name="PostalCode"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-6">
                        <div className="form-group">
                          <label className="form-label" htmlFor="Phone1">Phone</label>
                          <input type="text" id="Phone1"
                            name="ShippingPhone"
                            onChange={e => Common.updateInputValueInState(this.state.Resident.Shipping, e, this, this.state)}
                            className="form-control form-control-sm" />
                          <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                        </div>
                      </div>
             {/* End Address */}
                    </div>
                  </fieldset>
                </div>
              </div>


              <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
                <legend className="text-primary fw-500">Responsible Party Information </legend>
                <div className="row">

                  <div className="col-sm-5 col-md-3 col-lg-3 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="FirstName">First Name</label>
                      <input type="text" id="FirstName"
                        name="ResponsiblePartyFirstName"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-5 col-md-3 col-lg-3 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="FirstName">Middle Name</label>
                      <input type="text" id="FirstName"
                        name="ResponsiblePartyMiddleName"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-5 col-md-3 col-lg-3 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="LastName">Last Name</label>
                      <input type="text" id="LastName"
                        name="ResponsiblePartyLastName"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                {/* region  addrees */}
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 1</label>
                      <input id="Address1"
                        name="Address1"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 2</label>
                      <input id="Address2"
                        name="Address2"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">Address Line 3</label>
                      <input id="Address3"
                        name="Address3"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="City">City</label>
                      <input type="text" id="City"
                        name="City"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="State">State</label>
                      <select className="form-control form-control-sm"
                        name="StateID"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                      >
                        	<option value="AL">Alabama</option>
									<option value="AK">Alaska</option>
									<option value="AZ">Arizona</option>
									<option value="AR">Arkansas</option>
									<option value="CA">California</option>
									<option value="CO">Colorado</option>
									<option value="CT">Connecticut</option>
									<option value="DE">Delaware</option>
									<option value="DC">District of Columbia</option>
									<option value="FL">Florida</option>
									<option value="GA">Georgia</option>
									<option value="HI">Hawaii</option>
									<option value="ID">Idaho</option>
									<option value="IL">Illinois</option>
									<option value="IN">Indiana</option>
									<option value="IA">Iowa</option>
									<option value="KS">Kansas</option>
									<option value="KY">Kentucky</option>
									<option value="LA">Louisiana</option>
									<option value="ME">Maine</option>
									<option value="MD">Maryland</option>
									<option value="MA">Massachusetts</option>
									<option value="MI">Michigan</option>
									<option value="MN">Minnesota</option>
									<option value="MS">Mississippi</option>
									<option value="MO">Missouri</option>
									<option value="MT">Montana</option>
									<option value="NE">Nebraska</option>
									<option value="NV">Nevada</option>
									<option value="NH">New Hampshire</option>
									<option value="NJ">New Jersey</option>
									<option value="NM">New Mexico</option>
									<option value="NY">New York</option>
									<option value="NC">North Carolina</option>
									<option value="ND">North Dakota</option>
									<option value="OH">Ohio</option>
									<option value="OK">Oklahoma</option>
									<option value="OR">Oregon</option>
									<option  value="PA">Pennsylvania</option>
									<option value="RI">Rhode Island</option>
									<option value="SC">South Carolina</option>
									<option value="SD">South Dakota</option>
									<option value="TN">Tennessee</option>
									<option value="TX">Texas</option>
									<option value="UT">Utah</option>
									<option value="VT">Vermont</option>
									<option value="VA">Virginia</option>
									<option value="WA">Washington</option>
									<option value="WV">West Virginia</option>
									<option value="WI">Wisconsin</option>
									<option value="WY">Wyoming</option>
									<option value="PR">Puerto Rico</option>
									<option value="MP">Northern Mariana Islands</option>
									<option value="GU">Guam</option>
									<option value="AS">AMERICAN SAMOA</option>
									<option value="VI">Virgin Islands</option>
									<option value="AE">Armed Forces (Africa, Europe, Canada, Middle East)</option>
									<option value="AA">Armed Forces Americas</option>
									<option value="AP">Armed Forces Pacific</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Country">Country</label>
                      <select className="form-control form-control-sm"
                        name="CountryID"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                        id="State">
                          	<option  value="1">United States</option>
                        	<option value="2">Canada</option>
									<option value="3">Mexico</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-3 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Phone1">Postal code</label>
                      <input type="text" id="ResponsiblePartyPostalCode"
                        name="PostalCode"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible.address, e, this, this.state)}
                        className="form-control form-control-sm" />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Phone1">Phone</label>
                      <input type="text" id="Phone1"
                        name="ResponsiblePartyPhone"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible, e, this, this.state)}
                        className="form-control form-control-sm" />
                      <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="Phone1">Mobile</label>
                      <input type="text" id="Phone1"
                        name="ResponsiblePartyMobile"
                        onChange={e => Common.updateInputValueInState(this.state.Resident.Responsible, e, this, this.state)}
                        className="form-control form-control-sm" />
                      <small>nnn-nnn-nnnn or nnnnnnnnnn</small>
                    </div>
                  </div>
             {/* End Address */}
                </div>
              </fieldset>


            </>
          </CardBody>
          <CardFooter className="d-flex justify-content-end py-3">
            <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
            {/* <Button variant="danger" size="sm" >Cancel</Button> */}
          </CardFooter>
        </Form>
      </Card>

    );
  }
}
export default Resident;
import { ApiBaseUrl } from "../../../constants/endpoints";
export const postApi = {
    getRequestById: async (url, name) => {
        const apiUrl = `${ApiBaseUrl}${url}/${name}`; // Construct the URL correctly
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
    },
    Uri_GetIdByFacilityName: "EmailUpdate/getIdByFacilityName" // Endpoint URL without parameters
};
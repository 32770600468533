import React from "react";
import CheckboxTree from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import * as Api from "../../../General/api";
import * as Common from "../../../General/common";

let nodes = [];

export default class AssignActionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      RoleID: this.props.role.roleId,
      ActionIDs: [],
      checked: [],
      expanded: []
    };
  }
  componentDidMount() {
    //
    
    this.loadAction();

  }
  setActionData(actionData) {
    let objArray = []
    let selectedActions = [];
    actionData.map((action) => {
      let obj = {};
      obj.value = action.moduleID.toString() + 'moduleID';
      obj.label = action.moduleName;
      obj.children = [];
      action.moduleActions.map((child) => {
        let ch = {};
        ch.value = child.actionId.toString();
        ch.label = child.actionName;
        if (child.assigned) {
          selectedActions.push(child.actionId.toString());

        }
        obj.children.push(ch);
      })


      objArray.push(obj);

    })
    this.setState({ checked: selectedActions })
    return objArray;
  }
  loadAction = () => {
    let id = this.props.role.roleId;
    Api.getRequest("RoleAction/GetActionByRoleID?ID=" + id).then((res) => {
      let obj = this.setActionData(res.data.result);
      nodes = obj;
      this.setState({
        //ActionIDs: res.data.result,
        CreatedBy: 34,
        CreatedDate: "2020-12-21T12:12:05.628Z",
      });
      this.props.onSaveAPI();
    });
  };
  onSubmit() {
    // // debugger
    let selectedActionList = [];
    this.state.checked.map((object, i) => {
      let selectedActions = {};
      selectedActions.ActionID = parseInt(object)
      selectedActions.Assigned = true;
      selectedActionList.push(selectedActions);
    })
    this.setState({
      ActionIDs: selectedActionList
    })
    let obj = this.state;
    obj.ActionIDs = selectedActionList;
    Api.postRequest("roleAction/update", obj).then((res) => {
      this.props.onSaveAPI();
      this.props.onhideModal();
      Common.showSuccessAlertAutoClose("Actions Updated Successfully", "success", "Success!", 3000);
    });
  }
  render() {
    return (
      <>
        

        { <div>

          <CheckboxTree
            iconsClass="fa5"
            nodes={nodes}
            checked={this.state.checked}
            expanded={this.state.expanded}
            onCheck={checked => this.setState({ checked })}
            onExpand={expanded => this.setState({ expanded })}
          />

          
        </div>}


      </>
    );
  }
}



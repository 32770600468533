/*eslint-disable */
import React from "react";

export default class WoundNote extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <form>
                <div className="row  ">


                    <div className="col-sm-6 col-md-9 mx-auto">
                        <div className="form-group">
                            <label className="form-label" htmlFor="Note"><b>Patient: </b> Anthony Fairley</label>
                            <br></br>
                            <label className="form-label" htmlFor="Note"><b>Wound: </b> Left Great Toe</label>
                            <textarea id="Note" className="form-control form-control-sm" rows="10" > </textarea>
                        </div>
                    </div>



                </div>
            </form >
        )
    }
    
    onFormSave() {
        
    }

}// end of class

/*eslint-enable */
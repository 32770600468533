import React from "react";
import { useState } from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  FormLabel,
  FormControl,
  Spinner,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormikProvider from "../../context/formik";
import SignatureCanvas from "react-signature-canvas";
import { useEffect } from "react";
import moment from "moment";
import classnames from "classnames";
import * as Common from "../../../General/common";
import html2pdf from "html2pdf.js";
import * as icons from "@material-ui/icons";
// eslint-disable-next-line no-restricted-imports
import IconButton from "@material-ui/core/IconButton";
import { apiUrls, defaultUrls, blobSas } from "../../../constants/endpoints";
import * as Api from "../../../General/api";
import DocumentViewer from "./DocumentViewer";
import { useRef } from "react";
import print from "print-js";
import { blobContainerResidentName } from "../../../constants/endpoints";
const AOBModal = ({ isOpen, stateChanger, resident, type, props }) => {
  const signatureRef = React.createRef();
  const [show, setShow] = useState(isOpen);
  const [todayDate, setTodayDate] = useState("");
  const [downloadingEmptyDoc, setDownloadingEmptyDoc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [fileUrl, setFileUrl] = useState(Common.getUrlBlobSAS(blobContainerResidentName, resident?.aobPdfUrl));
  const [SecondaryInsurance, setSecondaryInsurance] = useState("");
  const [PrimaryInsurance, setPrimaryInsurance] = useState("");
  const [medicareId, setMedicareId] = useState(resident?.medicare);

  const handleClose = () => {
    if (type !== "view" && type != "upload") {
      Common.showConfirmation(
        "Are you sure you want to close without saving the data?",
        "Warning",
        () => {
          stateChanger(!isOpen);
        },
        () => {},
        null,
        "Exit Without Saving"
      );
    } else {
      stateChanger(!isOpen);
    }
  };
  const handlePrint = (e) => {
    e.preventDefault();
    print(fileUrl);
  };
  const initialValues = {
    isSelectAll: false,
    isPhysicallyAble: false,
    isPatientRights: false,
    isPatientResponsibilities: false,
    isFinancialResponsibility: false,
    isAssignmentRelease: false,
    isCompanyInformation: false,
    isTelephoneNumber: false,
    isWarrantyInformation: false,
    isPracticesNotice: false,
    isCopyOfSupplier: false,
    representativeName: "",
    representativeTitle: "",
    isAobWithFacility: false,
    signature: "",
  };
  const validationSchema = Yup.object().shape({
    reason: Yup.string()
      .required("Reason is required")
      .max(500, "Reason must be 500 characters or less"),
    isPhysicallyAble: Yup.bool(),
    isPatientRights: Yup.bool(),
    isPatientResponsibilities: Yup.bool(),
    isFinancialResponsibility: Yup.bool(),
    isAssignmentRelease: Yup.bool(),
    isCompanyInformation: Yup.bool(),
    isTelephoneNumber: Yup.bool(),
    isWarrantyInformation: Yup.bool(),
    isPracticesNotice: Yup.bool(),
    isCopyOfSupplier: Yup.bool(),
    isAobWithFacility: Yup.bool(),
    signature: Yup.string().required(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      const payload = {
        id: 0,
        leaveTypeId: values.leaveType,
        fromDate: values.startDate,
        toDate: values.endDate,
        longDescription: values.reason,
        isHalfLeave: values.isHalfLeave,
      };
    },
  });


  const uploadPdf = (pdf) => {
    try {
      if (pdf == null && file !== null) {
        pdf = file;
      }
      setIsLoading(true);
      debugger;
      Common.uploadPdfFile(blobContainerResidentName, pdf)
        .then((response) => {
          if (response != "e") {
            debugger;

            props.resident.aobPdfUrl = response;
            props.resident.imageAOBName = file?.name || "";
            debugger;

            setFileUrl(props.resident.aobPdfUrl);
            const payload = {
              url: response,
              residentId: resident.id,
              imageAOBName: file?.name,
            };
            Api.postRequest(apiUrls.upsertResidentAOB, payload, false).then(
              (response) => {
                if (response.data.success) {
                  props.getAllResident();
                  setIsLoading(false);
                  stateChanger(false);
                  Common.showSuccessAlert(
                    response.data.message,
                    "success",
                    "Success"
                  );
                } else {
                  setIsLoading(false);
                }
              }
            );
          } else {
            setIsLoading(false);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    } catch (ex) {
      setIsLoading(false);
    }
  };
  const saveChanges = async () => {
    setIsLoading(true);
    const pdfOptions = {
      margin: 5,
      filename: "AOBForm.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      exclude: ["#select-all", "#paper-aob"],
      outputType: "blob",
    };
    const signatureImage = new Image();
    signatureImage.src = formik.values.signature; // Assuming formik.values.signature contains the signature data URL
    signatureImage.style.width = "550px";
    signatureImage.style.height = "100px";
    const pdfElement = document.getElementById("pdf-container");
    const pdfCopy = pdfElement.cloneNode(true);

    // Find and remove the excluded elements from the copy
    const excludedField1 = pdfCopy.querySelector("#select-all");
    const excludedField2 = pdfCopy.querySelector("#paper-aob");
    const excludedField3 = pdfCopy.querySelector("#signature-field");
    const excludedField4 = pdfCopy.querySelector("#btn-clear");

    if (excludedField1) {
      excludedField1.remove();
    }
    if (excludedField2) {
      excludedField2.remove();
    }
    if (excludedField3) {
      excludedField3.innerHTML = "";
      excludedField3.appendChild(signatureImage);
    }
    if (excludedField4) {
      excludedField4.remove();
    }
    if (pdfElement) {
      const blob = await html2pdf(pdfCopy, pdfOptions).output("blob");

      uploadPdf(blob);
    }
  };
  const printEmptyDoc = () => {
    debugger;
    setDownloadingEmptyDoc(true);
    formik.handleReset();
    const pdfOptions = {
      margin: 5,
      filename: "AOBForm.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      exclude: ["#select-all", "#paper-aob"],
    };
    const signatureImage = new Image();
    signatureImage.src = formik.values.signature; // Assuming formik.values.signature contains the signature data URL
    signatureImage.style.width = "550px";
    signatureImage.style.height = "100px";
    const pdfElement = document.getElementById("pdf-container");
    const pdfCopy = pdfElement.cloneNode(true);

    // Find and remove the excluded elements from the copy
    const excludedField1 = pdfCopy.querySelector("#select-all");
    const excludedField2 = pdfCopy.querySelector("#paper-aob");
    const excludedField3 = pdfCopy.querySelector("#signature-field");
    const excludedField4 = pdfCopy.querySelector("#btn-clear");
    if (excludedField1) {
      excludedField1.remove();
    }
    if (excludedField2) {
      excludedField2.remove();
    }
    if (excludedField3) {
      excludedField3.innerHTML = "";
      excludedField3.appendChild(signatureImage);
    }
    if (excludedField4) {
      excludedField4.remove();
    }
    if (pdfElement) {
      html2pdf(pdfCopy, pdfOptions).then((pdf) => {
        setDownloadingEmptyDoc(false);
        pdf.output("blob").then((blob) => {
          // Create a URL for the blob
          const pdfUrl = URL.createObjectURL(blob);

          // Create a link element and trigger a download
          const downloadLink = document.createElement("a");
          downloadLink.href = pdfUrl;
          downloadLink.download = "AOBForm.pdf";
          document.body.appendChild(downloadLink);
          downloadLink.click();

          document.body.removeChild(downloadLink);
        });
      });
    }
  };

  const uploadAOBHandler = (e) => {
    if (e.target.files[0].type != "application/pdf") {
      setFile(null);
      e.target.value = "";
      setFileErrorMessage("Invalid file type. Please select a PDF file.");
    } else {
      setFileErrorMessage("");
      setFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (resident && resident.residentInsuranceList?.length > 0) {
      setMedicareId(resident.medicare);

      const findInsuranceByLevel = (levelId) =>
        resident.residentInsuranceList.find(
          (insurance) => insurance.fkInsuranceLevelId === levelId
        ) || {};

      setPrimaryInsurance(findInsuranceByLevel(1).insuranceName || "");
      setSecondaryInsurance(findInsuranceByLevel(2).insuranceName || "");
    }
  }, [resident]);
  
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdropClassName="aob-backdrop"
      backdrop="static"
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      <Modal.Header closeButton>
        <Modal.Title>AOB Form</Modal.Title>
        <div className="ml-auto">
          {!downloadingEmptyDoc && type != "view" && type != "upload" ? (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Menu"
              onClick={() => printEmptyDoc()}
            >
              <icons.Print />
            </IconButton>
          ) : (
            type != "view" &&
            type != "upload" && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="Menu"
                onClick={() => printEmptyDoc()}
              >
                <Spinner animation="border" variant="primary" />
              </IconButton>
            )
          )}
        </div>
      </Modal.Header>
      <Modal.Body>
        {type == "view" ? (
          <DocumentViewer fileUrl={fileUrl} />
        ) : type == "upload" ? (
          <>
            <Row>
              <Col xs={6}>
                <input
                  accept=" application/pdf"
                  type="file"
                  id="inputUploadAob"
                  onChange={uploadAOBHandler}
                ></input>
              </Col>
              <Col xs={6}>
                <Button
                  variant="primary"
                  size="sm"
                  disabled={isLoading || file == null}
                  onClick={() => uploadPdf()}
                >
                  {isLoading ? (
                    <Spinner size="sm" animation="border"></Spinner>
                  ) : (
                    "Upload"
                  )}
                </Button>
              </Col>
              <span style={{ color: "red" }} className="ml-3">
                {fileErrorMessage}
              </span>
            </Row>
          </>
        ) : (
          <div id="pdf-container">
            <div className="d-flex justify-content-center">
              <h7>
                <strong className="fw-bold">Assignment of Benefits</strong>
              </h7>
            </div>
            <Col>
              <Row>
                <span>
                  {" "}
                  <strong className="fw-bold">Facility</strong>
                </span>{" "}
                : <span className="ml-2">{resident?.facilityName}</span>
              </Row>
              <Row>
                <span>
                  <strong className="fw-bold">Residents</strong>
                </span>{" "}
                :{" "}
                <span className="ml-2">
                  {" "}
                  {resident?.lastName}, {resident?.firstName}
                </span>
              </Row>
            </Col>
            <Row className="m-0 mt-2">
              <Col xs={4}>
                <Row>
                  <span className="fs-small">Insurance Company Name : </span>{" "}
                  {PrimaryInsurance}
                </Row>
                <Row>
                  <span className="fs-small">Co Insurance Company Name : </span>{" "}
                  {SecondaryInsurance}
                </Row>
              </Col>
              <Col xs={4}>
                <Row>
                  <span className="fs-small">Insurance ID# :</span>
                </Row>
                <Row>
                  <span className="fs-small">Co Insurance ID# :</span>
                </Row>
              </Col>
              <Col xs={4}>
                <span className="fs-small">Medicare ID# :</span> {medicareId}
              </Col>
            </Row>
            <div className="mt-2">
              <h7>
                <strong className="fw-bold">
                  My check mark adjacent to the following items, along with my
                  signature below, attest that i have received, read, and/or
                  been instructed , in detail, on this information.
                </strong>
              </h7>
            </div>
            <div className="d-flex ml-10 mt-3">
              <FormikProvider formik={{ ...formik }}>
                <Form>
                  <Row
                    className="custom-grey-background p-2 mb-1 rounded"
                    id="select-all"
                  >
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isSelectAll}
                          onChange={() => {
                            debugger;
                            if (formik.values.isSelectAll == true) {
                              formik.setFieldValue("isSelectAll", false);
                              formik.setFieldValue("isPatientRights", false);
                              formik.setFieldValue(
                                "isPatientResponsibilities",
                                false
                              );
                              formik.setFieldValue(
                                "isFinancialResponsibility",
                                false
                              );
                              formik.setFieldValue(
                                "isAssignmentRelease",
                                false
                              );
                              formik.setFieldValue(
                                "isCompanyInformation",
                                false
                              );
                              formik.setFieldValue("isTelephoneNumber", false);
                              formik.setFieldValue(
                                "isWarrantyInformation",
                                false
                              );
                              formik.setFieldValue("isPracticesNotice", false);
                              formik.setFieldValue("isCopyOfSupplier", false);
                            }
                            if (formik.values.isSelectAll == false) {
                              formik.setFieldValue("isSelectAll", true);
                              formik.setFieldValue("isPatientRights", true);
                              formik.setFieldValue(
                                "isPatientResponsibilities",
                                true
                              );
                              formik.setFieldValue(
                                "isFinancialResponsibility",
                                true
                              );
                              formik.setFieldValue("isAssignmentRelease", true);
                              formik.setFieldValue(
                                "isCompanyInformation",
                                true
                              );
                              formik.setFieldValue("isTelephoneNumber", true);
                              formik.setFieldValue(
                                "isWarrantyInformation",
                                true
                              );
                              formik.setFieldValue("isPracticesNotice", true);
                              formik.setFieldValue("isCopyOfSupplier", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Click to select all boxes below"
                        />
                        <FormLabel
                          className="form-check-label "
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isPatientRights}
                          onChange={() => {
                            if (formik.values.isPatientRights == true) {
                              formik.setFieldValue("isPatientRights", false);
                            }
                            if (formik.values.isPatientRights == false) {
                              formik.setFieldValue("isPatientRights", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="My rights as a patient/client (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isPatientResponsibilities}
                          onChange={() => {
                            if (
                              formik.values.isPatientResponsibilities == true
                            ) {
                              formik.setFieldValue(
                                "isPatientResponsibilities",
                                false
                              );
                            }
                            if (
                              formik.values.isPatientResponsibilities == false
                            ) {
                              formik.setFieldValue(
                                "isPatientResponsibilities",
                                true
                              );
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="My responsibilities as a patient/client (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isFinancialResponsibility}
                          onChange={() => {
                            if (
                              formik.values.isFinancialResponsibility == true
                            ) {
                              formik.setFieldValue(
                                "isFinancialResponsibility",
                                false
                              );
                            }
                            if (
                              formik.values.isFinancialResponsibility == false
                            ) {
                              formik.setFieldValue(
                                "isFinancialResponsibility",
                                true
                              );
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="My financial responsibility (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isAssignmentRelease}
                          onChange={() => {
                            if (formik.values.isAssignmentRelease == true) {
                              formik.setFieldValue(
                                "isAssignmentRelease",
                                false
                              );
                            }
                            if (formik.values.isAssignmentRelease == false) {
                              formik.setFieldValue("isAssignmentRelease", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="My assignment release (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isCompanyInformation}
                          onChange={() => {
                            if (formik.values.isCompanyInformation == true) {
                              formik.setFieldValue(
                                "isCompanyInformation",
                                false
                              );
                            }
                            if (formik.values.isCompanyInformation == false) {
                              formik.setFieldValue(
                                "isCompanyInformation",
                                true
                              );
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Information on Company Products/Services (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isTelephoneNumber}
                          onChange={() => {
                            if (formik.values.isTelephoneNumber == true) {
                              formik.setFieldValue("isTelephoneNumber", false);
                            }
                            if (formik.values.isTelephoneNumber == false) {
                              formik.setFieldValue("isTelephoneNumber", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Important telephone numbers (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isWarrantyInformation}
                          onChange={() => {
                            if (formik.values.isWarrantyInformation == true) {
                              formik.setFieldValue(
                                "isWarrantyInformation",
                                false
                              );
                            }
                            if (formik.values.isWarrantyInformation == false) {
                              formik.setFieldValue(
                                "isWarrantyInformation",
                                true
                              );
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Warranty Information (cp)"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isPracticesNotice}
                          onChange={() => {
                            if (formik.values.isPracticesNotice == true) {
                              formik.setFieldValue("isPracticesNotice", false);
                            }
                            if (formik.values.isPracticesNotice == false) {
                              formik.setFieldValue("isPracticesNotice", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Notice of Privacy Practices"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isCopyOfSupplier}
                          onChange={() => {
                            if (formik.values.isCopyOfSupplier == true) {
                              formik.setFieldValue("isCopyOfSupplier", false);
                            }
                            if (formik.values.isCopyOfSupplier == false) {
                              formik.setFieldValue("isCopyOfSupplier", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Copy of supplier standards"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="mt-2">
                        <ol>
                          <li>
                            Assignment of Medicare, Medicaid, Medicare
                            Supplemental or other insurance benefits to Wound
                            Care Concepts Inc. for medical supplies and/or
                            medication(s) furnished to me by Wound Care Concepts
                            Inc.
                          </li>
                          <li>
                            Direct billing to Medicare, Medicaid, Medicare
                            Supplemental or other insurer(s).
                          </li>
                          <li>
                            Release of my medical information to Medicare,
                            Medicaid, Medicare Supplemental or other insurers
                            and their agents and assigns.
                          </li>
                          <li>
                            Wound Care Concepts Inc. to obtain medical or other
                            information necessary in order to process my
                            claim(s), including determining eligibility and
                            seeking reimbursement for medical supplies and/or
                            medication(s) provided.
                          </li>
                          <li>
                            Wound Care Concepts Inc. to contact me by telephone
                            or mail regarding my medical supplies and/or
                            medication(s) order.
                          </li>
                        </ol>
                        <span>
                          I request that payment of Medicare, Medicaid, Medicare
                          Supplemental or other insurance benefits be made on my
                          behalf to Wound Care Concepts Inc. for any medical
                          supplies and/or medications furnished to me by Wound
                          Care Concepts Inc.. I authorize any holder of medical
                          information about me to release to Wound Care Concepts
                          Inc., my physician(s), caregiver, CMS, its agents and
                          to my primary and/or other medical insurer any
                          information needed to determine or secure eligibility
                          information and/or reimbursement for covered services.
                          I agree to pay all amounts that are not covered by my
                          insurer(s) and for which I am responsible.
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2 custom-grey-background p-2 mb-1 rounded">
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isPhysicallyAble}
                          onChange={() => {
                            if (formik.values.isPhysicallyAble == true) {
                              formik.setFieldValue("isPhysicallyAble", false);
                            }
                            if (formik.values.isPhysicallyAble == false) {
                              formik.setFieldValue("isPhysicallyAble", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Is the Patient physically and mentally able to sign this form?"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  {!formik.values.isPhysicallyAble && (
                    <Row className="mt-2">
                      <Col xs={12}>
                        <Form.Group
                          controlId="physicallyAbleTextArea"
                          className="d-flex align-items-center"
                        >
                          <Form.Label className="mr-2">
                            Reason why patient is unable to sign
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={formik.values.physicallyAbleTextArea}
                            onChange={(e) =>
                              formik.setFieldValue(
                                "physicallyAbleTextArea",
                                e.target.value
                              )
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  )}
                  <Row className="custom-grey-background  mb-1 rounded">
                    <Col xs={12} className="mt-2">
                      <Form.Group
                        controlId="signatureCanvas"
                        className="d-flex align-items-center "
                      >
                        <Form.Label className="mr-2">
                          *Authorized Representative Signature
                        </Form.Label>
                        {/* <div className="bg-light rounded border mt-6"> */}
                        <div
                          className="shadow bg-white rounded"
                          id="signature-field"
                        >
                          <SignatureCanvas
                            ref={signatureRef}
                            penColor="green"
                            canvasProps={{ width: 550, height: 100 }}
                            onEnd={() =>
                              formik.setFieldValue(
                                "signature",
                                signatureRef.current.toDataURL()
                              )
                            }
                          />
                        </div>
                        {/* </div> */}
                        <div className="mt-1 ml-1">
                          <Button
                            id="btn-clear"
                            variant="danger"
                            size="sm"
                            onClick={() => signatureRef.current.clear()}
                          >
                            Clear
                          </Button>
                        </div>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col xs={6}>
                      <Form.Group
                        controlId="RepresentativeName"
                        className="d-flex align-items-center"
                      >
                        <Form.Label className="mr-2">
                          Representative Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formik.values.representativeName}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "representativeName",
                              e.target.value
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={6}>
                      <Form.Group
                        controlId="RepresentativeTitle"
                        className="d-flex align-items-center"
                      >
                        <Form.Label className="mr-2">
                          Representative Title
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={formik.values.representativeTitle}
                          onChange={(e) =>
                            formik.setFieldValue(
                              "representativeTitle",
                              e.target.value
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <span>
                    *If the authorized representative of the patient is signing,
                    please be advised your signature does not make you
                    responsible for the patient's financial obligation.
                  </span>
                  <Row
                    className="mt-2 custom-grey-background p-2 mb-1 rounded"
                    id="paper-aob"
                  >
                    <Col xs={12}>
                      <div className="form-check form-check-inline custom-grey-background rounded">
                        <Form.Check
                          style={{ marginLeft: "-35px" }}
                          checked={formik.values.isAobWithFacility}
                          onChange={() => {
                            if (formik.values.isAobWithFacility == true) {
                              formik.setFieldValue("isAobWithFacility", false);
                            }
                            if (formik.values.isAobWithFacility == false) {
                              formik.setFieldValue("isAobWithFacility", true);
                            }
                          }}
                          type="checkbox"
                          id="icon-primary-123"
                          name="icon-primary-123"
                          label="Left Paper AOB with Facility, check if yes"
                        />
                        <FormLabel
                          className="form-check-label"
                          htmlFor="icon-primary-123"
                        >
                          {" "}
                          <span className="switch-icon-left"></span>
                          <span className="switch-icon-right"></span>
                        </FormLabel>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs={12}>
                      <Form.Group controlId="Date">
                        <Form.Label>
                          <strong>Date:</strong>
                        </Form.Label>
                        <span className="ml-2">{todayDate}</span>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </FormikProvider>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" disabled={isLoading} onClick={handleClose}>
          Close
        </Button>
        {type == "new" && (
          <Button variant="primary" onClick={saveChanges}>
            {isLoading ? (
              <Spinner size="sm" animation="border"></Spinner>
            ) : (
              "Save & Print"
            )}
          </Button>
        )}
        {type == "view" && (
          <Button variant="primary" onClick={handlePrint}>
            Print
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default AOBModal;

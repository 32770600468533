import axios from "axios";
import { ApiBaseUrl } from '../../../constants/endpoints';

//const axios = require('axios').default;
//export const ApiBaseUrl = "https://localhost:44306/";
//export const ApiBaseUrl = "https://fastcareapi1.azurewebsites.net/api/"; // Dev API URL -- Kindly include /api in base URL insted adding it in every endpoint path 

//export const LOGIN_URL = "auth/login";
export const LOGIN_URL = ApiBaseUrl + "account/authenticate";
export const BtLOGIN_URL = ApiBaseUrl + "account/authenticateBTUser";

export const REGISTER_URL = "auth/register";
export const REQUEST_PASSWORD_URL = ApiBaseUrl + "User/ForgetPassword";
export const REQUEST_ResetPASSWORD_URL = ApiBaseUrl + "User/SendResetPasswordEmail";
export const SetPasswordDetalURL = ApiBaseUrl + "User/validateChangePasswordURL";

export const ME_URL = "me";
const instance = axios.create();
// export function login(Username, Password) {
//  // return instance.post('https://localhost:44306/api/Account/authenticate', { Username: 'Admin', Password });
//  return instance.post(LOGIN_URL, { Username, Password });
//   // .then((res) => {
//   //   //disableLoading();
//   //   
//   }
//);

//}

export function login(userName, password) {
  // // debugger;
  //return axios.post(LOGIN_URL, { email, password });
  return instance.post(LOGIN_URL, { userName, password });
}
export function loginBtUser(userName, password,UserID) {
   debugger;
  //return axios.post(LOGIN_URL, { email, password });
  return instance.post(BtLOGIN_URL, { userName, password,UserID });
}
export function register(email, fullname, username, password) {
  return instance.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email, Password, changepassword) {
  // // debugger;
  return instance.post(REQUEST_PASSWORD_URL, { email, Password });
}
export function requestResetPassword(email) {
  // // debugger;
  return instance.get(REQUEST_ResetPASSWORD_URL + "?email=" + email);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

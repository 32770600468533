
import $ from "jquery";
import React from "react";
import { Animated } from "react-animated-css";
import { ButtonToolbar, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import { ModulesName, UserActions } from "../../General/roleModuleActions";
import { injectIntl } from "react-intl";

var checkCount = 0;
class ResidentNotes extends React.Component {
  // eslint-disable-next-line no-use-before-define
  constructor(props) {
    super(props);
    this.state = {
      isNewNote: false,
      isPDFSelected: false,
      noteText: "",
      noteReason:null,
      rows: [],
      woundReasonSelect: [],
      currentlySelectedNoteId: 0,
      isLoading: false,
    };
    $("#newNotesSection").hide();
  }

  isLoading = (val) => {
    this.setState({ isLoading: val });
  };

  componentDidMount() {
    this.loadNotes();
   this.getAllLookupsValues();
  }
  getAllLookupsValues = async () => {

    var reasons = await Common.getAllLookupOptions(Api.Uri_GetLookUp_PatientNoteReasonFetchAll)
    debugger
    this.setState({ woundReasonSelect: reasons })
  }
  render() {
    return (
      <>
        <div
          className="alert border-info bg-transparent text-info fade show"
          role="alert"
        >
          <div className="d-flex align-items-center">
            <div className="alert-icon">
              <i className="fas fa-info-circle text-info mr-3"></i>
            </div>
            <div className="flex-1">
              <span className="h6 m-0 fw-700">
                {this.props.intl.formatMessage({ id: "FACILITY_QUEUE.USE_THESE_BUTTONS_FOR_MORE_ACTIONS" })}{" "}
              </span>
            </div>
            <button
              type="button"
              className="btn btn-danger btn-pills btn-sm btn-w-m mr-2 waves-effect waves-themed"
            >
              {this.props.intl.formatMessage({ id: "RESET" })}
            </button>
            <CheckPermission
              permission={UserActions.Create}
              module={ModulesName.Module_Residents}
              yes={() => (
                <button
                  type="button"
                  onClick={() => this.updateNewNoteState(true)}
                  className="btn btn-primary btn-pills btn-sm btn-w-m mr-2 waves-effect waves-themed"
                >
                  {this.props.intl.formatMessage({ id: "FACILITY_QUEUE.NEW_NOTE" })}
                </button>
              )}
              no={() => (
                <button
                  type="button"
                  className="btn disabled btn-primary btn-pills btn-sm btn-w-m mr-2 waves-effect waves-themed"
                >
                  {this.props.intl.formatMessage({ id: "FACILITY_QUEUE.NEW_NOTE" })}
                </button>
              )}
            />
            <button
              type="button"
              className="btn btn-info btn-pills btn-sm btn-w-m mr-2 waves-effect waves-themed"
            >
              {this.props.intl.formatMessage({ id: "FACILITY_QUEUE.PDF_ALL" })}
            </button>
            {this.state.isPDFSelected == true ? (
              <button
                type="button"
                className="btn btn-warning btn-pills btn-sm btn-w-m waves-effect waves-themed"
              >
                {this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.PDF_SELECTED" })}
              </button>
            ) : null}
          </div>
        </div>
        {this.state.isLoading && (
          <Animated animationIn="fadeIn" animationInDuration={1000}>
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          </Animated>
        )}
        {!this.state.isLoading && this.state.isNewNote == false && (
          <Animated animationIn="fadeIn" animationInDuration={1000}>
            <Card className="example example-compact">
              <CardHeader title={this.props.intl.formatMessage({ id: "RESIDENT_NOTES_LIST" })} />
              <CardBody>
                <table
                  className="table table-striped table-hover table-sm table-bordered"
                  id="residentNotes"
                >
                  <thead>
                    <tr>
                      <th>{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.SELECT" })}</th>
                      <th>{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.NOTES" })}</th>
                      <th>{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.CREATED_DATE" })}</th>
                      <th>{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.CREATED_BY" })}</th>
                      <th>{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.MODIFIED_BY" })}</th>
                      <th>{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.ACTIONS" })}</th>
                    </tr>
                  </thead>
                  <tbody>{this.renderRowsUI(this.state.rows)}</tbody>
                </table>
              </CardBody>
            </Card>
          </Animated>
        )}

        {!this.state.isLoading && this.state.isNewNote == true && (
          <Animated animationIn="fadeIn" animationInDuration={1000}>
            <div className="newNotesSection" id="newNotesSection">
              <div className="row">
              <div className="col-sm-2 col-md-2 col-lg-2 col-xl-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="woundType">{this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.NOTE_REASONS" })}</label>
                                <select className="form-control form-control-sm"
                                    id="noteReason"
                                    name="noteReason"
                                    required
                                    onChange={({ target }) => {
                                      this.setState({ noteReason: target.value });
                                    }}
                                    value={Common.replaceNullWithString(this.state.noteReason)}

                                >
                                    <option></option>
                                    {this.state.woundReasonSelect}
                                </select>
                            </div>
                        </div>
                        </div>

              <div className="row">

                <div className="col-12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="example-textarea">
                      {this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.NEW_NOTE" })}
                    </label>
                    <textarea
                      value={this.state.noteText}
                      onChange={({ target }) => {
                        this.setState({ noteText: target.value });
                      }}
                      className="form-control"
                      id="example-textarea"
                      rows="5"
                    ></textarea>
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <CheckPermission
                      permission={UserActions.Create}
                      module={ModulesName.Module_Residents}
                      yes={() => (
                        <button
                          disabled={this.state.isLoading}
                          type="button"
                          onClick={() => {
                            this.setState({
                              currentlySelectedNoteId: 0,
                              noteText: "",
                            });
                            this.saveNote();
                          }}
                          className="btn btn-success btn-sm float-right waves-effect waves-themed"
                        >
                          {this.props.intl.formatMessage({ id: "FACILITY_QUEUE.MODAL.SAVE" })}
                        </button>
                      )}
                      no={() => (
                        <button
                          disabled="true"
                          type="button"
                          className="btn btn-success btn-sm float-right waves-effect waves-themed"
                        >
                          {this.props.intl.formatMessage({ id: "FACILITY_QUEUE.MODAL.SAVE" })}
                        </button>
                      )}
                    />
                    <button
                      onClick={() => {
                        this.updateNewNoteState(false);
                      }}
                      type="button"
                      className="btn btn-dark btn-sm waves-effect waves-themed"
                    >
                      {this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.BACK" })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Animated>
        )}
      </>
    );
  }

  //Functions area

  renderRowsUI(list) {
    let rows = [];
    list.forEach((element, i) => {
      rows.push(
        <tr key={i.toString()}>
          <td align="center">
            <label className="checkbox">
              <input
                type="checkbox"
                name={"rn" + i}
                className="m-1"
                onChange={this.onCheckBoxClicked}
              />
              <span className="mx-1" />
            </label>
          </td>
          <td>
            <CheckPermission
              permission={UserActions.Create}
              module={ModulesName.Module_Residents}
              yes={() => (
                <button
                  className="btn btn-link btn-sm"
                  onClick={() => {
                    this.setState({
                      currentlySelectedNoteId: element.noteId,
                      noteText: element.subject,
                    });
                    this.updateNewNoteState(true);
                  }}
                >
                  {element.subject}
                </button>
              )}
              no={() => (
                <button className="btn btn-link disabled btn-sm">
                  {element.subject}
                </button>
              )}
            />
          </td>
          <td>{Common.GetDateTimeString(element.createdDate)}</td>
          <td>{element.createdByName}</td>
          <td>{element.modifiedByName}</td>
          <td>
            <ButtonToolbar className="justify-content-center">
              <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                {[DropdownButton].map((DropdownType, idx) => (
                  <DropdownType
                    size="xs"
                    variant="primary"
                    title="Actions"
                    id={`dropdown-button-drop-${idx}`}
                    key={idx}
                  >
                    <CheckPermission
                      permission={UserActions.Create}
                      module={ModulesName.Module_Residents}
                      yes={() => (
                        <Dropdown.Item
                          eventKey="0"
                          className="text-danger"
                          onClick={() => {
                            this.deleteNoteHandler(
                              element.noteId,
                              element.modifiedBy
                            );
                          }}
                        >
                          {this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.DELETE" })}
                        </Dropdown.Item>
                      )}
                      no={() => (
                        <Dropdown.Item
                          eventKey="0"
                          className="text-danger disabled"
                          disabled="true"
                        >
                          {this.props.intl.formatMessage({ id: "FACILITY.RESIDENT_NOTES.DELETE" })}
                        </Dropdown.Item>
                      )}
                    />
                  </DropdownType>
                ))}
              </div>
            </ButtonToolbar>
          </td>
        </tr>
      );
    });

    return rows;
  }

  deleteNoteHandler(noteId, modifiedBy, bypass = false) {
    if (!bypass) {
      let that = this;
      Common.showConfirmation(
        this.props.intl.formatMessage({ id: "ARE_YOU_SURE_REMOVE_NOTE" }),
        this.props.intl.formatMessage({ id: "CONFIRMATION" }),
        () => {
          that.deleteNoteHandler(noteId, modifiedBy, true);
        },
        () => {},
        null
      );
    }
    if (bypass) {
      let payload = {
        status: 0,
        noteId,
        modifiedBy,
      };
      this.isLoading(true);
      Api.postRequest(Api.Uri_UpsertResidentNotes, payload)
        .then((res) => {
          if (res.data.success) {
            if (res.data.result != null) {
              Common.showSuccessAlertAutoClose(
                res.data.message,
                "success",
                this.props.intl.formatMessage({ id: "SUCCESS.TITLE" }),
                3000
              );
              this.updateNewNoteState(false);
              this.setState({ noteText: "" });
              this.loadNotes();
            } else {
              Common.showSuccessAlertAutoClose(
                res.data.message,
                "warning",
                this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
                3000
              );
            }
          } else {
            Common.showSuccessAlertAutoClose(
              res.data.message,
              "warning",
              this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
              3000
            );
          }
        })
        .catch((ex) => {
          
          Common.showSuccessAlertAutoClose(
            this.props.intl.formatMessage({ id: "SOME_ERROR_OCCURED_WHILE" }),
            "warning",
            this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
            3000
          );
          Common.LogError(ex, "ResidentNote", "deleteNoteHandler");
        })
        .then(() => {
          this.isLoading(false);
        });
    }
  }

  updateNewNoteState(state) {
    this.setState({
      isNewNote: state,
    });
    // if (state == false) {
    Common.LoadDataTable("residentNotes", this.props.intl);
    // }
  }

  loadNotes() {
    this.isLoading(true);
    Api.getRequest(
      Api.Uri_GetAllResidentNotesByResidentID +
        "?residentID=" +
        this.props.ResidentID
    )
      .then((res) => {
        const result = res?.data?.result;
        if (result != undefined && result?.length !== 0) {
          this.setState({
            rows: result,
          });
        }
      })
      .catch((ex) => {
        
        Common.showSuccessAlertAutoClose(
          this.props.intl.formatMessage({ id: "SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
          3000
        );
        Common.LogError(ex, "ResidentNote", "LoadData");

      })
      .then(() => {
        this.isLoading(false);
        Common.LoadDataTable("residentNotes", this.props.intl);
      });
  }

  saveNote = () => {
    if (this.state.noteText.length === 0) {
      Common.showSuccessAlert(
        this.props.intl.formatMessage({ id: "PLEASE_TYPE_IN_SOME_TEXT" }),
        "danger",
        this.props.intl.formatMessage({ id: "VALIDATION_ERROR" })
      );
      return;
    }
    let payload = {
      subject: this.state.noteText,
      noteReason:this.state.noteReason,
      status: 1,
      residentId: this.props.ResidentID,
      createdBy: this.props.userID,
      modifiedBy: this.props.userID,
      noteId: this.state.currentlySelectedNoteId,
    };
    this.isLoading(true);
    Api.postRequest(Api.Uri_UpsertResidentNotes, payload)
      .then((res) => {
        if (res.data.success) {
          if (res.data.result != null) {
            Common.showSuccessAlertAutoClose(
              res.data.message,
              "success",
              this.props.intl.formatMessage({ id: "SUCCESS.TITLE" }),
              3000
            );
            this.updateNewNoteState(false);
            this.setState({ noteText: "" });
            this.loadNotes();
          } else {
            Common.showSuccessAlertAutoClose(
              res.data.message,
              "warning",
              this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
              3000
            );
          }
        } else {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "warning",
            this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
            3000
          );
        }
      })
      .catch((ex) => {
        
        Common.showSuccessAlertAutoClose(
          this.props.intl.formatMessage({ id: "SOME_ERROR_OCCURED_WHILE" }),
          "warning",
          this.props.intl.formatMessage({ id: "FAILED.TITLE" }),
          3000
        );
        Common.LogError(ex, "ResidentNote", "delete");

      })
      .then(() => {
        this.isLoading(false);
      });
  };

  onCheckBoxClicked = ({ target }) => {
    try {
      if (target.checked) {
        checkCount = checkCount + 1;
      } else {
        if (checkCount > 0) {
          checkCount = checkCount - 1;
        }
      }
      if (checkCount > 0) {
        this.setState({ isPDFSelected: true });
      } else {
        this.setState({ isPDFSelected: false });
      }
    } catch (error) {
      Common.LogError(error, "ResidentNote", "checkbocclicked");
      
    }
  };
} //end

const ResidentNotesComponent = injectIntl(ResidentNotes);

export default ResidentNotesComponent;
import RoutePaths from "@/app/constants/routepaths";
import { ModulesName } from "@/app/General/roleModuleActions";

const menuConfigBR = [
  {
    module: ModulesName.Module_Setup,
    route: RoutePaths.Page_SetupNav,
    messageId: "HEADER.ADMIN",
    submenuItems: [
      { route: RoutePaths.Page_Lookuplist, textId: "HEADER.LOOKUPS" },
      { route: RoutePaths.Page_Role, textId: "HEADER.ROLES" },
      {
        route: RoutePaths.Page_SystemSetting,
        textId: "HEADER.SYSTEM_SETTINGS",
      },
      { route: RoutePaths.Page_ICDCode, textId: "HEADER.ICD_CODE" },
      { route: RoutePaths.Page_Insurance, textId: "HEADER.SYNC_INSURANCE" },
      { route: RoutePaths.Page_UserList, textId: "HEADER.USERS" },
      {
        route: RoutePaths.Page_EMRCredential,
        textId: "HEADER.ERM_CREDENTIALS",
      },
      {
        route: RoutePaths.Page_WoundAndProductSpecialist,
        textId: "HEADER.WOUND_AND_PRODUCT_SPECIALIST",
      },
    ],
  },
  {
    module: ModulesName.Module_FacilityQueue,
    route: RoutePaths.Page_FacilityQueue,
    messageId: "HEADER.ASSESSMENT_QUEUE",
  },
  {
    module: ModulesName.Module_AssessmentReports,
    route: RoutePaths.Page_AssessmentReportNav,
    messageId: "HEADER.ASSESSMENT_REPORTS",
    submenuItems: [
      {
        route: RoutePaths.Page_WoundEvaluationReport,
        textId: "HEADER.WOUND_EVALUATION_REPORT",
      },
      {
        route: RoutePaths.Page_FacilityReport,
        textId: "HEADER.WOUND_REPORT",
      },
      { route: RoutePaths.Page_WoundReport, textId: "HEADER.TREND_REPORT" },
      { route: RoutePaths.Page_QAPIReport, textId: "HEADER.QAPI_REPORT" },
    ],
  },
];

export default menuConfigBR;

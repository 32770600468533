import JWT from "../encoder/jwt";
import LocalStoragePersistence from "../persistence/local-storage";
import AxiosApi from "./axios-api";

export default class AxiosTokenApi extends AxiosApi {
  /**
   * Storage instance
   * @type {LocalStoragePersistence}
   */
  _storage;

  /**
   * JWT instance
   * @type {JWT}
   */
  _jwt;

  /**
   * AxiosTokenApi constructor
   * @param {LocalStoragePersistence} storage
   * @param {JWT} jwt
   */
  constructor(storage, jwt) {
    super();

    this._storage = storage;
    this._jwt = jwt;

    const token = this._storage.getItem("gentell-fastcare-authToken");
    this._jwt.setToken(token);

    this._axiosInstance.interceptors.request.use(
      (config) => {
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  async refreshToken() {
    try {
      const token = this._jwt.getToken();
  
      if (!token) {
        return;
      }
  
      const response = await this.get("account/refreshToken");
      const { result: tokenRefreshed } = response;

      this._storage.setItem("gentell-fastcare-authToken", JSON.stringify(tokenRefreshed));
    } catch (error) {
      console.error(error);
      this._storage.removeItem("gentell-fastcare-authToken");
      this._storage.removeItem("gentell-fastcare-authUser");
    }
  }
}

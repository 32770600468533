
import React from 'react';
import { Badge } from 'react-bootstrap';
import $ from 'jquery';

import hospitalsDatajson from '../../data/hospitals.json'
import usersDatajson from '../../data/users.json';

export function PatientOrders() {

    var rows = [];
    for (var i = 0; i < 200; i++) {
        var obj = usersDatajson[i];
        rows.push(<tr key={i.toString()}>
            <td> <button type="button" className="btn btn-link">{hospitalsDatajson[i]}</button></td>
            <td> <button type="button" className="btn btn-link">{Math.floor(100000 + Math.random() * 900000)}</button></td>
            <td> <button type="button" className="btn btn-link">{}</button></td>
            <td>{Math.floor(Math.random() * 12) + 1 + "/" + (Math.floor(Math.random() * 30) + 1) + "/2020"}</td>
            <td>Resident Order</td>
            <td>Medicare</td>
            <td>
                <Badge variant="primary">Internal processing</Badge>
            </td>
        </tr>);
    }

    //initialize datatable
    $(document).ready(function () {

        if ($.fn.dataTable.isDataTable('#recentOrdersList')) {
            $('#recentOrdersList').DataTable = $('#recentOrdersList').DataTable();
        }
        else {
            $('#recentOrdersList').DataTable({
                responsive: true,
                pageLength: 25,
                lengthMenu: [[25, 50, 100, -1], [25, 50, 100, "All"]],
            });
        }
    });

    return (
        <>
            <table className="table table-striped table-hover table-sm table-bordered" id="recentOrdersList">
                <thead>
                    <tr>
                        <th>Facility Name</th>
                        <th>Order #</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Order Type</th>
                        <th>Bill</th>
                        <th width="150px">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </>
    );
}
export default PatientOrders;
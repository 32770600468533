//#region Imports
import { getDay, startOfDay, startOfTomorrow } from 'date-fns';
import { enGB } from 'date-fns/locale';
import React from 'react';
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates';
import '../../../../react-nice-dates.css';
import { apiUrls } from '../../../constants/endpoints';
import * as API from '../../../General/api';
import * as common from '../../../General/common';

//#endregion

const modifiers = {
    disabled: date => { return (getDay(date) === 6 || getDay(date) === 0) },
    highlight: date => { return (getDay(date) === 6 || getDay(date) === 0) }
}
const modifiersClassNames = { highlight: '-highlight' }

export default class AddVacation extends React.Component {

    constructor(props) {
        super(props);
        // debugger
        this.state = {
            id: props.vacation?.id ? props.vacation?.id : 0,
            startDate: props.vacation?.fromDate ? new Date(props.vacation?.fromDate) : null,
            endDate: props.vacation?.toDate ? new Date(props.vacation?.toDate) : null,
            focus: START_DATE,
            isLoading: false
        }
    }

    setisLoading = (val) => {
        this.setState({ isLoading: val });
    }

    handleFocusChange = newFocus => {
        this.setFocus(newFocus || START_DATE)
    }

    onSubmit = () => {
        if (this.state.isLoading) return; // prevent multiple savechanges
        if (this.state.startDate && this.state.endDate) {
            this.onSubmitAPICall();
        } else {
            common.showSuccessAlert("Please select Start and End Date to proceed", "warning", "Warning!!");
        }
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    onSubmitAPICall = () => {
        this.setisLoading(true);
        let obj = {
            id: this.state.id,
            fromDate: this.formatDate(startOfDay(this.state.startDate)),
            toDate: this.formatDate(startOfDay(this.state.endDate)),
            active: true
        };
        try {
            API.postRequest(apiUrls.upsertvacation, obj, false).then((raw) => {
                const response = raw.data;
                if (response.success) {
                    common.showSuccessAlert(response.message, "success", "Success!!");
                    this.props.refresh();
                } else {
                    common.showSuccessAlert(response.message, "error", "Error!!");
                    this.setisLoading(false);
                }
            })
        } catch (ex) {
            common.LogError(ex, "Vacation", "Add");
            this.setisLoading(false);
        }
    }

    setStartDate = (value) => { this.setState({ startDate: value }) }
    setEndDate = (value) => { this.setState({ endDate: value }) }
    setFocus = (value) => { this.setState({ focus: value }) }

    render() {
        return <>
            <div>
                {
                    this.state.isLoading ? <div className="row d-flex justify-content-center">
                        <span className="mt-5 spinner spinner-primary"></span>
                    </div> :
                        <div>
                            {/* <p>Selected start date: {this.state.startDate ? format(this.state.startDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
                            <p>Selected end date: {this.state.endDate ? format(this.state.endDate, 'dd MMM yyyy', { locale: enGB }) : 'none'}.</p>
                            <p>Currently selecting: {this.state.focus}.</p> */}
                            <DateRangePickerCalendar
                                minimumDate={startOfTomorrow()}
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                focus={this.state.focus}
                                modifiers={modifiers}
                                modifiersClassNames={modifiersClassNames}
                                onStartDateChange={this.setStartDate}
                                onEndDateChange={this.setEndDate}
                                onFocusChange={this.handleFocusChange}
                                locale={enGB}
                            />
                        </div>
                }
            </div>


        </>
    }

}
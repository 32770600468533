import { Switch } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import {
  Accordion,
  AccordionCollapse,
  AccordionToggle,
  Button
} from "react-bootstrap";
import { showDecisionAlert, showWarningAlert } from "../../../index";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import TracheostomyList from './tracheostomyList';


export function TracheostomyResident(props) {
  //#region Resident modal
  const [showModalResident, setShowResident] = useState(false);
  const FacilityId = props.facility.id;
  const handleCloseResident = () => setShowResident(false);
  const handleShowResident = () => setShowResident(true);
  //#endregion
  const [residentListState, setResidentListState] = useState([]);
  const [residentListUI, setResidentListUI] = useState([]);
  const displatWarningAlert = () => showWarningAlert("Coming soon!!!", "Alert");
  const displayDecisionAlert = (title, message) =>
    showDecisionAlert(
      function () { },
      function () { },
      title == null ? "Are you sure?" : title,
      message == null ? "You want to deactivate this Resident." : message
    );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllResident();
    //setResidentListTemp();
    //Common.LoadDataTable("facilityList")
  }, [getAllResident]);

  //#region Set Resident List
  const getAllResident = useCallback(() => {
    setIsLoading(true);
    Api.getRequestQry(
      Api.URI_GetAllWorkQueueResidentByFacilityID,
      "?facilityID=" + FacilityId
    )
      .then((res) => {
        // debugger;
        if (res.data.statusCode == 200) {
          if (res.data.result != null) {
            if (res.data.result.length > 0) {
              setResidentList(res.data.result);
            }
          }
        }

      })
      .catch((ex) => { })
      .then(() => {
        setIsLoading(false);
        Common.LoadDataTable("residentsList");
      });
  });

  const [residentStatus, setResidentStatus] = useState({});
const [residentName,setResidentName]=useState('')
  const checkAllProccessed = () => { };
  const handleResidentStatus = (residentID) => (event) => {
    let resident = {};
    resident.ResidentID = residentID;
    resident.Status = event.target.checked;
    updateResidentStatus(resident);
  };
  const updateResidentStatus = (obj) => {
    var msg = (obj.Status ? "Do you want to process this resident." : "Do you want to make your changes.");
    Common.showConfirmation(msg, "", updateStatus, null, obj);
  };
  const updateStatus = (obj) => {
    if (obj) {
      setIsLoading(true);
      Api.postRequest(Api.Uri_UpdateSaleOrderStatus, obj).then((res) => {
        if (res.data.success) {
          getAllResident();
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
        } else {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "warning",
            "Warning!",
            3000
          );
        }
        setIsLoading(false);
      });
    }
  };

  const releaseFacility = () => {
    // debugger;
    handleReleaseFacility(FacilityId);
  };
  const handleReleaseFacility = (facilityID) => {
    setIsLoading(true);
    var obj = {};
    obj.FacilityID = facilityID;
    // Api.postRequest(Api.Uri_UpdateFacilityStatus, obj)
    Api.getRequestQry(
      Api.URI_UpdateUrologicalBillingOUtsOrderStatusByFacilityID,
      "?facilityID=" + FacilityId
    )
      .then((res) => {
        if (res.data.success) {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "success",
            "Success!",
            3000
          );
          props.backPress();
        } else {
          Common.showSuccessAlertAutoClose(
            res.data.message,
            "warning",
            "Failed!",
            3000
          );
        }
      })
      .catch((ex) => {
        console.log(ex);
        Common.showSuccessAlertAutoClose(
          "some error occured while processing your request",
          "warning",
          "Failed!",
          3000
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  };
  const setResidentList = (list) => {
    var rows = [];
    var resStatus = residentStatus;
    for (var i = 0; i < list.length; i++) {
      const obj = JSON.parse(JSON.stringify(list[i]));
      const name = obj.lastName + ", " + obj.firstName;
      const residentID = obj.id;

      if (resStatus[residentID] == null) {
        resStatus[residentID] = false;
      }
      var statusBadgeType =
        obj.isProcess === true ? "badge-success" : "badge-warning";
      rows.push(
        <Accordion key={i.toString()} defaultActiveKey={!obj.isProcess ? i.toString() + obj.id : ""}>
          <Card>
            <CardHeader>
              <div
                className={`w-100 d-flex border-bottom  ${obj.isProcess
                  ? "bg-light-success border-success"
                  : "border-light-dark"
                  }`}
              >
                <AccordionToggle
                  as={Button}
                  variant="link"
                  className="h5 mb-0"
                  eventKey={i.toString() + obj.id}
                >
                  {name}
                </AccordionToggle>
                {/* <span className={`badge my-auto mx-4 ml-auto `}>
                  <span
                    className={`badge my-auto mx-4 ml-auto ${statusBadgeType}`}
                  >
                    {obj.isProcess === true ? "Processed" : "pending"}
                  </span>
                  <Switch
                    checked={obj.isProcess}
                    onChange={handleResidentStatus(obj.id)}
                    value="checkedB"
                    color="primary"
                  />
                </span> */}
              </div>
            </CardHeader>
            <AccordionCollapse eventKey={i.toString() + obj.id}>
              <CardBody>
         
                <fieldset disabled={obj.isProcess}>
                  <TracheostomyList facility={props.facility}   resident={obj} processStatusID={props.processStatusID} />
                </fieldset>
              </CardBody>
            </AccordionCollapse>
          </Card>
        </Accordion>
      );
    }

    //return rows
    setResidentListUI([]);
    setResidentListUI(rows);
  };

  // for (var i = 0; i < 3; i++) {
  //     var obj = usersDatajson[Math.floor(Math.random() * usersDatajson.length)];
  //     var dob = new Date(obj.birthdate);
  //     let randomCount = Math.floor(Math.random() * status.length);
  //     var randStatus = status[randomCount];
  //     var randStatusBadgeType = statusBadgeType[randomCount];
  //     //${randStatus == "Processed" ? "bg-light-success" :""}
  //     rows.push(
  //         <Accordion>
  //             <Card>
  //                 <CardHeader>
  //                     <div className={`w-100 d-flex border-bottom  ${randStatus == "Processed" ? "bg-light-success border-success" :"border-light-dark"}`}>
  //                         <AccordionToggle as={Button} variant="link" className="h5 mb-0" eventKey={i.toString()}>
  //                             {obj.first_name + " " + obj.last_name}
  //                         </AccordionToggle>
  //                         <span className={`badge my-auto mx-4 ml-auto ${randStatusBadgeType}`}>{randStatus}</span>
  //                     </div>
  //                 </CardHeader>
  //                 <AccordionCollapse eventKey={i.toString()}>
  //                     <CardBody><WorkQueueWounds /></CardBody>
  //                 </AccordionCollapse>
  //             </Card>
  //         </Accordion >
  //     );
  // }

  const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(
    ShowAdvancedSearch ? true : false
  );
  const showAdvanceSearchSection = () => {
    showAdvanceSearchSectionState(!ShowAdvancedSearch);
  };

  const toolbar = (
    <div className="card-toolbar">
      <div className="example-tools">
      {props.processStatusID ==Common.ProcessStatus.billingQueueID &&
        <button
          type="button"
          className="mr-5 btn btn-success btn-sm"
          onClick={() => {
            releaseFacility();
          }}
        >
          Release Facility
        </button>
}
        <button
          type="button"
          className="mr-5 btn btn-dark btn-sm"
          onClick={() => props.backPress()}
        >
          Back To Facility List
        </button>
        <Button variant="warning" size="sm" onClick={showAdvanceSearchSection}>
          Find Resident
        </Button>
      </div>
    </div>
  );

  //initialize datatable
  //   $(document).ready(function() {
  //     if ($.fn.dataTable.isDataTable("#residentsList")) {
  //       $("#residentsList").DataTable = $("#residentsList").DataTable();
  //     } else {
  //       $("#residentsList").DataTable({
  //         responsive: true,
  //         pageLength: 25,
  //         lengthMenu: [
  //           [25, 50, 100, -1],
  //           [25, 50, 100, "All"],
  //         ],
  //       });
  //     }
  //   });

  return (
    <>
      {ShowAdvancedSearch && (
        <Animated
          animationIn="fadeInLeft"
          animationInDuration={800}
          animationOut="fadeOut"
          isVisible={ShowAdvancedSearch}
        >
          <Card>
            <CardHeader title="Search Resident" />
            <CardBody>
              <div
                className="alert border-primary bg-light-primary fade show mb-5"
                role="alert"
              >
                <div className="d-flex align-items-center">
                  <div className="alert-icon">
                    <i className="fas fa-info-circle mr-4 text-primary"></i>
                  </div>
                  <div className="flex-1">
                    <span>
                      Find patients directly, for all statuses:
                      <ol>
                        <li>Use the beginning of first or last name</li>
                        <li>Use full date of birth in mm/dd/yyyy format</li>
                        <li>Use full ssn as nnn-nn-nnnn or nnnnnnnnn format</li>
                      </ol>
                      Otherwise, you will get the whole list for this patient
                      status.
                    </span>
                  </div>
                </div>
              </div>
              <form>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="PatientName">
                        Patient Name
                      </label>
                      <input
                        type="text"
                        id="PatientName"
                        className="form-control form-control-sm"
                      />
                      <small>Use 3 or more letters of first or last name</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="DOB">
                        DOB
                      </label>
                      <input
                        type="text"
                        id="DOB"
                        className="form-control form-control-sm"
                      />
                      <small>Use mm/dd/yyyy</small>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="SSN">
                        SSN
                      </label>
                      <input
                        type="text"
                        id="SSN"
                        className="form-control form-control-sm"
                      />
                      <small>Must be nnn-nn-nnnn or nnnnnnnnn</small>
                    </div>
                  </div>
                </div>
              </form>
            </CardBody>
            <CardFooter className="d-flex justify-content-end py-3">
              <Button variant="success" className="mr-5 px-10" size="sm">
                Find
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={showAdvanceSearchSection}
              >
                Cancel
              </Button>
            </CardFooter>
          </Card>
        </Animated>
      )}

      <Card className="example example-compact">
        <CardHeader>
          <div className="card-title">
            <span>
              <h3 className="card-label">Resident List</h3>
              <small className="">
                <b>Facility:</b> {props.facility.facilityName},{" "}
                <b>Chain:</b>{" "} {props.facility.chain},{" "}
                <b>Group:</b>{" "} {props.facility.organizationName}
                {/* ,{" "}<b>BillingDate:</b>{" "} {Common.getInputStringDate(new Date(props.facility.cycleDate))} */}
              </small>
            </span>
          </div>
          {toolbar}
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div className="row d-flex justify-content-center">
              <span className="mt-5 spinner spinner-primary"></span>
            </div>
          ) : (
              residentListUI
            )}
        </CardBody>
      </Card>
  
    </>
  );
}

export default TracheostomyResident;

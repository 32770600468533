import React, { useCallback, useEffect, useState } from 'react';
import { Animated } from "react-animated-css";
import { Button, Badge, ButtonToolbar, Dropdown, DropdownButton, Form } from "react-bootstrap";
import AddUrological from './addurological';
import PopupModal from "../modal/popupmodal";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { Card, CardBody } from "../../../_metronic/_partials/controls/Card"
var addNewUrologicalModal = null;
export default class UrologicalList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addEditOstomyUI:'',
      urological: {
        urologicalId: 0,
        fkResidentId: props.resident.id,
        diagnosisCode: null,
        isPermanency: false,
        isAlatexAllergy: false,
        isExternalCatheter: false,
        externalCatheter: null,
        isFoley: false,
        ballonSize: null,
        size: null,
        isSiliconeElastomeric: false,
        isAllSilicone: false,
        isSiliver: false,
        isIntermittent: false,
        intermittentType: null,
        intermittentSize: null,
        intermittentLength: null,
        frequency: null,
        quantitiy: null,
        saleOrderViewModels: []
      },
      rows: [],
      diagCodes: ["Retention of Urine R33.9", "Urinary Incontinence R32", "Stress Incontinence N39.3"],
      externalCatheter: ["Small: 23mm", "Medium 28mm", "Intermed 31mm", 'Large 35mm', 'X-Large: 40mm'],
      follyCatheter: ["18Fr", "5cc", "20Fr", '30cc', '14Fr', '22Fr']
    }
  }
  componentDidMount() {

    this.GetResidentUrological();
  }

  GetResidentUrological = () => {
    debugger

    // Api.getRequestById(Api.URI_GetUrologicalSaleOrderByResidentId, this.props.resident.id)
    Api.getRequestQry(Api.URI_GetUrologicalSaleOrderByResidentId, "?residentId="+this.props.resident.id+"&processStatusID=" + this.props.processStatusID)
    .then((res) => {
      debugger
      if (res.data.statusCode == 200) {
        if (res.data.result != null) {
          if (res.data.result.length > 0) {
            this.setUrologicalLstUI(res.data.result);
          }
        }
      }

      Common.LoadDataTable("woundList")
    }).catch((ex) => {
      // Common.LogError(error, "Wound", "GetResidentWound");
    });
  }
  processSaleOrder = (saleOrderID) => {

    // Api.getRequestById(Api.URI_ProcessUrologicalSaleOrderById, saleOrderID)
    Api.getRequestQry(Api.URI_ProcessUrologicalSaleOrderById, "?saleOrderID="+saleOrderID+"&processStatusID=" + this.props.processStatusID)

    .then((res) => {

      if (res.data.statusCode == 200) {
        if (res.data.result > 0) {
          Common.showSuccessAlertAutoClose("Urological Order processed Successfully", "success", "Success!", 3000);
        }
        this.GetResidentUrological();
      }

      Common.LoadDataTable("woundList")
    }).catch((ex) => {
      // Common.LogError(error, "Wound", "GetResidentWound");
    });
  }
  setUrologicalLstUI = (list) => {
    debugger
    let rows = [];

    for (var i = 0; i < list.length; i++) {
      const obj = list[i];
      if (obj.brightreeKey == null) {
        debugger
        rows.push(
          <>
            <tr key={i.toString()}>
              <td className="justify-content-center"> <b>{i + 1}</b> </td>
              <td>{obj.diagnosisCode}</td>
              <td>{obj.IsAlatexAllergy ? <Badge variant="info">Yes</Badge> : <Badge variant="info">No</Badge>}</td>
              <td>{obj.externalCatheter}</td>
              <td>{obj.size}</td>
              <td align="center">
                <>

                  <ButtonToolbar className="justify-content-center">
                  {this.props.processStatusID ==Common.ProcessStatus.workQueueID &&
                    <div className="mr-md-4 mr-0 mb-1 mb-md-0">

                      <Button
                        size="xs"
                        variant="success"
                        onClick={() => this.processSaleOrder(obj.urologicalId)}
                      >
                        Release
                  </Button>

                    </div>
      }
                    <div className="mr-md-4 mr-0 mb-1 mb-md-0">
                      {[DropdownButton].map((DropdownType, idx) => (
                        <DropdownType
                          size="xs"
                          variant="primary"
                          title="Actions"
                          id={`dropdown-button-drop-${idx}`}
                          key={idx}
                        >
                          <Dropdown.Item onClick={() => { this.handleShowEditUrologicalModal(obj) }} eventKey="1" >Edit Urological</Dropdown.Item>
                        </DropdownType>
                      ))}
                    </div>
                  </ButtonToolbar>
                </>
              </td>
            </tr>
            <tr>
              <td colSpan="9">
                <Card className="example example-compact">
                  <CardBody>
                    <Animated animationIn="fadeIn" animationInDuration={2000} >

                      <table className="table table-striped table-hover table-sm table-bordered" id="woundProductList" >
                        <thead>
                          <tr>
                            <th>Product Name</th>
                            {/* TASK 2120: Remove 'Remaining Quantity' when reordering
                            <th>Remaining Day(s)</th>
                            {/* <th>Type</th>
                      <th>Category</th> */}
                            {/* <th>SKU</th> */}
                            <th>Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.getProductUi(obj)}
                        </tbody>
                      </table>
                    </Animated>
                  </CardBody>
                </Card>
              </td>
            </tr>
          </>
        );
      }
    }
    this.setState({ rows: rows });
  }



  randomNumber(min, max) {
    var num = Math.random() * (max - min) + min;
    return Math.round(num);
  }
  getProductUi = (saleOrder) => {

    let productrows = [];
    let products = saleOrder.saleOrderViewModels;

    for (var i = 0; i < products.length; i++) {
      const obj = products[i];

      productrows.push(<tr key={i.toString()}>
        <td><button className="btn btn-link" >{obj.productName}</button></td>
        <td>{this.randomNumber(1, 7)}</td>

        {/* <td>{obj.productLevelName}</td>
      <td>{obj.productCategoryDescription}</td> */}
        {/* <td>{obj.sku}</td> */}
        <td >
          {obj.quantity}
        </td>
      </tr>);
    }
    return productrows;

  }


  handleShowEditUrologicalModal = (obj) => {

    this.setState({addEditOstomyUI:<AddUrological urological={obj} residentID={this.props.resident.id} close={this.handleCloseAdd} />})
    addNewUrologicalModal.showModal();
  }
  handleShowAddNewUrologicalModal = () => {

    this.setState({addEditOstomyUI:<AddUrological urological={this.state.urological} residentID={this.props.resident.id} close={this.handleCloseAdd} />})
    addNewUrologicalModal.showModal();
  }
  handleCloseAdd = () => {
    addNewUrologicalModal.hideModal();
    this.GetResidentUrological();

  };
  render() {
    return (
      <>
        <div className="d-flex justify-content-end">
          <button className="btn btn-primary btn-xs mr-5 mb-3" size="sm" onClick={() => { this.handleShowAddNewUrologicalModal() }} >Add New Urological</button>
        </div>
        <Animated animationIn="fadeIn" animationInDuration={2000} >

          <table className="table table-striped table-hover table-sm table-bordered" id={"UrologicalList"}>
            <thead>
              <tr>
                <th>Urological#</th>
                <th>Diagnosis Code (ICD10)</th>
                <th>Latex allergy</th>
                <th>External Catheter</th>
                <th>Foly Catheter</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.rows}
            </tbody>
          </table>
        </Animated>
        {
          <PopupModal title={
            <div className="">
              <span>
                <h3 className="card-label">Urological Intake</h3>
                <small className="">
                  {/* <b>Resident:</b>{" "} {residentName},{" "} 
                <b>Facility:</b> {props.facility.facilityName},{" "}
                <b>Chain:</b>{" "} {props.facility.chain},{" "}
                <b>Group:</b>{" "} {props.facility.organizationName} */}
                </small>
              </span>
            </div>
          } dialogClassName="modal-90w" ref={ref => { addNewUrologicalModal = ref; }}>
            <Animated animationIn="fadeIn" animationInDuration={2000} >
              {/* <AddUrological urological={this.state.urological} close={this.handleCloseAdd} /> */}
              {this.state.addEditOstomyUI}
            </Animated>
          </PopupModal>
        }
      </>
    );
  }
}

import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import React from "react";
import { injectIntl } from "react-intl";

function CardHeaderUS({ intl, ...props }) {
  return (
    <div className="card-title">
      <span>
        <b className="text-success">
          {intl.formatMessage({ id: "FACILITY_RESIDENT.FACILITY" })}:{" "}
          {props.facility.facilityName}
        </b>{" "}
        , <br />
        <b>
          {intl.formatMessage({ id: "FACILITY.OWNERSHIP_HEADQUARTERS" })}:
        </b>{" "}
        {props.facility.organizationName == ""
          ? "N/A"
          : props.facility.organizationName}
        ,{" "}
        <b>{intl.formatMessage({ id: "FACILITY.PURCHASING_GORUP_CHAIN" })}:</b>{" "}
        {props.facility.chain == "" ? "N/A" : props.facility.chain},{" "}
        <b>
          <FormControlLabel
            label={
              <Typography>
                <h4 className="mt-4">
                  {" "}
                  {intl.formatMessage({
                    id: "FACILITY_RESIDENT.DATA_TABLE.PART_B",
                  })}
                  :
                </h4>
              </Typography>
            }
            labelPlacement="start"
            control={
              <Checkbox
                checked={props.facility.medicarePartBbyGentell}
                value="checkedA"
              />
            }
          />
          <FormControlLabel
            label={
              <Typography>
                <h4 className="mt-4">
                  {" "}
                  {intl.formatMessage({
                    id: "FACILITY_RESIDENT.DATA_TABLE.EQUAL_CARE",
                  })}
                  :
                </h4>
              </Typography>
            }
            labelPlacement="start"
            control={
              <Checkbox checked={props.facility.isEcp} value="checkedB" />
            }
          />
          <FormControlLabel
            label={
              <Typography>
                <h4 className="mt-4">
                  {intl.formatMessage({
                    id: "FACILITY_RESIDENT.DATA_TABLE.OUTS",
                  })}
                  :
                </h4>
              </Typography>
            }
            labelPlacement="start"
            control={
              <Checkbox checked={props.facility.outProgram} value="checkedC" />
            }
          />
        </b>
        <h3 className="card-label mt-3">
          {intl.formatMessage({
            id: "FACILITY_RESIDENT.DATA_TABLE.RESIDENT_LIST",
          })}
        </h3>
      </span>
    </div>
  );
}

export default injectIntl(CardHeaderUS);

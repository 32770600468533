import RoutePaths from "@/app/constants/routepaths";
import RBAC from "@/app/General/roleModuleActions";

/**
 * Validate the preconditions before determining the current page.
 * @param {object} user
 */
function validatePreConditions(user) {
  if (!user || !user.roles || !Array.isArray(user.roles.roleList)) {
    throw new Error("Pre-condition failed");
  }
}

/**
 * Validate the postconditions after determining the current page.
 * @param {string} currentPage
 */
function validatePostConditions(currentPage) {
  if (!Object.values(RoutePaths).includes(currentPage)) {
    throw new Error("Post-condition failed");
  }
}

/**
 * Get current page based on user roles
 * @param {object} user
 * @returns {string} currentPage
 */
function getCurrentPage(user) {
  const roleToPageMap = {
    [RBAC.Role_Gentell_Administrator]: RoutePaths.Page_Facility,
    [RBAC.Role_Gentell_RegionalManager]: RoutePaths.Page_WorkQueue,
    [RBAC.Role_Gentell_WoundCareSpecialist]: RoutePaths.Page_WorkQueue,
    [RBAC.Role_Gentell_SeniorBiller]: RoutePaths.Page_Billing,
    [RBAC.Role_Gentell_IntakeProcessor]: RoutePaths.Page_Billing,
    [RBAC.Role_Facility_Level1]: RoutePaths.Page_FacilityQueue,
    [RBAC.Role_Facility_Level2]: RoutePaths.Page_FacilityQueue,
  };

  return (
    user.roles.roleList
      .map((role) => roleToPageMap[role.roleName])
      .find((page) => page !== undefined) || RoutePaths.Page_Facility
  );
}

/**
 * Main function to get current page by user roles
 * @param {object} user
 * @returns {string} currentPage
 */
export default function getCurrentPageByUserRoles(user) {
  validatePreConditions(user);

  const currentPage = getCurrentPage(user);

  validatePostConditions(currentPage);

  return currentPage;
}

import { jwtDecode } from 'jwt-decode';

export default class JWT {
  #token;

  setToken(token) {
    this.#token = token;
  }

  getToken() {
    return this.#token;
  }

  /**
   * Check if the token is valid
   * @returns {boolean}
   */
  isValid() {
    try {
      const { exp } = jwtDecode(this.#token);
      const currentTime = Date.now() / 1000;

      return exp > currentTime;
    } catch (error) {
      return false;
    }
  }

  /**
   * Check if the token is expired
   * @returns {boolean}
   */
  isExpired() {
    try {
      const { exp } = jwtDecode(this.#token);
      const currentTime = Date.now() / 1000;

      return exp < currentTime;
    } catch (error) {
      return true;
    }
  }

  /**
   * Check if the token will expire soon
   * @param {number} bufferTime - default 2 minutes
   * @returns {boolean}
   */
  itWillExpireSoon(bufferTime = 120_000) {
    try {
      const { exp } = jwtDecode(this.#token);
      const currentTime = Date.now() / 1000;

      return exp < currentTime + (bufferTime / 1000);
    } catch (error) {
      return false;
    }
  }
}

//import Checkbox from './Checkbox';
import { Checkbox } from "@material-ui/core";
import axios from 'axios';
import React from "react";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
const instance = axios.create();


export default class UserRole extends React.Component

{
    state = {
      
        Person: [],
        User: [],
        UserID: "",
        RoleID: [],
        CreatedBy: 1,
        CreatedDate: "",
        userRoleList: [],
        refresh: false
    };



    handleChange = (e) => {
  
        this.setState(
            {
                RoleID: e.target.value,
                UserID: e.target.value
            });


    }
    handleChangeRole = (e) => {

        this.setState(
            {
                RoleID: e.target.value,

            });


    }

    onButtonPress = event => {

        const userRole = {
            UserInRoleId: 0,
            RoleID:this.state.Person,
            UserID: this.props.userID,
            CreatedBy: 34,
            CreatedDate: "2020-12-18T12:39:06.615Z",
        };

    
       


        Api.postRequest('DropDown/UpdateUserRolesById',userRole)
        .then(response => {   
          //Common.showSuccessAlertAutoClose("User list get successful", "success", "Success!", 3000);
          //Common.showSuccessAlert(res.data.message, "error", "Error! " + res.data.statusCode);
          Common.showSuccessAlertAutoClose("User Role Updated Successfully", "success", "Success!", 3000);
          this.props.onSaveAPI();
        })  
        .catch(function (error) {  
            
        })  

    }


   
    

    handleCheckChieldElement = (event) => {
       
        let person = this.state.Person
        person.forEach(pers => {
           if (pers.roleID == event.target.value)
           pers.assigned =  event.target.checked
        })
        this.state.Person=person;
        this.setState(this.state)
      }
   

    componentDidMount() {

        ////// For Get UserRoleByID
        Api.getRequestQry(Api.Uri_GetRolesByUserID,"?userID="+this.props.userID+"&isForGentell="+this.props.isForGentell)
            .then(res => {
                this.state.Person = res.data.result;
                this.setState(this.state);
            })
            .catch(function (error) {
                
            })




    }
    
    createCheckbox = data => {
        return (
            <>
            <li class="list-unstyled">
                <Checkbox
                    id={data.roleID}
                    checked={data.assigned}
                  //  onChange={(e) =>this.GetUserFunction(data.key, e.target.checked)}
                  onChange={this.toggleCheckbox}
                  onChange={this.handleCheckChieldElement}
                    value={data.roleID}
                />
                <label for={data.roleID} value={data.roleID}>{data.roleName}</label>
            </li>
                {/* <li class="list-unstyled">
                    <input type="checkbox" key={data.key} id={data.key} name={data.key} value={data.value} />
                    <label for={data.key} value={data.key}>{data.value}</label>
                </li> */}
            </>
        )

    }

    createCheckboxes = () => (
        this.state.Person.map(this.createCheckbox)
    )




    render() {
        return (

            <form onSubmit={this.handleSubmit}>
                <div className="row">

                    <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">

                            <ul>
                                {this.createCheckboxes()}
                            </ul>

                            <br />


                        </div>
                    </div>



                    {/* <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                        <div className="form-group">
                            <label className="form-label" htmlFor="RoleId">Role</label>
                            <select className="form-control form-control-sm" id="RoleId" name="RoleID" onChange={this.handleChangeRole}>
                                <option value=''></option>
                                {this.state.Person.map(function (data, key) {
                                    return (
                                        <option key={key} value={data.key}>{data.value}</option>)
                                })}
                            </select>
                        </div>
                    </div> */}


                </div>
                

            </form>

        );
    }
}

//export default UserRoleForm;
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody } from '../../../_metronic/_partials/controls/Card';
import Select, { createFilter } from "react-select";
import jsPDF from 'jspdf';
import * as Api from "../../General/api";
import html2canvas from 'html2canvas';
import moment from 'moment';
import HttpResponse from '../../General/validate-response';
import { FormattedDate, FormattedMessage, injectIntl } from "react-intl";

/** @typedef {import('../../jsdoc/historical-report.jsdoc').HistoricalReportModel} HistoricalReportModel */
/** @typedef {import('../../jsdoc/historical-report.jsdoc').WoundNameViewModel} WoundNameViewModel */
/** @typedef {import('../../jsdoc/historical-report.jsdoc').ResidentNameViewModel} ResidentNameViewModel */

function HistoricalReport({ intl }) {
  const [isLoading, setIsLoading] = useState(true);
  const [facilityList, setFacilityList] = useState([]);
  const [facilityName, setFacilityName] = useState();
  const [residentsList, setResidentsList] = useState([]);
  const [residentName, setResidentName] = useState(null);
  const [woundNameList, setWoundNameList] = useState([]);
  const [woundName, setWoundName] = useState(null);
  const [showReport, setShowReport] = useState(false);
  const [historicalReport, setHistoricalReport] = useState({});
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  useEffect(() => {
    const handleFacilitiesMatchingInNewFastCare = async () => {
      const response = await Api.getRequest("HistoricalReport/getFacilities")
        .then(HttpResponse.handle)
        .catch(HttpResponse.handle);

      if (!HttpResponse.isOk(response)) return;

      /** @type {FacilityViewModel[]} */
      const facilities = Object.values(HttpResponse.getData(response));

      const facilitiesMapped = facilities.map((facility) => ({
        value: facility.id,
        label: facility.name,
      }));

      const facilitiesSorted = facilitiesMapped.sort((a, b) => a.label?.localeCompare(b.label));
  
      setFacilityList(facilitiesSorted);
      setIsLoading(false);
    }
  
    const errorWrapper = (error) => {
      console.error(error);
      setIsLoading(false);
    }
    
    handleFacilitiesMatchingInNewFastCare().catch(errorWrapper);
  }, []);

  useEffect(() => {
    const handleResidents = async () => {
      const response = await Api.getRequestQry("HistoricalReport/getResidentNames", `?facilityId=${facilityName.value}`)
        .then(HttpResponse.handle)
        .catch(HttpResponse.handle);

      if (!HttpResponse.isOk(response)) return;

      /** @type {ResidentNameViewModel[]} */
      const residents = Object.values(HttpResponse.getData(response));

      const residentsMapped = residents.map((resident) => ({
        value: resident.id,
        label: `${resident.firstName} ${resident.lastName}`,
      }));

      setResidentsList(residentsMapped);
    }

    const isAllFieldsFilled = [facilityName].every(Boolean);

    if (isAllFieldsFilled) handleResidents();
  }, [facilityName]);

  useEffect(() => {
    const handleWoundNames = async () => {
      const response = await Api.getRequestQry("HistoricalReport/getWoundNames", `?residentId=${residentName.value}`)
        .then(HttpResponse.handle)
        .catch(HttpResponse.handle);

      if (!HttpResponse.isOk(response)) return;

      /** @type {WoundNameViewModel[]} */
      const wounds = Object.values(HttpResponse.getData(response));

      const woundsMapped = wounds.map((wound) => ({
        value: wound.id,
        label: wound.name,
      }));

      setWoundNameList(woundsMapped);
    }

    const isAllFieldsFilled = [facilityName, residentName].every(Boolean);

    if (isAllFieldsFilled) handleWoundNames();
  }, [facilityName, residentName]);

  const searchReport = async (e) => {
    e.preventDefault();

    const isAllFieldsFilled = [facilityName, residentName, woundName].every(Boolean);

    if (isAllFieldsFilled) {
      setIsLoadingReport(true);

      const response = await Api.getRequestQry("HistoricalReport/getHistoricalReport", `?woundId=${woundName.value}`)
        .then(HttpResponse.handle)
        .catch(HttpResponse.handle);

      if (!HttpResponse.isOk(response)) {
        setIsLoadingReport(false); return
      }

      /** @type {HistoricalReportModel} */
      const historicalReportFromApi = HttpResponse.getData(response);

      const historicalReport = {
        facilityName: historicalReportFromApi.facilityName,
        facilityAddress: historicalReportFromApi.facilityStreet,
        resident: residentName.label,
        room: historicalReportFromApi.room,
        physician: historicalReportFromApi.physicianName,
        site: historicalReportFromApi.site,
        bodyRegionLocation: historicalReportFromApi.woundLocation, // body region location
        typeOfWound: historicalReportFromApi.woundType,
        acquisition: historicalReportFromApi.acquisition,
        wounds: historicalReportFromApi.assessments.map((assessment) => ({
          date: intl.formatDate(assessment.evaluationDate, { year: 'numeric',
            month: '2-digit',
            day: '2-digit',}),
          thicknessStage: assessment.thickness,
          tissueType: historicalReportFromApi.tissueType,
          measurements: {
            length: Number.parseFloat(assessment.length).toFixed(1),
            width: Number.parseFloat(assessment.width).toFixed(1),
            depth: Number.parseFloat(assessment.depth).toFixed(1),
            tunelling: assessment.tunelling,
            clockPosition: assessment.tunellingClockPosition,
          },
          undermining: {
            undermining: assessment.undermining ? 'Yes' : 'No',
            depth: assessment.underminingDepth,
            clockPosition: assessment.underminingClockPosition,
            toClockPosition: assessment.underminingClockPosition,
          },
          exudate: {
            color: assessment.exudateColor,
            exudateType: assessment.exudateType,
            debridement: assessment.debridement,
            lastDebridementDate: assessment.debridementDate ? intl.formatDate(assessment.debridementDate, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }) : "",
            amount: assessment.exudateAmount
          },
          woundBed: {
            odor: assessment.odor ? 'Yes' : 'No',
            pain: assessment.pain ? 'Yes' : 'No',
            painMedicationOrdered: assessment.painMedicationOrdered ? 'Yes' : 'No',
            woundBedDescription: {
              granulationPerc: assessment.granulationPercent,
              hyperGranulationPerc: assessment.hyperGranulationPercent, // TODO
              sloughPerc: assessment.sloughPercent,
              necroticPerc: assessment.necroticPercent,
              epithelialPerc: assessment.epithelialPercent, // TODO
              musclePerc: assessment.musclePercent, // TODO
              otherPerc: assessment.otherPercent, // TODO
            },
            woundBed: assessment.WoundBedDescription
          },
          periWound: {
            woundEdges: assessment.woundEdges,
            surroundingSkin: assessment.surroundingSkin ?? "" // vem null,
          },
          comments: {
            skinUlcerTreatment: assessment.skinUlcerTreatment ?? "",
            treatment: assessment.treatment ?? "",
            generalComments: assessment.generalComments ?? "",
            signature: assessment.signature ?? "",
            eSigned: assessment.eSigned ?  intl.formatDate(assessment.eSigned, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }) : "",
            primaryDressing: assessment.primaryDressing ?? "",
            secondaryDressing: assessment.secondaryDressing ?? "",
          }
        }))
      }

      setHistoricalReport(historicalReport);
      setShowReport(true);
      setIsLoadingReport(false);
    }
  }

  const changeFacilityName = (event) => {
    setResidentsList([]);
    setWoundNameList([]);
    setFacilityName(event);
  }

  const PrintElem = () => {
    let elementProcessed = 0;
    let heightFromTop = 30;
    let elementsHeight = 330;
    let sheetWidth = 555;
    let widthFromLeft = 20;

    const doc = new jsPDF('p', 'pt', 'a4', false);
    const data = document.querySelectorAll("div[id^='tableForPrint-']");

    const canvas = document.getElementById('c');
    const ctx = canvas.getContext('2d');

    Array.from(data).forEach((item, index) => {
      html2canvas(item, { quality: 3, scale: 3 }).then(canvasItem => {

        canvas.width = 1236
        canvas.height = 900
        ctx.scale(1, 1);
        ctx.drawImage(canvasItem, 0, 0, 1236, 900);

        heightFromTop = 30;
        const imgData = canvas.toDataURL('image/png');

        doc.addImage(imgData, 'PNG', widthFromLeft, heightFromTop, sheetWidth, elementsHeight, index, 'FAST');

        elementProcessed++;

        if (elementProcessed % data.length !== 0 && elementProcessed > 0) {
          doc.addPage();
          heightFromTop = 30;
        }

        if (elementProcessed === data.length) {
          const pageCount = doc.internal.getNumberOfPages();
          doc.setFont('Poppins', 'normal', 'bold');
          doc.setFontSize(8);
          doc.setTextColor(112, 108, 108);
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i)
            doc.text(intl.formatMessage({ id: 'REPORTS.HISTORICAL.PAGE' }) + ' ' + i + ' ' + intl.formatMessage({ id: 'REPORTS.HISTORICAL.OF' }) + ' ' + pageCount, doc.internal.pageSize.width - 50, doc.internal.pageSize.height - 20);
          }
          doc.save(`${facilityName.label} - ${intl.formatMessage({ id: 'REPORTS.HISTORICAL.PDF' })}`);

        }
      });
    });
  }

  const handleClearFilters = () => {
    setFacilityName(null);
    setResidentName(null);
    setResidentsList([]);
    setWoundName(null);
    setWoundNameList([]);
    setShowReport(false);
  }

  return (
    <>
      <Card>
        <CardHeader title={intl.formatMessage({ id: 'REPORTS.HISTORICAL.TITLE' })}></CardHeader>
        <CardBody>
          {
            isLoading ? (
              <div className="row d-flex justify-content-center">
                <span className="mt-5 spinner spinner-primary"></span>
              </div>
            ) : (
              <>
                <form onSubmit={e => searchReport(e)}>
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="facilityName">
                          <FormattedMessage id="REPORTS.HISTORICAL.FACILITY_NAME" defaultMessage="Facility Name" />
                        </label>
                        <Select
                          placeholder={intl.formatMessage({ id: 'REPORTS.HISTORICAL.SELECT_FACILITY' })}
                          options={facilityList}
                          id="facilityId"
                          name="facilityId"
                          required
                          isSearchable
                          isClearable
                          filterOption={createFilter({ matchFrom: 'start' })}
                          onChange={e => {
                            changeFacilityName(e);
                            setResidentName(null);
                            setWoundName(null);
                            setResidentsList([]);
                            setWoundNameList([]);
                          }}
                          value={facilityName}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="residentName">
                          <FormattedMessage id="REPORTS.HISTORICAL.RESIDENT_NAME" defaultMessage="Resident Name" />
                        </label>
                        <Select
                          options={residentsList}
                          placeholder={intl.formatMessage({ id: 'REPORTS.HISTORICAL.SELECT_FACILITY' })}

                          id="residentId"
                          name="residentId"
                          required
                          isClearable
                          isSearchable
                          filterOption={createFilter({ matchFrom: 'start' })}
                          onChange={e => {
                            setResidentName(e);
                            setWoundName(null);
                            setWoundNameList([]);
                          }}
                          value={residentName}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="woundName">
                          <FormattedMessage id="REPORTS.HISTORICAL.WOUND_NAME" defaultMessage="Wound Name" />
                        </label>
                        <Select
                          options={woundNameList}
                          placeholder={intl.formatMessage({ id: 'REPORTS.HISTORICAL.SELECT_FACILITY' })}
                          id="woundStatus"
                          name="woundStatus"
                          required
                          isClearable
                          isSearchable
                          filterOption={createFilter({ matchFrom: 'start' })}
                          onChange={e => setWoundName(e)}
                          value={woundName}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="d-flex justify-content-end py-3">
                        <button type="submit" className="btn btn-success btn-sm mr-5">
                          <FormattedMessage id="REPORTS.HISTORICAL.SEARCH_REPORT" defaultMessage="Search Report" />
                        </button>
                        <button className="btn btn-danger btn-sm mr-1" onClick={handleClearFilters}>
                          <FormattedMessage id="REPORTS.HISTORICAL.CLEAR_FILTER" defaultMessage="Clear filter" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <hr />
                {isLoadingReport && (
                  <div className="row d-flex justify-content-center">
                    <span className="mt-5 spinner spinner-primary"></span>
                  </div>
                )}
                {
                  showReport &&
                  <>
                    <div className='row'>
                      <div className='col-12'>
                        <button type="button" onClick={PrintElem} className="mr-3 btn btn-success btn-sm float-right">
                          <FormattedMessage id="REPORTS.HISTORICAL.PRINT_REPORT" defaultMessage="Print Report" />
                        </button>
                      </div>
                    </div>
                    {
                      historicalReport?.wounds?.map((wound, index) => (
                        <div className='historical-report' key={index}>
                          <div id={`tableForPrint-${index}`}>
                            <div id="printIt" className='historical-report_content'>
                              {index === 0 &&
                                <div className='mt-5'>
                                  <h2>{historicalReport.facilityName}</h2>
                                  <span>{historicalReport.facilityAddress}</span>
                                </div>
                              }
                              <div className='mt-5'>
                                <table className='w-100'>
                                  <tbody>
                                    <tr>
                                      <td colSpan={2}>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.RESIDENT" defaultMessage="Resident" />:
                                        </span>
                                        <span>{historicalReport.resident}</span>
                                      </td>
                                      <td>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.ROOM" defaultMessage="Room" />:
                                        </span>
                                        <span>{historicalReport.room}</span>
                                      </td>
                                      <td>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.PHYSICIAN" defaultMessage="Physician" />:
                                        </span>
                                        <span>{historicalReport.physician}</span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.SITE" defaultMessage="Site" />#:
                                        </span>
                                        <span>{historicalReport.site}</span>
                                      </td>
                                      <td>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.BODY_REGION_LOCATION" defaultMessage="Body Region/Location" />:
                                        </span>
                                        <span>{historicalReport.bodyRegionLocation}</span>
                                      </td>
                                      <td>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.TYPE_OF_WOUND" defaultMessage="Type of Wound" />:
                                        </span>
                                        <span>{historicalReport.typeOfWound}</span>
                                      </td>
                                      <td>
                                        <span className='font-weight-bolder mr-2'>
                                          <FormattedMessage id="REPORTS.HISTORICAL.ACQUISITION" defaultMessage="Acquisition" />:
                                        </span>
                                        <span>{historicalReport.acquisition}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div className='mt-5'>
                                <table className='table table-striped table-sm w-100'>
                                  <thead className='thead_style_cust'>
                                    <tr>
                                      <th width="10%" style={{ borderBottom: "1px solid black", backgroundColor: '#fff' }}></th>
                                      <th width="14%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>
                                        <FormattedMessage id="REPORTS.HISTORICAL.MEASUREMENTS" defaultMessage="Measurements" /> <span>(cm)</span>
                                      </th>
                                      <th width="12%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>
                                        <FormattedMessage id="REPORTS.HISTORICAL.EXUDATE" defaultMessage="Exudate" />
                                      </th>
                                      <th width="12%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>
                                        <FormattedMessage id="REPORTS.HISTORICAL.WOUND_BED" defaultMessage="Wound Bed" />
                                      </th>
                                      <th width="12%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>
                                        <FormattedMessage id="REPORTS.HISTORICAL.PERI_WOUND" defaultMessage="PeriWound" />
                                      </th>
                                      <th width="40%" style={{ border: 'solid #000', borderWidth: '0 1px 1px 1px' }}>
                                        <FormattedMessage id="REPORTS.HISTORICAL.COMMENTS" defaultMessage="Comments" />
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className='body_style_cust'>
                                    <tr style={{ border: "1px solid black" }}>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.DATE" defaultMessage="Date" />:
                                            </span> {wound.date}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>L</span> {wound.measurements.length} <span className='font-weight-bolder'>W</span> {wound.measurements.width} <span className='font-weight-bolder'>D</span> {wound.measurements.depth}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.COLOR" defaultMessage="Color" />:
                                            </span> {wound.exudate.color}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.ODOR" defaultMessage="Odor" />:
                                            </span>{wound.woundBed.odor}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.WOUND_EDGES" defaultMessage="Wound Edges" />:
                                            </span> {wound.periWound.woundEdges}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.SKIN_ULCER_TREATMENT" defaultMessage="Skin & Ulcer treatment" />:
                                            </span> {wound.comments.skinUlcerTreatment}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr style={{ border: "1px solid black" }}>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.THICKNESS_STAGE" defaultMessage="Thickness/Stage" />:
                                            </span> {wound.thicknessStage}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.TUNNELLING" defaultMessage="Tunneling" />:
                                            </span>{wound.measurements.tunelling}
                                          </p>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.DEPTH" defaultMessage="Depth" />:
                                            </span>{wound.measurements.depth}
                                          </p>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.CLOCK_POSITION" defaultMessage="Clock position" />:
                                            </span> {wound.measurements.clockPosition}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.EXUDATE_TYPE" defaultMessage="Exudate Type" />:
                                            </span> {wound.exudate.exudateType}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.PAIN" defaultMessage="Pain" />:
                                            </span>{wound.woundBed.pain}
                                          </p>
                                        </div>
                                      </td>
                                      <td></td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.TREATMENT" defaultMessage="Treatment" />:
                                            </span> {wound.comments.treatment}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr style={{ border: "1px solid black" }}>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.TISSUE_TYPE" defaultMessage="Tissue type" />:
                                            </span> {wound.tissueType}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        {wound.undermining &&
                                          <div className='border_bottom_cus py-2'>
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.UNDERMINING" defaultMessage="Undermining" />:
                                              </span> {wound.undermining.undermining}
                                            </p>
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.DEPTH" defaultMessage="Depth" />:
                                              </span> {wound.undermining.depth}
                                            </p>
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.FROM_CLOCK_POSITION" defaultMessage="From Clock position" />:
                                              </span> {wound.undermining.clockPosition}
                                            </p>
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.TO_CLOCK_POSITION" defaultMessage="To Clock position" />:
                                              </span> {wound.undermining.toClockPosition}
                                            </p>
                                          </div>
                                        }
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.DEBRIDEMENT" defaultMessage="Debridement" />:
                                            </span> {wound.exudate.debridement}
                                          </p>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.LAST_DEBRIDEMENT_DATE" defaultMessage="Last Debridement Date" />:
                                            </span> {wound.exudate.lastDebridementDate}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.PAIN_MEDICATION_ORDERED" defaultMessage="Pain Medication ordered" />:
                                            </span> {wound.woundBed.painMedicationOrdered}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.SURROUNDING_SKIN" defaultMessage="Surrounding Skin" />:
                                            </span> {wound.periWound.surroundingSkin}
                                          </p>
                                          {wound.periWound.surroundingSkin === "Other" &&
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.SURROUNDING_SKIN_OTHER" defaultMessage="Surrounding Skin Other" />:
                                              </span> {wound.periWound.surroundingSkin}
                                            </p>
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        {(wound.comments.primaryDressing || wound.comments.secondaryDressing) && (
                                          <div className='border_bottom_cus py-2'>
                                            {wound.comments.primaryDressing && (
                                              <p className='mb-0'>
                                                <span className='font-weight-bolder'>
                                                  <FormattedMessage id="REPORTS.HISTORICAL.PRIMARY_DRESSING" defaultMessage="Primary Dressing" />:
                                                </span> {wound.comments.primaryDressing}
                                              </p>
                                            )}
                                            {wound.comments.secondaryDressing && (
                                              <p className='mb-0'>
                                                <span className='font-weight-bolder'>
                                                  <FormattedMessage id="REPORTS.HISTORICAL.SECONDARY_DRESSING" defaultMessage="Secondary Dressing" />:
                                                </span> {wound.comments.secondaryDressing}
                                              </p>
                                            )}
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                    <tr style={{ border: "1px solid black" }}>
                                      <td></td>
                                      <td></td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.AMOUNT" defaultMessage="Amount" />:
                                            </span> {wound.exudate.amount}
                                          </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.WOUND_BED_DESCRIPTION" defaultMessage="Wound Bed Description" />:
                                            </span>
                                          </p>
                                          {wound.woundBed.woundBedDescription.granulationPerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.GRANULATION" defaultMessage="Granulation" /> %:
                                              </span>{wound.woundBed.woundBedDescription.granulationPerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBedDescription.hyperGranulationPerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.HYPER_GRANULATION" defaultMessage="HyperGranulation" /> %:
                                              </span>{wound.woundBed.woundBedDescription.hyperGranulationPerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBedDescription.sloughPerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.SLOUGH" defaultMessage="Slough" /> %:
                                              </span>{wound.woundBed.woundBedDescription.sloughPerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBedDescription.necroticPerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.NECROTIC_ESCHAR" defaultMessage="Necrotic/Eschar" /> %:
                                              </span>{wound.woundBed.woundBedDescription.necroticPerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBedDescription.epithelialPerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.EPITHELIAL" defaultMessage="Epithelial" /> %:
                                              </span>{wound.woundBed.woundBedDescription.epithelialPerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBedDescription.musclePerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.MUSCLE_TENDON_BONE" defaultMessage="Muscle, Tendon or Bone" /> %:
                                              </span>{wound.woundBed.woundBedDescription.musclePerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBedDescription.otherPerc > 0 && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                {wound.woundBed.woundBedDescription.otherWoundDesc} %:
                                              </span>{wound.woundBed.woundBedDescription.otherPerc}
                                            </p>
                                          )}
                                          {wound.woundBed.woundBed && (
                                            <p className='mb-0'>
                                              <span className='font-weight-bolder'>
                                                <FormattedMessage id="REPORTS.HISTORICAL.WOUND_BED" defaultMessage="Wound Bed" />:
                                              </span>{wound.woundBed.woundBed}
                                            </p>
                                          )}
                                        </div>
                                      </td>
                                      <td></td>
                                      <td>
                                        <div className='border_bottom_cus py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.SIGNATURE" defaultMessage="Signature" />:
                                            </span> {wound.comments.signature}
                                          </p>
                                          <p className='mb-0'>
                                            [<FormattedMessage id="REPORTS.HISTORICAL.E_SIGNED" defaultMessage="E-signed" />] {wound.comments.eSigned}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                    <tr style={{ border: "1px solid black" }}>
                                      <td colSpan={6}>
                                        <div className='py-2'>
                                          <p className='mb-0'>
                                            <span className='font-weight-bolder'>
                                              <FormattedMessage id="REPORTS.HISTORICAL.GENERAL_COMMENTS" defaultMessage="General Comments" />:
                                            </span> {wound.comments.generalComments}
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </>
                }
              </>
            )
          }
        </CardBody>
      </Card>
      <canvas id='c' width='100' height='100' style={{ display: 'none' }}></canvas>
    </>
  );
}

export default injectIntl(HistoricalReport);

import React, { Component } from 'react';
import { Button, Form } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
class SaleOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      formValidated: false,
      SaleOrder: {
        ResidentID:'',
        ScheduledDate: '',
        ActualDate: '',
        ClassificationID: '',
        PlaceOfServiceID: '',
        WIPState: '',
        OrderNote:'',
        DeliveryNote:'',
        SalesOrderProducts:[ {
          ItemID: '',
          ItemName: '',
          ItemQuantity:'',
        },
        {
          ItemID: '',
          ItemName: '',
          ItemQuantity:'',
        }]
       

      }

    }
  }
  handleSubmit = (event) => {
    // // debugger
    const form = event.currentTarget.form;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      let _state = this.state
      _state.formValidated = true;
      this.setState(_state);
    }
    else {
      let obj = this.state.SaleOrder;
      // // debugger
      Api.postRequest(Api.Uri_CreateSalesOrder, obj).then((res) => {
        Common.showSuccessAlertAutoClose("Sale Order Created Successfully", "success", "Success!", 3000);
      });
    }
  }

  render() {
    return (
      <Card>
         <Form
            validated={this.state.formValidated}>
        <CardHeader title='Sale Order' />
        <CardBody>
            <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
              <legend className="text-primary fw-500">Sale Order Detail </legend>
              <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" >ResidentID</label>
                    <input type="text" id="ResidentID"
                      name="ResidentID"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      value={this.state.SaleOrder.ResidentID}
                      className="form-control form-control-sm" />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailError}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="OriginalAdmissionDate">Scheduled Date</label>
                    <input type="date" id="OriginalAdmissionDate"
                      required
                      name="ScheduledDate"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      className="form-control form-control-sm" />
                    <div className="invalid-feedback">Scheduled Date Is Required</div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="OriginalAdmissionDate">Actual Date</label>
                    <input type="date" id="ActualDate"
                      required
                      name="ActualDate"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      className="form-control form-control-sm" />
                    <div className="invalid-feedback">Sale Note Date Is Required</div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Country">Classification</label>
                    <select className="form-control form-control-sm"
                      name="ClassificationID"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      id="ClassificationID">
                      <option value="0">Select Classification</option>
                      <option value="3">Capitation</option>
                      <option value="2">Distributor Domestic</option>
                      <option value="6">Distributor International</option>
                      <option value="8">Hospice</option>
                      <option value="4">OUTs</option>
                      <option value="7">Retail End User</option>
                      <option value="1">Retail Facility</option>
                      <option value="5">Wound Care</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Country">Place of Service</label>
                    <select className="form-control form-control-sm"
                      name="PlaceOfServiceID"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      id="PlaceOfServices">
                      <option value="0">Select Place of Service</option>
                      <option value="16">42-Ambulance - Air or Water</option>
                      <option value="15">41-Ambulance - Land</option>
                      <option value="8">24-Ambulatory Surgical Center</option>
                      <option value="32">13-Assisted Living Facility</option>
                      <option value="9">25-Birthing Center</option>
                      <option value="20">53-Community Mental Health Center</option>
                      <option value="25">61-Comprehensive Inpatient Rehabilitation Facility</option>
                      <option value="26">62-Comprehensive Outpatient Rehabilitation Facility</option>
                      <option value="13">33-Custodial Care Facility</option>
                      <option value="7">23-Emergency Room - Hospital</option>
                      <option value="27">65-End Stage Renal Disease Treatment Facility</option>
                      <option value="17">50-Federally qualified Health Center</option>
                      <option value="33">14-Group Home</option>
                      <option  value="4">12-Home</option>
                      <option value="2">04-Homeless Shelter</option>
                      <option value="14">34-Hospice</option>
                      <option value="34">49-Independent Clinic</option>
                      <option value="30">81-Independent Laboratory</option>
                      <option value="5">21-Inpatient Hospital</option>
                      <option value="18">51-Inpatient Psychiatric Facility</option>
                      <option value="21">54-Intermediate Care Facility/Mentally Retarded</option>
                      <option value="24">60-Mass Immunization Center</option>
                      <option value="10">26-Military Treatment Facility</option>
                      <option value="42">15-Mobile Unit</option>
                      <option value="12">32-Nursing Facility</option>
                      <option value="3">11-Office</option>
                      <option value="31">99-Other Unlisted Facility</option>
                      <option value="6">22-Outpatient Hospital</option>
                      <option value="35">01-Pharmacy</option>
                      <option value="37">09-Prison-Correctional Facility</option>
                      <option value="19">52-Psychiatric Facility partial Hospitalization</option>
                      <option value="23">56-Psychiatric Residential Treatment Center</option>
                      <option value="22">55-Residential Substance Abuse Treatment facility</option>
                      <option value="29">72-Rural Health Clinic</option>
                      <option value="1">03-School</option>
                      <option value="11">31-Skilled Nursing Facility</option>
                      <option value="28">71-State or Local Public Health Clinic</option>
                      <option value="41">02-Telehealth</option>
                      <option value="39">16-Temporary Lodging</option>
                      <option value="40">20-Urgent Care Facility</option>
                      <option value="38">17-Walk-In Retail Health Clinic</option>
                    </select>
                  </div>
                </div>
                <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Country">WIP State</label>
                    <select className="form-control form-control-sm"
                      name="WIPState"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      id="WIPState">
                      <option  value="0">Select WIP State</option>
                      <option value="40">OUTs10: Re-Order Information Needed</option>
                      <option value="41">OUTs12: Day Remaining Note Complete</option>
                      <option value="42">OUTs20: Insurance Eligibility Needed</option>
                      <option value="43">OUTs22: Insurance Incomplete</option>
                      <option value="44">OUTs30: Ready to Process Order</option>
                      <option value="45">OUTs40: Pending Drop Shipment</option>
                      <option value="46">OUTs42: Pending Shipment review Internal</option>
                      <option value="47">OUTs44: Sent to Warehouse</option>
                      <option value="48">OUTs46: Drop Shipment Complete</option>
                      <option value="49">OUTs48: Order Shipped from Warehouse</option>
                      <option value="50">OUTs50: Ready to Confirm</option>
                      <option value="51">OUTs52: Confirmation Exceptions</option>
                      <option value="52">RD10: Intake Retail</option>
                      <option value="53">RD20: Awaiting Payment on Account</option>
                      <option value="54">RD22: Product on Backorder</option>
                      <option value="55">RD30: Sent to Warehouse for Shipping</option>
                      <option value="56">RD32: Shipping Complete</option>
                      <option value="57">RD34: Shipping Complete with Exceptions</option>
                      <option value="60">SR10: Sample Request</option>
                      <option value="61">SR20: Sent Request to Warehouse</option>
                      <option value="62">SR30: Sample Sent - Ready to Confirm</option>
                      <option value="24">WC10: Order Creation</option>
                      <option value="25">WC14: Order Processing</option>
                      <option value="26">WC16: Insurance Exception</option>
                      <option value="27">WC18: Wound Exception</option>
                      <option value="28">WC20: PAR Needed/Requested</option>
                      <option value="29">WC30: Inventory Exception</option>
                      <option value="30">WC40: Ready to Schedule Shipment</option>
                      <option value="68">WC42: Print to Warehouse</option>
                      <option value="32">WC44: Sent to Warehouse for Processing</option>
                      <option value="63">WC46: Order Shipped</option>
                      <option value="69">WC48 Order Shipped with Exceptions</option>
                      <option value="38">WC50: Ready to Confirm</option>
                      <option value="39">WC52: Sent back from Confirmation for exceptions</option>
                      <option value="64">X9997 Confirmed Pending POD</option>
                      <option value="58">X9998: Void Sales Order</option>
                      <option value="31">X9999 Process Complete</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="State">Order Note</label>
                    <textarea id="OrderNote" rows="3"
                      name="OrderNote"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      className="form-control form-control-sm"></textarea>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="State">Delivery Note</label>
                    <textarea id="DeliveryNote" rows="3"
                      name="DeliveryNote"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder, e, this, this.state)}
                      className="form-control form-control-sm"></textarea>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset className="p-2 mb-2 bg-light border border-dark rounded">
              <legend className="text-primary fw-500">Sale Order Product </legend>
              <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Email">Item ID</label>
                    <input type="text" id="Email"
                      name="ItemID"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder.SalesOrderProducts[0], e, this, this.state)}
                      className="form-control form-control-sm" />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailError}
                    </div>
                  </div>
                </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Email">Item Name</label>
                    <input type="text" id="Email"
                      name="ItemName"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder.SalesOrderProducts[0], e, this, this.state)}
                      className="form-control form-control-sm" />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailError}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Mobile">Item Quantity</label>
                    <input type="number" id="Mobile"
                      name="ItemQuantity"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder.SalesOrderProducts[0], e, this, this.state)}
                      className="form-control form-control-sm" />
                  </div>
                </div>
                </div>
                <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Email">Item ID</label>
                    <input type="text" id="Email"
                      name="ItemID"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder.SalesOrderProducts[1], e, this, this.state)}
                      className="form-control form-control-sm" />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailError}
                    </div>
                  </div>
                </div>
              <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Email">Item Name</label>
                    <input type="text" id="Email"
                      name="ItemName"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder.SalesOrderProducts[1], e, this, this.state)}
                      className="form-control form-control-sm" />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {this.state.emailError}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="Mobile">Item Quantity</label>
                    <input type="number" id="Mobile"
                      name="ItemQuantity"
                      onChange={e => Common.updateInputValueInState(this.state.SaleOrder.SalesOrderProducts[1], e, this, this.state)}
                      className="form-control form-control-sm" />
                  </div>
                </div>
                </div>
              </fieldset>
         
        </CardBody>
        <CardFooter className="d-flex justify-content-end py-3">
        <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Save</Button>
        </CardFooter>
        </Form>
      </Card>

    );
  }
}
export default SaleOrder;
import React, { useEffect, useRef, useState } from 'react';
import { Animated } from "react-animated-css";
import { useSelector } from 'react-redux';
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import PopupModal from '../modal/popupmodal';
import ProductSelection from "./productselection";
import { ModulesName, UserActions } from "../../General/roleModuleActions";
import uuid from 'node-uuid'
//var prodcutSelectionModal = null;
export default function WoundProducts(props) {
    const prodcutSelectionModal = useRef(null);
    const { user } = useSelector((state) => state.auth);
    var productSelectionRef = useRef(null);


    const [woundProductListUI, setWoundProductListUI] = useState([]);
    const [woundProductHistoryListUI, setWoundProductHistoryListUI] = useState([]);
    const [orderProducts, setOrderProducts] = useState(null);
    const [saleOrderId, setSaleOrderId] = useState(null);
    const [fkDressingChangeFrequencyId, setFkDressingChangeFrequencyId] = useState(null);
    const [otherOtherAdditionalSecondaryDressing, setOtherAdditionalSecondaryDressing] = useState('');
    const [otherAdditionalPrimaryDressing, setOtherAdditionalPrimaryDressing] = useState('');
    const [saleNote, setSaleNote] = useState('');





    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getSalesOrderProduct();
        //setResidentListTemp();
        //Common.LoadDataTable("facilityList")
    }, []);



    const getSalesOrderProduct = () => {
        Api.getRequestQry(Api.URI_GetSaleOrderByResidentWoundID,
            "?woundDetailId="+ props.residentWound.wound.woundDetailId
            +"&saleOrderId="+ (props.residentWound.fkSalesOrderId>0? props.residentWound.fkSalesOrderId:0)
            + `&queue=${props.queue || 0}`
            ).then((res) => {
             
            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    setSaleOrderId(res.data.result.saleOrderId)
                    setOtherAdditionalPrimaryDressing(res.data.result.otherAdditionalPrimaryDressing)
                    setOtherAdditionalSecondaryDressing(res.data.result.otherAdditionalSecondaryDressing)
                    setFkDressingChangeFrequencyId(res.data.result.fkDressingChangeFrequencyId)
                    setSaleNote(res.data.result.orderNote)
                    setOrderProducts(res.data.result.saleOrderViewModels);
                    setSaleOrderProductList(res.data.result);
                }
            }

            //Common.LoadDataTable("woundProductList"+props.residentWound.wound.woundDetailId)
        }).catch((ex) => {
        });
    }

    function randomNumber(min, max) {
        var num=Math.random() * (max - min) + min;
        return Math.round(num);
      }

    const setSaleOrderProductList = (saleOrder) => {
        try {
            let productrows = [];
            let products = saleOrder.saleOrderViewModels;
            const history = saleOrder.saleOrderHistory || [];
            const salesOrderHistorySorted = history
                .sort((a, b) => new Date(b?.audit?.modifiedDate) - new Date(a?.audit?.modifiedDate))
                
            const latestSaleOrderNotCreatedInBillingQueue =
                salesOrderHistorySorted.find(x => x.createdInQueueId !== Common.ProcessStatus.billingQueueID)
            
            for (var i = 0; i < products.length; i++) {
                const obj = products[i];

                const shouldBeNotBold = latestSaleOrderNotCreatedInBillingQueue ? Boolean(
                    latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts?.find(
                        x => x.productId === obj.fkProductId
                        && x.quantity === obj.quantity
                        //&& x.remainingQuantity === obj.remainingQuantity
                        && x.isReorder === obj.isReorder
                    )
                ) : true;


                productrows.push(<tr key={uuid()} style={{ fontWeight: shouldBeNotBold ? 'normal' : 'bold' }}>
                    <td><button className="btn btn-link" 
                    disabled={props.isProcessorFunctionOn}
                    style={{
                        fontWeight: shouldBeNotBold ? 'normal' : 'bold',
                    }}

                    onClick={() => handleProductSelectionModal()}>{obj.productName}</button></td>
                   
                    
                    <td>{obj.productLevelName}</td>
                    <td>{obj.productCategoryDescription}</td>
                    {/* <td>{obj.sku}</td> */}
                    <td >
                        {obj.quantity}
                    </td>
                    {/* <td>{obj.remainingQuantity }</td> */}
             

                </tr>
               );
            }

            if (latestSaleOrderNotCreatedInBillingQueue) {
                const productsAssociatedWorkqueue = []

                for (let i = 0; i < latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts?.length; i++) {
                    const productFromSaleOrderOfWorkQueue = latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts[i];

                    const shouldNotBeStrikethrough = latestSaleOrderNotCreatedInBillingQueue ? Boolean(
                        products.find(
                            x => x.fkProductId === productFromSaleOrderOfWorkQueue?.productId
                            && x.quantity === productFromSaleOrderOfWorkQueue?.quantity
                            // TASK 2120: Remove 'Remaining Quantity' when reordering
                            //&& x.remainingQuantity === productFromSaleOrderOfWorkQueue?.remainingQuantity
                            && x.isReorder === productFromSaleOrderOfWorkQueue?.isReorder
                        )
                    ) : true;

                    productsAssociatedWorkqueue.push(
                        <tr key={uuid()} style={{ textDecoration: shouldNotBeStrikethrough ? 'none' : 'line-through' }}>
                            <td><button className="btn btn-link"
                            style={{
                                textDecoration: shouldNotBeStrikethrough ? 'none' : 'line-through',
                            }}
                            disabled={props.isProcessorFunctionOn}
                            onClick={() => handleProductSelectionModal()}>{latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts[i]?.name}</button></td>
                            <td>{latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts[i]?.levelName}</td>
                            <td>{latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts[i]?.description}</td>
                            <td>{latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts[i]?.quantity}</td>
                            {/* TASK 2120: Remove 'Remaining Quantity' when reordering
                            <td>{latestSaleOrderNotCreatedInBillingQueue?.saleOrderProducts[i]?.remainingQuantity}</td> */}
                        </tr>
                    );

                    setWoundProductHistoryListUI(productsAssociatedWorkqueue)
                }
            }
           let orderNote= saleOrder.orderNote==null || saleOrder.orderNote==""?null:
      productrows.push(<tr key={uuid()}>
        <td className="font-bold"><b>Order Note:</b>{saleOrder.orderNote}</td>
       <td></td>
       <td></td>
       <td></td>
       <td></td>
       <td></td>
</tr>);
           let otherAdditionalPrimaryDressing= saleOrder.otherAdditionalPrimaryDressing==null || saleOrder.otherAdditionalPrimaryDressing==""?null:
           productrows.push(<tr key={uuid()}> 
             <td className="font-bold"><b>Write in other Primary products:</b>{saleOrder.otherAdditionalPrimaryDressing}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
     </tr>);
                let otherAdditionalSecondaryDressing= saleOrder.otherAdditionalSecondaryDressing==null || saleOrder.otherAdditionalSecondaryDressing==""?null:
                productrows.push(<tr key={uuid()}>
                  <td className="font-bold"><b>Write in other Secondary products:</b>{saleOrder.otherAdditionalSecondaryDressing}</td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
                 <td></td>
          </tr>);
            setWoundProductListUI(productrows);
        } catch (error) {

        }
    }

    const handleProductSelectionModal = () => {
        if(props.isReadOnly==true){
            return false
        }
        prodcutSelectionModal.current.showModal()
    }
    //#region save products
    const setProductSelectionDetailToSend = () => {
        let selectedProducts = productSelectionRef.current.getSelectedProductList();
        if (selectedProducts.products.length >! 0
            &&selectedProducts?.selectedSecondaryProducts?.length>!0 
            &&selectedProducts?.selectedOtherFillerProducts?.length>!0 
            &&selectedProducts?.selectedOtherTraditionalProducts?.length>!0 ) {
            Common.showSuccessAlertAutoClose("Please select atleast one product.", "warning", "Failed!", 3000);
            return;
        }else if(props.isReorder)
        {
            let _selectedProdWithoutRemaingQty =selectedProducts.products.
            find(x => x.remainingQuantity===null || x.remainingQuantity ==="" || x.remainingQuantity<0);
            /* TASK 2120: Remove 'Remaining Quantity' when reordering
            if(_selectedProdWithoutRemaingQty!=undefined){
                Common.showSuccessAlertAutoClose("Remaining quantity is required for all products.", "warning", "Warning!", 3000);
                return;
            }*/
        }
        // if (selectedProducts.products != null) {
        //     if (selectedProducts.products.length > 0) {
                let proudctObjToSend = {
                    "saleOrderId": saleOrderId,
                    // "createdBy": user.userID,
                    // "modifiedBy": user.userID,
                    "fkResidentWoundId": props.residentWound.id,
                    "FkDressingChangeFrequencyId":selectedProducts.salesOrder.fkDressingChangeFrequencyId,
                    "OtherAdditionalSecondaryDressing":selectedProducts.salesOrder.otherAdditionalSecondaryDressing,
                    "OtherAdditionalPrimaryDressing":selectedProducts.salesOrder.otherAdditionalPrimaryDressing,
                    "OrderNote":selectedProducts.salesOrder.orderNote,
                    "saleOrderViewModels": [],
                    "otherPrimaryProducts":selectedProducts.otherPrimaryProducts,
                    "otherSecodaryProducts":selectedProducts.selectedSecondaryProducts,
                    "otherFillerProducts": selectedProducts.selectedOtherFillerProducts,
                    "otherTraditionalProducts": selectedProducts.selectedOtherTraditionalProducts,
                    "isReorder": props.isReorder
                };

                selectedProducts.products.forEach((value) => {
                    let obj = {
                        "fkProductId": value.id,
                        "saleOrderProductId": value.saleOrderProductId,
                        "quantity": value.quantity,
                        "remainingQuantity": value.remainingQuantity,
                        "isOrderNow": value.isOrderNow,
                        "productLevel":value.productLevel,
                        "createdBy": user.userID,
                        "modifiedBy": user.userID,
                    }
                    proudctObjToSend.saleOrderViewModels.push(obj);

                });

                UpsertSaleOrderApiCall(proudctObjToSend);
            }
    //     }
    // }
    //

    const UpsertSaleOrderApiCall = (productObject) => {
        let obj = productObject;
        obj = {
            ...obj,
            createdInQueueId: props.queue || Common.ProcessStatus.workQueueID,
        };
        Api.postRequest(Api.URI_UpsertSaleOrder, obj).then((res) => {
            if (res.data.success) {
                if (res.data.result != null) {
                    Common.showSuccessAlertAutoClose("Record updated Successfully", "success", "Success!", 3000);
                    setIsEdit(false);
                    prodcutSelectionModal.current.hideModal();
                    //productSelectionRef = null;
                    getSalesOrderProduct();
                }
                else {
                    Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlert(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {
            
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }
    //#endregion

    return (
        <>
            <PopupModal
                size="xl"
                title={<>
                    <h4 className="card-label h4">Resident List</h4>
                    <small className="">
                        <b>Resident:</b> {props.residentWound.residentName}, &nbsp;
                    <b>Room/Unit:</b> {props.residentWound.roomUnit}, &nbsp;
                    <b>Facility:</b> {props.residentWound.facilityName}, &nbsp;
                    <b>Wound Location</b> {props.residentWound.residentWoundLocation}
                    </small>
                </>}
                dialogClassName="modal-95w"
                module={ModulesName.Module_Orders}
                action={UserActions.Create}
                buttonPress={() => setProductSelectionDetailToSend()}
                ref={prodcutSelectionModal}
            >
                <Animated animationIn="fadeIn" animationInDuration={2000} >
                    <ProductSelection ref={productSelectionRef} 
                    orderProducts={orderProducts} 
                    primaryDressing={otherAdditionalPrimaryDressing} 
                    secondaryDressing={otherOtherAdditionalSecondaryDressing} 
                    orderNote={saleNote}
                    frequencyId={fkDressingChangeFrequencyId} 
                    isReorder={props.isReorder}


                    />

                    {/* <div className="d-flex justify-content-end">
                        <button className="btn btn-success btn-xs mb-3 mr-5" onClick={() => { productSelectionRef = null; setIsEdit(false); }}>Back</button>
                        <button className="btn btn-success btn-xs mb-3" onClick={() => setProductSelectionDetailToSend()}>save</button>
                    </div> */}
                </Animated>
            </PopupModal>


            <Animated animationIn="fadeIn" animationInDuration={2000} >
                <div className="d-flex justify-content-end">
                {/* <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_Orders}
                    yes={() => (
                        <button className="btn btn-success btn-xs mb-3" onClick={() => handleProductSelectionModal()}>Edit Product</button>

                    )}
                    no={() => null}
                  /> */}
                </div>

                {woundProductHistoryListUI?.length && props.queue === Common.ProcessStatus.billingQueueID ? (
                    <div className="d-flex justify-content-center bg-gray-300 pt-2 mb-1 rounded-md">
                        <h5 className="card-label h5">Original Order</h5>
                    </div>
                ): null}

                {woundProductHistoryListUI?.length && props.queue === Common.ProcessStatus.billingQueueID ? (
                    <table style={{ tableLayout: 'fixed', }} className="table table-striped table-hover table-sm table-bordered" id={"woundProductList-1" + props.residentWound.wound.woundDetailId}>
                        <thead>
                            <tr>
                                <th>Product Name</th>
                            
                                <th>Type</th>
                                <th>Category</th>
                                {/* <th>SKU</th> */}
                                <th>Quantity</th>
                                {/* TASK 2120: Remove 'Remaining Quantity' when reordering
                                 <th>Remaining Day(s)</th> */}
                                {/* <CheckPermission
                        permission={UserActions.Update}
                        module={ModulesName.Module_Orders}
                        yes={() => (
                            <th><button className="btn btn-success btn-xs mb-3" onClick={() => handleProductSelectionModal()}>Edit Product</button></th>


                        )}
                        no={() => null}
                    /> */}
                            </tr>
                        </thead>
                        <tbody>
                            {woundProductHistoryListUI}
                        </tbody>
                    </table>
                ): null}

                {woundProductHistoryListUI?.length && props.queue === Common.ProcessStatus.billingQueueID ? (
                    <div className="d-flex justify-content-center bg-gray-300 pt-2 mb-1 rounded-md">
                        <h5 className="card-label h5">Qualified Order</h5>
                    </div>
                ): null}

                <table style={{ tableLayout: 'fixed', }} className="table table-striped table-hover table-sm table-bordered" id={"woundProductList-2" + props.residentWound.wound.woundDetailId}>
                    <thead>
                        <tr>
                            <th>Product Name</th>
                           
                            <th>Type</th>
                            <th>Category</th>
                            {/* <th>SKU</th> */}
                            <th>Quantity</th>
                            {/* TASK 2120: Remove 'Remaining Quantity' when reordering
                            <th>Remaining Day(s)</th> */}
                            {/* <CheckPermission
                    permission={UserActions.Update}
                    module={ModulesName.Module_Orders}
                    yes={() => (
                        <th><button className="btn btn-success btn-xs mb-3" onClick={() => handleProductSelectionModal()}>Edit Product</button></th>


                    )}
                    no={() => null}
                  /> */}
                        </tr>
                    </thead>
                    <tbody>
                        {woundProductListUI}
                    </tbody>
                </table>
            </Animated>


        </>
    )
}//end of WoundProducts

/*eslint-disable */

import React, { useState, useEffect } from "react";
import { Link, Switch, Redirect, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout"
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import SetPassword from "./SetPassword";
import SVG from "react-inlinesvg";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import GenericModal from "../../../pages/modal/_aboutmodal";
import { versionNumber } from "../../../General/common"
import { FormattedMessage, injectIntl } from "react-intl";

function AuthPageWithIntl(props) {
    const handleOpen = () => {
        setShowModal(!showModal);
    };

    const { intl } = props

    const today = new Date().getFullYear();
    const location = useLocation()
    const [isLogin, setIsLogin] = useState(true);
    const [showModal, setShowModal] = useState(showModal ? true : false);

    const forgotInfo = <> {/*begin: Aside Container*/}
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
            {/* start:: Aside header */}
            {/* end:: Aside header */}

            {/* start:: Aside content */}
            <div id="test" className="flex-column-fluid d-flex flex-column justify-content-center mt-5" style={{ textAlign: "center" }}>

                <Link to="/" className="gentellLogoContainer">
                    <SVG className="gentellLogo"
                        src={toAbsoluteUrl("/media/svg/logos/gentell-fastcare.svg")}
                    ></SVG>
                </Link>
                <h3 className="font-size-h1 mb-5 text-white">
                    {intl.formatMessage({ id: 'LOGIN.WELCOME' })}
                </h3>
                <p className="font-weight-lighter text-white mb-5 ">
                    {intl.formatMessage({ id: 'LOGIN.REVOLUCIONAY_SYSTEM' })}
                </p>
                <p className="text-white mb-10">
                    {intl.formatMessage({ id: 'LOGIN.IF_PROVIDER' })}
                    <a className="text-warning font-weight-bolder" href={intl.formatMessage({ id: 'LOGIN.FASTCARE_EMAIL' })}> {intl.formatMessage({ id: 'LOGIN.FASTCARE_EMAIL' })} </a>
                    {intl.formatMessage({ id: 'LOGIN.OR_CALL' })} <a className="text-white font-weight-bolder" href={`tel:${intl.formatMessage({ id: 'LOGIN.PHONE_NUMBER' })}`}>{intl.formatMessage({ id: 'LOGIN.PHONE_NUMBER' })} </a>
                    {intl.formatMessage({ id: 'LOGIN.ACCOUNT_AND_PRICING' })}
                    <a className="text-warning font-weight-bolder" rel="noopener noreferrer" target="_blank" href={`https://${intl.formatMessage({ id: 'LOGIN.GENTELL_WEBSITE' })}/`}> {intl.formatMessage({ id: 'LOGIN.GENTELL_WEBSITE' })}</a>
                </p>

            </div>
            {/* end:: Aside content */}

            {/* start:: Aside footer for desktop */}
            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                    &copy; {today.toString()} Gentell Fastcare {versionNumber}
                </div>
                <div className="d-flex">
                    <a className="text-white ml-10" onClick={handleOpen}>
                        {intl.formatMessage({ id: 'LOGIN.ABOUT' })}
                    </a>
                    <GenericModal onPress={handleOpen} show={showModal} />
                    <a className="text-white ml-10" target="_blank" rel="noopener noreferrer" href="https://fastcare.freshdesk.com/support/solutions/articles/135948">
                        {intl.formatMessage({ id: 'LOGIN.HELP' })}
                    </a>
                </div>
            </div>
            {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/} </>
    const loginInfo = <> {/*begin: Aside Container*/}
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
            {/* start:: Aside header */}

            {/* end:: Aside header */}

            {/* start:: Aside content */}
            <div id="test" className="flex-column-fluid d-flex flex-column justify-content-center mt-5">
                <Link to="/" className="gentellLogoContainer">
                    <SVG className="gentellLogo"
                        src={toAbsoluteUrl("/media/svg/logos/gentell-fastcare.svg")}
                    ></SVG>
                </Link>
                <h3 className="font-size-h1 mb-5 text-white">
                    Password Safety Tips
                </h3>

                <div className="panel-body">
                    <ul className="password-safety-tips-ul text-white">
                        <li>
                            Never reveal your password to anyone.
                        </li>
                        <li>
                            Don't just use a single password, change it for every application.
                        </li>
                        <li>
                            Create passwords that are easy to remember but hard for others to guess.
                        </li>
                        <li>
                            Make your password a little different by adding a couple of unique letters.
                        </li>
                        <li>
                            Never include a username in your password.
                        </li>
                        <li>
                            Make the password at least 8 characters long.
                        </li>
                        <li>
                            Include numbers, capital letters and symbols.
                        </li>
                        <li>
                            Don’t use dictionary words.
                        </li>
                        <li>
                            Consider using a password manager.
                        </li>
                        <li>
                            Allowed special characters
                        </li>
                    </ul>
                </div>
            </div>
            {/* end:: Aside content */}

            {/* start:: Aside footer for desktop */}
            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div className="opacity-70 font-weight-bold	text-white">
                    &copy; {today.toString()} Gentell Fastcare {versionNumber}
                </div>
                <div className="d-flex">
                    <a className="text-white ml-10" onClick={handleOpen}>
                        About
                    </a>
                    <GenericModal onPress={handleOpen} show={showModal} />
                    <a className="text-white ml-10" target="_blank" rel="noopener noreferrer" href="https://fastcare.freshdesk.com/support/solutions/articles/135948">
                        Help
                    </a>
                </div>
            </div>
            {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/} </>
    useEffect(() => {
        if (/forgot-password/g.test(location.pathname) || /set-password/g.test(location.pathname)) {
            //alert("url has youtube");
            setIsLogin(false)
        } else {
            setIsLogin(true)
        }
    })
    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
                    id="kt_login"
                >
                    {/*begin::Aside*/}
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-5.jpg")})`
                        }}
                    >
                        {isLogin && forgotInfo}
                        {!isLogin && loginInfo}




                    </div>
                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
                        {/*begin::Content header*/}
                        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">Don't have an account yet?</span>
              <Link to="/auth/registration" className="font-weight-bold ml-2" id="kt_login_signup">Sign Up!</Link>
            </div> */}
                        {/*end::Content header*/}

                        {/* begin::Content body */}
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <Switch>
                                <ContentRoute path="/auth/login" component={Login} />
                                <ContentRoute path="/auth/registration" component={Registration} />
                                <ContentRoute
                                    path="/auth/forgot-password"
                                    component={ForgotPassword}
                                />
                                <ContentRoute
                                    path="/auth/set-password"
                                    component={SetPassword}
                                />
                                <Redirect from="/auth" exact={true} to="/auth/login" />
                                <Redirect to="/auth/login" />
                            </Switch>
                        </div>
                        {/*end::Content body*/}

                        {/* begin::Mobile footer */}
                        <div
                            className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                                &copy; 2020 Gentell Fastcare
                            </div>
                            <div className="d-flex order-1 order-sm-2 my-2">
                                <a className="text-dark-75 text-hover-primary ml-4" onClick={handleOpen}>
                                    {intl.formatMessage({ id: 'LOGIN.ABOUT' })}
                                </a>

                                <a className="text-dark-75 text-hover-primary ml-4" target="_blank" rel="noopener noreferrer" href="https://fastcare.freshdesk.com/support/solutions/articles/135948">
                                    {intl.formatMessage({ id: 'LOGIN.HELP' })}
                                </a>
                            </div>
                        </div>
                        {/* end::Mobile footer */}
                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    );
}

export const AuthPage = injectIntl(AuthPageWithIntl)

/*eslint-disable */

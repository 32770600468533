import React from "react";

export function FacilityDetail() {

    return (

        <>
            <h3 className="border-bottom border-dark text-primary mb-0">Facility Information: <span className="h6 text-danger">Abbey Rehab &amp; Nursing Center</span></h3>
            <table className="table table-striped table-sm w-100" id="facilityDetail">
                <tbody>
                    <tr>
                        <td width="20%" className="font-weight-bolder">Orgtype</td>
                        <td width="30%" className="border-right border-dark border-top-0"> Patient Facility</td>
                        <td width="20%" className="font-weight-bolder">Facility type</td>
                        <td width="30%"> Order Only</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Headquarter</td>
                        <td className="border-right border-dark border-top-0">No</td>
                        <td className="font-weight-bolder">Main Office</td>
                        <td> <button className="btn btn-link">Kane Financial Services</button> facility</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">EHR Vendor</td>
                        <td className="border-right border-dark border-top-0">Not connected to EHR</td>
                        <td className="font-weight-bolder">EHR Vendor Id</td>
                        <td> Unassigned</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">GPO</td>
                        <td className="border-right border-dark border-top-0">Not a member of any GPO</td>
                        <td className="font-weight-bolder"> NPI #</td>
                        <td>1255335980</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Date Added</td>
                        <td className="border-right border-dark border-top-0"> </td>
                        <td className="font-weight-bolder">Web Site</td>
                        <td><a href="http://abbeyhealthandrehab.com" target="_blank" rel="noopener noreferrer">Abbey Rehab &amp; Nursing Center</a></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Product Line </td>
                        <td className="border-right border-dark border-top-0">Gentell</td>
                        <td className="font-weight-bolder">Number of Beds</td>
                        <td>152</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Part B Receiving Person</td>
                        <td className="border-right border-dark border-top-0">DON</td>
                        <td className="font-weight-bolder"> Email (Part B)</td>
                        <td> </td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Central Supply Person</td>
                        <td className="border-right border-dark border-top-0"> </td>
                        <td className="font-weight-bolder">Email (Central)</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Address</td>
                        <td className="border-right border-dark border-top-0">7101 Dr Martin Luther King Jr Street N, St. Petersburg, FL 33702</td>
                        <td className="font-weight-bolder">Phone</td>
                        <td>(727) 527-7231</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder"></td>
                        <td className="border-right border-dark border-top-0"> </td>
                        <td className="font-weight-bolder">Fax</td>
                        <td>(727) 522-2486</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Ship To</td>
                        <td className="border-right border-dark border-top-0">7101 Dr Martin Luther King Jr Street N, St. Petersburg, FL 33702</td>
                        <td className="font-weight-bolder">Phone (Ship To)</td>
                        <td>(727) 527-7231  (727) 522-2486</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Bill To</td>
                        <td className="border-right border-dark border-top-0">7101 Dr Martin Luther King Jr Street N, St. Petersburg, FL 33702</td>
                        <td className="font-weight-bolder">Phone (Bill To)</td>
                        <td>(727) 527-7231 (727) 522-2486</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">End of Week Day</td>
                        <td className="border-right border-dark border-top-0">Sunday</td>
                        <td className="font-weight-bolder">Week is from</td>
                        <td>Monday to Sunday</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Time Zone</td>
                        <td className="border-right border-dark border-top-0">Eastern</td>
                        <td className="font-weight-bolder"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Wound Education Specialist</td>
                        <td className="border-right border-dark border-top-0">Bezdek, Deborah</td>
                        <td className="font-weight-bolder">Facility In Use</td>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Company</td>
                        <td className="border-right border-dark border-top-0">Gentell House Accounts</td>
                        <td className="font-weight-bolder">Rep</td>
                        <td>Arnie Andersen</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Address</td>
                        <td className="border-right border-dark border-top-0">2701 Bartram Rd, Bristol, PA 19007</td>
                        <td className="font-weight-bolder">Phone</td>
                        <td >(215) 788-2700</td>
                    </tr>
                    <tr>
                        <td className="font-weight-bolder">Memo</td>
                        <td align="left" colSpan="3"> ahm.dbezdek ; Welcome123</td>
                    </tr>
                </tbody>
            </table>

            <h3 className="border-bottom border-dark text-primary mb-0">Contacts</h3>
            <table className="table table-striped table-sm table-bordered" id="facilityContactDetail">
                <thead>
                    <tr>
                        <th width="15%">Name</th>
                        <th width="15%">Position</th>
                        <th width="30%">Email</th>
                        <th width="15%"> Phone 1</th>
                        <th width="15%">Phone 2</th>
                        <th width="10%">Main</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Bonita Holloman RN</td>
                        <td>DON</td>
                        <td className="text-break">don@abbeyhealthandrehb.com	</td>
                        <td>(727) 527-7231</td>
                        <td></td>
                        <td>Main</td>
                    </tr>
                    <tr>
                        <td>Bonita Holloman RN</td>
                        <td>NHA</td>
                        <td className="text-break ">administrator@abbeyhealthandrehab.com</td>
                        <td>(727) 527-7231</td>
                        <td>(727) 527-7231</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

            <div className="row">
                <div className="col-lg-8 col-md-12 col-sm-12">
                    <h3 className="border-bottom border-dark text-primary mb-0">Facility Contracted Info</h3>
                    <table className="table table-striped table-sm border-0 w-100" id="facilityContractedInfo">
                        <tbody>
                            <tr>
                                <td width="30%" align="left" className="font-weight-bolder">Gentell handles Medicare part B?</td>
                                <td width="20%"> Yes</td>
                                <td width="30%" align="left" className="font-weight-bolder">OUT`s Program</td>
                                <td width="20%">No </td>
                            </tr>
                            <tr>
                                <td align="left" className="font-weight-bolder">Woundcare Capitation Contract</td>
                                <td>No</td>
                                <td align="left" className="font-weight-bolder">Num patients woundcare cap.</td>
                                <td>15</td>
                            </tr>
                            <tr>
                                <td align="left" className="font-weight-bolder">WC cost per patient per day</td>
                                <td>$0.93</td>
                                <td align="left" className="font-weight-bolder">Min order for free shipping</td>
                                <td>$400.0</td>
                            </tr>
                            <tr>
                                <td align="left" className="font-weight-bolder">Discount</td>
                                <td> 10.0%</td>
                                <td align="left" className="font-weight-bolder">Min order for high discount</td>
                                <td>$10000.0</td>
                            </tr>
                            <tr>
                                <td align="left" className="font-weight-bolder">High level discount</td>
                                <td>12.0%</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                    <h3 className="border-bottom border-dark text-primary mb-0">Shipping Info</h3>
                    <table className="table table-striped table-sm border-0 w-100" id="facilityDetail">
                        <tbody>
                            <tr>
                                <td align="left" className="font-weight-bolder">28-Day Schedule</td>
                                <td align="right">No</td>
                            </tr>
                            <tr>
                                <td align="left" className="font-weight-bolder">Ships from this warehouse</td>
                                <td align="right">Bristol</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default FacilityDetail;
import React, { useEffect,  useState } from 'react';
import {
    Button,

    Modal,Dropdown, DropdownButton 
} from "react-bootstrap";
import {
    Card,
    CardBody,

    CardHeader
} from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import * as Common from "../../General/common";
import { ModulesName, UserActions } from "../../General/roleModuleActions";
import PopupModal from "../modal/popupmodal";
import AddEMRCredentail from "./addEditEMR";


var emrModal,addEMR = null;

export default function EMRCrendential(props)  {

   
    const [emrList, setEMRList ]= useState([]);
    const [emrID, setEmrID ]= useState('');
    const[  emr,setEMR]=useState({
      id:null,
      name: '',
      description: '',
      loginId: '',
      password: '',
      isCredentailUpdate:false,
  });
  
  useEffect(() => {
    getAllEMR();
}, []);



  const showAddEMRModel=()=>{
    setEMR({
        id:null,
        name: '',
        description: '',
        userName: '',
        password: ''
    
  
  })
  emrModal.showModal();

  }
  const showEditPopup =async (obj) => {
  
    await setEMR(obj);
    emrModal.showModal();
  };
  const onEMRFormSubmit=()=> {
    emrModal.hideModal();
    //getHeaderValue() ;
     Common.showSuccessAlertAutoClose("EMR Saved successful", "success", "Success!", 3000);
    getAllEMR();
  }


  // handleEdit = () =>{
  //   showEditPopup(t.obj);
  // }
 


  const getAllEMR=() =>{
    Api.getRequestQry(Api.apiUrls.getAllEMR,"?isActive="+2)
      .then((response) => {
        setAllEMRList(response.data.result);
        Common.LoadDataTable("repFirmList");
      })
      .catch(function(error) {
        
      });
  }
  const setAllEMRList = (list) => {
    let rows = [];
    try {
        for (var i = 0; i < list.length; i++) {
            const obj = list[i];
            rows.push(<tr key={i.toString()}>
         
          <td>
            {obj.name}
          </td>
          <td>
            {obj.description}
          </td>
          <td>
            {obj.userName}
          </td>
       
         

          {/* <td>
          <span className="badge badge-warning"> {.props.obj.roles}</span>
          </td> */}
          <td align="center">
            <div className="text-center">

              <DropdownButton
                drop={"down"}
                size="xs"
                variant="primary"
                title={'Actions'}
                id={`dropdown-button-drop-actions`}
                key={'down'}>

                {/* <Dropdown.Item eventKey="1" onClick={() => .editButton(props.obj.userId)} >Edit User </Dropdown.Item> */}
                <Dropdown.Item onClick={()=> showEditPopup(obj) }  eventKey="1" >Edit EMR</Dropdown.Item>
               
              </DropdownButton>
            </div>
          </td>
            </tr>);
        }
      
        setEMRList( rows );
    } catch (error) {
    }
}
 
    return (
      <>
        <Card className="example example-compact">
          <CardHeader title="EMR List" toolbar={  <div className="card-toolbar">
    <div className="example-tools">
      <Button
        variant="primary"
        size="sm"
        onClick={() => showAddEMRModel()}
      >
        Add EMR
      </Button>
    </div>
  </div>} />

          <CardBody>
            <table
              className="table table-striped table-hover table-sm table-bordered"
              id="repFirmList"
            >
              <thead>
                <tr>
                  <th data-priority="1">Name</th>
                  <th>Description</th>
                  <th>User Name</th>
              
                  <th data-priority="2" width="130px">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {emrList}
                </tbody>
            </table>
          </CardBody>
        </Card>
        {
          <PopupModal
            size="lg"
            title="Add EMR"
            module={ModulesName.Module_Setup}
            action={UserActions.Create}
            buttonPress={() => addEMR.onSubmit()}
            ref={(ref) => {
              emrModal = ref;
            }}
          >
            {" "}
            <AddEMRCredentail
              ref={(ref) => {
                addEMR = ref;
              }}
              onSaveAPI={() => onEMRFormSubmit()}
              emr={emr}
            />{" "}
          </PopupModal>
        }
      
    
      </>
    );
  
}

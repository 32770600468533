/*eslint-disable */
import React from "react";
import SignatureCanvas from 'react-signature-canvas';

export default class AOB extends React.Component {

    // eslint-disable-next-line no-use-before-define
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <h4>Facility: <span className="text-primary">{"Abbey Rehab & Nursing Center"}</span></h4>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <h4>Resident: <span className="text-primary">Test resident</span></h4>
                    </div>
                </div>
                <form>
                    <div className="row py-2 bg-light-primary border rounded">
                        <div className="col-sm-6 col-md-4 col-lg-4 mb-1">
                            <b>Insurance Company Name: </b> USA Today
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 mb-1">
                            <b>Insurance ID#: </b> 1234567
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 mb-1">
                            <b>Medicare ID#: </b> 001011100
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 mb-1">
                            <b>Co Insurance Company Name: </b> N/A
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-4 mb-1">
                            <b>Co Insurance ID#: </b> N/A
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <p className="my-4">
                                My check marks adjacent to the following items, along with my signature appearing below, attest that I have received, read, and/or been instructed, in detail, on this information.
                            </p>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                            <ul className="list-unstyled my-0 my-lg-5 border-dark border-md-0 border-right-lg">
                                <li className="mb-2 bg-light w-75 py-2 border rounded">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="clickToSelectAll"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        Click to select all boxes below
                                    </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_rights"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        My rights as a patient/client (cp)
                                    </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_responsibilities"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        My responsibilities as a patient/client (cp)
                                    </label>

                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_financial"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        My financial responsibility (cp)
                                            </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_assignment"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        My assignment release (cc)
                                   </label>
                                </li>
                            </ul>
                        </div>

                        <div className="col-sm-12 col-lg-6">
                            <ul className="list-unstyled my-0 my-lg-5">
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_products"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        Information on Company Products/Services (cp)
                                    </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_phone_nos"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        Important telephone numbers (cp)
                                    </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_warranty"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        Warranty Information (cp)
                                    </label>

                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_privacy"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        Notice of Privacy Practices
                                    </label>
                                </li>
                                <li className="mb-3">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            name="aob_standards"
                                            className="m-1"
                                        />
                                        <span className="mr-3" />
                                        Copy of supplier standards
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <ol className="my-5 ">
                                <li className="mb-2">
                                    Assignment of Medicare, Medicaid, Medicare Supplemental or other insurance benefits to Wound Care Concepts Inc. for medical supplies and/or medication(s) furnished to me by Wound Care Concepts Inc.
                                </li>
                                <li className="mb-2">
                                    Direct billing to Medicare, Medicaid, Medicare Supplemental or other insurer(s).
                                </li>
                                <li className="mb-2">
                                    Release of my medical information to Medicare, Medicaid, Medicare Supplemental or other insurers and their agents and assigns.
                                </li>
                                <li className="mb-2">
                                    Wound Care Concepts Inc. to obtain medical or other information necessary in order to process my claim(s), including determining eligibility and seeking reimbursement for medical supplies and/or medication(s) provided.
                                </li>
                                <li className="mb-2">
                                    Wound Care Concepts Inc. to contact me by telephone or mail regarding my medical supplies and/or medication(s) order.
                                </li>
                            </ol>
                        </div>

                        <div className="col-12">
                            <p>
                                I request that payment of Medicare, Medicaid, Medicare Supplemental or other insurance benefits be made on my behalf to Wound Care Concepts Inc. for any medical supplies and/or medications furnished to me by Wound Care Concepts Inc.. I authorize any holder of medical information about me to release to Wound Care Concepts Inc., my physician(s), caregiver, CMS, its agents and to my primary and/or other medical insurer any information needed to determine or secure eligibility information and/or reimbursement for covered services. I agree to pay all amounts that are not covered by my insurer(s) and for which I am responsible.
                            </p>
                            <label className="checkbox mb-4" htmlFor="aob_pat_signed">
                                <input
                                    type="checkbox"
                                    id="aob_pat_signed"
                                    name="pat_signed"
                                    className="m-1"
                                />
                                <span className="mr-3" />
                                Is the Patient physically and mentally able to sign this form? Check if yes
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="not_pat_reason" id="not_pat_reason">Reason why patient is unable to sign</label>
                                <textarea className="form-control" id="example-textarea" rows="7"></textarea>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="auth_rep_name">Representative Name</label>
                                        <input type="text" id="auth_rep_name" className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="auth_rep_title">Representative Title</label>
                                        <input type="text" id="auth_rep_title" className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="bg-light rounded border mt-6">
                                <div className="shadow bg-white rounded">
                                    <SignatureCanvas
                                        ref={(ref) => { this.sigCanvas = ref }}
                                        penColor='green'
                                        canvasProps={{ width: 550, height: 150, className: 'sigCanvas' }} />
                                </div>
                                <p className="text-center"><small>Signature Above</small></p>
                                <button type="button" onClick={() => this.sigCanvas.clear()} className="btn btn-danger btn-xs ml-3 mb-3">Clear</button>
                            </div>
                        </div>
                    </div>
                    <p className="my-4 text-primary">*If the authorized representative of the patient is signing, please be advised your signature does not make you responsible for the patient’s financial obligation.</p>
                    <div className="row">
                        <div className="col-sm-12 col-md-6">
                            <label className="checkbox">
                                <input
                                    type="checkbox"
                                    name="leftPaperAOB"
                                    className="m-1"
                                />
                                <span className="mr-3" />
                             Left Paper AOB with Facility, check if yes
                        </label>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            Date : <b>10/11/2020</b>
                        </div>
                    </div>
                </form >
            </>
        )
    }

    onFormSave() {
        
    }

}// end of class

/*eslint-enable */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form } from "react-bootstrap";
import Select from 'react-select';
import UrologicalAccessoriesSelection from './urologicalAccessoriesSelection'
import * as Api from "../../General/api";
import * as Common from "../../General/common";
// var productSelection=null;

export default class AddUrological extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            intermittent: props.urological.isIntermittent,
            external: props.urological.external,
            foley: props.urological.isFoley,
            intermittentTypeID: '',
            urological: props.urological,
            orderProducts:props.urological.saleOrderViewModels

        }
        this.productSelection = null;
    }




    changeIntermittent = (e) => {

        if (e.target.checked) {

            // this.setState({intermittent:true}),
            // this.setState({external:false}),
            // this.setState({foley:false})
            this.setState({ intermittent: true, external: false, foley: false })


        }

    }
    changeExternalCatheter = (e) => {
        if (e.target.checked) {
            // this.setState({intermittent:false}),
            // this.setState({external:true}),
            // this.setState({foley:false})2
            this.setState({ intermittent: false, external: true, foley: false })

        }

    }
    changeFoley = (e) => {
        if (e.target.checked) {

            this.setState({ intermittent: false, external: false, foley: true })

        }
    }
    changeIntermittentType = (e) => {
        debugger
        // setIntermittentType(e.value)

    }
    handleSubmit = (event) => {
        //1243 is unstageable check

        // let selectedProducts = this.productSelection.getSelectedProductList();
        const form = event.currentTarget.form;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            let _state = this.state
            _state.formValidated = true;
            this.setState(_state);
        }
        else {

            this.URI_UpsertResidentUrological();


        }
    }

    URI_UpsertResidentUrological() {
        let obj = this.state.urological;
debugger
        obj.isExternalCatheter=this.state.external;
        obj.isFoley=this.state.foley;
        obj.isIntermittent=this.state.intermittent;

        let selectedProducts = this.productSelection.getSelectedProductList();
        debugger
        if (selectedProducts.products.length <= 0) {
            Common.showSuccessAlertAutoClose("Please select atleast one product.", "warning", "Failed!", 3000);
            return;
        }
        obj.saleOrderViewModels = [];
        selectedProducts.products.forEach((value) => {
            let objProduct = {
                "fkProductId": value.id,
                "quantity": value.quantity,
                "sku": value.sku,
                // "createdBy": this.props.user.userID,
                // "modifiedBy": this.props.user.userID,
            }
            obj.saleOrderViewModels.push(objProduct);

        });
        Api.postRequest(Api.URI_UpsertUrologicalSaleOrder, obj).then((res) => {
            debugger
            if (res.data.success) {
                if (res.data.result != null) {


                    Common.showSuccessAlertAutoClose("urological Order Created/Updated Successfully", "success", "Success!", 3000);
                    // this.props.refresh && this.props.refresh();
                    this.props.close();


                    //this.props.close();
                }
                else {
                    Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
                }
            }
            else {
                Common.showSuccessAlertAutoClose(res.data.message, "warning", "Failed!", 3000);
            }
        }).catch((ex) => {

            // // // debugger
            Common.showSuccessAlertAutoClose("some error occured while processing your request", "warning", "Failed!", 3000);
        });
    }
    render() {
        return (
            <>
                <Form >
                    {/* <Form> */}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div>

                                <fieldset className="p-2 mb-2 border rounded">

                                    <legend className="text-primary fw-500">
                                        Information{" "}
                                    </legend>
                                    {/* <div className='d-flex justify-content-left'>
                                <div className="form-group mt-0 mt-sm-2 mt-md-2 mt-xl-2">
                                    <label className="checkbox text-danger">
                                        <input
                                            type="checkbox"
                                            className="m-1 form-control form-control-sm"
                                        />
                                        <span className="mr-3 " />
                                        Reorder
                                    </label>
                                </div>
                            </div> */}
                                    <div className="row">
                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Status">
                                                    Diagnosis Code (ICD10)
                                    </label>
                                                <select className="form-control form-control-sm"
                                                    id="diagnosisCode"
                                                    name="diagnosisCode"
                                                    //  onChange={e =>this.setDiagnosisCodeOptions(e)}
                                                    onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}

                                                    value={Common.replaceNullWithString(this.state.urological.diagnosisCode)}
                                                >

                                                    <option value=""></option>
                                                    <option value="R33.9">Retention of Urine R33.9</option>
                                                    <option value="N39.3">Stress Incontinence N39.3</option>
                                                    <option value="R32">Urinary Incontinence R32</option>
                                                    <option value="N13.9">Obstructive and reflux Uropathy,Unsp N13.9</option>
                                                    <option value="N31.9">Neuromuscular Dysfunction Bladder N31.9</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-sm-7 col-md-6 col-lg-6 col-xl-6">
                                            <div className="form-group">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="isPermanency"
                                                        checked={this.state.urological.isPermanency}
                                                        onChange={(e) =>
                                                            Common.updateInputValueInState(
                                                                this.state.urological,
                                                                e,
                                                                this,
                                                                this.state
                                                            )
                                                        }
                                                        className="m-1"
                                                    />
                                                    <span className="mr-3 " />
                                            Reorder Does patient have permanent urinary incontinence, retention or other condtion?
                                        </label>

                                                <label className="font-size-sm">
                                                    <small> (<b>Note:</b> Permanency is defined as a condition that is expected to last greater than 90 days)</small>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3">
                                            <div className="form-group">
                                                <label className="checkbox">
                                                    <input
                                                        type="checkbox"
                                                        name="isAlatexAllergy"
                                                        checked={this.state.urological.isAlatexAllergy}

                                                        onChange={(e) =>
                                                            Common.updateInputValueInState(
                                                                this.state.urological,
                                                                e,
                                                                this,
                                                                this.state
                                                            )
                                                        }
                                                        className="m-1"
                                                    />
                                                    <span className="mr-3 " />
                                                Does the patient have alatex allergy?
                                        </label>
                                            </div>
                                        </div>

                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className='col-12'>
                            <fieldset className="p-2 mb-2 border rounded">
                                <legend className="text-primary fw-500">
                                    Catheter{" "}
                                </legend>
                                <div className='row'>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4">
                                        <div className="form-group mt-6">
                                            <Form.Check
                                                type="radio"
                                                label="External Catheter"
                                                checked={this.state.external}
                                                onChange={e => this.changeExternalCatheter(e)}
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4">
                                        <div className="form-group mt-6">
                                            <Form.Check
                                                type="radio"
                                                label="Foley (Indwelling)"
                                                onChange={e => this.changeFoley(e)}
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                                checked={this.state.foley}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2 col-xl-4">
                                        <div className="form-group mt-6">
                                            <Form.Check
                                                type="radio"
                                                label="Intermittent"
                                                onChange={e => this.changeIntermittent(e)}
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                                checked={this.state.intermittent}

                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row'>
                                    {this.state.external
                                        &&
                                        <div className="col-sm-6 col-md-2 col-lg-2 col-xl-2">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="Status">
                                                    External Catheter
                                    </label>
                                                {/* <Select hideSelectedOptions={true} options={ExternalCatheter} /> */}
                                                <select className="form-control form-control-sm"
                                                    id="externalCatheter"
                                                    name="externalCatheter"
                                                    onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                    value={Common.replaceNullWithString(this.state.urological.externalCatheter)}
                                                >
                                                    <option value=""></option>
                                                    <option value="Small: 23mm">Small: 23mm</option>
                                                    <option value="Medium: 28mm">Medium: 28mm</option>
                                                    <option value="intermed: 31mm">intermed: 31mm</option>
                                                    <option value="Large: 35mm">Large: 35mm</option>
                                                    <option value="X-Large: 40mm">X-Large: 40mm</option>
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {this.state.foley
                                        &&
                                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                            <fieldset className="p-2 mb-2 border rounded">
                                                <legend className="text-secondary fw-200">
                                                    Foley (Indwelling){" "}
                                                </legend>
                                                <div className="row">
                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="Status">
                                                                Ballon Size
                                                </label>
                                                            {/* <Select hideSelectedOptions={true} options={FoleyBallonSize} /> */}
                                                            <select className="form-control form-control-sm"
                                                                id="ballonSize"
                                                                name="ballonSize"
                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                value={Common.replaceNullWithString(this.state.urological.ballonSize)}
                                                            >
                                                                <option value=""></option>
                                                                <option value="5cc">5cc</option>
                                                                <option value="10cc">10cc</option>
                                                                <option value="30cc">30cc</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="Status">
                                                                Size
                                                </label>
                                                            {/* <Select hideSelectedOptions={true} options={FoleySize} /> */}
                                                            <select className="form-control form-control-sm"
                                                                id="size"
                                                                name="size"
                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                value={Common.replaceNullWithString(this.state.urological.size)}
                                                            >
                                                                <option value=""></option>
                                                                <option value="14Fr">14Fr</option>
                                                                <option value="18Fr">18Fr</option>
                                                                <option value="20Fr">20Fr</option>
                                                                <option value="22Fr">22Fr</option>
                                                                <option value="24Fr">24Fr</option>


                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className='d-flex justify-content-left'>
                                                        <div className="form-group ml-5 mt-0 mt-sm-2 mt-md-2 mt-xl-2">
                                                            <label className="checkbox ">
                                                                <input
                                                                    type="checkbox"
                                                                    name="isSiliconeElastomeric"
                                                                    onChange={(e) =>
                                                                        Common.updateInputValueInState(
                                                                            this.state.urological,
                                                                            e,
                                                                            this,
                                                                            this.state
                                                                        )
                                                                    }
                                                                    className="m-1"
                                                                />
                                                                <span className="mr-3 " />
                                                        Silicone Elastomeric
                                                    </label>
                                                        </div>
                                                        <div className="form-group ml-2 mt-0 mt-sm-2 mt-md-2 mt-xl-2">
                                                            <label className="checkbox ">
                                                                <input
                                                                    type="checkbox"
                                                                    name="isAllSilicone"
                                                                    onChange={(e) =>
                                                                        Common.updateInputValueInState(
                                                                            this.state.urological,
                                                                            e,
                                                                            this,
                                                                            this.state
                                                                        )
                                                                    }
                                                                    className="m-1"
                                                                />
                                                                <span className="mr-3 " />
                                                        All Silicone
                                                    </label>
                                                        </div>
                                                        <div className="form-group ml-2 mt-0 mt-sm-2 mt-md-2 mt-xl-2">
                                                            <label className="checkbox ">
                                                                <input
                                                                    type="checkbox"
                                                                    name="isSiliver"
                                                                    onChange={(e) =>
                                                                        Common.updateInputValueInState(
                                                                            this.state.urological,
                                                                            e,
                                                                            this,
                                                                            this.state
                                                                        )
                                                                    }
                                                                    className="m-1"
                                                                />
                                                                <span className="mr-3 " />
                                                        Siliver
                                                    </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </div>
                                    }
                                    {this.state.intermittent
                                        &&
                                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                            <fieldset className="p-2 mb-2 border rounded">
                                                <legend className="text-secondary fw-200">
                                                    Intermittent{" "}
                                                </legend>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="Status">
                                                                Type
                                                    </label>
                                                            <select className="form-control form-control-sm"
                                                                id="intermittentType"
                                                                name="intermittentType"
                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                value={Common.replaceNullWithString(this.state.urological.intermittentType)}
                                                            >
                                                                <option value=""></option>
                                                                <option value="Straight">Straight</option>
                                                                <option value="Closed System">Closed System</option>
                                                                <option value="Red Rubber">Red Rubber</option>


                                                            </select>

                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="Status">
                                                                Size
                                                    </label>
                                                            {/* <Select hideSelectedOptions={true} options={FoleySize} /> */}
                                                            <select className="form-control form-control-sm"
                                                                id="intermittentSize"
                                                                name="intermittentSize"
                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                value={Common.replaceNullWithString(this.state.urological.intermittentSize)}
                                                            >
                                                                <option value=""></option>
                                                                <option value="14Fr">14Fr</option>
                                                                <option value="18Fr">18Fr</option>
                                                                <option value="20Fr">20Fr</option>
                                                                <option value="22Fr">22Fr</option>
                                                                <option value="24Fr">24Fr</option>


                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-6 col-md-4 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="Status">
                                                                Length
                                                    </label>
                                                            <select className="form-control form-control-sm"
                                                                id="intermittentLength"
                                                                name="intermittentLength"
                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                value={Common.replaceNullWithString(this.state.urological.intermittentLength)}
                                                            >
                                                                <option value=""></option>
                                                                <option value="14Fr">14Fr</option>
                                                                <option value="18Fr">18Fr</option>
                                                                <option value="20Fr">20Fr</option>
                                                                <option value="22Fr">22Fr</option>
                                                                <option value="24Fr">24Fr</option>


                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="frequency">
                                                                Frequency
                                                </label>
                                                            <input
                                                                type="number"
                                                                name="frequency"

                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                defaultValue={Common.replaceNullWithString(this.state.urological.frequency)}
                                                                
                                                                className="form-control form-control-sm"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="quantity">
                                                                Quantitiy
                                                </label>
                                                            <input
                                                                type="number"
                                                                name="quantitiy"

                                                                onChange={e => Common.updateInputValueInState(this.state.urological, e, this, this.state)}
                                                                defaultValue={Common.replaceNullWithString(this.state.urological.quantitiy)}
                                                                className="form-control form-control-sm"
                                                            />
                                                        </div>
                                                    </div>

                                                    {/* {intermittentTypeID=="Coude"&&
                                           <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                           <div className="form-group">
                                               <label className="form-label" htmlFor="quantity">
                                               Coude Note
                                               </label>
                                               <input
                                                   type="text"
                                                   defaultValue={""}
                                                   className="form-control form-control-sm"
                                               />
                                           </div>
                                       </div>} */}

                                                </div>
                                            </fieldset>
                                        </div>
                                    }
                                </div>


                            </fieldset>
                        </div>

                        <div className='col-md-12'>
                            <fieldset className="p-2 mb-2 border rounded">
                                <legend className="text-primary fw-500">
                                    Accessories{" "}
                                </legend>
                                <UrologicalAccessoriesSelection ref={(ref) => this.productSelection = ref} orderProducts={this.state.orderProducts}  />
                                <div className='row'>
                                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="quantity">
                                                Remaining Quantitiy
                                                </label>
                                            <input
                                                type="quantity"
                                                defaultValue={""}
                                                className="form-control form-control-sm"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <legend className="text-primary fw-500">
                            Accessories{" "}
                        </legend>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Status">
                                        Drainage Bags
                                    </label>
                                    <Select isMult={true} hideSelectedOptions={true} options={basicAccessories} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Status">
                                        Trays
                                    </label>
                                    <Select isMult={true} hideSelectedOptions={true} options={otherAccessories} />
                                </div>
                            </div>

                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="Status">
                                        Others
                                    </label>
                                    <Select isMult={true} hideSelectedOptions={true} options={otherAccessories} />
                                </div>
                            </div>
                        </div> */}
                            </fieldset>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm" onClick={e => this.handleSubmit(e)}>Process</Button>
                        {/* <Button type="button" variant="dark" size="sm" onClick={() => this.props.close()}>Back</Button> */}
                    </div>
                </Form >
            </>

        )
    }
}
import { FormControlLabel } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';

// import $ from 'jquery';
import Switch from '@material-ui/core/Switch';
//import { useState } from 'react';
import { Alert, Badge, Button, ButtonToolbar, Dropdown, DropdownButton, Modal, Table } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../_metronic/_partials/controls/Card";
import * as Api from "../../General/api";
import CheckPermission from "../../General/CheckPermission";
import * as Common from "../../General/common";
import RBAC, { ModulesName, UserActions } from "../../General/roleModuleActions";
import Select from 'react-select';



var addFacilityModal = null;

//#endregion
//#region Physician modal
//var physicianModal = null;
//#endregion
export function FacilityWoundAndProductSpecialist() {

    const [facilityList, setfacilityList] = useState([]);
    const [newClinicianDetail, setNewClinicianDetail] = useState([]);

    const [facilityClinicians, setFacilityClinicians] = useState([]);
    const [clinicians, setClinicians] = useState([]);

    const [clinicianId, setClinicianId] = useState(0);
    const [newClinicianId, setNewClinicianId] = useState(0);


    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [roleID, setRoleID] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showResident, setShowResident] = useState(false);
    const [operatorsPurchasingGroupID, setOperatorsPurchasingGroupID] = useState('');
    const [ownershipHeadquartersID, setOwnershipHeadquartersID] = useState('');


    useEffect(() => {

        getAllClinicians();

    }, []);

    const getAllClinicians = async () => {
        var clinicain = await Api.getRequestById(Api.Uri_GetUserByRoleID, Common.LookupsFK.Fk_RoleClinicianID);
        const clinicainData = clinicain.data.result
        let clinicainOption = clinicainData.map(v => (
            <option key={v.key} value={v.key}>{v.value}</option>
        ));
        setClinicians(clinicainOption)
    }

    useEffect(() => {

        if (clinicianId > 0) {
            getAllFacilitiesClinicians();
        }


    }, [clinicianId]);

    const getAllFacilitiesClinicians = async () => {
        var facilityClinicain = await Api.getRequestQry(Api.Uri_getClinicianFacilities, "?clinicianId=" + clinicianId);
        const clinicainData = facilityClinicain.data.result
        setFacilityClinicians(clinicainData)
    }
    // const getAllFacility = useCallback(() => {
    //     Api.getRequest(Api.Uri_GetFacility)
    //         .then((response) => {
    //             setfacilityList(response.data.result);
    //             debugger
    //             if (response.data.result.length > 0) {

    //                 let facilityList = [];
    //                 response.data.result
    //                     .map((v) => (
    //                         facilityList.push({ value: v.id, label: v.facilityName })
    //                         // <option key={v.key} value={v.key}>
    //                         //   {v.value}
    //                         // </option>
    //                     ));

    //                 setSelectFacilityOptions(facilityList)
    //             }


    //         })

    //         .catch(function (error) {
    //             Common.LogError(error, "Facility", "getAllFacility");

    //         }).then(() => {
    //             Common.LoadDataTable("facilityList");
    //         });
    // })

    const handleNewClinician = () => {
        if (newClinicianId < 1) {
            Common.showSuccessAlert("Please select new clinician", "warning", "Warning!", 3000);
            return false

        } else if (startDate == '' || startDate == null) {
            Common.showSuccessAlert("Please select start date", "warning", "Warning!", 3000);
            return false
        } else if (endDate == '' || endDate == null) {
            Common.showSuccessAlert("Please select end date", "warning", "Warning!", 3000);
            return false
        }
        var clinician = {};
        clinician.fkClinicianId = newClinicianId;
        clinician.startDate = startDate;
        clinician.endDate = endDate;

        // setNewClinicianDetail(clinician);
        var selectedList = facilityClinicians.filter(x=>x.isProcess==true);
        debugger
        if(selectedList.length>0){
            clinician.facilityClinicianModels = selectedList;
            Api.postRequest(Api.apiUrls.upsertFacilityClinician, 
                clinician).then((res) => {
                if (res.data.success) {
                
                    getAllFacilitiesClinicians()
                    Common.showSuccessAlert("New clinician detail saved successfully", "success", "Success!", 3000);
                   
                } else {
                    Common.showSuccessAlert(
                        res.data.message,
                        "error",
                        "Error!",
                        3000
                    );
                }
            });

        }else{
            Common.showSuccessAlert("Please facilities to process", "warning", "Warning!", 3000);
            
        }
       
    };

    const handleRemove =(isRemove,selectedFacility)=>{

        const newList = facilityClinicians. map((item) => {
            if (item. id === selectedFacility.id) {
                item.isRemoved = isRemove
            }
            return item
        })

        setFacilityClinicians(newList);
    }
    const handlePermanent =(isPermanent,selectedFacility)=>{

        const newList = facilityClinicians. map((item) => {
            if (item. id === selectedFacility.id) {
                item.isPermanent = isPermanent
            }
            return item
        })

        setFacilityClinicians(newList);
    }

    const handleProcess =(isProcess,selectedFacility)=>{
debugger
        const newList = facilityClinicians. map((item) => {
            if (item. id === selectedFacility.id) {
                item.isProcess = isProcess
            }
            return item
        })

        setFacilityClinicians(newList);
    }

    return (
        <>

            {!isLoading && (
                <Card className="example example-compact">
                    <CardHeader title='Facility Woundcare Specialist' />
                    <CardBody>
                        <div className="row">

                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="ThisFacilityBelongsTo">

                                        Wound And Product Specialist
                                    </label>
                                    <select
                                        className="form-control form-control-sm"
                                        name="fkChainId"
                                        value={clinicianId}
                                        onChange={e => setClinicianId(e.target.value)}

                                    >
                                        <option> </option>
                                        {clinicians}
                                    </select>
                                </div>
                            </div>
                            {/* <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="ThisFacilityBelongsTo">
                                        Facility

                                    </label>
                                    <Select isMulti='true'
                                        value={selectedFacility}
                                        hideSelectedOptions={true}
                                        onChange={handleSelectedFacility}
                                        options={selectFacilityOptions} />

                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="ThisFacilityBelongsTo">

                                        New Wound And Product Specialist
                                    </label>
                                    <select
                                        className="form-control form-control-sm"
                                        name="newClinicianId"
                                        value={newClinicianId}
                                        onChange={e => setNewClinicianId(e.target.value)}

                                    >
                                        <option> </option>
                                        {clinicians}
                                    </select>
                                </div>
                            </div>

                            <div className="col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="SSN">Start Date</label>
                                    <input type="date" id="Title"
                                        name="startDate"
                                        value={startDate}
                                        onChange={e => setStartDate(e.target.value)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>
                            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-2">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="LastName">End Date</label>
                                    <input type="date" id="LastName"
                                        name="endDate"
                                        value={endDate}
                                        onChange={e => setEndDate(e.target.value)}
                                        className="form-control form-control-sm" />
                                </div>
                            </div>


                        </div>
                        {/* <div className="d-flex justify-content-end py-3">
                            <Button variant="primary" className="mr-5 px-10" size="sm"
                                >Exit</Button>
                        </div> */}
                        <div className="d-flex justify-content-end py-3">
                            <Button variant="success" className="mr-5 px-10" size="sm"
                            onClick={handleNewClinician} >Save</Button>
                        </div>
                        <Table responsive striped hover bordered size='sm' className='w-100'>
                            <thead>

                                <tr>
                                    <th>Sr #</th>
                                    <th>Facility Name</th>
                                    <th>Process</th>
                                    <th>Remove</th>
                                    <th>Permanent</th>

                                </tr>
                            </thead>
                            <tbody>
                                {facilityClinicians?.map((fac, i) => {
                                    debugger

                                    return (
                                        <tr key={i.toString()}>
                                            <td>{i + 1}</td>
                                            <td>{fac.facilityName}</td>
                                            <td>   <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="m-1"
                                                    id={"Process"+i.toString()}
                                                    name={"Process"+i.toString()}
                                                    onChange={e=>handleProcess(e.target.checked,fac)}
                                                />
                                                <span className="mr-3" />
                                                Process
                                            </label></td>
                                            <td>   <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="m-1"
                                                    id={"Remove"+i.toString()}
                                                    name={"Remove"+i.toString()}
                                                    onChange={e=>handleRemove(e.target.checked,fac)}
                                                />
                                                <span className="mr-3" />
                                                Remove
                                            </label></td>
                                            <td>   <label className="checkbox">
                                                <input
                                                    type="checkbox"
                                                    className="m-1"
                                                    id={"Temporary"+i.toString()}
                                                    name={"Temporary"+i.toString()}
                                                    onChange={e=>handlePermanent(e.target.checked,fac)}
                                                />
                                                <span className="mr-3" />
                                                Permanent
                                            </label></td>

                                        </tr>
                                    );

                                })}



                            </tbody>
                        </Table>

                        
                    </CardBody>
                </Card>

            )}



        </>
    );
}

export default FacilityWoundAndProductSpecialist;
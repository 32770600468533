/*eslint-disable */

import React from "react";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Dropdown, DropdownButton } from "react-bootstrap";
import { Card, CardBody, CardFooter, CardHeader } from "../../../../_metronic/_partials/controls/Card";
import Addconsultantsmodal from './addconsultantsmodal'
import { Animated } from "react-animated-css";
import $ from 'jquery';
import * as Common from '../../../General/common';
import * as Api from "../../../General/api";

export default function Consultantslistmodal(props) {

    const { user } = useSelector((state) => state.auth);

    //#region  add Consultant
    const [showModalAddConsultant, setShowAddConsultant] = useState(false);

    const handleCloseAddConsultant = () => setShowAddConsultant(false);
    const handleShowAddConsultant = () => setShowAddConsultant(true);

    const handleAddConsultant = () => {
        //setShowAddConsultant(!showModalAddConsultant)
        AddconsultantsmodalRef.onSaveButtonClick(handleCloseAddConsultant);
    };

    var AddconsultantsmodalRef = null
    //#endregion

    //#region loop to  print the rep firm list
    const [consultantList, setconsultantList] = useState([]);
    const [consultantListUI, setconsultantListUI] = useState([]);

    useEffect(() => {
        GetConsultantList();
    });

    const GetConsultantList = () => {
        Api.getRequestQry(Api.URI_GetAllFacilityConsultantByFacilityID, "?ID=" + props.FacilityID).then((res) => {
            if (res.data.statusCode == 200) {
                if (res.data.result != null) {
                    if (res.data.result.length > 0) {
                        setconsultantList(res.data.result);
                        _setConsultantList(res.data.result);
                    }
                }
            }

            Common.LoadDataTable("consultantsList")
        }).catch((ex) => {
        });
    }

    const _setConsultantList = (list) => {
        let rows = [];
        for (var i = 0; i < list.length; i++) {
            const obj = list[i]
            rows.push(<tr key={i.toString()}>
                <td>{obj.name}</td>
                <td>{obj.company != null ? obj.company : "N/A"}</td>
                <td>{obj.email != null ? obj.email : "N/A"}</td>
                <td> {obj.mobile != null ? obj.mobile : "N/A"}</td>
                <td>{obj.fax != null ? obj.fax : "N/A"}</td>
                <td align="center">
                    <div className="text-center">

                        <DropdownButton
                            drop={"down"}
                            size="xs"
                            variant="primary"
                            title={'Actions'}
                            id={`dropdown-button-drop-actions`}
                            key={'down'}>

                            <Dropdown.Item eventKey="1" onClick={handleShowAddConsultant}>Edit Consultant</Dropdown.Item>
                            <Dropdown.Item eventKey="3">Delete</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </td>
            </tr>);
        }
        setconsultantListUI(rows);
    }


    //#endregion

    const [ShowAdvancedSearch, showAdvanceSearchSectionState] = useState(ShowAdvancedSearch ? true : false);
    const showAdvanceSearchSection = () => {
        showAdvanceSearchSectionState(!ShowAdvancedSearch);
    };

    //initialize datatable


    return (
        <>
            {showModalAddConsultant && <Animated animationIn="fadeInLeft" animationInDuration={1500}>
                <Card className="">
                    <CardHeader title='New Consultant' />
                    <CardBody>

                        <Addconsultantsmodal
                            ref={(ref) => AddconsultantsmodalRef = ref}
                            userID={user.userID}
                            facilityID={props.FacilityID}
                        />

                    </CardBody>
                    <CardFooter className="d-flex justify-content-end py-3">
                        <Button variant="success" className="mr-5 px-10" size="sm" onClick={handleAddConsultant}>Save</Button>
                        <Button variant="danger" size="sm" onClick={handleCloseAddConsultant}>Cancel</Button>
                    </CardFooter>
                </Card>
            </Animated>}
            <div className="alert border-primary bg-transparent text-primary fade show" role="alert">
                <div className="d-flex align-items-center">
                    <div className="alert-icon">
                        <i className="fas fa-info-circle mr-4 text-primary"></i>
                    </div>
                    <div className="flex-1">
                        <span className="h5 m-0 fw-700">Use buttons to do more actions </span>
                    </div>
                    <Button variant="primary" size="sm" onClick={handleShowAddConsultant}>New Consultant</Button>
                </div>
            </div>

            <table className="table table-striped table-hover table-sm table-bordered" id="consultantsList">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Comapny</th>
                        <th>Email</th>
                        <th>Telephone#</th>
                        <th>Fax</th>
                        <th width="130px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {consultantListUI}
                </tbody>
            </table>

            {/* Add/edit Consultant modal */}
            {/* <Modal size="xl"
                aria-labelledby="addConsultantModal"
                centered scrollable backdrop="static"
                keyboard={false} show={showModalAddConsultant} onHide={handleCloseAddConsultant}>
                <Modal.Header className="py-3" closeButton>
                    <Modal.Title>Add Consultant</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Addconsultantsmodal />
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <Button variant="secondary" size="sm" onClick={handleCloseAddConsultant}>
                        Close
                    </Button>
                    <Button variant="primary" size="sm" onClick={() => this.onSave(), handleCloseAddConsultant}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </>
    );

}
// const mapStateToProps = (state) => {
//     const user = state.auth.user;
//     return { user }
//   };

//   export default connect(mapStateToProps)(Consultantslistmodal);

/*eslint-disable */